import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class HmCatalogServiceImpl {

    apiUrl = `${config.baseUrl}api/hm-catalogs`;

    getCatalogList() {
        return axios.get(`${this.apiUrl}/my-catalogs`, get_config());
    }

    getCatalog(catalogId: string) {
        return axios.get(`${this.apiUrl}/missing-products/${catalogId}`, get_config());
    }

}