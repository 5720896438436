import { useEffect, useState } from "react";

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import PageHeader from "../../shared/pagesHeader";
import AccountForm from "../../common/customers/AccountForm";
import { AppDispatch } from "../../../redux/Store";
import { ficService } from "../../../service";
import { getCustomerAction } from "../../../redux/actions/ficActions";
import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../redux/actions/loaderActions";

const HmCreateCustomerAccount = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const ficState = useSelector((state: any) => state.ficReducer);

  const params: any = useParams();

  useEffect(() => {
    console.info("HM Customer Arrived");
    dispatch(getCustomerAction(params.customer_id));
  }, [dispatch, params.customer_id]);

  const handleSubmit = (value: any) => {
    setErrorMessage("");
    dispatch(startLoadingAction());
  
    let account = {
      company: value.company,
      hm_customer_id: ficState.currentCustomer.id,
      customer_no: value.customer_no,
      country: value.country,
      customer_dseq: value.customer_dseq,
      customer_vat_no: value.customer_vat_no,
      customer_eori_no: value.customer_eori_no,
      customer_ioss_no: value.customer_ioss_no,
      customer_tax_id: value.customer_tax_id,
      customer_arn: value.customer_arn,
      customer_ddp: value.customer_ddp === "on",
      enable_fic: value.enable_fic === "on",
      memo: value.memo,
    };

    ficService
      .createOrUpdateCustomerAccount(account)
      .then((response) => {
        dispatch(stopLoadingAction());
        navigate(`/customer/${params.customer_id}`);
      })
      .catch((response) => {
        dispatch(stopLoadingAction());
        setErrorMessage(response.message);
      });
  };

  return (
    <>
      <div className="fic-admin container">
        <PageHeader
          heading={"Fic - Create Account"}
          backUrl={`/customer/${params.customer_id}`}
        />

        <AccountForm
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          customer={ficState.currentCustomer}
        />
      </div>
    </>
  );
};

export default HmCreateCustomerAccount;
