
const initialState = {
    recentTrackingList: [],
    loaded: false,
}

const shipingTrackingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "GET_RECENT_SHIPMENT_TRACKING":
            return {
                ...state,
                recentTrackingList: action.payload.data,
                loaded: true
            };
        case "RESET_SHIPMENT_TRACKING":
            return {
                ...state,
                recentTrackingList: initialState.recentTrackingList,
                loaded: initialState.loaded
            };            
        default:
            return state;
    }

}

export default shipingTrackingReducer;