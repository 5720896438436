import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../shared/pagesHeader";
import DataTable from "react-data-table-component";
import { getCatalogsMissingProductAction } from "../../../redux/actions/hmCatalogsActions";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/Store";

const customStyles = {
    headRow: {
        style: {
            border: "none",
            fontSize: "15px"
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: "rgb(230, 244, 244)",
            borderBottomColor: "#FFFFFF",
            borderRadius: "10px",
            outline: "1px solid #FFFFFF",
        },
    },
};


const MissingCatalogProducts = () => {
    const dispatch: AppDispatch = useDispatch();
    const hmCatalogsState = useSelector((state: any) => state.hmCatalogsReducer);
    const params: any = useParams();

    useEffect(() => {
        console.info("Missing Catatlog Product Arrived");
        if (hmCatalogsState.catalog_missing_data.id !== params.catalog_id) {
            dispatch(getCatalogsMissingProductAction(params.catalog_id))
        }
    }, [hmCatalogsState, dispatch, params.catalog_id])

    const columns = [
        {
            name: "Brand",
            selector: (row: any) => row.brand,
            sortable: true,
        },
        {
            name: "display_name",
            selector: (row: any) => row.display_name,
            sortable: true,
        },
        {
            name: "quantity",
            selector: (row: any) => row.quantity,
            sortable: true,
        },
        {
            name: "sku",
            selector: (row: any) => row.sku,
            sortable: true,
        },
    ]

    return (
        <>
            <div className='container'>
                <PageHeader
                    heading={"Missing Catalog Product"}
                    backUrl={"/catalogs"}
                />

                <div className="my_catalogs">
                    <div className="catalogs_missing_table">
                        <DataTable
                            columns={columns}
                            data={hmCatalogsState.catalog_missing_data.products}
                            // selectableRows
                            pagination
                            highlightOnHover
                            pointerOnHover
                            customStyles={customStyles}
                        // actions={actionsMemo}
                        // onRowClicked={(row: any, event) => rowClicked(row, event)}
                        // onSelectedRowsChange={filteredItems}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MissingCatalogProducts;