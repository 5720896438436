// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    cursor: pointer;
}

.cus-nav .cus-nav-link {
    border-radius: 30px;
    margin-left: 9px;
    background: #efefef;
    padding: 8px 30px;
    margin: 0;
}

.MuiPaper-root {
    padding: 2ex;
}

.text-left {
    text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/common.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".logo {\n    cursor: pointer;\n}\n\n.cus-nav .cus-nav-link {\n    border-radius: 30px;\n    margin-left: 9px;\n    background: #efefef;\n    padding: 8px 30px;\n    margin: 0;\n}\n\n.MuiPaper-root {\n    padding: 2ex;\n}\n\n.text-left {\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
