
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { AppDispatch } from "../../../../redux/Store";
import PageHeader from "../../../shared/pagesHeader";
import { WayfairAdminMenu } from "../../../common/admin/wayfair/WayfairAdminMenu"

import { getFabricXrefAction } from "../../../../redux/actions/wayfairActions";

const WayfairFabricMappings = (props: any) => {

    const [validated, setValidated] = useState(false);
    const [fabricMappingSearchFormData, setFabricMappingSearchFormData] = useState({ supplierpartnumber: '' } as any);

    const [supplierPartNumber, setSupplierPartNumber] = useState(null as any);

    const [errorMessage, setErrorMessage] = useState(null as any);


    const dispatch: AppDispatch = useDispatch();


    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);
    const loaderState = useSelector((state: any) => state.LoaderReducer);


    useEffect(() => {
        console.info("Wayfair Fabric Mappings Arrived");

        if (supplierPartNumber !== null && !loaderState.loading) {

            if (wayfairState.currentFabricXref.supplierpartnumber === supplierPartNumber) {
                const supplierpartnumberEnc = encodeURIComponent(supplierPartNumber);
                navigate(`/admin/wayfair/fabric-xref/${supplierpartnumberEnc}`)
            } else {
                setErrorMessage(`Mapping ${supplierPartNumber} not found!`)
            }

        }
    }, [dispatch, navigate, wayfairState.currentFabricXref, supplierPartNumber, loaderState]);


    const onCreateMappingClicked = () => (event: any) => {
        navigate(`/admin/wayfair/create-fabric-xref`);
    };

    const handleFormFieldUpdate = (event: any) => {
        let formData = { ...fabricMappingSearchFormData }
        formData[event.target.name] = event.target.value
        setFabricMappingSearchFormData(formData);
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        let isValid = true;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            isValid = false;
        }

        setValidated(true);

        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries());

        if (isValid) {
            event.preventDefault();
            event.stopPropagation();
            setErrorMessage(null);
            setSupplierPartNumber(formDataObj.supplierpartnumber);
            dispatch(getFabricXrefAction(formDataObj.supplierpartnumber))
        }
    };


    return (
        <>
            <div className="wayfair-admin container">

                <WayfairAdminMenu tab="fabric-mappings" />

                <PageHeader
                    heading={"Fabric Mappings"}
                    backUrl={"/admin"}
                    buttonIcon={"fa-plus"}
                    buttonName={"Create Mapping"}
                    handleClick={onCreateMappingClicked()}
                />



                <div className="form-align">

                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-6" controlId="supplierpartnumber">
                                    <Form.Label>Supplier Part Number</Form.Label>
                                    <Form.Control type="text" required placeholder="Enter supplier part number" name="supplierpartnumber" value={fabricMappingSearchFormData.supplierpartnumber} onChange={handleFormFieldUpdate} />
                                    <Form.Text className="text-muted">
                                    </Form.Text>
                                </Form.Group>
                            </div>
                        </div>

                        {errorMessage && (
                            <div className="row">

                                <div className="col-md-12">
                                    <Alert variant={"danger"}>
                                        {errorMessage}
                                    </Alert>
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-md-3" style={{ "marginTop": "2ex" }}>
                                <Button variant="primary" type="submit" className="mb-3">
                                    Search
                                </Button>
                            </div>
                        </div>
                    </Form >


                </div>

            </div>



        </>)
};

export default WayfairFabricMappings;