import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getShopifyStoreAction, updateShopifyStoreAction, getUserListAction } from '../../../redux/actions/shopifyStoreActions';
import { ToastContainer, toast } from "react-toastify";
import { Audio } from "react-loader-spinner";
import ShopifyStoreForm from '../../shared/shopifyStoreForm';
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from '../../../redux/Store';
import { isSuperUser } from "../../../utils/Helper";

const EditShopifyStore = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const shopifyStoreState = useSelector((state: any) => state.shopifyStoreReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    const [shopName, setShopName] = useState("");
    const [accountId, setAccountID] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [skuPrefix, setSkuPrefix] = useState("");
    const [holdBack, setHoldBack] = useState(0);
    const [saveInventoryFeed, setSaveInventoryFeed] = useState(false);
    const [brands, setBrands] = useState<string[]>([]);
    const [ownerUserId, setOwnerUserId] = useState("");

    const success = (msg: string) => toast.success(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
    });

    useEffect(() => {
        if (id) {
            dispatch(getShopifyStoreAction(id));
            dispatch(getUserListAction());
        }
    }, [id, dispatch]);

    useEffect(() => {
        console.info("Store Detail Arrived")
        if (shopifyStoreState.storeDetails) {

            setShopName(shopifyStoreState.storeDetails.shopify_shop_name);
            setAccountID(shopifyStoreState.storeDetails.account_id);
            setApiKey(shopifyStoreState.storeDetails.shopify_api_key);
            setApiSecret(shopifyStoreState.storeDetails.shopify_api_secret);
            setAccessToken(shopifyStoreState.storeDetails.shopify_access_token);
            setSkuPrefix(shopifyStoreState.storeDetails.sku_prefix);
            setHoldBack(shopifyStoreState.storeDetails.hold_back);
            setSaveInventoryFeed(shopifyStoreState.storeDetails.save_inventory_feed);
            setBrands(shopifyStoreState.storeDetails.brands);
            setOwnerUserId(shopifyStoreState.storeDetails.owner_user_id);}
    }, [shopifyStoreState.storeDetails, id, dispatch]);

    const onSuccess = () => {
        success(shopifyStoreState.mesg);
        navigate(`/shopify-store/${id}?type=readonly`);
    };

    const updateClickHandle = () => {
        const data = {
            config: {
                id: id,
                shop_name: shopName,
                account_id: accountId,
                api_key: apiKey,
                api_secret: apiSecret,
                access_token: accessToken,
                sku_prefix: skuPrefix,
                hold_back: holdBack,
                save_inventory_feed: saveInventoryFeed,
                brands: brands,
                owner_user_id: ownerUserId
            }
        };

        dispatch(updateShopifyStoreAction(id!, data, onSuccess));
    };

    return (
        <>
            <section className="user-sec py-2">
                <div className="container mt-15">
                    <div className="inner-sec">
                        <PageHeader
                            heading={"Edit Shopify Store"}
                            backUrl={"/shopify-store"}
                            buttonName={"Update"}
                            handleClick={updateClickHandle}
                        />

                        {shopifyStoreState.storeDetails ? (<ShopifyStoreForm
                            editClick={false}
                            setShopName={setShopName}
                            shopName={shopName}
                            setAccountId={setAccountID}
                            accountId={accountId}
                            setApiKey={setApiKey}
                            apiKey={apiKey}
                            setApiSecret={setApiSecret}
                            apiSecret={apiSecret}
                            setAccessToken={setAccessToken}
                            accessToken={accessToken}
                            setSkuPrefix={setSkuPrefix}
                            skuPrefix={skuPrefix}
                            setHoldBack={setHoldBack}
                            holdBack={holdBack}
                            setSaveInventoryFeed={setSaveInventoryFeed}
                            saveInventoryFeed={saveInventoryFeed}
                            setBrands={setBrands}
                            brands={brands}
                            setOwnerUserId={setOwnerUserId}
                            ownerUserId={ownerUserId}
                            isAdmin={isSuperUser()}
                            user={shopifyStoreState.user} // Ensure this is correct
                        />) : null}
                    </div>
                </div>
            </section>

            <ToastContainer autoClose={3000} />

            {loading && (
                <div className="spinner">
                    <Audio
                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            )}
        </>
    );
};

export default EditShopifyStore;
