
const initialState = {
  isLoggedIn: false,
  token: "",
  id: "",
  errors: null,
  csrf_token: null,
}

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem('token', action.payload.response.user.authentication_token)
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.response.user.authentication_token,
        id: action.payload.response.user.id
      };
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        token: ""
      };
    case "LOGOUT_ERROR":
      return {
        ...state,
        errors: action.payload.response.errors
      };
    case "CLEAR_AUTH_ERROR":
      return {
        ...state,
        errors: null
      }
    case "SET_CSRF_TOKEN":
      return {
        ...state,
        csrf_token: action.payload
      }
    default:
      return state;
  }
};

export default authReducer;

