
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class ConfigurationServiceImpl {

    apiUrl = `${config.baseUrl}api/settings`;

    getSettings() {
        return axios.get(this.apiUrl, get_config());
    }

    updateSettings(settings: any) {
        return axios.post(this.apiUrl, {"settings": settings}, get_config());
    }

}