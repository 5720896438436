import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { shopifyStoreService } from "../../service";

export const getAllShopifyStoresAction = (loader: boolean) => (dispatch: any) => {
    if (loader) {
        dispatch(startLoadingAction());
    }
    shopifyStoreService.getStoreList().then(response => {
        if (loader) {
            dispatch(stopLoadingAction());
        }
        dispatch({
            type: "GET_ALL_SHOPIFY_STORES_DATA",
            payload: response.data
        });
    }).catch(err => {
        if (loader) {
            dispatch(stopLoadingAction());
        }
        dispatch({
            type: "GET_ALL_SHOPIFY_STORES_DATA_ERROR",
            status: err.response.status
        })
    })
}

export const getShopifyStoreAction = (storeId: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    shopifyStoreService.getStore(storeId).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_SHOPIFY_STORE_DATA",
            payload: response.data
        });
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_SHOPIFY_STORE_DATA_ERROR",
            status: err.response.status
        });
    });
};

export const deleteShopifyStoreAction = (storeId: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    shopifyStoreService.deleteStore(storeId).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "DELETE_SHOPIFY_STORE",
            payload: storeId
        });
        dispatch(getAllShopifyStoresAction(false));
    }).catch(err => {
        console.log(err);
        console.error(err);
        dispatch({
            type: "DELETE_SHOPIFY_STORE_ERROR",
            status: err.response.status
        });
    });
};

export const shopifyStoreSetDeleteStatusAction = (deleteStatus: boolean) => (dispatch: any) => {
    dispatch({
        type: "SET_SHOPIFY_STORE_DELETE_STATUS",
        payload: deleteStatus
    });
}

export const createShopifyStoreAction = (data: any, onSuccess: (arg0: string) => any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    shopifyStoreService.createStore(data).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "CREATE_SHOPIFY_STORE",
            payload: data.store
        });

        onSuccess(response.data.store_id);

        dispatch(getAllShopifyStoresAction(false));
    }).catch(err => {
        console.error(err);
        dispatch(stopLoadingAction());
        dispatch({
            type: "CREATE_SHOPIFY_STORE_ERRORS",
            payload: err.response
        });
    });
};

export const shopifyStoreSetCreateStatusAction = (createStatus: boolean) => (dispatch: any) => {
    dispatch({
        type: "SET_SHOPIFY_STORE_CREATE_STATUS",
        payload: createStatus
    });
}

export const shopifyStoreSetCreateStatusNullAction = () => (dispatch: any) => {
    dispatch({
        type: "SET_SHOPIFY_STORE_CREATE_STATUS_NULL"
    });
}

export const updateShopifyStoreAction = (storeId: string, data: any, onSuccess: () => any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    shopifyStoreService.updateStore({ id: storeId, ...data }).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "UPDATE_SHOPIFY_STORE",
            payload: response.data
        });
        onSuccess();
        dispatch(getAllShopifyStoresAction(false));
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "UPDATE_SHOPIFY_STORE_ERRORS",
            payload: err.response
        });
    });
};

export const getUserListAction = () => (dispatch: any) => {
    dispatch(startLoadingAction());
    shopifyStoreService.getUserList().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_SHOPIFY_USER_LIST_SUCCESS",
            payload: response.data
        });
    }).catch(err => {
        console.error(err);
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_SHOPIFY_USER_LIST_ERROR",
            status: err.response.status
        });
    });
};
