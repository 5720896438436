import React from "react";
import { useNavigate } from "react-router-dom";
import './resuablecomponent.css'
import '../pages/AmazonSellerCentral/rbmws.css'

const PageHeader = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-3 pos-rel row">
        <div className="col-3">
          <button
            className="back_btn btn cancel_btn rounded-pill"
            onClick={() => navigate(props.backUrl)}
          >
            <i className="fas fa-arrow-left me-2"></i>Back
          </button>
        </div>
        <div className="col-6">
          <div className="mb-4">
            <h1 className="mb-0 text-align-center">{props.heading}</h1>
          </div>
        </div>
        <div className="col-3">
          {props.buttonName && 
          <div className="header_button">
            <button onClick={props.handleClick} className="add_new save_btn rounded-pill">
            { props.buttonIcon ? (<i className={`fas ${props.buttonIcon} me-2`}></i>) : null}{props.buttonName}
            </button>
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default PageHeader;