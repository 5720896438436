import { ficService } from "../../service";
import { stopLoadingAction, startLoadingAction } from "./loaderActions";

export const getAllCustomersAction = () => (dispatch: any) => {
  dispatch(startLoadingAction());
  ficService.getAllCustomers().then((response) => {
    dispatch({
      type: "GET_ALL_FIC_CUSTOMERS",
      payload: response.data,
    });
    dispatch(stopLoadingAction());
  });
};

export const getCustomersWithAccountsAction =
  (id: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    dispatch({
      type: "GET_FIC_CUSTOMERS_ACCOUNTS_LOADING",
      payload: true,
    });
    ficService.getCustomer(id).then((response) => {
      dispatch({
        type: "GET_CURRENT_FIC_CUSTOMER",
        payload: response.data.records[0],
      });
      ficService
        .getCustomerAccounts(response.data.records[0].id)
        .then((response) => {
          dispatch({
            type: "GET_FIC_CUSTOMER_ACCOUNTS",
            payload: response.data,
          });
          dispatch(stopLoadingAction());
        });
    });
  };

export const getCustomerAction = (id: string) => (dispatch: any) => {
  dispatch({
    type: "GET_FIC_CUSTOMER_ACCOUNTS_LOADING",
    payload: true,
  });
  ficService.getCustomer(id).then((response) => {
    dispatch({
      type: "GET_CURRENT_FIC_CUSTOMER",
      payload: response.data.records[0],
    });
  });
};

export const getAccountAction = (id: string) => (dispatch: any) => {
  dispatch(startLoadingAction());
  ficService.getAccount(id).then((response) => {
    dispatch({
      type: "GET_CURRENT_FIC_ACCOUNT",
      payload: response.data.records[0],
    });
    dispatch(stopLoadingAction());
  });
};

export const getAllCountriesAction = () => (dispatch: any) => {
  dispatch(startLoadingAction());
  ficService.getAllCountries().then((response) => {
    dispatch({
      type: "GET_FIC_COUNTRIES",
      payload: response.data,
    });

    dispatch(stopLoadingAction());
  });
};

export const getCountryAction = (id: string) => (dispatch: any) => {
  dispatch(startLoadingAction());
  ficService.getCountry(id).then((response) => {
    dispatch({
      type: "GET_CURRENT_FIC_COUNTRY",
      payload: response.data.records[0],
    });
    dispatch(stopLoadingAction());
  });
};
