// AddNewShopifyStore.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createShopifyStoreAction, 
        getUserListAction, 
        shopifyStoreSetCreateStatusAction,
        shopifyStoreSetCreateStatusNullAction
        } from '../../../redux/actions/shopifyStoreActions';
import { ToastContainer, toast } from "react-toastify";
import { Audio } from "react-loader-spinner";
import ShopifyStoreForm from '../../shared/shopifyStoreForm';
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from '../../../redux/Store';
import { isSuperUser } from "../../../utils/Helper";

const AddNewShopifyStore = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const shopifyStoreState = useSelector((state: any) => state.shopifyStoreReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    const [shopName, setShopName] = useState("");
    const [accountId, setAccountID] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [skuPrefix, setSkuPrefix] = useState("");
    const [holdBack, setHoldBack] = useState(0);
    const [saveInventoryFeed, setSaveInventoryFeed] = useState(false);
    const [brands, setBrands] = useState<string[]>([]);
    const [ownerUserId, setOwnerUserId] = useState("");

    const data = {
        config: {
            shopify_shop_name: shopName,
            account_id: accountId,
            shopify_api_key: apiKey,
            shopify_api_secret: apiSecret,
            shopify_access_token: accessToken,
            sku_prefix: skuPrefix,
            hold_back: holdBack,
            save_inventory_feed: saveInventoryFeed,
            brands: brands,
            owner_user_id: ownerUserId
        }
    };

    const success = (msg: string) => toast.success(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
    });

    const error = (msg: string) => toast.error(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
    });

    const warning = (msg: string) => toast.warning(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
    });

    useEffect(() => {
        if (shopifyStoreState.createStatus) {
            dispatch(shopifyStoreSetCreateStatusAction(false));
            setTimeout(() => navigate("/shopify-store"), 15000);
        }
        if (!shopifyStoreState.createStatus && shopifyStoreState.statusCode === 400) {
            dispatch(shopifyStoreSetCreateStatusNullAction());
            error(shopifyStoreState.mesg);
        }
    }, [shopifyStoreState, navigate, dispatch]);

    useEffect(() => {
        dispatch(getUserListAction());
    }, [dispatch]);

    const onSuccess = (store_id: string) => {
        success(shopifyStoreState.mesg);
        navigate(`/shopify-store/${store_id}?type=readonly`);
    };

    const addClickHandle = () => {
        if (brands.length === 0) {
            warning("Please add at least one brand");
        } else {
            dispatch(createShopifyStoreAction(data, onSuccess));
        }
    };

    return (
        <>
            <section className="user-sec py-2">
                <div className="container mt-15">
                    <div className="inner-sec">
                        <PageHeader
                            heading={"Add Shopify Store"}
                            backUrl={"/shopify-store"}
                            buttonName={"Save"}
                            handleClick={addClickHandle}
                        />
                        <ShopifyStoreForm
                            editClick={false}
                            setShopName={setShopName}
                            shopName={shopName}
                            setAccountId={setAccountID}
                            accountId={accountId}
                            setApiKey={setApiKey}
                            apiKey={apiKey}
                            setApiSecret={setApiSecret}
                            apiSecret={apiSecret}
                            setAccessToken={setAccessToken}
                            accessToken={accessToken}
                            setSkuPrefix={setSkuPrefix}
                            skuPrefix={skuPrefix}
                            setHoldBack={setHoldBack}
                            holdBack={holdBack}
                            setSaveInventoryFeed={setSaveInventoryFeed}
                            saveInventoryFeed={saveInventoryFeed}
                            setBrands={setBrands}
                            brands={brands}
                            isAdmin={isSuperUser()}
                            setOwnerUserId={setOwnerUserId}
                            ownerUserId={ownerUserId}
                            user={shopifyStoreState.user}
                        />
                    </div>
                </div>
            </section>

            <ToastContainer autoClose={3000} />

            {loading &&
                <div className="spinner">
                    <Audio
                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            }
        </>
    );
};

export default AddNewShopifyStore;
