
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import PageHeader from "../../../shared/pagesHeader";
import WarehouseForm from "../../../common/admin/wayfair/WarehouseForm";
import { AppDispatch } from "../../../../redux/Store";
import { wayfairService } from "../../../../service";
import { getSupplierAction } from "../../../../redux/actions/wayfairActions";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";


const WayfairCreateWarehouse = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    const params: any = useParams();

    useEffect(() => {
        console.info("Wayfair Supplier Arrived");
        dispatch(getSupplierAction(params.supplier_id))
    }, [dispatch, params.supplier_id]);    

    const handleSubmit = (value: any) => {
        setErrorMessage('');        
        dispatch(startLoadingAction());
        let warehouse = {
            "wayfair_supplier_id": value.wayfair_supplier_id,
            "child_supplier_id": value.child_supplier_id,            
            "warehouse_name": value.warehouse_name,
            "warehouse_mode_type": value.warehouse_mode_type
        };

        wayfairService.createOrUpdateWarehouse(warehouse).then(response => {
            dispatch(stopLoadingAction());
            navigate(`/admin/wayfair/supplier/${params.supplier_id}`);
        }).catch((response) => {
            dispatch(stopLoadingAction());
            setErrorMessage(response.message);
        });

    };


    let warehouse = {
        wayfair_supplier_id: wayfairState.currentSupplier ? wayfairState.currentSupplier.wayfair_supplier_id : null,
        child_supplier_id: '',
        warehouse_name: '',
        warehouse_mode_type: ''
    };
    
    return (
    <>
            <div className="wayfair-admin container">
                <PageHeader
                    heading={"Wayfair - Create Warehouse"}
                    backUrl={`/admin/wayfair/supplier/${params.supplier_id}`}
                />

                <WarehouseForm handleSubmit={handleSubmit} warehouse={warehouse} errorMessage={errorMessage}/>
                                                
            </div>    
    
    </>)
};


export default WayfairCreateWarehouse;
