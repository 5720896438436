import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { translationsService } from "../../service";


export const getLocalesAction = (loading: any) => (dispatch: any) => {
    if (loading) {
        dispatch(startLoadingAction());
    }
    translationsService.getLocaleList().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "SET_LOCALE_LIST",
            payload: response.data.locales
        })
    }).catch(err => {
        dispatch(stopLoadingAction());
    })
};


export const getTranslatedProductsAction = (loading: any, locale_code: string) => (dispatch: any) => {
    if (loading) {
        dispatch(startLoadingAction());
    }
    dispatch({
        type: "RESET_TRANSLATION_PRODUCTS",
    })
    dispatch({
        type: "SET_PRODUCT_TABLE_IN_PROGRESS",
        payload: true
    })

    translationsService.getTranslatedProducts(locale_code).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "SET_TRANSLATED_PRODUCTS",
            payload: {
                locale_code: locale_code,
                data: response.data
            }
        })

    }).catch(err => {
        dispatch(stopLoadingAction());
    })


    translationsService.getProducts(locale_code).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "SET_LOCALE_PRODUCTS",
            payload: {
                locale_code: locale_code,
                data: response.data
            }
        })

    }).catch(err => {
        dispatch(stopLoadingAction());
    })        
};



export const getProductAction = (loading: any, localeCode: string, itemNumber: string) => (dispatch: any) => {
    if (loading) {
        dispatch(startLoadingAction());
    }
    dispatch({
        type: "SET_PRODUCT_REQUEST_IN_PROGRESS",
        payload: true
    })
    translationsService.getProductsByItemNumber(localeCode, itemNumber).then(response => {
        if (loading) {
            dispatch(stopLoadingAction());
        }
        dispatch({
            type: "SET_LOCALE_PRODUCT",
            payload: {
                data: response.data.length > 0 ? response.data[0] : null,
                item_number: itemNumber,
                locale_code: localeCode
            }
        })
    }).catch(err => {
        if (loading) {
            dispatch(stopLoadingAction());
        }
    })
};


export const getTranslatedProductAction = (loading: any, localeCode: string, itemNumber: string) => (dispatch: any) => {
    if (loading) {
        dispatch(startLoadingAction());
    }
    translationsService.getTranslatedProductsByItemNumber(localeCode, itemNumber).then(response => {        
        dispatch({
            type: "SET_LOCALE_TRANSLATED_PRODUCT",
            payload: response.data.length > 0 ? response.data[0] : null
        })
        dispatch(stopLoadingAction());
    }).catch(err => {
        if (loading) {
            dispatch(stopLoadingAction());
        }
    })
};

export const getSuggestedTranslationAction = (loading: any, localeCode: string, itemNumber: string) => (dispatch: any) => {
    if (loading) {
        dispatch(startLoadingAction());
    }
    translationsService.getSuggestedTranslationsByItemNumber(localeCode, itemNumber).then(response => {
        if (loading) {
            dispatch(stopLoadingAction());
        }
        dispatch({
            type: "SET_LOCALE_SUGGESTED_TRANSLATION",
            payload: response.data.length > 0 ? response.data[0] : null
        })
    }).catch(err => {
        if (loading) {
            dispatch(stopLoadingAction());
        }
    })
};

export const updateTranslationAction = (loading: any, localeCode: string, itemNumber: string, translation: any) => (dispatch: any) =>{
    if (loading) {
        dispatch(startLoadingAction());
    }
    translationsService.updateTranslation(localeCode, itemNumber, {
        display_name: translation.display_name,
        short_description: translation.short_description
    }).then(response => {
        dispatch(getTranslatedProductAction(loading, localeCode, itemNumber));
    }).catch(err => {
        if (loading) {
            dispatch(stopLoadingAction());
        }
    })

}


export const resetTranslationAction = (loading: any, localeCode: string, itemNumber: string) => (dispatch: any) =>{
    if (loading) {
        dispatch(startLoadingAction());
    }
    translationsService.resetTranslation(localeCode, itemNumber).then(response => {
        dispatch(getTranslatedProductAction(loading, localeCode, itemNumber));
    }).catch(err => {
        if (loading) {
            dispatch(stopLoadingAction());
        }
    })

}

export const clearProductAction = (loading: any) => (dispatch: any) => {
    if (loading) {
        dispatch(startLoadingAction());
    }

    dispatch({
        type: "CLEAR_LOCALE_PRODUCT"
    });
}
