import {
  isLogin,
  isSuperUser,
  isFreightClubUser,
  isAmazonVerificationUser,
  isAmazonBuyboxUserVerification,
  isLocaleEditor,
  isOverstockppcUser,
  isTradeshowUser,
  isMapProcessor
} from "./utils/Helper";

import { Route, Navigate, Routes } from "react-router-dom";
import NavBar from "./components/common/NavBar";
import Footer from "./components/common/Footer";

import Dashboard from "./components/pages/Dashboard";
import FedexCost from "./components/pages/FedexShippingCost/FedexCostList";
import SubscraptionsNotifications from "./components/pages/NotificationSubscriptions/SubscriptionsNotification";
import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import UserProfile from "./components/pages/UserAccount/UserProfile";
import FreightClub from "./components/pages/FreightClub/FreightClubHome";
import ReportDetail from "./components/pages/FreightClub/ReportDetails";
import RbmwsHome from "./components/pages/AmazonSellerCentral/RbmwsHome";
import RbmwsDetails from "./components/pages/AmazonSellerCentral/RbmwsDetails";
import AddNewSellerStore from "./components/pages/AmazonSellerCentral/AddNewSellerStore";
import AmazonContentVerification from "./components/pages/AmazonContentVerification/AmazonContentVerification";
import AmazonBuyboxTrackerHome from "./components/pages/AmazonBuyboxTracker/AmazonBuyboxTrackerHome";
import Catalogs from "./components/pages/Catalogs/Catalogs";
import MissingCatalogProducts from "./components/pages/Catalogs/MissingCatalogProduct";
import TrackingList from "./components/pages/ShipmentTracking/TrackingList";
import ImagesPage from "./components/pages/Images/ImagesPage";
import LocaleList from "./components/pages/LocaleEditor/LocaleList";
import TranslationList from "./components/pages/LocaleEditor/TranslationList";
import LocaleEditor from "./components/pages/LocaleEditor/LocaleEditor";
import OverstockPPCReports from "./components/pages/OverstockPPC/OverstockPPCReports";
import InventoryHolds from "./components/pages/Inventory/InventoryHolds";
import TradeshowConfigPage from "./components/pages/Tradeshow/TradeshowConfig";

import SuperUserDashboard from "./components/pages/admin/SuperUserDashboard";
import Settings from "./components/pages/admin/Settings";
import UserList from "./components/pages/admin/UserList";

import WayfairAdmin from "./components/pages/admin/wayfair/WayfairAdmin";
import WayfairCreateSupplier from "./components/pages/admin/wayfair/WayfairCreateSupplier";
import WayfairCreateWarehouse from "./components/pages/admin/wayfair/WayfairCreateWarehouse";
import WayfairWarehouse from "./components/pages/admin/wayfair/WayfairWarehouse";
import WayfairFixedInventory from "./components/pages/admin/wayfair/WayfairFixedInventory";
import WayfairFixedInventoryRecord from "./components/pages/admin/wayfair/WayfairFixedInventoryRecord";
import WayfairCreateFixedInventory from "./components/pages/admin/wayfair/WayfairCreateFixedInventory";
import WayfairUnresolvedItems from "./components/pages/admin/wayfair/WayfairUnresolvedItems";

import WayfairSupplier from "./components/pages/admin/wayfair/WayfairSupplier";
import WayfairCreateFabricXref from "./components/pages/admin/wayfair/WayfairCreateFabricXref";
import WayfairFabricXref from "./components/pages/admin/wayfair/WayfairFabricXref";
import WayfairFabricMappings from "./components/pages/admin/wayfair/WayfairFabricMappings";
import WayfairOrderErrors from "./components/pages/admin/wayfair/WayfairOrderErrors";
import WayfairShipmentErrors from "./components/pages/admin/wayfair/WayfairShipmentErrors";
import WayfairOrderCreateSkuExceptionMap from "./components/pages/admin/wayfair/WayfairOrderCreateSkuExceptionMap"
import WayfairOrderUpdateSkuExceptionMap from "./components/pages/admin/wayfair/WayfairOrderUpdateSkuExceptionMap"
import WayfairPendingShipments from "./components/pages/admin/wayfair/WayfairPendingShipments";

import OverstockPPCAdmin from "./components/pages/admin/overstockppc/OverstockPPCAdmin";
import OverstockPPCCreateAccountConfig from "./components/pages/admin/overstockppc/OverstockPPCCreateAccountConfig";
import OverstockPPCAccountConfig from "./components/pages/admin/overstockppc/OverstockPPCAccountConfig";

import FedexDebugShippingCost from "./components/pages/admin/FedexDebugShippingCost";
import FedexFicupRates from "./components/pages/admin/FedexFicupRates";
import FedexFicupLabelStatus from "./components/pages/admin/FedexFicupLabelStatus";
import CarrierExclusionsHome from "./components/pages/admin/FreightClubCarrierExclusionsHome";

import FicCountryList from "./components/pages/admin/fic/FicCountryList";
import HmCustomerList from "./components/pages/Customers/HmCustomerList";
import HmCreateCustomerAccount from "./components/pages/Customers/HmCreateCustomerAccount";
import FicCreateCountry from "./components/pages/admin/fic/FicCreateCountry";
import HmCreateCustomer from "./components/pages/Customers/HmCreateCustomer";
import HmCustomerAccount from "./components/pages/Customers/HmCustomerAccount";
import FicCountry from "./components/pages/admin/fic/FicCountry";
import HmCustomer from "./components/pages/Customers/HmCustomer";
import ShopifyHome from "./components/pages/ShopifyStoreConfigurations/ShopifyStoreHome"
import AddNewShopifyStore from "./components/pages/ShopifyStoreConfigurations/AddNewShopifyStore";
import EditShopifyStore from "./components/pages/ShopifyStoreConfigurations/EditShopifyStore";

import MapViolationsIndex from "./components/pages/MapViolations/MapViolationsIndex";

export const PrivateRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isLogin();

  return authed ? children : <Navigate to={"/login"} />;
};

export const SuperUserRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isSuperUser();

  return authed ? children : <Navigate to={"/login"} />;
};

export const FreightClubUserRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isFreightClubUser();

  return authed ? children : <Navigate to={"/login"} />;
};

export const AmazonVerificationUserRoute: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const authed = isAmazonVerificationUser();

  return authed ? children : <Navigate to={"/login"} />;
};

export const AmazonBuyboxUserRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isAmazonBuyboxUserVerification();

  return authed ? children : <Navigate to={"/login"} />;
};

export const LocaleEditorRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isLocaleEditor();

  return authed ? children : <Navigate to={"/login"} />;
};

export const OverstockppcUserRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isOverstockppcUser();

  return authed ? children : <Navigate to={"/login"} />;
};

export const TradeshowUserRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isTradeshowUser();

  return authed ? children : <Navigate to={"/login"} />;
};


export const MapProcessorRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isMapProcessor();
  return authed ? children : <Navigate to={"/login"} />;
};



const Routers = () => {

  return (
    <>
      <NavBar />
      <div className="main-body">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/fedex-cost" element={<PrivateRoute><FedexCost /></PrivateRoute>} />
          <Route path="/subscription-notifications" element={<PrivateRoute><SubscraptionsNotifications /></PrivateRoute>} />
          <Route path="/user-profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />

          <Route path="/admin" element={<SuperUserRoute><SuperUserDashboard /></SuperUserRoute>} />
          <Route path="/admin/settings" element={<SuperUserRoute><Settings /></SuperUserRoute>} />
          <Route path="/admin/user-list" element={<SuperUserRoute><UserList /></SuperUserRoute>} />
          <Route path="/admin/fedex/debug" element={<SuperUserRoute><FedexDebugShippingCost /></SuperUserRoute>} />
          <Route path="/admin/fedex/ficup-rates" element={<SuperUserRoute><FedexFicupRates /></SuperUserRoute>} />
          <Route path="/admin/fedex/ficup-label-status" element={<SuperUserRoute><FedexFicupLabelStatus /></SuperUserRoute>} />
          <Route path="/admin/freight-club/carrierExclusions" element={<SuperUserRoute><CarrierExclusionsHome /></SuperUserRoute>} />

          <Route path="/amazon-content-verification" element={<AmazonVerificationUserRoute><AmazonContentVerification /></AmazonVerificationUserRoute>} />
          <Route path="/catalogs" element={<SuperUserRoute><Catalogs /></SuperUserRoute>} />
          <Route path="/catalogs/:catalog_id" element={<SuperUserRoute><MissingCatalogProducts /></SuperUserRoute>} />
          <Route path="/amazon-buybox-tracker" element={<AmazonBuyboxUserRoute><AmazonBuyboxTrackerHome /></AmazonBuyboxUserRoute>} />

          <Route path="/freight-club/reports" element={<FreightClubUserRoute><FreightClub /></FreightClubUserRoute>} />
          <Route path="/freight-club/reports/deatil/:report_id" element={<FreightClubUserRoute><ReportDetail /></FreightClubUserRoute>} />

          <Route path="/rbmws/home" element={<SuperUserRoute><RbmwsHome /></SuperUserRoute>} />
          <Route path="/rbmws/details/:id" element={<SuperUserRoute><RbmwsDetails /></SuperUserRoute>} />
          <Route path="/rbmws/add-new" element={<SuperUserRoute><AddNewSellerStore /></SuperUserRoute>} />

          <Route path="/shipping/tracking" element={<SuperUserRoute><TrackingList /></SuperUserRoute>} />
          <Route path="/images" element={<PrivateRoute><ImagesPage /></PrivateRoute>} />

          <Route path="/locales/:locale_code/:sku" element={<LocaleEditorRoute><LocaleEditor /></LocaleEditorRoute>} />
          <Route path="/locales/:locale_code" element={<LocaleEditorRoute><TranslationList /></LocaleEditorRoute>} />
          <Route path="/locales" element={<LocaleEditorRoute><LocaleList /></LocaleEditorRoute>} />
          <Route path="/overstock-ppc" element={<OverstockppcUserRoute><OverstockPPCReports /></OverstockppcUserRoute>} />
          <Route path="/inventory" element={<InventoryHolds />} />
          <Route path="/tradeshow-config" element={<TradeshowUserRoute><TradeshowConfigPage /></TradeshowUserRoute>} />
          <Route path="/admin/wayfair" element={<SuperUserRoute><WayfairAdmin /></SuperUserRoute>} />
          <Route path="/map-violations" element={<MapProcessorRoute><MapViolationsIndex /></MapProcessorRoute>} />
          
          <Route path="/admin/wayfair/fixed-inventory" element={<SuperUserRoute><WayfairFixedInventory /></SuperUserRoute>} />
          <Route path="/admin/wayfair/fixed-inventory/:id" element={<SuperUserRoute><WayfairFixedInventoryRecord /></SuperUserRoute>} />
          <Route path="/admin/wayfair/create-fixed-inventory" element={<SuperUserRoute><WayfairCreateFixedInventory /></SuperUserRoute>} />
          <Route path="/admin/wayfair/unresolved-items" element={<SuperUserRoute><WayfairUnresolvedItems /></SuperUserRoute>} />
          <Route path="/admin/wayfair/create-supplier" element={<SuperUserRoute><WayfairCreateSupplier /></SuperUserRoute>} />
          <Route path="/admin/wayfair/supplier/:id" element={<SuperUserRoute><WayfairSupplier /></SuperUserRoute>} />
          <Route path="/admin/wayfair/supplier/:supplier_id/create-warehouse" element={<SuperUserRoute><WayfairCreateWarehouse /></SuperUserRoute>} />
          <Route path="/admin/wayfair/supplier/:supplier_id/warehouse/:id" element={<SuperUserRoute><WayfairWarehouse /></SuperUserRoute>} />
          <Route path="/admin/wayfair/create-fabric-xref" element={<SuperUserRoute><WayfairCreateFabricXref /></SuperUserRoute>} />
          <Route path="/admin/wayfair/fabric-xref/:supplierpartnumber" element={<SuperUserRoute><WayfairFabricXref /></SuperUserRoute>} />
          <Route path="/admin/wayfair/fabric-mappings" element={<SuperUserRoute><WayfairFabricMappings /></SuperUserRoute>} />
          <Route path="/admin/wayfair/order-errors" element={<SuperUserRoute><WayfairOrderErrors /></SuperUserRoute>} />
          <Route path="/admin/wayfair/shipments/errors" element={<SuperUserRoute><WayfairShipmentErrors /></SuperUserRoute>} />
          <Route path="/admin/wayfair/shipments/pending" element={<SuperUserRoute><WayfairPendingShipments /></SuperUserRoute>} />
          <Route path="/admin/wayfair/order/:wayfair_customer_po/sku-exceptions-map/:wayfair_supplier_part_number/create" element={<SuperUserRoute><WayfairOrderCreateSkuExceptionMap /></SuperUserRoute>} />
          <Route path="/admin/wayfair/order/:wayfair_customer_po/sku-exceptions-map/:wayfair_supplier_part_number" element={<SuperUserRoute><WayfairOrderUpdateSkuExceptionMap /></SuperUserRoute>} />
                    
          <Route path="/admin/overstock-ppc" element={<SuperUserRoute><OverstockPPCAdmin /></SuperUserRoute>} />
          <Route path="/admin/overstock-ppc/create-account" element={<SuperUserRoute><OverstockPPCCreateAccountConfig /></SuperUserRoute>} />
          <Route path="/admin/overstock-ppc/:id" element={<SuperUserRoute><OverstockPPCAccountConfig /></SuperUserRoute>} />


          <Route path="/customer" element={<SuperUserRoute><HmCustomerList /></SuperUserRoute>} />
          <Route path="/customer/create" element={<SuperUserRoute><HmCreateCustomer /></SuperUserRoute>} />
          <Route path="/customer/:id" element={<SuperUserRoute><HmCustomer /></SuperUserRoute>} />
          <Route path="/customer/:customer_id/create-account" element={<SuperUserRoute><HmCreateCustomerAccount /></SuperUserRoute>} />
          <Route path="/customer/:customer_id/account/:id" element={<SuperUserRoute><HmCustomerAccount /></SuperUserRoute>} />

          <Route path="/shopify-store" element={<SuperUserRoute><ShopifyHome /></SuperUserRoute>} />
          <Route path="/shopify-store/add-new" element={<SuperUserRoute><AddNewShopifyStore /></SuperUserRoute>} />
          <Route path="/shopify-store/:id" element={<SuperUserRoute><EditShopifyStore /></SuperUserRoute>} />

          <Route path="/admin/fic/country" element={<SuperUserRoute><FicCountryList /></SuperUserRoute>} />
          <Route path="/admin/fic/country/create" element={<SuperUserRoute><FicCreateCountry /></SuperUserRoute>} />
          <Route path="/admin/fic/country/:id" element={<SuperUserRoute><FicCountry /></SuperUserRoute>} />

        </Routes>

      </div>

      <Footer />
    </>
  )
};

export default Routers;
