
const createReportsinitialState = {
    status: false,
    mesg: null,
    error_code: null,
    error_message: null,
    error: false
}
export const createFreightClubReportReducer = (state = createReportsinitialState, action: any) => {
    switch (action.type) {
        case "CREATE_NEW_ORDER_REPORT":

            return {
                ...state,
                status: true
            };
        case "CREATE_NEW_ORDER_REPORT_ERROR":
            return {
                ...state,
                error_code: 500,
                error: true,
                error_message: "An unexpected error has occurred"
            }
        default:
            return state;
    }
}

export default createFreightClubReportReducer;
