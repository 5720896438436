import './App.css';

import { ToastContainer } from "react-toastify";
import { useSelector } from 'react-redux';
import { Audio } from "react-loader-spinner";

import { BrowserRouter as Router } from "react-router-dom";

import Routers from './Routers';


function App() {

  const { loading } = useSelector((state: any) => state.LoaderReducer);

  return (
    <div className="App">
      <Router>
        <Routers />
      </Router>

      <ToastContainer autoClose={3000} />
      {loading &&
        <div className="spinner">
          <Audio

            height={100}
            width={100}
            color="#00BFFF"
            ariaLabel="loading"
          />
        </div>
      }
    </div>

  );
}

export default App;
