import { useState } from "react";

import { ficService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";
import CountryForm from "../../../common/admin/fic/CountryForm";
import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../../redux/actions/loaderActions";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";

const FicCreateCountry = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const ficState = useSelector((state: any) => state.ficReducer);

  const handleSubmit = (value: any) => {
    setErrorMessage("");
    dispatch(startLoadingAction());
    let country = {
      country_code: value.country_code,
      vat_required: value.vat_required === "on",
      eori_required: value.eori_required === "on",
      reg_required: value.reg_required === "on",
      tax_id_required: value.tax_id_required === "on",
      arn_required: value.arn_required === "on",
      ddp_required: value.ddp_required === "on",
    };

    ficService
      .createOrUpdateCountry(country)
      .then((response) => {
        dispatch(stopLoadingAction());
        navigate("/admin/fic/country");
      })
      .catch((response) => {
        dispatch(stopLoadingAction());
        setErrorMessage(response.message);
      });
  };

  return (
    <>
      <div className="fic-admin container">
        <PageHeader
          heading={"FIC - Create Country"}
          backUrl={"/admin/fic/country"}
        />

        <CountryForm
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          existingCountries={ficState.countries.records.map((country: any) => country.country_code)}
        />
      </div>
    </>
  );
};

export default FicCreateCountry;
