import { useNavigate } from "react-router-dom";

export const WayfairAdminMenu = (props: any) => {

    const navigate = useNavigate();

    return (
        <>
            <h1 style={{ marginTop: '1ex' }}>Wayfair Administration</h1>
            <ul className="nav nav-tabs" style={{ marginBottom: '2ex', marginTop: '2ex' }}>
                <li className="nav-item">
                    {props.tab === 'suppliers' ? (

                        <p className="nav-link active" aria-current="page">Suppliers</p>

                    ) : (
                        <p className="nav-link btn" aria-current="page" onClick={() => navigate('/admin/wayfair')}>Suppliers</p>
                    )}
                </li>
                <li className="nav-item">

                    {props.tab === 'order-errors' ? (

                        <p className="nav-link active" aria-current="page">Order Errors</p>

                    ) : (
                        <p className="nav-link btn" aria-current="page" onClick={() => navigate('/admin/wayfair/order-errors')}>Order Errors</p>
                    )}

                </li>
                <li className="nav-item">
                    {props.tab === 'shipment-errors' ? (
                        <p className="nav-link active" aria-current="page">Shipment Errors</p>

                    ) : (
                        <p className="nav-link btn" aria-current="page" onClick={() => navigate('/admin/wayfair/shipments/errors')}>Shipment Errors</p>
                    )}
                </li>
                <li className="nav-item">

                    {props.tab === 'fixed-inventory' ? (

                        <p className="nav-link active" aria-current="page">Fixed Inventory</p>

                    ) : (
                        <p className="nav-link btn" aria-current="page" onClick={() => navigate('/admin/wayfair/fixed-inventory')}>Fixed Inventory</p>
                    )}

                </li>
                <li className="nav-item">

                    {props.tab === 'fabric-mappings' ? (

                        <p className="nav-link active" aria-current="page">Fabric Mappings</p>

                    ) : (
                        <p className="nav-link btn" aria-current="page" onClick={() => navigate('/admin/wayfair/fabric-mappings')}>Fabric Mappings</p>
                    )}

                </li>
                <li className="nav-item">

                    {props.tab === 'unresolved-items' ? (

                        <p className="nav-link active" aria-current="page">Unresolved Items</p>

                    ) : (
                        <p className="nav-link btn" aria-current="page" onClick={() => navigate('/admin/wayfair/unresolved-items')}>Unresolved Items</p>
                    )}

                </li>
                <li className="nav-item">

                    {props.tab === 'pending-shipments' ? (

                        <p className="nav-link active" aria-current="page">Pending Shipments</p>

                    ) : (
                        <p className="nav-link btn" aria-current="page" onClick={() => navigate('/admin/wayfair/shipments/pending')}>Pending Shipments</p>
                    )}

                </li>

                

            </ul>
        </>
    )
}

export default WayfairAdminMenu
