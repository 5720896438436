
import { useNavigate } from "react-router-dom";


type FACardProps = {
    title: string,
    path: string,
    icon: string
}

export const FACard = (props: FACardProps) => {

    const navigate = useNavigate();
    
    return (
        <div className="col-md-3 mb-3">
            <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                    <i className={`dashboard-icon ${props.icon}`}></i>
                    <h6
                        onClick={() => navigate(props.path)}
                        className="custom-btn">
                        Go
                    </h6>
                </div>
            </div>
        </div>
    )

}

export default FACard;
