
import { useState } from "react";

import { overstockPPCService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch } from "react-redux";
import OverstockPPCAccountConfigForm from "../../../common/admin/overstockppc/OverstockPPCAccountConfigForm";

const OverstockPPCCreateAccountConfig = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();

    const handleSubmit = (value: any) => {
        setErrorMessage('');
    
        dispatch(startLoadingAction());
        let accountConfig = {
            name: value.name,
            company: value.company, // required
            customer_id: value.customer_id, // required
            customer_dseq: value.customer_dseq, // required
            budget_perc_of_sale: value.budget_perc_of_sale,
            max_no_sales_budget_perc: value.max_no_sales_budget_perc,
            low_performance_penalty: value.low_performance_penalty,
            high_performance_penalty: value.high_performance_penalty,
            no_sales_penalty: value.no_sales_penalty,
            new_product_penalty: value.new_product_penalty,
            months_product_is_new: parseInt(value.months_product_is_new),
            weekly_performance_factor: value.weekly_performance_factor,
            monthly_performance_factor: value.monthly_performance_factor,
            min_bid: value.min_bid,
            max_probability_zero_clicks_scalar: value.max_probability_zero_clicks_scalar,
            jitter_sample_size: parseInt(value.jitter_sample_size),
            jitter_perc_max: value.jitter_perc_max,
            weight_no_sales_by_target_budget: value.weight_no_sales_by_target_budget,
            weight_no_sales_by_target_clicks: value.weight_no_sales_by_target_clicks,
            no_sales_max_wasted_clicks_error_factor: value.no_sales_max_wasted_clicks_error_factor,
            weight_profitable: value.weight_profitable,
            weight_unprofitable: value.weight_unprofitable,
            monthly_volume_per_spend_distribution_exponent: value.monthly_volume_per_spend_distribution_exponent,
            weekly_volume_per_spend_distribution_exponent: value.weekly_volume_per_spend_distribution_exponent,
            weekly_volume_per_spend_distribution_ema_periods: value.weekly_volume_per_spend_distribution_ema_periods,
            weight_overstock_rpt_sales: value.weight_overstock_rpt_sales,
            weight_jba_order_sales: value.weight_jba_order_sales
        };

        overstockPPCService.createOrUpdateConfiguration(accountConfig).then(response => {
            dispatch(stopLoadingAction());
            navigate('/admin/overstock-ppc');
        }).catch((response) => {
            dispatch(stopLoadingAction());
            setErrorMessage(response.message);
        });
        
    }
    
    return (
    <>
            <div className="overstockppc-admin container">
                <PageHeader
                    heading={"Overstock PPC - Create Account"}
                    backUrl={"/admin/overstock-ppc"}
                />                
                <OverstockPPCAccountConfigForm handleSubmit={handleSubmit} errorMessage={errorMessage}/>                
            </div>    
    
    </>)
};


export default OverstockPPCCreateAccountConfig;
