import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { amazonContentVerificationService } from "../../service";


export const getAmazonContentVerificationDiffAction = () => (dispatch: any) => {
    dispatch(startLoadingAction());
    amazonContentVerificationService.getContentDiff().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_AMAZON_CONTENT_VERIFICATION",
            payload: { data: response.data, status: response.status, statusText: response.statusText }
        });
    }).catch(err => {
        console.error("Error=%O", err);
    })

}


export const getAmazonVerificationDuplicateAction = (page: Number, colorBias: any, pageSize: Number) => (dispatch: any) => {
    amazonContentVerificationService.getLikelyDuplicates(page,colorBias,pageSize).then(response => {
        dispatch({
            type: "GET_ALL_AMAZON_CONTENT_VERIFICATION",
            payload: { data: response.data, status: response.status }
        });
    }).catch(err => {
        dispatch({
            type: "ERROR_AMAZON_CONTENT_VERIFICATION",
            payload: err.response.data.result
        });
    });
};


export const resetDuplicateDataAction = () => (dispatch: any) => {
    dispatch({
        type: 'UPDATE_DUPLICATE_DATA',
        payload: []
      })
}
