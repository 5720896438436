import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { hmCatalogService } from "../../service";


export const getMyCatalogsAction = () => (dispatch: any) => {
  dispatch(startLoadingAction());
  hmCatalogService.getCatalogList().then(response => {
    dispatch({
      type: "GET_MY_CATALOGS",
      payload: {
        catalogs: response.data.catalogs,
        status: response.status
      },
    });
    dispatch(stopLoadingAction());
  }).catch(err => {
    dispatch(stopLoadingAction());
  });
};


export const getCatalogsMissingProductAction = (catalogId: string) => (dispatch: any) => {
  dispatch(startLoadingAction());
  hmCatalogService.getCatalog(catalogId).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_CATALOGS_MISSSING_PRODUCT",
      payload: {
        'products': response.data.products,
        'id': catalogId,
        'status': response.status
      }
    });
  });
};
