
const initialState = {
    status: null,
    catalogs : [] as any[],
    catalog_missing_data: {id: null, products: []} as any,
    set_catalog_product: [] as any[],
    statusCode: null,
    mesg: null

}

const catalogsReducer = (state=initialState, action:any) => {
   
    switch(action.type){
        case "GET_MY_CATALOGS":
        
            return{
                ...state,
                catalogs: action.payload.catalogs as any[],
                status: action.payload.status,  
            }
            
        case "SET_CATALOGS_PRODUCTS":
            return{
                ...state,
                set_catalog_product: action.payload.data as any[],
                status: action.payload.status,  
            }

        case "GET_CATALOGS_MISSSING_PRODUCT":
            let id = action.payload.id
            
            const missing_data = {
                'id':id,
                'products':action.payload.products
            }
            
            return{
                ...state,
                catalog_missing_data: missing_data,
                status: action.payload.status,  
            }
        default:
            return state
    }

}

export default catalogsReducer;