import { configurationService } from "../../service";


export const getAllAdminSettingsAction = () => (dispatch: any) => {
    configurationService.getSettings().then(response => {
        dispatch({
            type: "GET_ALL_ADMIN_SETTINGS",
            payload: response.data
        });
    });
};


export const postAdminSettingsAction = (markupValue: any, surchargeValue: any) => (dispatch: any) => {
    
    const settings = [
            ["hm.freight.club.markup", String(markupValue)],
            ["hm.freight.club.surcharge", String(surchargeValue)]
    ];

    configurationService.updateSettings(settings).then(response => {
        dispatch({
            type: "POST_ADMIN_SETTINGS",
            payload: response.data
        });
    });
};


export const adminSettingsResetStatusAction = () => (dispatch: any) => {
    dispatch({
        type: "ADMIN_SETTINGS_RESET_STATUS"
    });
};
