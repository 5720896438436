import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class AuthServiceImpl {

    apiLoginUrl = `${config.baseUrl}login?include_auth_token=1`;
    apiGetCsrfUrl = `${config.baseUrl}login`;
    apiUserRolesUrl = `${config.baseUrl}api/user-roles`


    userLogin(email: string, password: string) {

        return axios.post(this.apiLoginUrl, {
            "password": password,
            "email": email
        }, get_config());
    }

    getUserRoles() {
        return axios.get(this.apiUserRolesUrl, get_config());
    }

    getCsrfToken() {
        return axios.get(this.apiGetCsrfUrl, { data: null, headers: { 'Content-Type': 'application/json' } });
    }


    initCsrf() {
        
        this.getCsrfToken().then(function (resp) {
            let csrf_token = resp.data['response']['csrf_token']
            localStorage.setItem('csrf_token', csrf_token);
        })


        axios.interceptors.request.use(function (config: any) {
            let csrf_token = localStorage.getItem('csrf_token');
            //console.log("CONFIG", config["method"]);
            if (["post", "delete", "patch", "put", "get"].includes(config["method"])) {
                if (csrf_token !== '') {
                    config.headers["X-CSRF-Token"] = csrf_token
                }
            }
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });
    }

    init() {
        if (config.useCsrf) {
            this.initCsrf();
        }
    }
}
