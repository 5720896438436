
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLocalesAction } from "../../../redux/actions/translationsActions";
import { AppDispatch } from "../../../redux/Store";

import PageHeader from "../../shared/pagesHeader";

type LocaleInfo = {
    locale_code: string;
    locale_name: string;
};


const LocaleList = () => {

    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();

    const translationsState = useSelector((state: any) => state.translationsReducer);

    useEffect(() => {
        console.info("LocaleEditor Arrived");
        dispatch(getLocalesAction(true));
    }, [dispatch]);

    return (
        <>
            <div className='container'>
                <PageHeader
                    heading={"Locale List"}
                    backUrl={"/dashboard"}
                />

                {translationsState.locales.length > 0 ? (
                    
                    <ul className="list-group">{   translationsState.locales.map( (locale: LocaleInfo) => (
                        <li className="list-group-item" key={locale.locale_code} onClick={() => navigate(`/locales/${locale.locale_code}`)}>                            
                            <button className="btn btn-link">{locale.locale_name}</button>
                        </li>
                    ))}
                    </ul>
                ) : null}

            </div>
        </>
    )
}

export default LocaleList;
