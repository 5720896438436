import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../shared/pagesHeader";
import DataTable from "react-data-table-component";
import {getMyCatalogsAction } from "../../../redux/actions/hmCatalogsActions";
import { useNavigate} from "react-router-dom";
import { AppDispatch } from "../../../redux/Store";

const customStyles = {
    headRow: {
      style: {
        border: "none",
        fontSize: "15px"
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "10px",
        outline: "1px solid #FFFFFF",
      }, 
    },
  };


const Catalogs = () => {
    const dispatch : AppDispatch = useDispatch();
    const hmCatalogsState = useSelector((state: any) => state.hmCatalogsReducer);
    const navigate = useNavigate();

    useEffect (()=>{
        console.info("Catalogs Arrived");
        if(hmCatalogsState.catalogs.length < 1){
            dispatch(getMyCatalogsAction())
        }
            
    },[hmCatalogsState,dispatch])


    const rowClicked = (row: any, event: any) => {
        navigate("/catalogs/"+row.catalog_id)
      };
    
    const columns = [
        {
            name: "Name",
            selector: (row: any) => row.catalog_name,
        //   selector: (row: any) => row,
            sortable: true,
        },
        {
            name: "Catalog ID",
            selector: (row: any) => row.catalog_id,
        //   selector: (row: any) => row,
            sortable: true,
        },
    ]
  
    return (
        <>
            <div className='container'>
                <PageHeader
                    heading={"My Catalogs"}
                    backUrl={"/dashboard"}
                />

                <div className="my_catalogs">
                    <div className="my_catalogs_table">
                        <DataTable
                        columns={columns}
                        data={[...hmCatalogsState.catalogs]}
                        // selectableRows
                        pagination
                        highlightOnHover
                        pointerOnHover
                        customStyles={customStyles}
                        // actions={actionsMemo}
                        onRowClicked={(row: any, event) => rowClicked(row, event)}
                        // onSelectedRowsChange={filteredItems}
                        />
                        </div>
                </div>
               
            </div>
        
        </>
    )
}

export default Catalogs;