import { daysAdd } from "../../utils/Helper"
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { shipmentTrackingService } from "../../service";


export const getRecentShipmentTrackingAction = (loading_status: any = null) => (dispatch: any) => {
  if (loading_status) {
    dispatch(startLoadingAction());
    dispatch({
      type: "RESET_SHIPMENT_TRACKING"
    });
  }
  const fromDate = daysAdd(new Date(), -14);
  shipmentTrackingService.getShipmentTrackingList(fromDate).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_RECENT_SHIPMENT_TRACKING",
      payload: { data: response.data }
    });
  });
};
