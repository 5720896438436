const initialState = {
    data:null,
    record: null,
    request: null,
    response: null,
    status: false,
    errorCode:null,
    error:null
}

const fedexDebugReducer = (state=initialState, action:any) =>{
    switch(action.type){
        case "FEDEX_DEBUG":
            return {
                ...state,
                data: action.payload.data,
                record: action.payload.record,
                request: action.payload.request,
                response: action.payload.response,
                status:true
              };
        case "FEDEX_ERROR_DEBUG":
            return{
                ...state,
                error:action.payload.data.error,
                errorCode:action.payload.status,
            }
        default:
            return state;
    }
}

export default fedexDebugReducer;