

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFedexFicupRatesAction } from "../../../redux/actions/fedexFicupActions";
import { AppDispatch } from "../../../redux/Store";
import { startLoadingAction, stopLoadingAction } from "../../../redux/actions/loaderActions";
import PageHeader from "../../shared/pagesHeader";
import Modal from "react-bootstrap/Modal";
import { shipmentTrackingService } from "../../../service";
import { ToastContainer, toast } from "react-toastify";

const FedexFicupRates = (props: any) => {

    const dispatch: AppDispatch = useDispatch();
    const [showImportForm, setShowImportForm] = useState(false);

    const [importErrorMessage, setImportErrorMessage] = useState(null as string | null);
    const [uploadFormWarningMessages, setUploadFormWarningMessages] = useState([]);

    const [excelFormFile, setExcelFormFile] = useState(null as File | null);
    const fedexFicupState = useSelector((state: any) => state.fedexFicupReducer);


    const resetImportErrorMessages = () => {
        setImportErrorMessage(null);
        setUploadFormWarningMessages([]);
    }

    useEffect(() => {
        console.info("Fedex Ficup Rates Arrived");
        dispatch(getFedexFicupRatesAction(true));
    }, [dispatch]);

    const onImportClicked = () => (e: any) => {
        resetImportErrorMessages();
        setShowImportForm(true);
    };

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const onImportProcessClicked = (e: any) => {

        if (excelFormFile === null) {
            setImportErrorMessage("Excel file is required");
            return;
        }

        resetImportErrorMessages();
        dispatch(startLoadingAction());


        shipmentTrackingService.uploadFedexFicupRates(excelFormFile).then(
            response => {

                dispatch(getFedexFicupRatesAction(true));
                success("Upload complete!");
                setShowImportForm(false);
            }
        ).catch(error => {
            dispatch(stopLoadingAction());

            if ('undefined' !== typeof error.response.data.error_message) {
                setImportErrorMessage(error.response.data.error_message);
                if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
                    setUploadFormWarningMessages(error.response.data.warning_messages);
                    console.error("warnings", error.response.data.warning_messages);
                }
            } else {
                setImportErrorMessage(error.message);
            }

        })
    };

    const handleExcelFormChange = (e: any) => {

        if (importErrorMessage !== null) {
            resetImportErrorMessages();
        }

        setExcelFormFile(e.target.files[0]);

    };

    let country_names: any[] = []

    let hasFicupRates = fedexFicupState.ficup_rates && fedexFicupState.ficup_rates.length > 0;

    let ficup_rates: any[] = fedexFicupState.ficup_rates;
    if (hasFicupRates) {
        Object.keys(fedexFicupState.ficup_rates[0]).forEach((country) => {
            if (country !== "max_weight_lbs") {
                country_names.push(country);
            }
        });
    }

    return (
        <>
            <section className="user-pro mt-3">
                <div className="container">
                    <PageHeader
                        heading={"Fedex ficup rates"}
                        backUrl={"/admin"}
                        buttonName={"Import"}
                        handleClick={onImportClicked()}
                        buttonIcon={"fa-upload"}
                    />

                    <div className="container">


                        <div className="row justify-content-center align-items-center mt-3">

                            <div className="table_layout">

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            {hasFicupRates ? (
                                                <tr>
                                                    <th>Max Weight (LBS)</th>
                                                    {
                                                        country_names.map((name) => {
                                                            return (
                                                                <th>
                                                                    {name}
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ) : (
                                                <tr><th></th></tr>
                                            )}
                                        </thead>
                                        <tbody>
                                            {hasFicupRates ? (

                                                <>
                                                    {
                                                        ficup_rates.map((row: any) => {
                                                            return (
                                                                <tr>
                                                                    <td>{row.max_weight_lbs}</td>
                                                                    {
                                                                        country_names.map((name) => {
                                                                            return (
                                                                                <td title={`${name} ${row.max_weight_lbs} lbs rate ${row[name]}`}>
                                                                                    {row[name]}
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }

                                                                </tr>
                                                            )

                                                        })
                                                    }
                                                </>
                                            ) : (
                                                <tr>
                                                    <td>
                                                        {fedexFicupState.ficup_rates !== null && fedexFicupState.ficup_rates.length < 1 ? (<p>No Rates Available</p>) : (<p></p>)}
                                                    </td>
                                                </tr>

                                            )}
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>
                    </div>

                    <Modal
                        show={showImportForm}
                        dialogClassName="modal-lg"
                        aria-labelledby="example-modal-sizes-title-lg"
                        onHide={() => setShowImportForm(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                Import ficup rates
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {importErrorMessage ? (<>
                                <div className="row">
                                    <div className="col-md-10 offset-md-1 alert alert-danger">
                                        {importErrorMessage}
                                    </div>
                                </div>
                                {uploadFormWarningMessages.map(((warningMessage, idx) => {
                                    return (
                                        <div className="row" key={idx}>
                                            <div className="col-md-10 offset-md-1 alert alert-warning">
                                                {warningMessage}
                                            </div>
                                        </div>)
                                }))}

                            </>) : null}
                            <div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p>Excel document</p>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            onChange={handleExcelFormChange}
                                            type="file" className="form-control mb-30 detail_content" />
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={onImportProcessClicked}>Import</button>
                        </Modal.Footer>
                    </Modal>
                    <ToastContainer autoClose={3000} />
                </div>

            </section>
        </>
    )
}


export default FedexFicupRates;
