
import { useEffect, useState } from "react";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const FabricXrefForm = (props: any) => {

    const [validated, setValidated] = useState(false);
    const [fabricXrefFormData, setFabricXrefFormData] = useState({} as any);


    useEffect(() => {
        console.info("FabricXrefForm Arrived");

        if (props.fabricXref) {
            setFabricXrefFormData({
                ...props.fabricXref,
                no_frame: props.fabricXref.hm_frame === "NO Frame",
                hm_product_status: props.fabricXref.hm_product_status === null ? '__null' : props.fabricXref.hm_product_status
            });
        } else {
            setFabricXrefFormData(props.fabricXref ? props.fabricXref : { hm_product_status: null, no_frame: false });
        }

    }, [props.fabricXref]);


    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        let isValid = true;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            isValid = false;
        }

        setValidated(true);

        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries());

        if (isValid && props.handleSubmit) {
            event.preventDefault();
            event.stopPropagation();
            props.handleSubmit(formDataObj);
        }
    };

    const handleFormFieldUpdate = (event: any) => {
        let formData = { ...fabricXrefFormData }
        formData[event.target.name] = event.target.value
        setFabricXrefFormData(formData);
    };


    const handleFormFieldToggle = (event: any) => {
        let formData = { ...fabricXrefFormData }
        formData[event.target.name] = !formData[event.target.name];
        setFabricXrefFormData(formData);
    };

    const statusOptions = [
        {
            value: "__null",
            label: "[null]"
        },
        {
            value: "Drop",
            label: "Drop"
        }
    ];



    return (
        <div className="form-align">


            {props.errorMessage && (
                <Alert variant={"danger"}>
                    {props.errorMessage}
                </Alert>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Control type="hidden" name="id" value={fabricXrefFormData.id} />
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="supplierpartnumber">
                            <Form.Label>Supplier Part Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter supplier part number" name="supplierpartnumber" value={fabricXrefFormData.supplierpartnumber} onChange={handleFormFieldUpdate} readOnly={!!fabricXrefFormData.id} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="hm_fabric_number">
                            <Form.Label>Fabric SKU</Form.Label>
                            <Form.Control type="text" placeholder="Enter Fabric SKU" name="hm_fabric_number" value={fabricXrefFormData.hm_fabric_number} onChange={handleFormFieldUpdate} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="hm_frame">
                            <Form.Label>Frame SKU</Form.Label>
                            <Form.Check type="checkbox" name="no_frame" label="No Frame" onChange={handleFormFieldToggle} checked={fabricXrefFormData.no_frame} />
                            {!fabricXrefFormData.no_frame && (
                                <Form.Control type="text" placeholder="Enter Frame SKU" name="hm_frame" value={fabricXrefFormData.hm_frame} onChange={handleFormFieldUpdate} />
                            )}
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="hm_order_sku">
                            <Form.Label>Howard Miller Order SKU</Form.Label>
                            <Form.Control type="text" placeholder="Enter SKU" name="hm_order_sku" onChange={handleFormFieldUpdate} value={fabricXrefFormData.hm_order_sku} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="hm_product_status">
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                aria-label="Product status" name="hm_product_status" onChange={handleFormFieldUpdate} value={fabricXrefFormData.hm_product_status} required>
                                <option value="">Select status...</option>

                                {statusOptions.map((status: any) => (
                                    <option value={status.value} key={status.value}>{status.label}</option>
                                ))}

                            </Form.Select>

                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3" style={{ "marginTop": "2ex" }}>
                        <Button variant="primary" type="submit" className="mb-3">
                            Save
                        </Button>
                    </div>
                </div>
            </Form >
        </div >
    )
}

export default FabricXrefForm;