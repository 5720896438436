import { useEffect, useState } from "react";

import React from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { countries } from 'countries-list';

const CountryForm = (props: any) => {
  const [validated, setValidated] = useState(false);
  const [countryFormData, setCountryFormData] = useState({} as any);

  useEffect(() => {
    console.info("CountryForm Arrived");
    setCountryFormData(props.country ? props.country : {});
  }, [props.country]);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    let isValid = true;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    }

    setValidated(true);

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());
    if (isValid && props.handleSubmit) {
      event.preventDefault();
      event.stopPropagation();
      props.handleSubmit(formDataObj);
    }
  };

  const handleFormFieldUpdate = (event: any) => {
    let formData = { ...countryFormData };
    formData[event.target.name] = event.target.value;
    setCountryFormData(formData);    
  };

  const handleFormFieldToggle = (event: any) => {
    let formData = { ...countryFormData };
    formData[event.target.name] = !countryFormData[event.target.name];
    setCountryFormData(formData);    
  }

  return (
    <div className="form-align">
      {props.errorMessage && (
        <Alert variant={"danger"}>{props.errorMessage}</Alert>
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Control type="hidden" name="id" value={countryFormData.id} />
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="country_code">
              <Form.Label>Country</Form.Label>
              <Form.Select
                name="country_code"
                isInvalid={countryFormData.country_code === '' && validated}
                required
                aria-label="Select a Country"
                onChange={handleFormFieldUpdate}
                value={countryFormData.country_code}
                disabled={props.disableCountrySelect}
              >
                <option value="">Select a country</option>
                {Object.entries(countries)
                .sort(([, a], [, b]) => a.name.localeCompare(b.name))
                .map(([countryCode, country]) => (
                  <option
                    key={country.name}
                    value={countryCode}
                    disabled={props.existingCountries?.includes(countryCode)}
                  >
                     {`${country.name} [${countryCode}]`}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="vat_required">
              <Form.Label>VAT Required</Form.Label>
              <Form.Check
                type="checkbox"
                name="vat_required"
                onChange={handleFormFieldToggle}
                checked={countryFormData.vat_required}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="eori_required">
              <Form.Label>EORI Required</Form.Label>
              <Form.Check
                type="checkbox"
                name="eori_required"
                onChange={handleFormFieldToggle}
                checked={countryFormData.eori_required}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="reg_required">
              <Form.Label>Shipper OSS No. Required</Form.Label>
              <Form.Check
                type="checkbox"
                name="reg_required"
                onChange={handleFormFieldToggle}
                checked={countryFormData.reg_required}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="tax_id_required">
              <Form.Label>Tax ID Required</Form.Label>
              <Form.Check
                type="checkbox"
                name="tax_id_required"
                onChange={handleFormFieldToggle}
                checked={countryFormData.tax_id_required}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="arn_required">
              <Form.Label>ARN Required</Form.Label>
              <Form.Check
                type="checkbox"
                name="arn_required"
                onChange={handleFormFieldToggle}
                checked={countryFormData.arn_required}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="ddp_required">
              <Form.Label>DDP Required</Form.Label>
              <Form.Check
                type="checkbox"
                name="ddp_required"
                onChange={handleFormFieldToggle}
                checked={countryFormData.ddp_required}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Button variant="primary" type="submit" className="mb-3">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CountryForm;
