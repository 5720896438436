import { useDispatch, useSelector } from "react-redux"; 
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { AppDispatch } from "../../../../redux/Store";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { getWarehouseAction, getAllWayfairInventoryPerWarehouseAction, excludeProductsAction, removeExclusionsAction, getAllExcludedProductsPerWarehouseAction } from "../../../../redux/actions/wayfairActions";
import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";

import WarehouseForm from "../../../common/admin/wayfair/WarehouseForm";

interface InventoryItem {
    staged_inventory: {
        id: string;
        wayfair_supplier_part_number: string;
        wayfair_quantity_on_hand: number;
        wayfair_quantity_backordered: number;
        wayfair_quantity_on_order: number;
        wayfair_discontinued: boolean;
    };
    fabric_xref: {
        id: string;
        supplierpartnumber: string;
        hm_fabric_number: string;
        hm_frame: string;
        hm_product_status: string | null;
        hm_order_sku: string | null;
    };
}

const WayfairWarehouse = (props: any) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const params: any = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    const [filteredInventory, setFilteredInventory] = useState<InventoryItem[]>([]);
    const [initialExclusions, setInitialExclusions] = useState<string[]>([]);
    const [selectedPartNumbers, setSelectedPartNumbers] = useState<string[]>([]);

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());
        let warehouse = {
            "id": value.id,
            "wayfair_supplier_id": value.wayfair_supplier_id,
            "child_supplier_id": value.child_supplier_id,
            "warehouse_name": value.warehouse_name,
            "warehouse_mode_type": value.warehouse_mode_type
        };

        wayfairService.createOrUpdateWarehouse(warehouse).then(response => {
            dispatch(stopLoadingAction());
            navigate(`/admin/wayfair/supplier/${params.supplier_id}`);
        }).catch((response) => {
            setErrorMessage(response.message);
            dispatch(stopLoadingAction());
        });
    };

    const fetchInventoryAndExclusions = async (offset = 0, limit = 10) => {
        setInitialExclusions([]);
        setSelectedPartNumbers([]);
        await dispatch(getAllWayfairInventoryPerWarehouseAction(searchTerm, wayfairState.currentWarehouse.id, offset, limit));
        await dispatch(getAllExcludedProductsPerWarehouseAction(wayfairState.currentWarehouse.child_supplier_id));
    };

    const handleSearch = async () => {
        if (wayfairState.currentWarehouse?.id) {
            await fetchInventoryAndExclusions(0, limit);
        }
    };

    const handleBatchSave = async () => {
        const toExclude = filteredInventory.filter(
            (item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
                !selectedPartNumbers.includes(item.staged_inventory.wayfair_supplier_part_number)
            ).filter(
            (item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
                !initialExclusions.includes(item.staged_inventory.wayfair_supplier_part_number)
            ).map(
            (item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
                item.staged_inventory.wayfair_supplier_part_number
            );

        const toInclude = selectedPartNumbers.filter(key => initialExclusions.includes(key));

        if ((toExclude.length > 0 || toInclude.length > 0) && window.confirm(`Are you sure?`)) {
            const promises = [];
            if (toExclude.length > 0) {
                promises.push(dispatch(excludeProductsAction(wayfairState.currentWarehouse.child_supplier_id, toExclude)));
            }
            if (toInclude.length > 0) {
                promises.push(dispatch(removeExclusionsAction(wayfairState.currentWarehouse.child_supplier_id, toInclude)));
            }
            try {
                await Promise.all(promises);
                alert("Products updated successfully.");
                const updatedExclusions = initialExclusions.filter(part => !toInclude.includes(part)).concat(toExclude);
                setInitialExclusions(updatedExclusions);
                setSelectedPartNumbers(filteredInventory.filter(
                    (item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
                        !updatedExclusions.includes(item.staged_inventory.wayfair_supplier_part_number)
                    ).map(
                    (item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
                        item.staged_inventory.wayfair_supplier_part_number
                    ));
            } catch (error) {
                setErrorMessage("Failed to update products.");
                handleReset();
            }
        }
    };

    const handleCheckboxChange = (partNumber: string) => {
        if (selectedPartNumbers.includes(partNumber)) {
            setSelectedPartNumbers(selectedPartNumbers.filter(num => num !== partNumber));
        } else {
            setSelectedPartNumbers([...selectedPartNumbers, partNumber]);
        }
    };

    const handleSelectAllChange = () => {
        const newChangedPartNumbers: { [key: string]: boolean } = {};
        if (!selectAll) {
            filteredInventory.forEach(item => {
                newChangedPartNumbers[item.staged_inventory.wayfair_supplier_part_number] = true;
            });
            setSelectedPartNumbers(filteredInventory.map(item => item.staged_inventory.wayfair_supplier_part_number));
        } else {
            filteredInventory.forEach(item => {
                newChangedPartNumbers[item.staged_inventory.wayfair_supplier_part_number] = false;
            });
            setSelectedPartNumbers([]);
        }
        setSelectAll(!selectAll);
    };

    const handleReset = () => {
        setSelectedPartNumbers(filteredInventory.filter((item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
            !initialExclusions.includes(item.staged_inventory.wayfair_supplier_part_number)
        ).map((item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
            item.staged_inventory.wayfair_supplier_part_number));
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
        const newOffset = (newPage - 1) * limit;
        fetchInventoryAndExclusions(newOffset, limit); 
    };

    const handleLimitChange = (event: { target: { value: string; }; }) => {
        const newLimit = parseInt(event.target.value);
        setLimit(newLimit);
        setCurrentPage(1);
        fetchInventoryAndExclusions(0, newLimit);
    };

    useEffect(() => {
        if (params.id) {
            dispatch(getWarehouseAction(params.id));
            dispatch(getAllWayfairInventoryPerWarehouseAction('', params.id, 0, 10));
        }
    }, [dispatch, params.id]);

    useEffect(() => {
        if (wayfairState.currentWarehouse?.child_supplier_id) {
            dispatch(getAllExcludedProductsPerWarehouseAction(wayfairState.currentWarehouse.child_supplier_id));
        }
    }, [dispatch, wayfairState.currentWarehouse])

    useEffect(() => {
        if (wayfairState.allWayfairInventoryPerWarehouse && wayfairState.allExcludedProductsPerWarehouse) {
            const inventory = wayfairState.allWayfairInventoryPerWarehouse || [];
            setFilteredInventory(inventory);
            const excludedPartNumbers = wayfairState.allExcludedProductsPerWarehouse.map((item: any) => item.supplier_part_number);
            setInitialExclusions(excludedPartNumbers);
            setSelectedPartNumbers(inventory.filter((item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
                !excludedPartNumbers.includes(item.staged_inventory.wayfair_supplier_part_number)
            ).map((item: { staged_inventory: { wayfair_supplier_part_number: any; }; }) => 
                item.staged_inventory.wayfair_supplier_part_number));
        }
    }, [dispatch, wayfairState.allWayfairInventoryPerWarehouse, wayfairState.allExcludedProductsPerWarehouse]);

    return (
        <>
            <div className="container">
                <PageHeader
                    heading={"Wayfair Warehouse"}
                    backUrl={`/admin/wayfair/supplier/${params.supplier_id}`}
                />
                <WarehouseForm handleSubmit={handleSubmit} errorMessage={errorMessage} warehouse={wayfairState.currentWarehouse} />

                <div className="form-group">
                    <label className="d-flex mt-2 mb-2" htmlFor="search">
                        Search Part Number
                        <div className="d-flex fas fa-info-circle ml-1 mt-1 mb-2" title="To search for parts, enter the part number in the search field and click 'Search'. 
                            To perform an exact case-insensitive search, use '=' followed by the part number (e.g., '=12345').
                            Unselected parts will have their inventory set to zero for this warehouse.">
                        </div>
                    </label>
                    <div className="d-flex">
                        <input
                            type="text"
                            id="search"
                            className="form-control mr-2"
                            style={{ width: '300px' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <button className="d-flex btn btn-primary mt-2 mb-2" onClick={handleSearch}>Search</button>
                </div>
            </div>

            <div className="container">
                {wayfairState.inventoryLoading ? (
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                                <label htmlFor="limitSelect">Items per page: </label>
                                <select id="limitSelect" value={limit} onChange={handleLimitChange}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={5}>100</option>
                                    <option value={10}>500</option>
                                    <option value={20}>1000</option>
                                </select>
                            </div>
                            <div className="d-flex justify-content-center flex-grow-1" style={{ marginLeft: '20px' }}>
                                <p>Total results for this search: {wayfairState.totalSearchResult}</p>
                            </div>

                            <div className="d-flex justify-content-center align-items-center mb-3">
                                <button 
                                    className="btn btn-link" 
                                    onClick={() => handlePageChange(currentPage - 1)} 
                                    disabled={currentPage === 1}
                                    style={{ fontSize: '24px' }}
                                >
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <span style={{ margin: '0 20px', fontSize: '18px' }}>Page {currentPage}</span>
                                <button 
                                    className="btn btn-link" 
                                    onClick={() => handlePageChange(currentPage + 1)} 
                                    disabled={filteredInventory.length < limit}
                                    style={{ fontSize: '24px' }}
                                >
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>

                        <div className="table_layout">
                            <div className="table-responsive">
                                {filteredInventory.length > 0 ? (
                                    <>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <button className="btn btn-secondary" onClick={handleReset}>
                                                    <i className="fas fa-redo-alt"></i> Reset
                                                </button>
                                            </div>
                                            <div className="col-sm-8">
                                                <button className="btn btn-danger" onClick={handleBatchSave}>Save</button>
                                            </div>
                                        </div>

                                        <table className="table mb-2" cellSpacing="20">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                        />
                                                    </th>
                                                    <th>Part Number</th>
                                                    <th>Quantity on Hand</th>
                                                    <th>Quantity Backordered</th>
                                                    <th>Quantity on Order</th>
                                                    <th>Discontinued</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredInventory.map((item: InventoryItem) => {
                                                    const isChecked = selectedPartNumbers.includes(item.staged_inventory.wayfair_supplier_part_number);
                                                    return (
                                                        <tr key={item.staged_inventory.id}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isChecked}
                                                                    onChange={() => handleCheckboxChange(item.staged_inventory.wayfair_supplier_part_number)}
                                                                />
                                                            </td>
                                                            <td>{item.staged_inventory.wayfair_supplier_part_number}</td>
                                                            <td>{item.staged_inventory.wayfair_quantity_on_hand}</td>
                                                            <td>{item.staged_inventory.wayfair_quantity_backordered}</td>
                                                            <td>{item.staged_inventory.wayfair_quantity_on_order}</td>
                                                            <td>{item.staged_inventory.wayfair_discontinued ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>

                                        <div className="d-flex justify-content-center mb-2">
                                            <button className="btn btn-danger" onClick={handleBatchSave}>Save</button>
                                        </div>
                                    </>
                                ) : (
                                    <div>No Store data found...!!!</div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default WayfairWarehouse;
