import { get_config } from "../utils/Helper";
import axios from "axios";
import config from "../constants/config";

export class ShopifyStoreServiceImpl {

    apiStoresUrl = `${config.baseUrl}api/shopify-stores`;

    getStoreList() {
        return axios.get(this.apiStoresUrl, get_config());
    }

    getStore(id: string) {
        return axios.get(`${this.apiStoresUrl}/${id}`, get_config());
    }

    createStore(storeDetails: any) {
        return axios.post(this.apiStoresUrl, storeDetails, get_config());
    }

    updateStore(storeDetails: any) {
        return axios.put(this.apiStoresUrl, storeDetails, get_config());
    }

    deleteStore(storeId: string) {
        return axios.delete(
            `${this.apiStoresUrl}/${storeId}`,
            get_config()
        );
    }

    getUserList() {
        return axios.get(`${this.apiStoresUrl}/user-list`, get_config());
    }
}