
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";

import { AppDispatch } from "../../../../redux/Store";
import { getUnresolvedItemsAction } from "../../../../redux/actions/wayfairActions";
import PageHeader from "../../../shared/pagesHeader";
import { WayfairAdminMenu } from "../../../common/admin/wayfair/WayfairAdminMenu"
import { wayfairService } from "../../../../service";
import {
    startLoadingAction,
    stopLoadingAction,
} from "../../../../redux/actions/loaderActions";

const WayfairUnresolvedItems = (props: any) => {

    const dispatch: AppDispatch = useDispatch();

    const [showUploadReportForm, setShowUploadReportForm] = useState(false);
    const [uploadFormErrorMessage, setUploadFormErrorMessage] = useState(
        null as string | null
    );

    const [uploadFormWarningMessages, setUploadFormWarningMessages] = useState(
        []
    );

    const [uploadFormReportFile, setUploadFormReportFile] = useState(
        null as File | null
    );

    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);


    const resetUploadFormErrorMessages = () => {
        setUploadFormErrorMessage(null);
        setUploadFormWarningMessages([]);
    };


    useEffect(() => {
        console.info("Wayfair Unresolved Items Arrived");
        dispatch(getUnresolvedItemsAction())
    }, [dispatch]);


    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });


    const downloadXlsxClicked = () => (event: any) => {
        wayfairService.getUnresolvedItemsXlsx()
            .then((response) => {
                let filename =
                    response.headers["content-disposition"].split("filename=")[1];

                var file_data = new Blob([response.data], {
                    type: "application/octet-stream",
                });

                const url = window.URL.createObjectURL(file_data);
                const link = document.createElement("a");

                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                alert(error.message);
            });

    };

    const renderIssue = (item: any) => {

        switch (item.source) {
            case "INVENTORY_EXPORT":
                return "Unmapped";
            case "HM_WOODMARK_MISSING_FABRIC":
                return "Missing fabric";
            case "HM_WOODMARK_MISSING_FRAME":
                return "Missing frame";
            case "HM_WOODMARK_MISSING_FABRIC_AND_FRAME":
                return "Missing fabric and frame"
            default:
                return `[${item.source}]`;
        }

    };

    const handleItemClick = (supplierpartnumber: string) => (event: any) => {

        const supplierpartnumberEnc = encodeURIComponent(supplierpartnumber);
        navigate(`/admin/wayfair/fabric-xref/${supplierpartnumberEnc}`)
    };

    const handleReportChange = (e: any) => {
        if (uploadFormErrorMessage !== null) {
            resetUploadFormErrorMessages();
        }

        setUploadFormReportFile(e.target.files[0]);
    };

    const onUploadSaveClicked = () => (event: any) => {

        console.log("onUploadSaveClicked");

        if (uploadFormReportFile === null) {

            console.log("NULL");
            setUploadFormErrorMessage("Mapping file is required");
            return;
        }

        resetUploadFormErrorMessages();
        dispatch(startLoadingAction());

        wayfairService
            .importMappings(
                uploadFormReportFile
            )
            .then((response) => {
                success("Upload complete!");
                setShowUploadReportForm(false);
                dispatch(getUnresolvedItemsAction())
            })
            .catch((error) => {
                dispatch(stopLoadingAction());

                if ("undefined" !== typeof error.response && "undefined" !== typeof error.response.data.error_message) {
                    setUploadFormErrorMessage(error.response.data.error_message);
                    if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
                        setUploadFormWarningMessages(error.response.data.warning_messages);
                        console.error("warnings", error.response.data.warning_messages);
                    }
                } else {
                    setUploadFormErrorMessage(error.message);
                }
            });

    };

    const updateMappingsClicked = () => (event: any) => {
        setShowUploadReportForm(true);
    };


    return (
        <>
            <div className="wayfair-admin container">

                <WayfairAdminMenu tab="unresolved-items" />

                <PageHeader
                    heading={"Unresolved Items"}
                    backUrl={"/admin"}
                />


                <div className="d-flex justify-content-end mb-4">
                    <div className="me-3">
                        <button
                            onClick={downloadXlsxClicked()}
                            className="btn rounded-pill edit_prim_btn"><i className="fa fa-file-excel"></i> Download</button>
                    </div>
                    <div className="me-3">
                        <button
                            onClick={updateMappingsClicked()}
                            className="btn rounded-pill save_btn"><i className="fa fa-file-import"></i> Update</button>
                    </div>
                </div>

                <div className="form-align">


                    {wayfairState.unresolvedItems.data && (
                        <>
                            <p>Results: {1 + wayfairState.unresolvedItems.offset} - {wayfairState.unresolvedItems.data.records.length + wayfairState.unresolvedItems.offset} of {wayfairState.unresolvedItems.data.total}</p>

                            {
                                wayfairState.unresolvedItems.data.total > 0 ? (
                                    <>

                                        <div className="row justify-content-center align-items-center mt-3">

                                            <div className="table_layout">

                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Warehouse</th>
                                                                <th>Supplier Part Number</th>
                                                                <th>Issue</th>
                                                                <th>Fabric</th>
                                                                <th>Frame</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {wayfairState.unresolvedItems.data.records.map((unresolvedItem: any) => {

                                                                return (

                                                                    <tr key={`${unresolvedItem.wayfair_supplier_id}-${unresolvedItem.wayfair_supplier_part_number}`}>
                                                                        <td>
                                                                            {unresolvedItem.warehouse_name}
                                                                        </td>
                                                                        <td>
                                                                            {unresolvedItem.source === "INVENTORY_EXPORT" ? (
                                                                                <span className="btn btn-link disabled">{unresolvedItem.wayfair_supplier_part_number}</span>
                                                                            ) : (
                                                                                <span className="btn btn-link" onClick={handleItemClick(unresolvedItem.wayfair_supplier_part_number)}>{unresolvedItem.wayfair_supplier_part_number}</span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {renderIssue(unresolvedItem)}
                                                                        </td>
                                                                        <td title={`Fabric: ${unresolvedItem.hm_fabric_number}`}>
                                                                            {unresolvedItem.hm_fabric_number}
                                                                        </td>
                                                                        <td title={`Frame: ${unresolvedItem.hm_frame}`}>
                                                                            {unresolvedItem.hm_frame}
                                                                        </td>
                                                                    </tr>)
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <p>No unresolved items found</p>
                                )
                            }

                        </>


                    )}

                </div>

                <Modal
                    show={showUploadReportForm}
                    dialogClassName="modal-lg"
                    aria-labelledby="example-modal-sizes-title-lg"
                    onHide={() => setShowUploadReportForm(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Upload Mappings
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {uploadFormErrorMessage ? (
                            <>
                                <div className="row">
                                    <div className="col-md-10 offset-md-1 alert alert-danger">
                                        {uploadFormErrorMessage}
                                    </div>
                                </div>
                                {uploadFormWarningMessages.map((warningMessage, idx) => {
                                    return (
                                        <div className="row" key={idx}>
                                            <div className="col-md-10 offset-md-1 alert alert-warning">
                                                {warningMessage}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : null}

                        <div className="row">
                            <div className="col-md-4">
                                <p>Mappings Excel File</p>
                            </div>
                            <div className="col-md-8">
                                <input
                                    onChange={handleReportChange}
                                    type="file"
                                    className="form-control mb-30 detail_content"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={onUploadSaveClicked()}>
                            Save
                        </button>
                    </Modal.Footer>
                </Modal>

                <ToastContainer autoClose={3000} />

            </div>



        </>)
};

export default WayfairUnresolvedItems;