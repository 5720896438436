import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import React from "react";

import { AppDispatch } from "../../../redux/Store";

import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../redux/actions/loaderActions";
import { getCustomerAction, getAccountAction } from "../../../redux/actions/ficActions";
import { ficService } from "../../../service";
import PageHeader from "../../shared/pagesHeader";

import AccountForm from "../../common/customers/AccountForm";

const HmCustomerAccount = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const params: any = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const ficState = useSelector((state: any) => state.ficReducer);

  const handleSubmit = (value: any) => {
    setErrorMessage("");
    dispatch(startLoadingAction());
    
    let account = {
      id: value.id,
      company: value.company,
      hm_customer_id: ficState.currentCustomer.id,
      customer_no: value.customer_no,
      country: value.country,
      customer_dseq: value.customer_dseq,
      customer_vat_no: value.customer_vat_no,
      customer_eori_no: value.customer_eori_no,
      customer_ioss_no: value.customer_ioss_no,
      customer_tax_id: value.customer_tax_id,
      customer_arn: value.customer_arn,
      customer_ddp: value.customer_ddp === "on",
      enable_fic: value.enable_fic === "on",
      memo: value.memo,
    };

    ficService
      .createOrUpdateCustomerAccount(account)
      .then((response) => {
        dispatch(startLoadingAction());
        navigate(`/customer/${params.customer_id}`);
      })
      .catch((response) => {
        setErrorMessage(response.message);
        dispatch(stopLoadingAction());
      });
  };

  useEffect(() => {
    console.info("Fic Account Arrived");
    dispatch(getAccountAction(params.id));
    dispatch(getCustomerAction(params.customer_id));
  }, [dispatch, params.id, params.customer_id]);

  console.info(ficState);

  return (
    <>
      <div className="container">
        <PageHeader
          heading={"Fic Account"}
          backUrl={`/customer/${params.customer_id}`}
        />

        <AccountForm
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          account={ficState.currentAccount}
          customer={ficState.currentCustomer}
        />
      </div>
    </>
  );
};

export default HmCustomerAccount;
