import Alert from "react-bootstrap/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { AppDispatch } from "../../../redux/Store";
import { ficService } from "../../../service";
import { getAllCustomersAction } from "../../../redux/actions/ficActions";
import PageHeader from "../../shared/pagesHeader";
import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../redux/actions/loaderActions";

const HmCustomerList = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const ficState = useSelector((state: any) => state.ficReducer);

  const handleAddCustomerClick = () => {
    navigate("/customer/create");
  };

  const handleDeleteCustomerClick = (id: string) => (event: any) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      dispatch(startLoadingAction());
      ficService
        .deleteCustomers([id])
        .then((response) => {
          dispatch(getAllCustomersAction());
        })
        .catch((response) => {
          dispatch(stopLoadingAction());
          setErrorMessage(response.message);
        });
    }
  };

  useEffect(() => {
    console.info("HM Customer List Arrived");
    dispatch(getAllCustomersAction());
  }, [dispatch]);

  console.log("fic", ficState);

  return (
    <>
      <div className="fic-admin container">
        <PageHeader
          heading={"HM Customers"}
          backUrl={"/dashboard"}
          buttonName={"Add Customer"}
          buttonIcon={"fa-plus"}
          handleClick={handleAddCustomerClick}
        />

        {errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}

        <div className="form-align">
          <h2>Customers</h2>
          {ficState.customers && (
            <>
              {ficState.customers.total > 0 ? (
                <>
                  {ficState.customers.records.map((customer: any) => {
                    return (
                      <div className="row" key={customer.id}>
                        <div className="col-md-12">
                          <button
                            className="btn btn-link"
                            onClick={handleDeleteCustomerClick(customer.id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <button
                            className="btn btn-link"
                            onClick={() =>
                              navigate(`/customer/${customer.id}`)
                            }
                          >
                            {customer.customer_name}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>No customers found</p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HmCustomerList;
