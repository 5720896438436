import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class FedexShippingCostServiceImpl {

    apiFedexShippingCostUrl = `${config.baseUrl}api/fedex/shipping-cost`;
    apiFedexCountryListUrl = `${config.baseUrl}api/fedex/address`;
    apiFedexDebugShippingCostUrl = `${config.baseUrl}api/fedex/debug-shipping-cost-for-product`;

    getShippingCosts(country: any) {
        return axios.get(`${this.apiFedexShippingCostUrl}?country=${country}`, get_config())
    }

    getCountryList() {
        return axios.get(this.apiFedexCountryListUrl, get_config());
    }

    debugShippingCost(product_sku: string, country_code: string) {
        const data = {
            "product_sku": product_sku,
            "country_code": country_code
        }
        return axios.post(this.apiFedexDebugShippingCostUrl, data, get_config())
    }
}
