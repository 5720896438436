
const initialState = {
    users:[],
    status:false,
    newUserCreate:false,
    userUpdate:false,
    statusCode:null,
    mesg:''
}

const adminUserReducer = (state=initialState, action:any) =>{
    
    switch(action.type){        
        case "GET_ALL_USERS":
            return {
                ...state,
                users:action.payload,
                status:true
              };
        case "ADD_NEW_USER":
            return {
                ...state,
                users:action.payload,
                status:true,
                newUserCreate:true
            };
        case "ADD_NEW_USER_ERROR":      
            return {
                ...state,
                statusCode:action.payload.status,
                mesg:action.payload.data.mesg
            };
        case "ADMIN_UPDATE_USER":
            return {
                ...state,
                users:action.payload,
                status:true,
                userUpdate:true
            }
        case "ADMIN_UPDATE_USER_ERROR":
            return {
                ...state,
                statusCode:action.payload.status,
                mesg:action.payload.data.mesg
            }
        case "ADMIN_CLEAR_NEW_USER_CREATE":
            return {
                ...state,
                newUserCreate: false
            }
        case "ADMIN_CLEAR_USER_UPDATE":
            return {
                ...state,
                userUpdate: false
            }
        case "ADMIN_CLEAR_STATUS_CODE":
            return {
                ...state,
                statusCode: null
            }
            
        default:
            return state;
    }
}

export default adminUserReducer;

