const config = {
  // FHIR server base url
  baseUrl: "https://hmccdev.com/",
  /*
    auth: {
       bearer: 'token',
       // OR for basic auth
       user: 'user',
       pass: 'secret'
    },
    */
  // Valid Options are 'same-origin', 'include'
  credentials: "same-origin",
  headers: {
    /*
    'X-Custom-Header': 'Custom Value',
    'X-Another-Custom': 'Another Value',
    */
  },
  useCsrf: false,
};

export default config;
