import axios from "axios";
import config from "../constants/config";
import { get_config } from "../utils/Helper";

export class TradeshowConfigService {
  apiUrl = `${config.baseUrl}api/tradeshow-config`;

  getConfig() {
    const url = `${this.apiUrl}`;  
    return axios.get(url, get_config());
  }

  updateConfig(configData: any) {
    const url = `${this.apiUrl}`;
    return axios.put(url, configData, get_config());
  }
}
