const initialState = {
    countries:[]
}

const countryReducer = (state=initialState, action:any) =>{
    
    switch(action.type){
        case "SET_COUNTRIES":
            return {
                ...state,
                countries:action.payload.countries
              };
        default:
            return state;
    }
}

export default countryReducer;