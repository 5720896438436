

type OverstockPPCReportsTableProps = {
    rows: any[],
}

export const OverstockPPCReportsTable = (props: OverstockPPCReportsTableProps) => {

    return (
        <>
            <table className="table">
                <tbody>
                    <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Records</th>
                    </tr>

                    {
                        props.rows.map(
                            (row: any, idx: number) => (
                                <tr key={idx}>
                                    <td>{row.start_date}</td>
                                    <td>{row.end_date}</td>
                                    <td>{row.row_count}</td>
                                </tr>
                            )

                        )
                    }


                    {props.rows.length < 1 && (
                        <tr>
                            <td colSpan={3}>No reports available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );

}

export default OverstockPPCReportsTable;
