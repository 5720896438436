const initialState = {
    timezones:null
}

const temporalReducer = (state=initialState, action:any) =>{
    switch(action.type){
        case "SET_TIMEZONES":
            return {
                ...state,
                timezones:action.payload
              };      
        default:
            return state;
    }
}

export default temporalReducer;