
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class InventoryHoldsServiceImpl {
  apiUrl = `${config.baseUrl}api/inventory/holds`;

  
  createReport(
    documentType: string, 
    startDate: string, 
    endDate: string, 
    skuPrefix: string, 
    reportFile: File) {

    const url = `${this.apiUrl}/reports`;
    const formData = new FormData();
    formData.append("sku_prefix", skuPrefix);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("document", reportFile);
    formData.append("document_type", documentType);

    return axios.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authentication-Token': localStorage.getItem("token")
        },
      }
    );

  }

  getReportRequests() {

    const url = `${this.apiUrl}/reports`;

    return axios.get(
      url,
      get_config()
    );

  }

  getReportStatus(report_id: string) {
    const url = `${this.apiUrl}/reports/${report_id}/status`;
    return axios.get(
      url,
      get_config()
    );
  }


  getReportResult(report_id: string) {
    const url = `${this.apiUrl}/reports/${report_id}`;

    let cfg = get_config()
    return axios.get(
      url,
      {
        ...cfg,
        responseType: 'arraybuffer',
      }
    );
  }



}