
import { useEffect, useState } from "react";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const OverstockPPCAccountConfigForm = (props: any) => {

    const [validated, setValidated] = useState(false);
    const [configFormData, setConfigFormData] = useState({} as any);

    const companyOptions = [
        {
            id: 'HP',
            name: "Howard Miller"
        },
        {
            id: 'HB',
            name: "Hekman"
        }
    ];

    useEffect(() => {
        console.info("OverstockPPCAccountConfigForm Arrived");
        setConfigFormData(props.configFormData ? props.configFormData : {

            name: '',
            company: '', // required
            customer_id: '', // required
            customer_dseq: '', // required
            budget_perc_of_sale: '0.1000',
            max_no_sales_budget_perc: '0.5000',

            low_performance_penalty: '0.8333',
            high_performance_penalty: '0.0000',
            no_sales_penalty: '0.2500',
            new_product_penalty: '0.0000',

            months_product_is_new: 6,
            weekly_performance_factor: '1.0000',
            monthly_performance_factor: '1.0000',
            min_bid: '0.15',
            max_probability_zero_clicks_scalar: '10.0000',
            jitter_sample_size: 500,
            jitter_perc_max: '0.5000',
            weight_no_sales_by_target_budget: '0.75',
            weight_no_sales_by_target_clicks: '0.25',
            no_sales_max_wasted_clicks_error_factor: '3.0000',
            weight_profitable: '0.9000',
            weight_unprofitable: '0.1000',
            monthly_volume_per_spend_distribution_exponent: '2.0000',
            weekly_volume_per_spend_distribution_exponent: '2.0000',
            weekly_volume_per_spend_distribution_ema_periods: '4.0000'
        });
    }, [props.configFormData]);


    const handleSubmit = (event: any) => {
        const form = event.currentTarget;

        let isValid = true;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            isValid = false;
        }

        setValidated(true);

        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries());

        if (isValid && props.handleSubmit) {
            event.preventDefault();
            event.stopPropagation();
            props.handleSubmit(formDataObj);
        }
    };

    const handleFormFieldUpdate = (event: any) => {
        let formData = { ...configFormData }
        formData[event.target.name] = event.target.value
        setConfigFormData(formData);
    };

    return (
        <div className="form-align">


            {props.errorMessage && (
                <Alert variant={"danger"}>
                    {props.errorMessage}
                </Alert>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Control type="hidden" name="id" value={configFormData.id} />


                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="name">
                            <Form.Label>Account Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter account name" name="name" required onChange={handleFormFieldUpdate} value={configFormData.name} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">


                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="company">
                            <Form.Label>Company</Form.Label>
                            <Form.Select
                                isInvalid={configFormData.company === '' && validated}
                                required aria-label="Company" name="company" onChange={handleFormFieldUpdate} value={configFormData.company}>
                                <option value="">Select company...</option>

                                <>
                                    {companyOptions.map((modeType: any) => (
                                        <option value={modeType.id} key={modeType.id}>{modeType.name}</option>
                                    ))}
                                </>

                            </Form.Select>

                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>

                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="customer_id">
                            <Form.Label>Customer ID</Form.Label>
                            <Form.Control type="text" required placeholder="Enter customer id" name="customer_id" onChange={handleFormFieldUpdate} value={configFormData.customer_id} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="customer_dseq">
                            <Form.Label>Customer DSEQ</Form.Label>
                            <Form.Control type="text" required placeholder="Enter customer dseq" name="customer_dseq" onChange={handleFormFieldUpdate} value={configFormData.customer_dseq} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="budget_perc_of_sale">
                            <Form.Label>Total budget fraction of sales</Form.Label>
                            <Form.Control type="number" required placeholder="Enter value (0 to 1)" min="0" max="1" step="0.0001" name="budget_perc_of_sale" onChange={handleFormFieldUpdate} value={configFormData.budget_perc_of_sale} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="max_no_sales_budget_perc">
                            <Form.Label>Max budget fraction for products without sales</Form.Label>
                            <Form.Control type="number" required placeholder="Enter value (0 to 1)" min="0" max="1" step="0.0001" name="max_no_sales_budget_perc" onChange={handleFormFieldUpdate} value={configFormData.max_no_sales_budget_perc} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="min_bid">
                            <Form.Label>Minimum bid</Form.Label>
                            <Form.Control type="number" required placeholder="Enter bid" min="0" step="0.01" name="min_bid" onChange={handleFormFieldUpdate} value={configFormData.min_bid} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="low_performance_penalty">
                            <Form.Label>Low performance penalty</Form.Label>
                            <Form.Control type="number" required placeholder="Enter value (0 to 1)" min="0" max="1" step="0.0001" name="low_performance_penalty" onChange={handleFormFieldUpdate} value={configFormData.low_performance_penalty} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="high_performance_penalty">
                            <Form.Label>High performance pentalty</Form.Label>
                            <Form.Control type="number" required placeholder="Enter value (0 to 1)" min="0" max="1" step="0.0001" name="high_performance_penalty" onChange={handleFormFieldUpdate} value={configFormData.high_performance_penalty} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="no_sales_penalty">
                            <Form.Label>No sales penalty</Form.Label>
                            <Form.Control type="number" required placeholder="Enter value (0 to 1)" min="0" max="1" step="0.0001" name="no_sales_penalty" onChange={handleFormFieldUpdate} value={configFormData.no_sales_penalty} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" controlId="new_product_penalty">
                            <Form.Label>New product penalty</Form.Label>
                            <Form.Control type="number" required placeholder="Enter value (0 to 1)" min="0" max="1" step="0.0001" name="new_product_penalty" onChange={handleFormFieldUpdate} value={configFormData.new_product_penalty} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="months_product_is_new">
                            <Form.Label>Months product is considered new</Form.Label>
                            <Form.Control type="number" required placeholder="Enter number of months" min="0" name="months_product_is_new" onChange={handleFormFieldUpdate} value={configFormData.months_product_is_new} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weekly_performance_factor">
                            <Form.Label>Weekly performance factor</Form.Label>
                            <Form.Control type="number" required placeholder="Enter factor" min="0" step="0.0001" name="weekly_performance_factor" onChange={handleFormFieldUpdate} value={configFormData.weekly_performance_factor} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="monthly_performance_factor">
                            <Form.Label>Monthly performance factor</Form.Label>
                            <Form.Control type="number" required placeholder="Enter factor" min="0" step="0.0001" name="monthly_performance_factor" onChange={handleFormFieldUpdate} value={configFormData.monthly_performance_factor} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="max_probability_zero_clicks_scalar">
                            <Form.Label>Max probability of zero clicks scaler</Form.Label>
                            <Form.Control type="number" required placeholder="Enter scaler" min="0" name="max_probability_zero_clicks_scalar" onChange={handleFormFieldUpdate} value={configFormData.max_probability_zero_clicks_scalar} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="jitter_sample_size">
                            <Form.Label>Jitter sample size</Form.Label>
                            <Form.Control type="number" required placeholder="Enter sample size" min="1" name="jitter_sample_size" onChange={handleFormFieldUpdate} value={configFormData.jitter_sample_size} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="jitter_perc_max">
                            <Form.Label>Maximum jitter percentage</Form.Label>
                            <Form.Control type="number" required placeholder="Enter factor" min="0" max="1" step="0.0001" name="jitter_perc_max" onChange={handleFormFieldUpdate} value={configFormData.jitter_perc_max} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weight_no_sales_by_target_budget">
                            <Form.Label>No sales weight by target budget</Form.Label>
                            <Form.Control type="number" required placeholder="Enter factor" min="0" step="0.0001" name="weight_no_sales_by_target_budget" onChange={handleFormFieldUpdate} value={configFormData.weight_no_sales_by_target_budget} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weight_no_sales_by_target_clicks">
                            <Form.Label>No sales weight by target clicks</Form.Label>
                            <Form.Control type="number" required placeholder="Enter factor" min="0" step="0.0001" name="weight_no_sales_by_target_clicks" onChange={handleFormFieldUpdate} value={configFormData.weight_no_sales_by_target_clicks} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="no_sales_max_wasted_clicks_error_factor">
                            <Form.Label>Max wasted clicks for products without sales</Form.Label>
                            <Form.Control type="number" required placeholder="Enter factor" min="0" step="0.0001" name="no_sales_max_wasted_clicks_error_factor" onChange={handleFormFieldUpdate} value={configFormData.no_sales_max_wasted_clicks_error_factor} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>                

                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weight_profitable">
                            <Form.Label>Weight profitable</Form.Label>
                            <Form.Control type="number" required placeholder="Enter weight" min="0" step="0.0001" name="weight_profitable" onChange={handleFormFieldUpdate} value={configFormData.weight_profitable} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weight_unprofitable">
                            <Form.Label>Weight unprofitable</Form.Label>
                            <Form.Control type="number" required placeholder="Enter weight" min="0" step="0.0001" name="weight_unprofitable" onChange={handleFormFieldUpdate} value={configFormData.weight_unprofitable} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weight_overstock_rpt_sales">
                            <Form.Label>Weight Overstock Report Sales</Form.Label>
                            <Form.Control type="number" required placeholder="Enter weight" min="0" step="0.0001" name="weight_overstock_rpt_sales" onChange={handleFormFieldUpdate} value={configFormData.weight_overstock_rpt_sales} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weight_jba_order_sales">
                            <Form.Label>Weight Howard Miller Order Sales</Form.Label>
                            <Form.Control type="number" required placeholder="Enter weight" min="0" step="0.0001" name="weight_jba_order_sales" onChange={handleFormFieldUpdate} value={configFormData.weight_jba_order_sales} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="monthly_volume_per_spend_distribution_exponent">
                            <Form.Label>Monthly volume per spend distribution exponent</Form.Label>
                            <Form.Control type="number" required placeholder="Enter exponent" min="0" step="0.0001" name="monthly_volume_per_spend_distribution_exponent" onChange={handleFormFieldUpdate} value={configFormData.monthly_volume_per_spend_distribution_exponent} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weekly_volume_per_spend_distribution_exponent">
                            <Form.Label>Weekly volume per spend distribution exponent</Form.Label>
                            <Form.Control type="number" required placeholder="Enter exponent" min="0" step="0.0001" name="weekly_volume_per_spend_distribution_exponent" onChange={handleFormFieldUpdate} value={configFormData.weekly_volume_per_spend_distribution_exponent} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-4" controlId="weekly_volume_per_spend_distribution_ema_periods">
                            <Form.Label>Weekly volume per spend EMA periods</Form.Label>
                            <Form.Control type="number" required placeholder="Enter number of periods" min="0" step="0.0001" name="weekly_volume_per_spend_distribution_ema_periods" onChange={handleFormFieldUpdate} value={configFormData.weekly_volume_per_spend_distribution_ema_periods} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>                  

                <div className="row">
                    <div className="col-md-3">
                        <Button variant="primary" type="submit" className="mb-3">
                            Save
                        </Button>
                    </div>
                </div>
            </Form >
        </div >
    )
}

export default OverstockPPCAccountConfigForm;