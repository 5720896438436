import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { getAmazonContentVerificationDiffAction } from "../../../redux/actions/amazonContentVerificationActions";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import PageHeader from "../../shared/pagesHeader";
import { Button, Tabs } from "react-bootstrap";
import AmazonContentDuplicates from "../../shared/amazonContentDuplicates";
import Tab from 'react-bootstrap/Tab'
import { AppDispatch } from "../../../redux/Store";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "25px",
      outline: "1px solid #FFFFFF",
    },
  },
};

function convertArrayOfObjectsToCSV(array: any) {
  let result: any;
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: { [x: string]: any; }) => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;
      let new_item = JSON.stringify([item[key]]);
      let finalone = new_item.replace(/"|{|}/g, "");
      let final = finalone.replace(/,/g, ' ');
      result += final.substring(1, final.length - 1);
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(data: any) {
  console.log(data.length, 'data length')
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(data);
  if (csv == null) return;

  const filename = 'amazonveri.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const Export = ({ onExport }: { onExport: any }) => <Button onClick={(e: any) => onExport(e.target.value)}>Export to csv</Button>;



const AmazonContentVerification = () => {

  const dispatch: AppDispatch = useDispatch();


  const [show, setShow] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState({});
  const [selectedRow1, setSelectedRow1] = useState({});
  const [key, setKey] = useState('home');

  const amazonContentVerificationState = useSelector(
    (state: any) => state.amazonContentVerificationReducer
  );

  useEffect(() => {
    console.info("Amazon Verifications Arrived");
    if (amazonContentVerificationState.status !== 200) {
      dispatch(getAmazonContentVerificationDiffAction());
    }
  }, [amazonContentVerificationState.status, dispatch]);

  const rowClicked = (row: any, event: any) => {
    setShow(!show);
    setSelectedRow(row);
    // setSelectedRow1(row);
  };

  useEffect(() => {
    if (key === 'Content-Verification') {
      dispatch({
        type: 'UPDATE_DUPLICATE_DATA',
        payload: []
      })
    }
  }, [key, dispatch])


  const toTitleCase = (str: any) => {
    let tab_name = str.split("_")
    const new_tab = tab_name.join(" ")

    return new_tab.replace(
      /\w\S*/g,
      function (txt: any) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const filteredItems = ({ selectedRows }: any) => {

    setSelectedRow1(selectedRows)
    return (selectedRows);
  };

  const actionsMemo = useMemo(
    () => <Export onExport={() => downloadCSV(selectedRow1)} />,
    [selectedRow1]);


  const columns = [
    {
      name: "ASIN",
      selector: (row: any) => row.asin,
      sortable: true,
      width: '10em'
    },
    {
      name: "Missmatch Records",
      selector: (row: any) => {
        var keys = Object.keys(row);

        const mismatch_list: any = [];

        keys.map((data) => {
          if (data !== "asin" && data !== "feed_date") {
            mismatch_list.push(
              <span key={data} className="badge bg-info text-dark me-2">
                {data}
              </span>
            );
          }
          return null;
        });
        return mismatch_list;
      },
      sortable: true,
    },
  ];

  return (
    <>
      <div className="container">
        <PageHeader
          heading={"Amazon Verification"}
          backUrl={"/dashboard"}
        />

        <div className="tabs-verify">

          <Tabs
            id="controlled-tab-example"
            defaultActiveKey="Content-Verification"
            onSelect={(k: any) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="Content-Verification" title="Content Verification">
              <DataTable
                columns={columns}
                data={amazonContentVerificationState.data.mismatch}
                selectableRows
                pagination
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
                subHeader
                actions={actionsMemo}
                onRowClicked={(row: any, event) => rowClicked(row, event)}
                onSelectedRowsChange={filteredItems}
              />
            </Tab>
            <Tab eventKey="Possible-Duplicates" title="Possible Duplicates">
              {key === "Possible-Duplicates" && (
                <AmazonContentDuplicates />
              )}
            </Tab>

          </Tabs>

        </div>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Mismatch Report - ASIN({selectedRow.asin})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2 className="modal_head">
              Feed Date
            </h2>
            <div className="p-3">
              <div className="current_div feed_div">
                <h3 className="tablink_head mb-0">Current</h3>
                <p className="tab_content_data current_data">{selectedRow?.feed_date?.current}</p>
              </div>
              <div className="expected_div feed_div">
                <h3 className="tablink_head mb-0">Expected</h3>
                <p className="tab_content_data expected_data">{selectedRow?.feed_date?.expected}</p>
              </div>
            </div>
            <div className="accordion" id="accordionPanelsStayOpenExample">
              {Object.keys(selectedRow).map((key: any, index: any) => {
                if (key !== "asin" && key !== "feed_date") {
                  return (
                    <div className="accordion-item" key={key}>
                      <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="accordion-button cstm_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#' + key} aria-expanded="true" aria-controls={key}>
                          {toTitleCase(key)}
                        </button>
                      </h2>
                      <div id={key} className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                          <div className="current_div">
                            <h3 className="tablink_head mb-0">Current</h3>
                            {selectedRow[key]['current'] ? (<p className="tab_content_data current_data">{selectedRow[key]['current']}</p>) : (<h5 className="tab_content_data current_data no_value"> {'<NULL>'} </h5>)}
                          </div>
                          <div className="expected_div">
                            <h3 className="tablink_head mb-0">Expected</h3>
                            {selectedRow[key]['expected'] ? (<p className="tab_content_data expected_data">{selectedRow[key]['expected']}</p>) : (
                              <h5 className="tab_content_data expected_data no_value"> {'<NULL>'} </h5>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AmazonContentVerification;
