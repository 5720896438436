import { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const CustomerForm = (props: any) => {
  const [validated, setValidated] = useState(false);
  const [customerFormData, setCustomerFormData] = useState({} as any);

  useEffect(() => {
    console.info("CustomerForm Arrived");
    setCustomerFormData(props.customer ? props.customer : {});
  }, [props.customer]);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    let isValid = true;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    }

    setValidated(true);

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    if (isValid && props.handleSubmit) {
      event.preventDefault();
      event.stopPropagation();
      props.handleSubmit(formDataObj);
    }
  };

  const handleFormFieldUpdate = (event: any) => {
    let formData = { ...customerFormData };
    formData[event.target.name] = event.target.value;
    setCustomerFormData(formData);
  };

  return (
    <div className="form-align">
      {props.errorMessage && (
        <Alert variant={"danger"}>{props.errorMessage}</Alert>
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Control type="hidden" name="id" value={customerFormData.id} />
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Customer Name"
                name="customer_name"
                onChange={handleFormFieldUpdate}
                value={customerFormData.customer_name}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                placeholder="Company"
                name="company"
                onChange={handleFormFieldUpdate}
                value={customerFormData.company}
                maxLength={2}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_no">
              <Form.Label>Customer Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Customer Number"
                name="customer_no"
                onChange={handleFormFieldUpdate}
                value={customerFormData.customer_no}
                maxLength={16}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="contact_name">
              <Form.Label>Contact Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Name"
                name="contact_name"
                onChange={handleFormFieldUpdate}
                value={customerFormData.contact_name}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="contact_addr_1">
              <Form.Label>Contact Address 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Address Line 1"
                name="contact_addr_1"
                onChange={handleFormFieldUpdate}
                value={customerFormData.contact_addr_1}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="contact_addr_2">
              <Form.Label>Contact Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Address Line 2"
                name="contact_addr_2"
                onChange={handleFormFieldUpdate}
                value={customerFormData.contact_addr_2}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="contact_city">
              <Form.Label>Contact City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact City"
                name="contact_city"
                onChange={handleFormFieldUpdate}
                value={customerFormData.contact_city}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="contact_state">
              <Form.Label>Customer State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact State"
                name="contact_state"
                onChange={handleFormFieldUpdate}
                value={customerFormData.contact_state}
                maxLength={64}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="contact_country">
              <Form.Label>Contact Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Country"
                name="contact_country"
                onChange={handleFormFieldUpdate}
                value={customerFormData.contact_country}
                maxLength={3}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="postal_code">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postal Code"
                name="postal_code"
                onChange={handleFormFieldUpdate}
                value={customerFormData.postal_code}
                maxLength={32}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="shipping_contact_email">
              <Form.Label>Shipping Contact Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Shipping Contact Email"
                name="shipping_contact_email"
                onChange={handleFormFieldUpdate}
                value={customerFormData.shipping_contact_email}
                maxLength={255}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="memo">
              <Form.Label>Memo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Memo"
                name="memo"
                onChange={handleFormFieldUpdate}
                value={customerFormData.memo}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Button variant="primary" type="submit" className="mb-3">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CustomerForm;
