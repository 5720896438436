
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentShipmentTrackingAction } from "../../../redux/actions/shipmentTrackingActions";
import { AppDispatch } from "../../../redux/Store";
import PageHeader from "../../shared/pagesHeader";

const TrackingList = () => {

    const dispatch: AppDispatch = useDispatch();

    const [filters, setFilters] = useState({
        'record_timestamp': '',
        'service_type': '',
        'tracking_no': '',
        'company_no': '',
        'order_no': '',
        'cust_purchase_order': '',
        'weight_lbs': '',
    });

    const shippingTrackingState = useSelector((state: any) => state.shipingTrackingReducer);

    useEffect(() => {
        dispatch(getRecentShipmentTrackingAction(true));
    }, [dispatch]);

    const refreshButtonClick = () => {
        dispatch(getRecentShipmentTrackingAction(true));
    }


    const renderCompany = (record: any) => {
        if (record.company_no === 'HP') {
            return (
                <a href={`http://services.howardmiller.com/cgi-bin/IN7010E?sordn=${record.order_no}`} target="_blank" rel="noreferrer">Howard Miller</a>
            )
        }
        if (record.company_no === 'HB') {
            return (
                <a href={`http://services.hekman.com/cgi-bin/HK5039E?SORDN=${record.order_no}&STEXT=Y`} target="_blank" rel="noreferrer">Hekman</a>
            )
        }
        return record.company_no;
    };

    const renderOrderNo = (record: any) => {
        if (record.company_no === 'HP') {
            return (
                <a href={`http://services.howardmiller.com/cgi-bin/IN7010E?sordn=${record.order_no}`} target="_blank" rel="noreferrer">{record.order_no}</a>
            )
        }
        if (record.company_no === 'HB') {
            return (
                <a href={`http://services.hekman.com/cgi-bin/HK5039E?SORDN=${record.order_no}&STEXT=Y`} target="_blank" rel="noreferrer">{record.order_no}</a>
            )
        }
        return record.order_no;
    };

    const timestampFormat = new Intl.DateTimeFormat('en',
        {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        });

    const formatTimestamp = (value: string, fmt: Intl.DateTimeFormat) => {
        const val = new Date(value);
        return fmt.format(val);
    };

    const onFilterClearClick = (filterName: string) => {
        var update: any = {}
        update[filterName] = '';
        var newFilters = {
            ...filters,
            ...update
        };
        setFilters(newFilters);
    };

    const onFilterChange = (filterName: string, newValue: string) => {
        var update: any = {}
        update[filterName] = newValue;
        var newFilters = {
            ...filters,
            ...update
        };
        setFilters(newFilters);
    };

    const partialIMatch = (filter: string, value: string) => {
        return value != null && value.toLowerCase().indexOf(filter.trim().toLowerCase()) >= 0;
    };

    const prefixIMatch = (filter: string, value: string) => {
        return value.toLowerCase().startsWith(filter.toLowerCase());
    };

    const trackingListFilter = (value: any) => {
        var ret = true;

        if (filters.record_timestamp.trim().length > 0) {
            ret &&= prefixIMatch(filters.record_timestamp, formatTimestamp(value.record_timestamp, timestampFormat));
        }

        if (filters.tracking_no.trim().length > 0) {
            ret &&= partialIMatch(filters.tracking_no, value.tracking_no);
        }

        if (filters.order_no.trim().length > 0) {
            ret &&= partialIMatch(filters.order_no, value.order_no);
        }

        if (filters.cust_purchase_order.trim().length > 0) {
            ret &&= partialIMatch(filters.cust_purchase_order, value.cust_purchase_order);
        }

        if (filters.weight_lbs.trim().length > 0) {
            ret &&= partialIMatch(filters.weight_lbs, value.weight_lbs);
        }

        if (filters.service_type.trim().length > 0) {
            ret &&= value.service_type === filters.service_type;
        }

        if (filters.company_no.trim().length > 0) {
            ret &&= value.company_no === filters.company_no;
        }

        return ret;
    };

    return (
        <>
            <div className="container">
                <div className="inner-sec">
                    <div className="shipping-tracking container">
                        <PageHeader
                            heading={"Shipping Tracking List"}
                            backUrl={"/dashboard"}
                            handleClick={() => refreshButtonClick()}
                            buttonName={"Refresh"}
                            buttonIcon={"fa-sync"}
                        />
                    </div>

                    <div className="table_layout">
                        <div className="table-responsive">
                            <table className="table mb-2">
                                <thead>
                                    <tr>
                                        <th className="cstm-td table_head">Date/Time</th>
                                        <th className="cstm-td table_head">Carrier</th>
                                        <th className="cstm-td table_head">Tracking #</th>
                                        <th className="cstm-td table_head">Company</th>
                                        <th className="cstm-td table_head">Order #</th>
                                        <th className="cstm-td table_head">PO #</th>
                                        <th className="cstm-td table_head">Weight</th>
                                    </tr>
                                    <tr>
                                        <th className="cstm-td table_head table_filter">
                                            <div className="input-group" style={{ minWidth: "200px" }}>
                                                <input type="text" className="form-control" onChange={(e) => onFilterChange('record_timestamp', e.target.value)} value={filters.record_timestamp} />
                                                <div className="input-group-addon">
                                                    <span className="input-group-text"><button className="btn" style={{ lineHeight: '22px', padding: '0' }} aria-hidden="true" onClick={() => onFilterClearClick('record_timestamp')}><i className="fa fa-trash"></i> </button></span>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="cstm-td table_head table_filter">
                                            <select className="form-select form-control" style={{ minWidth: "100px" }} onChange={(e) => onFilterChange('service_type', e.target.value)} value={filters.service_type}>
                                                <option value=''>Any</option>
                                                <option value="UPS">UPS</option>
                                                <option value="FedEx">FedEx</option>
                                                <option value="USPS">USPS</option>
                                            </select>
                                        </th>
                                        <th className="cstm-td table_head table_filter">
                                            <div className="input-group">
                                                <input type="text" className="form-control" onChange={(e) => onFilterChange('tracking_no', e.target.value)} value={filters.tracking_no} />
                                                <div className="input-group-addon">
                                                    <span className="input-group-text"><button className="btn" style={{ lineHeight: '22px', padding: '0' }} aria-hidden="true" onClick={() => onFilterClearClick('tracking_no')}><i className="fa fa-trash"></i> </button></span>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="cstm-td table_head table_filter">
                                            <select className="form-select form-control" style={{ minWidth: "175px" }} onChange={(e) => onFilterChange('company_no', e.target.value)} value={filters.company_no}>
                                                <option value=''>Any</option>
                                                <option value="HP">Howard Miller</option>
                                                <option value="HB">Hekman</option>
                                            </select>
                                        </th>
                                        <th className="cstm-td table_head table_filter">
                                            <div className="input-group">
                                                <input type="text" className="form-control" onChange={(e) => onFilterChange('order_no', e.target.value)} value={filters.order_no} />
                                                <div className="input-group-addon">
                                                    <span className="input-group-text"><button className="btn" style={{ lineHeight: '22px', padding: '0' }} aria-hidden="true" onClick={() => onFilterClearClick('order_no')}><i className="fa fa-trash"></i> </button></span>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="cstm-td table_head table_filter">
                                            <div className="input-group">
                                                <input type="text" className="form-control" onChange={(e) => onFilterChange('cust_purchase_order', e.target.value)} value={filters.cust_purchase_order} />
                                                <div className="input-group-addon">
                                                    <span className="input-group-text"><button className="btn" style={{ lineHeight: '22px', padding: '0' }} aria-hidden="true" onClick={() => onFilterClearClick('cust_purchase_order')}><i className="fa fa-trash"></i> </button></span>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="cstm-td table_head table_filter">
                                            <div className="input-group">
                                                <input type="text" className="form-control" onChange={(e) => onFilterChange('weight_lbs', e.target.value)} value={filters.weight_lbs} />
                                                <div className="input-group-addon">
                                                    <span className="input-group-text"><button className="btn" style={{ lineHeight: '22px', padding: '0' }} aria-hidden="true" onClick={() => onFilterClearClick('weight_lbs')}><i className="fa fa-trash"></i> </button></span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                </thead>
                                {shippingTrackingState.loaded &&
                                    <tbody>
                                        {shippingTrackingState.recentTrackingList.filter(trackingListFilter).map((tracking: any, index: any) => (
                                            <tr key={tracking.id} className="cstm-tr table_body">
                                                <td>{formatTimestamp(tracking.record_timestamp, timestampFormat)}</td>
                                                <td>{tracking.service_type}</td>
                                                <td>{tracking.tracking_no}</td>
                                                <td>{renderCompany(tracking)}</td>
                                                <td>{renderOrderNo(tracking)}</td>
                                                <td>{tracking.cust_purchase_order}</td>
                                                <td>{tracking.weight_lbs} lbs</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default TrackingList;
