
const initialState = {
    reportedViolations: {
        offset: null,
        limit: null,
        data: null
    }
};


const mapViolationsReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case "GET_REPORTED_MAP_VIOLATIONS":
            return {
                ...state,
                reportedViolations: action.payload
            }
        default:
            return state
    }
}

export default mapViolationsReducer;