
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { productRestockNotificationsService } from "../../service";


export const getSubscriptionsNotificationsAction = () => (dispatch: any) => {
  dispatch(startLoadingAction());
  productRestockNotificationsService.getSubscriptions().then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_SUBSCRIPTIONS",
      payload: { subscriptions: response.data.subscription }
    })
  });
};


export const bulkRemoveSubscraptionsNotificationsAction = (skus: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  productRestockNotificationsService.removeSubscriptions(skus).then(response => {
    dispatch(getSubscriptionsNotificationsAction())
  })
};


export const addSubscriptionsAction = (skus: string[]) => (dispatch: any) => {
  dispatch(startLoadingAction());
  productRestockNotificationsService.addSubscriptions(skus).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "ADD_SUBSCRIPTIONS",
      payload: {
        "skus": skus,
      }
    });
  });

};

export const removeSubscriptionsAction = (skus: string[]) => (dispatch: any) => {  
  dispatch(startLoadingAction());
  productRestockNotificationsService.removeSubscriptions(skus).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "REMOVE_SUBSCRIPTIONS",
      payload: {
        "skus": skus,
      }
    });
  });
};

export const bulkAddSubscriptionsAction = (skus: string[]) => (dispatch: any) => {
  dispatch(startLoadingAction());
  productRestockNotificationsService.addSubscriptions(skus).then(response => {
    if (response.status === 200) {
      dispatch(getSubscriptionsNotificationsAction())
    }
  });
};
