import "./auth.css";
import { useSelector, useDispatch } from "react-redux";
import { authClearErrorsAction, loginAction } from "../../redux/actions/authActions";
import { setRoleInactiveAction } from "../../redux/actions/userRoleActions";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppDispatch } from "../../redux/Store";

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const authState = useSelector((state: any) => state.authReducer);
  const userRole = useSelector((state: any) => state.userRoleReducer);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const success = () =>
    toast.success("Succesfully Loggedin", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    console.info("Login Arrived");

    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (userRole.active) {
        if (localStorage.getItem("active")) {
          dispatch(setRoleInactiveAction())
          success();
          navigate("/dashboard")
        }
      }
    }
  }, [authState.isLoggedIn, userRole.active, navigate, dispatch]);

  useEffect(() => {
    if (authState.errors) {

      if (authState.errors.password && authState.errors.password.length > 0) {
        toast.error(authState.errors.password[0], {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        toast.error("Unexpected error " + authState.errors, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      dispatch(authClearErrorsAction())
    }
  });

  const onLoginClicked = () => {
    dispatch(loginAction(email, password))
  };

  return (
    <>
      <section id="signin" className="login_front_div">
        <div className="container">
          <div className="subdomain">
            <h1 className="sign_up_h1 text-center">Sign In</h1>
            <div className="sign_in_form">
              <div className="form-group col-md-12 inner-addon left-addon">
                <input
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  type="email"
                  name="email"
                  className="form-control"
                  id="uname"
                  placeholder="Email"
                  data-rule="minlen:4"
                  data-msg="Please enter at correct username"
                />
                <div className="validation"></div>
              </div>
              <div className="form-group col-md-12 inner-addon left-addon">
                <input
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                  type="password"
                  name="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  data-rule="minlen:4"
                  data-msg="Please enter at correct password"
                />
                <div className="validation"></div>
              </div>
              <div className="form-group col-md-12 checkbox">
              </div>
              <div className="form-group col-md-12 inner-addon">
                <button
                  onClick={() => onLoginClicked()}
                  type="button"
                  className="red-theme-btn"
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer autoClose={3000} />
    </>
  );
};

export default Login;
