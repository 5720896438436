
import { useEffect, useState } from "react";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import { getAllShopifyStoresAction } from "../../../../redux/actions/shopifyStoreActions";
import { AppDispatch } from "../../../../redux/Store";

const SupplierForm = (props: any) => {

  const dispatch: AppDispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const [supplierFormData, setSupplierFormData] = useState({} as any);


  const shopifyStoreState = useSelector((state: any) => state.shopifyStoreReducer);

  useEffect(() => {
    console.info("SupplierForm Arrived");
    setSupplierFormData(props.supplier ? props.supplier : {});
  }, [props.supplier]);

  useEffect(() => {
    console.info("Shopify stores data arrived");
    if (shopifyStoreState.stores === null) {
      dispatch(getAllShopifyStoresAction(false));
    }
  }, [dispatch, shopifyStoreState.stores]);


  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    let isValid = true;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    }

    setValidated(true);

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    if (isValid && props.handleSubmit) {
      event.preventDefault();
      event.stopPropagation();
      props.handleSubmit(formDataObj);
    }
  };

  const handleFormFieldUpdate = (event: any) => {
    let formData = { ...supplierFormData }
    formData[event.target.name] = event.target.value
    setSupplierFormData(formData);
  };

  const handleFormFieldToggle = (event: any) => {
    let formData = { ...supplierFormData }
    formData[event.target.name] = !formData[event.target.name];
    setSupplierFormData(formData);
  };

  return (
    <div className="form-align">


      {props.errorMessage && (
        <Alert variant={"danger"}>
          {props.errorMessage}
        </Alert>
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>


        <Form.Control type="hidden" name="id" value={supplierFormData.id} />
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="wayfair_supplier_id">
              <Form.Label>Supplier ID</Form.Label>
              <Form.Control type="number" placeholder="Enter supplier id" name="wayfair_supplier_id" onChange={handleFormFieldUpdate} value={supplierFormData.wayfair_supplier_id} />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Supplier Name" name="name" onChange={handleFormFieldUpdate} value={supplierFormData.name} />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="menu_label">
              <Form.Label>Supplier Menu Label</Form.Label>
              <Form.Control type="text" placeholder="Supplier Menu Label" name="menu_label" onChange={handleFormFieldUpdate} value={supplierFormData.menu_label} />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="menu_label">
              <Form.Label>Enabled</Form.Label>
              <Form.Check type="checkbox" name="enabled" label="Enabled" onChange={handleFormFieldToggle} checked={supplierFormData.enabled} />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-6" controlId="wayfair_client_id">
              <Form.Label>Supplier Client ID</Form.Label>
              <Form.Control type="text" placeholder="Supplier Client ID" name="wayfair_client_id" onChange={handleFormFieldUpdate} value={supplierFormData.wayfair_client_id} />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Form.Group className="mb-12" controlId="wayfair_client_secret">
              <Form.Label>Supplier Client Secret</Form.Label>
              <Form.Control type="text" placeholder="Supplier Client Secret" name="wayfair_client_secret" onChange={handleFormFieldUpdate} value={supplierFormData.wayfair_client_secret} />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Form.Group className="mb-12" controlId="shopify_marketplace">
              <Form.Label>Shopify marketplace</Form.Label>
              <Form.Control type="text" placeholder="Shopify marketplace" name="shopify_marketplace" onChange={handleFormFieldUpdate} value={supplierFormData.shopify_marketplace || ''} />
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>


        <div className="row">
          <div className="col-md-12">
            <Form.Group className="mb-12" controlId="shopify_marketplace">
              <Form.Label>Shopify Store</Form.Label>

              {shopifyStoreState.stores !== null && (
                <Form.Select aria-label="Select Store" name="shopify_store_id" onChange={handleFormFieldUpdate} value={supplierFormData.shopify_store_id || ''}>
                  <option value="">None</option>
                  {shopifyStoreState.stores.map((store: any) => (
                    <option value={store.id} key={store.id}>{store.account_id}</option>
                  ))}

                </Form.Select>
              )}

              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <Form.Group className="mb-4" controlId="filter_name">
              <Form.Label>Inventory Filter</Form.Label>

              {shopifyStoreState.stores !== null && (
                <Form.Select aria-label="Select Filter" name="filter_name" onChange={handleFormFieldUpdate} value={supplierFormData.filter_name || ''}>
                  <option value="">None</option>
                  <option value="europeanfilter">europeanfilter</option>
                </Form.Select>
              )}

              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
          </div>
        </div>



        <div className="row" style={{ marginTop: '1ex' }}>
          <div className="col-md-3">
            <Button variant="primary" type="submit" className="mb-3">
              Save
            </Button>
          </div>
        </div>
      </Form >
    </div >
  )
}

export default SupplierForm;