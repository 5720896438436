
import { overstockPPCService } from "../../service";
import { startLoadingAction, stopLoadingAction } from "./loaderActions";


export const getSponsoredProductConversionWeeklyReportsAction = (company: string, page: number = 0) => (dispatch: any) => {

    const limit = 12;
    let offset = 0;
    let page_no = page;

    if (page > 1) {
        offset = (page - 1) * limit;
    } else {
        page_no = 1;
    }

    dispatch({ type: "CLEAR_PRODUCT_CONVERSION_WEEKLY_REPORTS" });

    overstockPPCService.getSponsoredProductConversionWeeklyReports(company, offset, limit).then(response => {

        const record_from = 1 + ((page_no - 1) * limit);
        dispatch({
            type: "SET_PRODUCT_CONVERSION_WEEKLY_REPORTS",
            payload: {
                page: page_no,
                record_from: record_from,
                record_to: record_from + response.data.length,
                data: response.data.records,
                total_records: response.data.total,
                last_page: Math.ceil(response.data.total / limit),
                company: company,
                pending: false
            }

        });

    });
};

export const getSponsoredProductConversionMonthlyReportsAction = (company: string, page: number = 0) => (dispatch: any) => {

    const limit = 12;
    let offset = 0;
    let page_no = page;

    if (page > 1) {
        offset = (page - 1) * limit;
    } else {
        page_no = 1;
    }

    dispatch({ type: "CLEAR_PRODUCT_CONVERSION_MONTHLY_REPORTS" });

    overstockPPCService.getSponsoredProductConversionMonthlyReports(company, offset, limit).then(response => {

        const record_from = 1 + ((page_no - 1) * limit);
        dispatch({
            type: "SET_PRODUCT_CONVERSION_MONTHLY_REPORTS",
            payload: {
                page: page_no,
                record_from: record_from,
                record_to: record_from + response.data.length,
                data: response.data.records,
                total_records: response.data.total,
                last_page: Math.ceil(response.data.total / limit),
                company: company,
                pending: false
            }

        });

    });
};

export const getSponsoredProductAdvertisingWeeklyReportsAction = (company: string, page: number = 0) => (dispatch: any) => {

    const limit = 12;
    let offset = 0;
    let page_no = page;

    if (page > 1) {
        offset = (page - 1) * limit;
    } else {
        page_no = 1;
    }

    dispatch({ type: "CLEAR_PRODUCT_ADVERTISING_WEEKLY_REPORTS" });

    overstockPPCService.getSponsoredProductAdvertisingWeeklyReports(company, offset, limit).then(response => {

        const record_from = 1 + ((page_no - 1) * limit);
        dispatch({
            type: "SET_PRODUCT_ADVERTISING_WEEKLY_REPORTS",
            payload: {
                page: page_no,
                record_from: record_from,
                record_to: record_from + response.data.length,
                data: response.data.records,
                total_records: response.data.total,
                last_page: Math.ceil(response.data.total / limit),
                company: company,
                pending: false
            }

        });

    });
};

export const getSponsoredProductAdvertisingMonthlyReportsAction = (company: string, page: number = 0) => (dispatch: any) => {

    const limit = 12;
    let offset = 0;
    let page_no = page;

    if (page > 1) {
        offset = (page - 1) * limit;
    } else {
        page_no = 1;
    }

    dispatch({ type: "CLEAR_PRODUCT_ADVERTISING_MONTHLY_REPORTS" });

    overstockPPCService.getSponsoredProductAdvertisingMonthlyReports(company, offset, limit).then(response => {

        const record_from = 1 + ((page_no - 1) * limit);
        dispatch({
            type: "SET_PRODUCT_ADVERTISING_MONTHLY_REPORTS",
            payload: {
                page: page_no,
                record_from: record_from,
                record_to: record_from + response.data.length,
                data: response.data.records,
                total_records: response.data.total,
                last_page: Math.ceil(response.data.total / limit),
                company: company,
                pending: false
            }

        });

    });
};

export const getOrdersDashboardWeeklyReportsAction = (company: string, page: number = 0) => (dispatch: any) => {

    const limit = 12;
    let offset = 0;
    let page_no = page;

    if (page > 1) {
        offset = (page - 1) * limit;
    } else {
        page_no = 1;
    }

    dispatch({ type: "CLEAR_ORDERS_DASHBOARD_WEEKLY_REPORTS" });

    overstockPPCService.getOrdersDashboardWeeklyReports(company, offset, limit).then(response => {

        const record_from = 1 + ((page_no - 1) * limit);
        dispatch({
            type: "SET_ORDERS_DASHBOARD_WEEKLY_REPORTS",
            payload: {
                page: page_no,
                record_from: record_from,
                record_to: record_from + response.data.length,
                data: response.data.records,
                total_records: response.data.total,
                last_page: Math.ceil(response.data.total / limit),
                company: company,
                pending: false
            }

        });

    });
};

export const getOrdersDashboardMonthlyReportsAction = (company: string, page: number = 0) => (dispatch: any) => {

    const limit = 12;
    let offset = 0;
    let page_no = page;

    if (page > 1) {
        offset = (page - 1) * limit;
    } else {
        page_no = 1;
    }

    dispatch({ type: "CLEAR_ORDERS_DASHBOARD_MONTHLY_REPORTS" });

    overstockPPCService.getOrdersDashboardMonthlyReports(company, offset, limit).then(response => {

        const record_from = 1 + ((page_no - 1) * limit);
        dispatch({
            type: "SET_ORDERS_DASHBOARD_MONTHLY_REPORTS",
            payload: {
                page: page_no,
                record_from: record_from,
                record_to: record_from + response.data.length,
                data: response.data.records,
                total_records: response.data.total,
                last_page: Math.ceil(response.data.total / limit),
                company: company,
                pending: false
            }

        });

    });
};


export const getStrategiesAction = (company: string) => (dispatch: any) => {

    dispatch({
        type: "CLEAR_OVERSTOCK_PPC_STRATEGY_COMPANY",
    });

    overstockPPCService.getStrategies(company).then(response => {
        dispatch({
            type: "SET_OVERSTOCK_PPC_STRATEGIES",
            payload: {
                company: company,
                results: response.data
            }
        });

    });
};

export const getSalesReportsAction = (company: string) => (dispatch: any) => {
    overstockPPCService.getSalesReports(company).then(response => {

        const data = {
            company: company,
            monthly: {
                dates: response.data.monthly.dates,
                total_sales: response.data.monthly.total_sales.map((value: string) => Number(value))
            },
            weekly: {
                dates: response.data.weekly.dates,
                total_sales: response.data.weekly.total_sales.map((value: string) => Number(value))
            }
        }

        dispatch({
            type: "SET_OVERSTOCK_PPC_SALES_REPORTS",
            payload: data,
        })
    });
}


export const getConfigurationListAction = () => (dispatch:any) => {

    dispatch(startLoadingAction());
    overstockPPCService.getConfigurationList().then(response => {
        dispatch(stopLoadingAction());
        const data = {
            total: response.data.total,
            records: response.data.records
        }

        dispatch({
            type: "GET_ALL_OVERSTOCKPPC_CONFIGURATIONS",
            payload: data,
        })
    });
};


export const getOverstockPPCAccountConfigurationAction = (id: string) => (dispatch:any) => {

    dispatch(startLoadingAction());
    overstockPPCService.getConfiguration(id).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_CURRENT_OVERSTOCKPPC_CONFIGURATION",
            payload: response.data.records.length > 0 ? response.data.records[0] : null,
        })
    });
};
