
import { useEffect, useState } from "react";

import PageHeader from "../../../shared/pagesHeader";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import WayfairAdminMenu from "../../../common/admin/wayfair/WayfairAdminMenu";
import { getFabricXrefAction, getFixedInventoryBySupplierPartNumberAction, getWayfairOrderErrorsAction, getWayfairOrderSkuExceptionMap } from "../../../../redux/actions/wayfairActions";
import Modal from "react-bootstrap/Modal";

const WayfairOrderErrors = (props: any) => {

    const [orderError, setOrderError] = useState(null as any);
    const [showOrderError, setShowOrderError] = useState(false as boolean);

    const [fixedInventoryRedirectPartNumber, setFixedInventoryRedirectPartNumber] = useState(null as string | null);
    const [fabricMappingRedirectPartNumber, setFabricMappingRedirectPartNumber] = useState(null as string | null);
    const [skuExceptionRedirectInfo, setSkuExceptionRedirectInfo] = useState(null as any | null);


    const dispatch: AppDispatch = useDispatch();
    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    const navigate = useNavigate();

    useEffect(() => {
        console.info("Wayfair Order Errors Arrived");
        dispatch(getWayfairOrderErrorsAction(!!wayfairState.wayfairSandbox))
    }, [dispatch, wayfairState.wayfairSandbox]);


    const onToggleSandboxClicked = () => () => {
        dispatch(getWayfairOrderErrorsAction(!wayfairState.wayfairSandbox))
    }

    useEffect(() => {

        if (wayfairState.currentFabricXref && fabricMappingRedirectPartNumber) {

            const supplierpartnumberEnc = encodeURIComponent(fabricMappingRedirectPartNumber);

            if (wayfairState.currentFabricXref.supplierpartnumber === fabricMappingRedirectPartNumber) {
                setFabricMappingRedirectPartNumber(null);
                navigate(`/admin/wayfair/fabric-xref/${supplierpartnumberEnc}?returnTo=order-errors`);
            } else if (!wayfairState.currentFabricXref.supplierpartnumber && fabricMappingRedirectPartNumber) {
                setFabricMappingRedirectPartNumber(null);
                navigate(`/admin/wayfair/create-fabric-xref?supplierpartnumber=${supplierpartnumberEnc}&returnTo=order-errors`);
            }
        }

    }, [dispatch, navigate, wayfairState.currentFabricXref, fabricMappingRedirectPartNumber]);



    useEffect(() => {

        if (wayfairState.currentFixedInventory && fixedInventoryRedirectPartNumber) {

            if (wayfairState.currentFixedInventory.wayfair_supplier_part_number === fixedInventoryRedirectPartNumber) {
                setFabricMappingRedirectPartNumber(null);
                const fixedInventoryIdEnc = encodeURIComponent(wayfairState.currentFixedInventory.id);
                navigate(`/admin/wayfair/fixed-inventory/${fixedInventoryIdEnc}?returnTo=order-errors`);

            } else if (!wayfairState.currentFixedInventory.wayfair_supplier_part_number && fixedInventoryRedirectPartNumber) {
                setFabricMappingRedirectPartNumber(null);
                const supplierpartnumberEnc = encodeURIComponent(fixedInventoryRedirectPartNumber);
                navigate(`/admin/wayfair/create-fixed-inventory?wayfair_supplier_part_number=${supplierpartnumberEnc}&returnTo=order-errors`);
            }
        }

    }, [dispatch, navigate, wayfairState.currentFixedInventory, fixedInventoryRedirectPartNumber]);


    useEffect(() => {

        if (wayfairState.currentWayfairOrderSkuExceptionMap && skuExceptionRedirectInfo) {

            const { targetCustomerPo, targetSupplierPartNumber } = skuExceptionRedirectInfo;

            const targetCustomerPoEnc = encodeURIComponent(targetCustomerPo)
            const targetSupplierPartNumberEnc = encodeURIComponent(targetSupplierPartNumber)

            if (
                wayfairState.currentWayfairOrderSkuExceptionMap.id &&
                wayfairState.currentWayfairOrderSkuExceptionMap.wayfair_customer_po === targetCustomerPo &&
                wayfairState.currentWayfairOrderSkuExceptionMap.wayfair_supplier_part_number === targetSupplierPartNumber
            ) {
                setSkuExceptionRedirectInfo(null);
                navigate(`/admin/wayfair/order/${targetCustomerPoEnc}/sku-exceptions-map/${targetSupplierPartNumberEnc}`);
            } else if (
                !wayfairState.currentWayfairOrderSkuExceptionMap.id &&
                wayfairState.currentWayfairOrderSkuExceptionMap.wayfair_supplier_part_number === targetSupplierPartNumber &&
                wayfairState.currentWayfairOrderSkuExceptionMap.wayfair_supplier_part_number === targetSupplierPartNumber &&
                skuExceptionRedirectInfo) {
                setSkuExceptionRedirectInfo(null);
                navigate(`/admin/wayfair/order/${targetCustomerPoEnc}/sku-exceptions-map/${targetSupplierPartNumberEnc}/create`);

            }
        }

    }, [dispatch, navigate, wayfairState.currentWayfairOrderSkuExceptionMap, skuExceptionRedirectInfo]);


    const handleErrorClick = (orderError: any) => (err: any) => {
        setOrderError(orderError);
        setShowOrderError(true);
    }

    const handleUpdateFabricMappingClicked = (line: any) => (err: any) => {
        setOrderError(null);
        setShowOrderError(false);
        const targetSupplierPartNumber = line.partNumber;
        setFabricMappingRedirectPartNumber(targetSupplierPartNumber);

        if (wayfairState.currentFabricXref && wayfairState.currentFabricXref.supplierpartnumber === line.partNumber) {
            const supplierpartnumberEnc = encodeURIComponent(targetSupplierPartNumber);
            navigate(`/admin/wayfair/fabric-xref/${supplierpartnumberEnc}?returnTo=order-errors`);
        } else {
            dispatch(getFabricXrefAction(targetSupplierPartNumber));
        }

    }


    const handleUpdateSkuExceptionMapClicked = (line: any) => (err: any) => {
        setOrderError(null);
        setShowOrderError(false);

        const targetSupplierPartNumber = line.supplier_part_number;

        const targetCustomerPo = orderError.customer_po

        setSkuExceptionRedirectInfo({ targetSupplierPartNumber, targetCustomerPo });

        if (
            wayfairState.currentWayfairOrderSkuExceptionMap &&
            wayfairState.currentWayfairOrderSkuExceptionMap.id &&
            wayfairState.currentWayfairOrderSkuExceptionMap.wayfair_customer_po === targetCustomerPo &&
            wayfairState.currentWayfairOrderSkuExceptionMap.wayfair_supplier_part_number === targetSupplierPartNumber) {
            const supplierpartnumberEnc = encodeURIComponent(targetSupplierPartNumber);
            const customerPoEnc = encodeURIComponent(targetCustomerPo);
            navigate(`/admin/wayfair/order/${customerPoEnc}/sku-exceptions-map/${supplierpartnumberEnc}`);
        } else {
            dispatch(getWayfairOrderSkuExceptionMap(targetCustomerPo, targetSupplierPartNumber));
        }

    }

    const handleUpdateFixedInventoryClicked = (line: any) => (err: any) => {
        setOrderError(null);
        setShowOrderError(false);

        const targetSupplierPartNumber = line.partNumber;
        setFixedInventoryRedirectPartNumber(targetSupplierPartNumber);

        if (wayfairState.currentFixedInventory && wayfairState.currentFixedInventory.wayfair_supplier_part_number === line.partNumber) {
            const fixedInventoryIdEnc = encodeURIComponent(wayfairState.currentFixedInventory.id);
            navigate(`/admin/wayfair/fixed-inventory/${fixedInventoryIdEnc}?returnTo=order-errors`);
        } else {
            dispatch(getFixedInventoryBySupplierPartNumberAction(targetSupplierPartNumber));
        }
    }


    const handleUnknownItemUpdateFabricMappingClicked = (item: any) => (err: any) => {
        setOrderError(null);
        setShowOrderError(false);
        const targetSupplierPartNumber = item.supplier_part_number;
        setFabricMappingRedirectPartNumber(targetSupplierPartNumber);

        if (wayfairState.currentFabricXref && wayfairState.currentFabricXref.supplierpartnumber === item.supplier_part_number) {
            const supplierpartnumberEnc = encodeURIComponent(targetSupplierPartNumber);
            navigate(`/admin/wayfair/fabric-xref/${supplierpartnumberEnc}?returnTo=order-errors`);
        } else {
            dispatch(getFabricXrefAction(targetSupplierPartNumber));
        }
    }

    const handleUnknownItemUpdateFixedInventoryClicked = (item: any) => (err: any) => {
        setOrderError(null);
        setShowOrderError(false);

        const targetSupplierPartNumber = item.supplier_part_number;
        setFixedInventoryRedirectPartNumber(targetSupplierPartNumber);

        if (wayfairState.currentFixedInventory && wayfairState.currentFixedInventory.wayfair_supplier_part_number === item.supplier_part_number) {
            const fixedInventoryIdEnc = encodeURIComponent(wayfairState.currentFixedInventory.id);
            navigate(`/admin/wayfair/fixed-inventory/${fixedInventoryIdEnc}?returnTo=order-errors`);
        } else {
            dispatch(getFixedInventoryBySupplierPartNumberAction(targetSupplierPartNumber));
        }
    }

    const formatTimestamp = (ts: number) => {
        return (new Date(ts)).toLocaleString();
    }    

    const ourErrors = {
        error_code: null,
        hm_errors: [],
        hk_errors: [],
        other_errors: [],
        unknown_items: []
    };

    if (orderError) {
        ourErrors.error_code = orderError.error_info.error_code;
        if (orderError.error_info.error_code === "UNMATCHED_ITEMS") {
            ourErrors.hm_errors = orderError.error_info.data.unmatched_hm_items.filter((item: any) => item.company === "HP");
            ourErrors.hk_errors = orderError.error_info.data.unmatched_hm_items.filter((item: any) => item.company === "HB");
            ourErrors.other_errors = orderError.error_info.data.unmatched_hm_items.filter((item: any) => item.company !== "HB" && item.company !== "HP");

        }

        if (orderError.error_info.error_code === "UNKNOWN_ITEMS") {
            ourErrors.unknown_items = orderError.error_info.data.unknown_items;
        }

    }

    return (
        <>
            <div className="wayfair-admin container">

                <WayfairAdminMenu tab="order-errors" />

                <PageHeader
                    heading={"Order Errors"}
                    backUrl={"/admin"}
                />

                <div className="cstm-tr">


                    <div style={{ marginBottom: '1em' }} className="row">
                        <div className="form-check col-md-4 offset-md-8" style={{ textAlign: 'right' }}>
                            <div style={{ display: 'inline-block' }}>
                                <input className="form-check-input" id="sandboxCheckbox" type="checkbox" checked={!!wayfairState.wayfairSandbox} onChange={onToggleSandboxClicked()} />
                                <label className="form-check-label" htmlFor="sandboxCheckbox">Sandbox</label>
                            </div>
                        </div>
                    </div>

                    {wayfairState.wayfairOrderErrors && (
                        <>

                            {wayfairState.wayfairOrderErrors.total > 0 ? (
                                <>
                                    <p className="alert alert-info">Found {wayfairState.wayfairOrderErrors.total} errors</p>

                                    {wayfairState.wayfairOrderErrors.records.map((ent: any) => (

                                        <p className="alert alert-warning" style={{ "cursor": "pointer" }} onClick={handleErrorClick(ent)} key={ent.id}><span><u className="fas fa-eye"></u></span> {formatTimestamp(ent.created_at)} {ent.error_info.error_message}</p>

                                    ))}
                                </>
                            ) : (

                                <>
                                    <p className="alert alert-success">No order errors!</p>
                                </>
                            )}


                        </>

                    )}
                </div>

            </div>

            <Modal
                show={showOrderError}
                dialogClassName="modal-lg"
                aria-labelledby="example-modal-sizes-title-lg"
                onHide={() => setShowOrderError(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Order '{orderError && orderError.customer_po}' Error
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {ourErrors.hm_errors.length > 0 && (
                        <>
                            <h4>Found {ourErrors.hm_errors.length} unmatched Howard Miller order lines</h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Quantity Allocated</th>
                                        <th>Quantity Shipped</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ourErrors.hm_errors.map((line: any, idx: number) => (
                                        <tr key={idx}>
                                            <td >{line.item}</td>
                                            <td >{line.qty_allocated}</td>
                                            <td >{line.qty_shipped}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}

                    {ourErrors.hk_errors.length > 0 && (

                        <>
                            <h4>Found {ourErrors.hk_errors.length} unmatched Hekman order lines</h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Quantity Allocated</th>
                                        <th>Quantity Shipped</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ourErrors.hk_errors.map((line: any, idx: number) => (
                                        <tr key={idx}>
                                            <td >{line.item}</td>
                                            <td >{line.qty_allocated}</td>
                                            <td >{line.qty_shipped}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </>

                    )}

                    {ourErrors.error_code === "UNMATCHED_ITEMS" && (
                        <h4>Found {orderError && orderError.error_info.data.unmatched_wayfair_items.length} unmatched Wayfair order lines</h4>)}

                    {orderError && ourErrors.error_code === "UNMATCHED_ITEMS" && orderError.error_info.data.unmatched_wayfair_items.length > 0 && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Supplier Part Number</th>
                                    <th>Quantity</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {orderError && orderError.error_info.data.unmatched_wayfair_items.map((line: any, idx: number) => (

                                    <tr key={idx}>
                                        <td>{line.partNumber}</td>
                                        <td>{line.quantity}</td>
                                        <td className="buttonGroup">
                                            <button className="btn btn-warning" onClick={handleUpdateFabricMappingClicked(line)}>Update Fabric Mapping</button>
                                            <button className="btn btn-warning" onClick={handleUpdateFixedInventoryClicked(line)}>Update Fixed Inventory</button>
                                            <button className="btn btn-warning" onClick={handleUpdateSkuExceptionMapClicked(line)}>Update Exception</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    )}

                    {ourErrors.other_errors.length > 0 && (
                        <>
                            <h4>Found {ourErrors.other_errors.length} other unmatched order lines</h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Quantity Allocated</th>
                                        <th>Quantity Shipped</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ourErrors.other_errors.map((line: any, idx: number) => (
                                        <tr key={idx}>
                                            <td >{line.item}</td>
                                            <td >{line.qty_allocated}</td>
                                            <td >{line.qty_shipped}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}


                    {ourErrors.unknown_items.length > 0 && (
                        <>
                            <h4>Found {ourErrors.unknown_items.length} unknown order items</h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Supplier Part Number</th>
                                        <th>Quantity</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ourErrors.unknown_items.map((item: any, idx: number) => (
                                        <tr key={idx}>
                                            <td>{item.supplier_part_number}</td>
                                            <td>{item.quantity}</td>
                                            <td className="buttonGroup">
                                                <button className="btn btn-warning" onClick={handleUnknownItemUpdateFabricMappingClicked(item)}>Update Fabric Mapping</button>
                                                <button className="btn btn-warning" onClick={handleUnknownItemUpdateFixedInventoryClicked(item)}>Update Fixed Inventory</button>
                                                <button className="btn btn-warning" onClick={handleUpdateSkuExceptionMapClicked(item)}>Update Exception</button>
                                            </td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </>)
};


export default WayfairOrderErrors;
