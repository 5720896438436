import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConfigurationListAction } from "../../../../redux/actions/overstockPpcActions";

import { AppDispatch } from "../../../../redux/Store";
import PageHeader from "../../../shared/pagesHeader";


const OverstockPPCAdmin = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    
    const overstockPpcState = useSelector(
        (state: any) => state.overstockPpcReducer
      );

    useEffect(() => {
        console.info("Ocerstock PPC Admin Arrived");
        dispatch(getConfigurationListAction());
    }, [dispatch]);

    const onAddAccountClicked = () => {
        navigate('/admin/overstock-ppc/create-account');
    }

    return (
        <>
            <section className="user-pro mt-3">
                <div className="container">
                    <PageHeader
                        heading={"OverstockPPC Administration"}
                        backUrl={"/admin"}
                        buttonName={"Add Account"}
                        handleClick={onAddAccountClicked}
                        buttonIcon={"fa-upload"}
                    />
                
                <div className="text-left">
                {overstockPpcState.configurationRecords !== null && (
                    <>
                    <h4>Overstock accounts</h4>
                    {overstockPpcState.configurationRecords.records.length > 0 ? 

                        overstockPpcState.configurationRecords.records.map((row: any) => { return (
                            <div className="row" key={row.id} >
                                <p className="col-md-12 btn btn-link" style={{"textAlign": "left"}} onClick={() => navigate(`/admin/overstock-ppc/${row.id}`)}><i className="fa fa-edit"></i> {row.name}</p>
                            </div>
                    )}) : (
                        <p>No configurations found</p>
                    )}
                    </>

                )}
                </div>

                </div>  
            </section>
        </>
    )

}

export default OverstockPPCAdmin;