
import { useEffect } from "react";

import PageHeader from "../../../shared/pagesHeader";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import WayfairAdminMenu from "../../../common/admin/wayfair/WayfairAdminMenu";
import { getWayfairOrderShipmentErrorsAction } from "../../../../redux/actions/wayfairActions";

const WayfairShipmentErrors = (props: any) => {

    const dispatch: AppDispatch = useDispatch();
    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    useEffect(() => {
        console.info("Wayfair Order Errors Arrived");
        dispatch(getWayfairOrderShipmentErrorsAction(!!wayfairState.wayfairSandbox))
    }, [dispatch, wayfairState.wayfairSandbox]);


    const onToggleSandboxClicked = () => () => {
        dispatch(getWayfairOrderShipmentErrorsAction(!wayfairState.wayfairSandbox))
    }

    const formatTimestamp = (ts: number) => {
        return (new Date(ts)).toLocaleString();
    } 

    return (
        <>
            <div className="wayfair-admin container">

                <WayfairAdminMenu tab="shipment-errors" />

                <PageHeader
                    heading={"Shipment Errors"}
                    backUrl={"/admin"}
                />

                <div className="cstm-tr">

                    <div style={{ marginBottom: '1em' }} className="row">
                        <div className="form-check col-md-4 offset-md-8" style={{ textAlign: 'right' }}>
                            <div style={{ display: 'inline-block' }}>
                                <input className="form-check-input" id="sandboxCheckbox" type="checkbox" checked={!!wayfairState.wayfairSandbox} onClick={onToggleSandboxClicked()} />
                                <label className="form-check-label" htmlFor="sandboxCheckbox">Sandbox</label>
                            </div>
                        </div>
                    </div>

                    {wayfairState.wayfairOrderShipmentErrors && (
                        <>

                            {wayfairState.wayfairOrderShipmentErrors.total > 0 ? (
                                <>
                                    <p className="alert alert-info">Found {wayfairState.wayfairOrderShipmentErrors.total} errors</p>

                                    {wayfairState.wayfairOrderShipmentErrors.records.map((ent: any) => (

                                        <p className="alert alert-warning" key={ent.id}><span><u className="fas fa-exclamation-triangle"></u></span> {formatTimestamp(ent.created_at)} {ent.error_info.error_message}</p>

                                    ))}
                                </>
                            ) : (

                                <>
                                    <p className="alert alert-success">No shipment errors!</p>
                                </>
                            )}


                        </>

                    )}

                </div>

            </div>
        </>
    )

};

export default WayfairShipmentErrors;