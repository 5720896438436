
const initialState = {
    settings :[],
    statue:false,
    dataSaved: false
}


const adminSettingReducer = (state = initialState, action:any) => {
    switch(action.type) {
        case "GET_ALL_ADMIN_SETTINGS":
            return {
                ...state,
                settings:action.payload,
                status:true
            }
        case "POST_ADMIN_SETTINGS":
            return {
                ...state,
                settings:action.payload,
                status:true,
                dataSaved:true
            }
        case "ADMIN_SETTINGS_RESET_STATUS":
            return {
                ...state,
                status: false,
                dataSaved: false                
            }
        default:
            return state;
    }
};

export default adminSettingReducer;