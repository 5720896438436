import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AppDispatch } from "../../../../redux/Store";


import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";

import FabricXrefForm from "../../../common/admin/wayfair/FabricXrefForm";
import { useState } from "react";

const WayfairCreateFabricXref = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const getBackUrl = () => {
        if (searchParams.get('returnTo') === 'order-errors') {
            return `/admin/wayfair/order-errors`;
        }
        return `/admin/wayfair/fabric-mappings`;
    };

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());

        let has_id = !!value.id;

        let hm_product_status = value.hm_product_status === '__null' ? null : value.hm_product_status;

        let hm_frame = !!value.no_frame ? "NO Frame" : value.hm_frame;

        let fabricXref = has_id ? {
            "id": value.id,
            "supplierpartnumber": value.supplierpartnumber,
            "hm_fabric_number": value.hm_fabric_number,
            "hm_frame": hm_frame,
            "hm_product_status": hm_product_status,
            "hm_order_sku": value.hm_order_sku,
            "is_memocut": value.is_memocut && hm_frame === "NO Frame"
        } : {
            "supplierpartnumber": value.supplierpartnumber,
            "hm_fabric_number": value.hm_fabric_number,
            "hm_frame": hm_frame,
            "hm_product_status": hm_product_status,
            "hm_order_sku": value.hm_order_sku,
            "is_memocut": value.is_memocut && hm_frame === "NO Frame"
        };


        wayfairService.createOrUpdateFabricXref(fabricXref).then(response => {
            dispatch(stopLoadingAction());

            navigate(getBackUrl());
        }).catch((error) => {

            if (error.response && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage(error.message);
            }
            dispatch(stopLoadingAction());
        });

    };

    const formData: any = {}

    if (searchParams.get('supplierpartnumber')) {
        formData['supplierpartnumber'] = searchParams.get('supplierpartnumber');
    }

    return (
        <>
            <div className="container">
                <PageHeader
                    heading={"Create Wayfair Fabric Cross-reference"}
                    backUrl={getBackUrl()}
                />

                <FabricXrefForm handleSubmit={handleSubmit} errorMessage={errorMessage} fabricXref={formData} />

            </div>

        </>)
};

export default WayfairCreateFabricXref;
