const ficState = {
  customers: null,
  currentCustomer: null,
  currentAccount: null,
  customerAccountsLoading: false,
  customerAccounts: null,
  countries: null,
  currentCountry: null,
};

export const ficReducer = (state = ficState, action: any) => {
  switch (action.type) {
    case "GET_ALL_FIC_CUSTOMERS":
      return {
        ...state,
        customers: action.payload,
      };
    case "GET_CURRENT_FIC_CUSTOMER":
      return {
        ...state,
        currentCustomer: action.payload,
      };
    case "GET_FIC_CUSTOMER_ACCOUNTS_LOADING":
      return {
        ...state,
        customerAccountsLoading: action.payload,
      };
    case "GET_FIC_CUSTOMER_ACCOUNTS":
      return {
        ...state,
        customerAccounts: action.payload,
        customerAccountsLoading: false,
      };
    case "GET_CURRENT_FIC_ACCOUNT":
      return {
        ...state,
        currentAccount: action.payload,
      };
    case "GET_FIC_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };
    case "GET_CURRENT_FIC_COUNTRY":
      return {
        ...state,
        currentCountry: action.payload,
      };
    default:
      return state;
  }
};

export default ficReducer;
