import { useEffect, useMemo } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  getSubscriptionsNotificationsAction,
  bulkRemoveSubscraptionsNotificationsAction,
  bulkAddSubscriptionsAction,
  addSubscriptionsAction,
  removeSubscriptionsAction
} from "../../../redux/actions/subscriptionsActions";
import Switch from "react-switch";
import DataTable from "react-data-table-component";
import "./subscription.css";
import {Audio} from "react-loader-spinner";
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "25px",
      outline: "1px solid #FFFFFF",
    },
  },
};
const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

const SubscraptionsNotifications = (props: any) => {

  const [action, setAction] = useState("");
  const [selectedData, setSelectedData]: any = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterapply, setfilterapply] = useState(false);
  const [selectItemToggle, setselectItemToggle] = useState(true)
  const [resetPaginationToggle, setResetPaginationToggle]: any =
    useState(false);
  
  const subscriptionsState = useSelector((state: any) => state.subscriptionsReducer);
  const { loading } = useSelector((state: any) => state.LoaderReducer);
  const dispatch : AppDispatch = useDispatch();


  const handleChangeToggle = (sku: string, newValue: any) => {

    
    if (newValue) {
      dispatch(addSubscriptionsAction([sku]));
    } else {
      dispatch(removeSubscriptionsAction([sku]));
    }
    
  };

  const columns = [
    {
      name: "ID",
      selector: (row: any) => row.ID,
    },
    {
      name: "SKU",
      selector: (row: any) => row.SKU,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row: any) => row.Name,
      sortable: true,
    },
    {
      name: "Subscribed",
      selector: (row: any) => row.Subscribed,
      cell: (row: any) => (
        <label>
          <Switch
            onChange={(e) => handleChangeToggle(row.SKU, e)}
            checked={
              row.Subscribed
            }
          />
        </label>
      ),
      sortable: true,
    },
  ];

  useEffect(() => {
    console.info("Notifications Subscription Arrived");
    dispatch(getSubscriptionsNotificationsAction())
  }, [dispatch]);

  const handleClick = () => {
    const skus = [];
    for (let i = 0; i < selectedData.length; i++) {
      skus.push(selectedData[i]["SKU"]);
    }
    if (action === "Subscribe to selected") {
      dispatch(bulkAddSubscriptionsAction(skus));
    }
    if (action === "Unsubscribe from selected") {
      dispatch(bulkRemoveSubscraptionsNotificationsAction(skus));
    }
  };

  const handleChange = (state: any) => {
    setSelectedData(state.selectedRows);
    if (state.selectedRows.length > 0) {
      setselectItemToggle(false)
    }
    else {
      setselectItemToggle(true)
    }
  };

  const removeDuplicate = (data: any) => {
    const keys = ['ID']
    const filtered = data.filter(
      (s => (o: any) =>
        (k => !s.has(k) && s.add(k))
          (keys.map(k => o[k]).join('|'))
      )
        (new Set())
    );
    return filtered;
  }

  const multiSearch = () => {
    if (filterText.length > 0) {
      const result: any = []
      var searchData = filterText.split(",")
      searchData = searchData.filter(e => e);

      if (searchData.length > 0) {
        searchData.forEach(dta => {
          const filteredItems = subscriptionsState?.subscriptions?.filter(
            (item: any) =>
              (item.Name && item.Name.toLowerCase().includes(dta.toLowerCase())) ||
              (item.ID && item.ID.toLowerCase().includes(dta.toLowerCase())) ||
              (item.SKU && item.SKU.toLowerCase().includes(dta.toLowerCase()))
          );
          filteredItems.forEach((d: any) => {
            result.push(d)
          })
        });
      }
      return removeDuplicate(result)
    } else {
      setfilterapply(false)
    }
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setfilterapply(false)
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => {
            setfilterapply(true);
            setFilterText(e.target.value);
          }}
        />
        <ClearButton type="button" onClick={handleClear}>
          X
        </ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      {loading ?
        <div className="spinner">
            <Audio
            
            height={100}
            width={100}
            color="#00BFFF"
            ariaLabel="loading"          
        />
        </div>

        : <div className="container subscraptions">
            <PageHeader
              heading={"Notifications Subscription"}
              backUrl={"/dashboard"}
            />
          <div className="row">
            <div className="col-12">
              <div className="mb-3 d-flex">
                <select
                  onChange={(e) => setAction(e.target.value)}
                  className="form-select custom-select1"
                  defaultValue=""
                >
                  <option className="d-none" value="">Select Action</option>
                  <option>Subscribe to selected</option>
                  <option>Unsubscribe from selected</option>
                </select>
                <div className="col-md-2 ml-30">
                  <button
                    onClick={handleClick}
                    type="button"
                    disabled={!action || selectItemToggle}
                    className="btn btn-primary custom-primary dashbord-btn"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
              {subscriptionsState.subscriptions && (
                <DataTable
                  columns={columns}
                  data={filterapply ? multiSearch() : subscriptionsState.subscriptions}
                  selectableRows
                  pagination
                  highlightOnHover
                  pointerOnHover
                  customStyles={customStyles}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  onSelectedRowsChange={handleChange}
                />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SubscraptionsNotifications;