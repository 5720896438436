import React, { useEffect, useState } from "react";
import { getAmazonVerificationDuplicateAction, resetDuplicateDataAction } from "../../redux/actions/amazonContentVerificationActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { dateTimeFormater } from "../../utils/Helper";
import Spinner from 'react-bootstrap/Spinner'
import RangeSlider from 'react-bootstrap-range-slider';
import { AppDispatch } from "../../redux/Store";

const AmazonContentDuplicates = (props: any) => {

  const [page, setPage] = useState(1)
  const [colorBias, setColorBias] = useState(0)
  
  const pageSize = 20;

  const amazonContentVerificationState = useSelector((state: any) => state.amazonContentVerificationReducer);
  const dispatch : AppDispatch = useDispatch();

  useEffect(() => {
    console.info("Amazon Content Duplicates Arrived");
    dispatch(getAmazonVerificationDuplicateAction(page,colorBias,pageSize));

    /*
    return () => {
      amazonContentVerificationState.duplicate = []
    };
    */
  },[colorBias, dispatch, page, pageSize])

  const fetchMoreData = () => {
    setPage(page+1)
    dispatch(getAmazonVerificationDuplicateAction(page,colorBias,pageSize))
  };

  const changerange = () => {
    setPage(1)
    amazonContentVerificationState.duplicate = []
    dispatch(getAmazonVerificationDuplicateAction(page,colorBias,pageSize))
  }


  const sliderFocus = () =>{
    dispatch(resetDuplicateDataAction());
    amazonContentVerificationState.duplicate = []
  }

  return (

    <section>
      <div className="colorBias">
            <h4>Color Bias:</h4>
            <div className="range_conatiner">
              <RangeSlider
                className="cstm_range"
                value={colorBias}
                onChange={(e:any) => setColorBias(e.target.value)}
                tooltip='on'
                min={-1}
                max={1}
                step={0.1}
                onBlur={() => changerange()}
                onFocus={() => sliderFocus()}
              />
            </div>
      </div>
      <div>
      <InfiniteScroll
          dataLength={amazonContentVerificationState.duplicate.length}
          next={fetchMoreData}
          hasMore={true}
          loader={<div className="text-center"><Spinner animation="border" variant="info" /></div>}
          scrollableTarget="scrollableDiv"
          >
          
          {amazonContentVerificationState.duplicate.map((obj:any, index:any) => (
          <div className="collect_data d-flex" key={index} >

            <div className="d-flex">
              <div className="imgbx_container">
                <p className="my-2 text-center">Orig Image</p>
                <div className="imgbx_collect_data">
                  <img src={obj['orig_image_url']} alt="orig" />
                </div>
              </div>
              <div className="imgbx_container">
                <p className="my-2 text-center">Other Image</p>
                <div className="imgbx_collect_data">
                  <img src={obj['other_image_url']} alt="other" />
                </div>
              </div>
            </div>
            <div className="duplicate_details">
              <div className="">Certainty : {obj['certainty']}</div>
              <div className="">Created At : {dateTimeFormater(obj['created_at'])} </div>
              <div className="">Orig Asin : {obj['orig_asin']}</div>
              <div className="">Other Asin : {obj['other_asin']}</div>
              <div className="">SKU : {obj['sku']}</div>
              <div className="">Updated At : {dateTimeFormater(obj['updated_at'])} </div>
              <br />
            </div>
          </div>

          ))}
          </InfiniteScroll>
      </div>
    </section>
  );
};

export default AmazonContentDuplicates;
