

const SellerStoreLogs = (props: any) =>{
    const logs = props.data

    return(
        <>
        <div>
        {typeof(logs) === "object" ?
        <>
        <table className="table table-hover ">
        <thead>
                <tr>
                <th>#</th>
                <th scope="col">Level</th>
                <th scope="col">Message</th>
                <th scope="col">Task ID</th>
                <th scope="col">Time</th>
                </tr>
            </thead>
            {logs && (
                <tbody>
            
                {logs.map((log:any, index:any) =><tr key={index}>
                    <th>{index+1}</th>
                    <th><span className="badge bg-info text-dark me-2" >{log.level}</span></th>
                    <td>{log.msg}</td>
                    <td>{log.task_id}</td>
                    <td>{log.timestamp}</td></tr>
                    )}     
                </tbody>
            )}
            
            
        </table>
        </>
        :  <div className="error_center"> 
        {logs}
        </div> 
        }
        </div>
        </>
    );
}

export default SellerStoreLogs