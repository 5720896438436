import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllUserAction,
    createNewUserAction,
    adminUpdateUserDetailsAction,
    adminUserClearNewUserCreateAction,
    adminUserClearUserUpdateAction,
    adminUserClearStatusCodeAction
} from "../../../redux/actions/adminUserActions";
import { deleteUserClearStatusCodeAction, deleteUserProfileAction } from "../../../redux/actions/adminDeleteUserActions";
import { Audio } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import { checkValidEmail } from "../../../utils/Helper";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './admin.css'
import '../page.css'

import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";
import managedRoles from "../../../constants/managedRoles";

const UserList = () => {

    const defaultRoleChecksState: any = managedRoles.reduce(
        (acc: any, val: string) => { acc[val] = false; return acc },
        {});

    const [deleteUser, setDeleteUser] = useState('');
    const [editUser, setEditUser]: any = useState();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [addModalshow, setAddModalshow] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const [show, setShow] = useState(false);
    const [userRoles, setUserRoles] = useState(['user']);
    const [roleChecks, setRoleChecks] = useState(defaultRoleChecksState);

    const { loading } = useSelector((state: any) => state.LoaderReducer);
    const adminUserState = useSelector((state: any) => state.adminUserReducer);
    const adminDeleteUserState = useSelector((state: any) => state.adminDeleteUserReducer);

    const dispatch: AppDispatch = useDispatch();

    const warning = (msg: String) =>
        toast.warning(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const error = (msg: String) =>
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    useEffect(() => {
        console.info("Users List Arrived");
        dispatch(getAllUserAction(true));
    }, [dispatch])

    const confirmDelete = (id: any) => {
        dispatch(deleteUserProfileAction(deleteUser));
    }

    const saveChanges = () => {
        if (!checkValidEmail(email)) {
            warning("Please Enter A valid Email");
        } else if (password !== repassword || password.length < 1 || repassword.length < 1) {
            warning("Password Not Match");
        } else {
            dispatch(createNewUserAction(email, password, username, userRoles));
        }
    }

    const userUpdate = () => {
        if (!checkValidEmail(email)) {
            warning("Please Enter A valid Email");
        } else if (password !== repassword) {
            warning("Password Not Match");
        } else {
            const user_id = editUser.id
            dispatch(adminUpdateUserDetailsAction(email, password, user_id, userRoles))
        }
    }

    const handleClose = () => {
        setShow(false);
        setAddModalshow(false);
        setUsername('');
        setEmail('');
        setPassword('');
        setRepassword('');
        setEditClick(false);
        setRoleChecks(defaultRoleChecksState);
        setUserRoles(['user'])
    };

    const handleCloseAsCallback = useCallback(() => {
        setShow(false);
        setAddModalshow(false);
        setUsername('');
        setEmail('');
        setPassword('');
        setRepassword('');
        setEditClick(false);
        setRoleChecks(defaultRoleChecksState);
        setUserRoles(['user'])
    },[defaultRoleChecksState]);


    useEffect(() => {
        if (adminUserState.newUserCreate) {
            success("User Succesfully Created");
            dispatch(adminUserClearNewUserCreateAction());
            handleCloseAsCallback();
        } else if (adminUserState.userUpdate) {
            dispatch(adminUserClearUserUpdateAction());
            success("User Details Updated");
            handleCloseAsCallback();
        } else if (adminUserState.statusCode === 500) {
            adminUserState.statusCode = null
            dispatch(adminUserClearStatusCodeAction())
            setAddModalshow(false);
            error(adminUserState.mesg)
        } else if (adminUserState.statusCode === 400) {
            dispatch(adminUserClearStatusCodeAction())
            setAddModalshow(false);
            error(adminUserState.mesg)
            handleCloseAsCallback();
        }
    }, [adminUserState, handleCloseAsCallback, dispatch])

    useEffect(() => {
        if (adminDeleteUserState.status_code === 403) {
            dispatch(deleteUserClearStatusCodeAction());
            setShow(false)
            error("Permission Denied");

        } else if (adminDeleteUserState.status_code === 200) {
            setShow(false);
            dispatch(deleteUserClearStatusCodeAction());
            success("User Succesfully Deleted");
        }
    }, [adminDeleteUserState, dispatch])




    const deleteButtonClick = (id: any) => {
        setDeleteUser(id);
        setShow(true);
    }

    const addButtonClick = () => {
        setAddModalshow(true);
    }

    const EditUserClick = (data: any) => {
        setUsername(data.username);
        setEmail(data.email);
        setEditUser(data);
        setAddModalshow(true);
        setEditClick(true)

        let userrole = ['user']

        let roleState: any = { ...defaultRoleChecksState };


        data.roles.forEach((role: any) => {
            if (managedRoles.indexOf(role.name) >= 0) {
                roleState[role.name] = true;
            }
            if (userrole.indexOf(role.name) < 0) {
                userrole.push(role.name);
            }
        })

        setRoleChecks(roleState);
        setUserRoles(userrole)

    }

    useEffect(() => {
        if (editUser !== undefined) {
            setEditClick(true);
            setUsername(editUser.username);
            setEmail(editUser.email);
            setPassword('');
            setRepassword('');
        }

    }, [editUser])

    const roleExists = (data: any, role: any) => {
        let result = false
        data.forEach((e: { [x: string]: string; }) => {
            if (e['name'] === role) {
                result = true
            }
        });
        return result
    }


    const userRoleChanges = (role: string) => {
        let userrole = [...userRoles]
        let indexValue = null;

        let roleState: any = { ...roleChecks };

        if (managedRoles.indexOf(role) >= 0) {
            roleState[role] = !roleState[role];

            if (roleState[role]) {
                userrole.push(role);
            } else {
                indexValue = userrole.indexOf(role);
                if (indexValue > -1) {
                    userrole.splice(indexValue, 1);
                }
            }

            setRoleChecks(roleState);
            setUserRoles(userrole);
        }

    }

    return (
        <>
            <section className="user-sec py-2">
                <div className="container">
                    <div className="inner-sec">
                        <PageHeader
                            heading={"All User List"}
                            backUrl={"/admin"}
                            handleClick={() => addButtonClick()}
                            buttonName={"Add New"}
                        />

                        <div className="table_layout">
                            <div className="table-responsive">
                                <table className="table mb-2">
                                    <thead>
                                        <tr>
                                            <th className="cstm-td table_head">ID</th>
                                            <th className="cstm-td table_head">Username</th>
                                            <th className="cstm-td table_head">Email</th>
                                            <th className="cstm-td table_head">Status</th>
                                            <th className="cstm-td table_head">Roles</th>
                                            <th className="cstm-td table_head">Operation</th>
                                        </tr>

                                    </thead>
                                    {adminUserState.status &&
                                        <tbody>
                                            {adminUserState.users.map((data: any, index: any) => (
                                                <tr key={data.id}>
                                                    <td className="cstm-td">{data.id}</td>
                                                    <td className="cstm-td">{data.username}</td>
                                                    <td className="cstm-td">{data.email}</td>
                                                    <td className="cstm-td">
                                                        {data.active ? (
                                                            <span className="badge status_badge success">Active</span>
                                                        ) : (
                                                            <span className="badge status_badge danger">Inactive</span>
                                                        )}

                                                    </td>
                                                    <td className=" text-center">
                                                        {data.roles.map((roledata: any, roleindex: any) => (
                                                            <span key={roledata.name} className="badge bg-info text-dark me-2">{roledata.name}</span>
                                                        ))}
                                                    </td>

                                                    <td className="cstm-td">
                                                        <i
                                                            onClick={() => EditUserClick(data)}
                                                            className="fas fa-pencil-alt me-3"></i>
                                                        {!roleExists(data.roles, 'superuser') && (
                                                            <i
                                                                onClick={() => deleteButtonClick(data.id)}
                                                                className="fas fa-trash"></i>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={addModalshow} size="lg" onHide={handleClose} className="add_user_modal">
                <Modal.Header closeButton>
                    {editClick ? (
                        <Modal.Title>Update User</Modal.Title>
                    ) : (
                        <Modal.Title>Add New User</Modal.Title>
                    )}

                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-1" controlId="formBasicUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                readOnly={editClick}
                                type="email" placeholder="Enter username" />
                        </Form.Group>

                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email" placeholder="Enter email" />
                        </Form.Group>

                        <Form.Group className="mb-1" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password" placeholder="Password" />
                        </Form.Group>

                        <Form.Group className="mb-1" controlId="formBasicRePassword">
                            <Form.Label>Re-Password</Form.Label>
                            <Form.Control
                                value={repassword}
                                onChange={(e) => setRepassword(e.target.value)}
                                type="password" placeholder="Password" />
                        </Form.Group>
                        <div className="">
                            <Form.Label className="d-block">Roles</Form.Label>

                            <Form.Check
                                inline
                                label="user"
                                name="group1"
                                type="checkbox"
                                id="user"
                                disabled={true}
                                checked={true}
                            />

                            {managedRoles.map((role) => (
                                <Form.Check
                                    key={role}
                                    inline
                                    label={role}
                                    name="group1"
                                    type="checkbox"
                                    id={role}
                                    checked={roleChecks[role]}
                                    onChange={() => { userRoleChanges(role) }}
                                />))}

                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {editClick ? (
                        <Button variant="primary" onClick={() => userUpdate()}>
                            Update User
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={() => saveChanges()}>
                            Add User
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} className="delete_list_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => confirmDelete(deleteUser)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer autoClose={3000} />
            {loading &&
                <div className="spinner">
                    <Audio

                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            }
        </>
    )
};

export default UserList;