
import { wayfairService } from "../../service";
import { stopLoadingAction, startLoadingAction } from "./loaderActions";


export const getAllSuppliersAction = () => (dispatch: any) => {

    dispatch(startLoadingAction());
    wayfairService.getAllSuppliers().then(response => {
        dispatch({
            type: "GET_ALL_WAYFAIR_SUPPLIERS",
            payload: response.data
        })
        dispatch(stopLoadingAction());
    })
};

export const getAllFixedInventoryAction = () => (dispatch: any) => {

    dispatch(startLoadingAction());
    wayfairService.getAllFixedInventory().then(response => {
        dispatch({
            type: "GET_ALL_WAYFAIR_FIXED_INVENTORY",
            payload: response.data
        })
        dispatch(stopLoadingAction());
    })
};


export const getSupplierWithWarehousesAction = (id: string) => (dispatch: any) => {

    dispatch(startLoadingAction());
    dispatch({
        type: "GET_WAYFAIR_SUPPLIER_WAREHOUSES_LOADING",
        payload: true
    });
    wayfairService.getSupplier(id).then(response => {
        dispatch({
            type: "GET_CURRENT_WAYFAIR_SUPPLIER",
            payload: response.data.records[0]
        });
        wayfairService.getSupplierWarehouses(response.data.records[0].wayfair_supplier_id).then(response => {
            dispatch({
                type: "GET_WAYFAIR_SUPPLIER_WAREHOUSES",
                payload: response.data
            })
            dispatch(stopLoadingAction());
        });

    });

};


export const getAllSupplierWarehousesAction = (wait: boolean) => (dispatch: any) => {

    if (wait) {
        dispatch(startLoadingAction());
    }
    wayfairService.getAllSupplierWarehouses().then(response => {
        dispatch({
            type: "GET_ALL_WAYFAIR_SUPPLIER_WAREHOUSES",
            payload: {
                records: response.data.records,
                total: response.data.total
            }
        });
        if (wait) {
            dispatch(stopLoadingAction());
        }
    });

};





export const getSupplierAction = (id: string) => (dispatch: any) => {
    dispatch({
        type: "GET_WAYFAIR_SUPPLIER_WAREHOUSES_LOADING",
        payload: true
    });
    wayfairService.getSupplier(id).then(response => {
        dispatch({
            type: "GET_CURRENT_WAYFAIR_SUPPLIER",
            payload: response.data.records[0]
        });
    });
};


export const getFixedInventoryAction = (id: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getFixedInventory(id).then(response => {
        dispatch({
            type: "GET_CURRENT_WAYFAIR_FIXED_INVENTORY",
            payload: response.data.records[0]
        });
        dispatch(stopLoadingAction());
    });
};


export const getFixedInventoryBySupplierPartNumberAction = (wayfair_supplier_part_number: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getFixedInventoryBySupplierPartNumber(wayfair_supplier_part_number).then(response => {
        dispatch({
            type: "GET_CURRENT_WAYFAIR_FIXED_INVENTORY",
            payload: response.data.records.length > 0 ? response.data.records[0] : {}
        });
        dispatch(stopLoadingAction());
    });
};


export const getWarehouseAction = (id: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getWarehouse(id).then(response => {
        dispatch({
            type: "GET_CURRENT_WAYFAIR_WAREHOUSE",
            payload: response.data.records[0]
        });
        dispatch(stopLoadingAction());
    });
};


export const getWarehouseTypesAction = () => (dispatch: any) => {

    dispatch(startLoadingAction());
    wayfairService.getWarehouseTypes().then(response => {
        dispatch({
            type: "GET_WAYFAIR_WAREHOUSE_TYPES",
            payload: response.data
        });

        dispatch(stopLoadingAction());
    });

};

export const getUnresolvedItemsAction = () => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getUnresolvedItems(0, 10000).then(response => {
        dispatch({
            type: "GET_WAYFAIR_UNRESOLVED_ITEMS",
            payload: {
                data: response.data,
                offset: 0,
                limit: 10000
            }
        });

        dispatch(stopLoadingAction());
    });
};

export const getFabricXrefAction = (supplierpartnumber: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getFabricXref(supplierpartnumber).then(response => {
        dispatch({
            type: "GET_WAYFAIR_FABRIC_XREF",
            payload: response.data.records.length > 0 ? response.data.records[0] : {}
        });

        dispatch(stopLoadingAction());
    });
};

export const getWayfairOrderSkuExceptionMap = (wayfairCustomerPo: string, wayfairSupplierPartNumber: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getWayfairOrderSkuExceptionMap(wayfairCustomerPo, wayfairSupplierPartNumber).then(response => {    
        const payload = response.data.records.length > 0 ? response.data.records[0] : {
            "wayfair_customer_po": wayfairCustomerPo,
            "wayfair_supplier_part_number": wayfairSupplierPartNumber
        };
        dispatch({
            type: "GET_WAYFAIR_ORDER_SKU_EXCEPTION_MAP",
            payload: payload
        });

        dispatch(stopLoadingAction());
    });
};


export const getWayfairOrderErrorsAction = (wayfairSandbox: boolean) => (dispatch: any) => {
    dispatch(startLoadingAction());

    dispatch({
        type: "SET_WAYFAIR_SANDBOX",
        payload: {
            wayfairSandbox: wayfairSandbox
        }
    });
    wayfairService.getWayfairOrderErrors(wayfairSandbox).then(response => {
        dispatch({
            type: "GET_WAYFAIR_ORDER_ERRORS",
            payload: {
                records: response.data.records,
                total: response.data.total
            }
        });

        dispatch(stopLoadingAction());
    });
}


export const getAllWarehousesAction = () => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getAllWarehouses().then(response => {
        dispatch({
            type: "GET_ALL_WAREHOUSES",
            payload: response.data.warehouses
        });
        dispatch(stopLoadingAction());
    });
};


export const getAllWayfairInventoryPerWarehouseAction = (searchTerm = '', warehouseId = '') => (dispatch: any) => {
    dispatch({ type: "START_INVENTORY_LOADING" });
    wayfairService.getAllWayfairInventoryPerWarehouse(searchTerm, warehouseId).then(response => {
        dispatch({
            type: "GET_ALL_WAYFAIR_INVENTORY_PER_WAREHOUSE",
            payload: {
                inventory: response.data.inventory,
                total: response.data.total
            }
        });
        dispatch({ type: "STOP_INVENTORY_LOADING" });
        return response;
    }).catch(() => {
        dispatch({ type: "STOP_INVENTORY_LOADING" });
    });
};


export const getAllExcludedProductsPerWarehouseAction = (warehouseId: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    wayfairService.getAllExcludedProductsPerWarehouse(warehouseId).then(response => {
        dispatch({
            type: "GET_ALL_EXCLUDED_PRODUCTS_PER_WAREHOUSE",
            payload: {
                inventory: response.data.records,
                total: response.data.total
                }
        });
        dispatch(stopLoadingAction());
        return response;
    });
};


export const excludeProductsAction = (warehouseId: string, partNumbers: string[]) => (dispatch: any) => {
    dispatch(startLoadingAction());
    return wayfairService.excludeProducts(warehouseId, partNumbers).then(response => {
        dispatch({
            type: "EXCLUDE_PRODUCTS",
            payload: {
                "warehouse_id": warehouseId,
                "add_excluded_supplier_part_numbers": partNumbers
            }
        });
        dispatch(stopLoadingAction());
    }).catch((error: any) => {
        dispatch(stopLoadingAction());
        throw error; 
    });
};

export const removeExclusionsAction = (warehouseId: string, partNumbers: string[]) => (dispatch: any) => {
    dispatch(startLoadingAction());
    return wayfairService.removeExclusions(warehouseId, partNumbers).then(response => {
        dispatch({
            type: "REMOVE_EXCLUSIONS",
            payload: {
                "warehouse_id": warehouseId,
                "remove_excluded_supplier_part_numbers": partNumbers
            }
        });
        dispatch(stopLoadingAction());
    }).catch((error: any) => {
        dispatch(stopLoadingAction());
        throw error; 
    });
};


export const getWayfairOrderShipmentErrorsAction = (wayfairSandbox: boolean) => (dispatch: any) => {
    dispatch(startLoadingAction());
    dispatch({
        type: "SET_WAYFAIR_SANDBOX",
        payload: {
            wayfairSandbox: wayfairSandbox
        }
    });
    wayfairService.getWayfairOrderItemTrackingErrors(wayfairSandbox).then(response => {
        dispatch({
            type: "GET_WAYFAIR_ORDER_SHIPMENT_ERRORS",
            payload: {
                records: response.data.records,
                total: response.data.total
            }
        });

        dispatch(stopLoadingAction());
    });
}


export const getWayfairOrderItemTrackingPendingAction = (wayfairSandbox: boolean, offset: number, limit: number) => (dispatch: any) => {
    dispatch(startLoadingAction());
    dispatch({
        type: "SET_WAYFAIR_SANDBOX",
        payload: {
            wayfairSandbox: wayfairSandbox
        }
    });
    wayfairService.getWayfairOrderItemTrackingPending(wayfairSandbox, offset, limit).then(response => {
        dispatch({
            type: "GET_WAYFAIR_ORDER_ITEM_TRACKING_PENDING",
            payload: {
                records: response.data.records,                
                total: response.data.total,
                offset,
                limit
            }
        });

        dispatch(stopLoadingAction());
    });
}

