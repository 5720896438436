import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";


import { ToastContainer, toast } from "react-toastify";
import { AppDispatch } from "../../../../redux/Store";

import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { getSupplierWithWarehousesAction } from "../../../../redux/actions/wayfairActions";
import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";
import { useNavigate, useParams } from "react-router-dom";
import SupplierForm from "../../../common/admin/wayfair/SupplierForm";

import { WayfairAdminMenu } from "../../../common/admin/wayfair/WayfairAdminMenu"

const WayfairSupplier = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const [showUploadInventoryExportForm, setShowUploadInventoryExportForm] = useState(false as boolean);
    const [uploadFormErrorMessage, setUploadFormErrorMessage] = useState(null as string | null);
    const [uploadFormWarningMessages, setUploadFormWarningMessages] = useState([]);
    const [uploadFormReportFile, setUploadFormReportFile] = useState(null as File | null);

    const params: any = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());
        let supplier = {
            "id": value.id,
            "wayfair_supplier_id": value.wayfair_supplier_id,
            "name": value.name,
            "menu_label": value.menu_label,
            "enabled": !!value.enabled,
            "wayfair_client_id": value.wayfair_client_id,
            "wayfair_client_secret": value.wayfair_client_secret,
            "shopify_marketplace": value.shopify_marketplace,
            "shopify_store_id": value.shopify_store_id || null,
            "filter_name": value.filter_name || null
        };

        wayfairService.createOrUpdateSupplier(supplier).then(response => {
            dispatch(startLoadingAction());
            navigate('/admin/wayfair');
        }).catch((response) => {
            setErrorMessage(response.message);
            dispatch(stopLoadingAction());
        });
    };

    const handleDeleteWarehouseClick = (id: string) => (event: any) => {

        if (window.confirm("Are you sure you want to delete this warehouse?")) {
            dispatch(startLoadingAction());
            wayfairService.deleteWarehouses([id]).then(response => {
                dispatch(getSupplierWithWarehousesAction(params.id))
            }).catch((response) => {
                dispatch(stopLoadingAction());
                setErrorMessage(response.message);
            });
        }
    };

    const handleCreateWarehouseClick = (event: any) => {
        navigate(`/admin/wayfair/supplier/${params.id}/create-warehouse`);
    };

    const resetUploadFormErrorMessages = () => {
        setUploadFormErrorMessage(null);
        setUploadFormWarningMessages([]);
    };

    const handleReportChange = (event: any) => {
        if (uploadFormErrorMessage !== null) {
            resetUploadFormErrorMessages();
        }

        setUploadFormReportFile(event.target.files[0]);
    };

    const onUploadInventoryClicked = (event: any) => {
        setShowUploadInventoryExportForm(true);
    };

    const onUploadInventoryExportSaveClicked = (event: any) => {

        if (uploadFormReportFile === null) {
            setUploadFormErrorMessage("Report end date is required");
            return;
        }

        resetUploadFormErrorMessages();
        dispatch(startLoadingAction());

        wayfairService
            .uploadInventoryExport(
                wayfairState.currentSupplier.wayfair_supplier_id,
                uploadFormReportFile
            )
            .then((response) => {
                dispatch(stopLoadingAction());
                success("Upload complete!");
                setShowUploadInventoryExportForm(false);
            })
            .catch((error) => {
                dispatch(stopLoadingAction());

                if ("undefined" !== typeof error.response.data.error_message) {
                    setUploadFormErrorMessage(error.response.data.error_message);
                    if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
                        setUploadFormWarningMessages(error.response.data.warning_messages);
                        console.error("warnings", error.response.data.warning_messages);
                    }
                } else {
                    setUploadFormErrorMessage(error.message);
                }
            });

    };


    useEffect(() => {
        console.info("Wayfair Supplier Arrived");
        dispatch(getSupplierWithWarehousesAction(params.id))
    }, [dispatch, params.id]);

    return (
        <>
            <div className="container">

                <WayfairAdminMenu tab="suppliers" />

                <PageHeader
                    heading={"Wayfair Supplier"}
                    backUrl={"/admin/wayfair"}
                    buttonIcon={"fa-upload"}
                    buttonName={"Upload Inventory"}
                    handleClick={onUploadInventoryClicked}
                />



                <SupplierForm handleSubmit={handleSubmit} errorMessage={errorMessage} supplier={wayfairState.currentSupplier} />

                {!wayfairState.supplierWarehousesLoading && wayfairState.supplierWarehouses && (
                    <div className="form-align">
                        <h2>Warehouses</h2>

                        {
                            wayfairState.supplierWarehouses.total > 0 ? (
                                <>

                                    {wayfairState.supplierWarehouses.records.map((warehouse: any) => {

                                        return (

                                            <div className="row" key={warehouse.id}>
                                                <div className="col-md-12">
                                                    <button className="btn btn-link" onClick={handleDeleteWarehouseClick(warehouse.id)}><i className="fa fa-trash"></i></button>
                                                    <button className="btn btn-link" onClick={() => navigate(`/admin/wayfair/supplier/${params.id}/warehouse/${warehouse.id}`)}>{warehouse.warehouse_name}</button>
                                                </div>
                                            </div>)
                                    })}

                                </>
                            ) : (
                                <p>No warehouses found</p>
                            )
                        }

                        <div className="row">
                            <div className="col-md-12">
                                <button onClick={handleCreateWarehouseClick} className="add_new save_btn rounded-pill">
                                    <i className={`fas fa-plus me-2`}></i>Create Warehouse
                                </button>
                            </div>
                        </div>

                    </div>
                )}


            </div>


            <Modal
                show={showUploadInventoryExportForm}
                dialogClassName="modal-lg"
                aria-labelledby="example-modal-sizes-title-lg"
                onHide={() => setShowUploadInventoryExportForm(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Upload Inventory Export
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {uploadFormErrorMessage ? (
                        <>
                            <div className="row">
                                <div className="col-md-10 offset-md-1 alert alert-danger">
                                    {uploadFormErrorMessage}
                                </div>
                            </div>
                            {uploadFormWarningMessages.map((warningMessage, idx) => {
                                return (
                                    <div className="row" key={idx}>
                                        <div className="col-md-10 offset-md-1 alert alert-warning">
                                            {warningMessage}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : null}

                    <div className="row">
                        <div className="col-md-4">
                            <p>Inventory Export File</p>
                        </div>
                        <div className="col-md-8">
                            <input
                                onChange={handleReportChange}
                                type="file"
                                className="form-control mb-30 detail_content"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={onUploadInventoryExportSaveClicked}
                    >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>

            <ToastContainer autoClose={3000} />

        </>)
};

export default WayfairSupplier;

