
const deleteInitialState = {
    status:false,
    status_code:null
}
export const adminDeleteUserReducer = (state=deleteInitialState, action:any) =>{
    switch(action.type){
        case "DELETE_USER_ERROR":
            return {
                ...state,
                status:true,
                status_code:action.payload.status
              };
        case "DELETE_USER_SUCCESS":
            return {
                ...state,
                status:true,
                status_code:action.payload.status
              };
        case "DELETE_USER_CLEAR_STATUS_CODE":
            return {
                ...state,
                status_code: null
            }
        default:
            return state;
    }
}

export default adminDeleteUserReducer;
