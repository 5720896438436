import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { amazonSellerCentralService } from "../../../service";
import {
    getSellerCentralStoresAction,
    updateStoreDataAction,
    sellerCentralZeroOutAction,
    sellerCentralUpdateInventoryAction,
    sellerCentralUpdatePriceAction,
    getSellerCentralStoreLogsAction,
    sellerCentralClearBrandAddAction,
    sellerCentralClearStoreUpdateAction,
    sellerCentralClearStatusCodeAction,
    sellerCentralClearUpdateInventoryStatusCodeAction,
    sellerCentralClearZeroOutStatusCodeAction,
    sellerCentralClearUpdatePriceStatusCodeAction,
} from "../../../redux/actions/amazonSellerCentralActions";

import { Audio } from "react-loader-spinner";

import './rbmws.css'

import { ToastContainer, toast } from "react-toastify";

import SellerCentralStoreForm from "../../shared/sellerCentralStoreForm";

import SellerStoreLogs from '../../shared/sellerStoreLogs';
import { AppDispatch } from "../../../redux/Store";
import { isSuperUser } from "../../../utils/Helper";

const RbmwsDetails = () => {

    const dispatch: AppDispatch = useDispatch();
    const params: any = useParams();
    const navigate = useNavigate();

    const [accountId, setAccountID] = useState('');
    const [addressToCode, setAddressToCode] = useState('');
    const [awsAccessKey, setAwsAccessKey] = useState('');
    const [awsSecretKey, setAwsSecretKey] = useState('');
    const [brands, setBrands] = useState([]);

    const [dsClientNos, setDsClientNos] = useState([]);
    const [origDsClientNos, setOrigDsClientNos] = useState([]);

    const [reportEmails, setReportEmails] = useState([]);
    const [origReportEmails, setOrigReportEmails] = useState([]);

    const [commission, setCommission] = useState('');
    const [dbHost, setDbHost] = useState('');
    const [dbPort, setDbPort] = useState('');
    const [dbPassword, setDbPassword] = useState('');
    const [dbUser, setDbUser] = useState('');
    const [dbTable, setDbTable] = useState('');
    const [inventoryUpdate, setInventoryUpdate] = useState(false);
    const [pricingUpdate, setPricingUpdate] = useState(true);
    const [googleIgnoreSheet, setGoogleIgnoreSheet] = useState('');
    const [sheetsReportingId, setSheetsReportingId] = useState('');
    const [googleStockSheet, setGoogleStockSheet] = useState('');
    const [handlingTime, setHandlingTime] = useState('');
    const [holdBack, setHoldBack] = useState('');
    const [lwaAppID, setLwaAppID] = useState('');
    const [lwaClientSecret, setLwaClientSecret] = useState('');
    const [marketplace, setMarketplace] = useState('');
    const [profitMargin, setProfitMargin] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [roleArn, setRoleArn] = useState('');
    const [serviceAccountFile, setServiceAccountFile] = useState(null as any);
    const [hasServiceAccountFile, setHasServiceAccountFile] = useState(false);
    const [serviceAccountFilename, setServiceAccountFilename] = useState(null as any);
    const [serviceAccountFileData, setServiceAccountFileData] = useState(null as any);
    const [originalServiceAccountFilename, setOriginalServiceAccountFilename] = useState(null as any);
    const [deleteServiceAccountFile, setDeleteServiceAccountFile] = useState(false);
    const [skuPrefix, setSkuPrefix] = useState('');
    const [taxRate, setTaxRate] = useState('');
    const [editClick, setEditClick] = useState(true);
    const [handlingMode, setHandlingMode] = useState('');
    const [handlingTimeNonParcel, setHandlingTimeNonParcel] = useState('');
    const [spapiReportEncoding, setSpapiReportEncoding] = useState('');
    const [saveDetailsFeed, setSaveDetailsFeed] = useState(false);
    const [saveInventoryFeed, setSaveInventoryFeed] = useState(false);
    const [savePricesFeed, setSavePricesFeed] = useState(false);
    const [ownerUserId, setOwnerUserId] = useState('');
    const [marketServiceType, setMarketServiceType] = useState('');
    const [s3AccessKey, setS3AccessKey] = useState('');
    const [s3SecretKey, setS3SecretKey] = useState('');
    const [s3Bucket, setS3Bucket] = useState('');
    const [zeroLtl, setZeroLtl] = useState(false);
    const [reportingOnly, setReportingOnly] = useState(false);
    const [useFedexFicupRates, setUseFedexFicupRates] = useState(false);
    const [acendaDefaultShippingMethod, setAcendaDefaultShippingMethod] = useState('');
    const [acendaFedexFicupShippingMethod, setAcendaFedexFicupShippingMethod] = useState('');
    const [enableFba, setEnableFba] = useState(false);
    const [acendaFbaShippingMethod, setAcendaFbaShippingMethod] = useState('');
    const [acendaMarketplaceName, setAcendaMarketplaceName] = useState('');
    const [acendaFbaMarketplaceName, setAcendaFbaMarketplaceName] = useState('');
    const [acendaClientId, setAcendaClientId] = useState('');
    const [acendaClientSecret, setAcendaClientSecret] = useState('');
    const [acendaApiBaseUrl, setAcendaApiBaseUrl] = useState('');
    const [acendaReleaseDate, setAcendaReleaseDate] = useState('');
    const [acendaReleaseTimezone, setAcendaReleaseTimezone] = useState('');
    const [shopifyFbaMarketplaceName, setShopifyFbaMarketplaceName] = useState('');
    const [shopifyMarketplaceName, setShopifyMarketplaceName] = useState('');
    const [shopifyReleaseDate, setShopifyReleaseDate] = useState('');
    const [shopifyReleaseTimezone, setShopifyReleaseTimezone] = useState('');

    const [ssStoreId, setSsStoreId] = useState('');
    const [ssApiKey, setSsApiKey] = useState('');
    const [ssApiSecret, setSsApiSecret] = useState('');
    const [accountTypeCode, setAccountTypeCode] = useState('');
    const [ascSellerId, setAscSellerId] = useState(false);
    const [enableWarehouseWemove, setEnableWarehouseWemove] = useState('');
    const [warehouseSkuPrefixWemove, setWarehouseSkuPrefixWemove] = useState('');
    const [shopifyWarehouseMarketplaceNameWemove, setShopifyWarehouseMarketplaceNameWemove] = useState('');
    const [shopifyStoreId, setShopifyStoreId] = useState('');
    const [wholesalePricingMethod, setWholesalePricingMethod] = useState('');

    const amazonSellerCentralState = useSelector((state: any) => state.amazonSellerCentralReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    const [loadingServiceAccountFile, setLoadingServiceAccountFile] = useState(false);

    useEffect(() => {
        console.info("Store Detail Arrived");
        dispatch(getSellerCentralStoresAction(params.id, true));
        dispatch(getSellerCentralStoreLogsAction(params.id));
    }, [dispatch, params.id])

    useEffect(() => {
        console.info("Store Detail Arrived 2");
        if (amazonSellerCentralState.storeDetails) {

            setAccountID(amazonSellerCentralState.storeDetails.account_id);
            setAddressToCode(amazonSellerCentralState.storeDetails.address_to_code);
            setAwsAccessKey(amazonSellerCentralState.storeDetails.aws_access_key);
            setAwsSecretKey(amazonSellerCentralState.storeDetails.aws_secret_key);
            setBrands(amazonSellerCentralState.storeDetails.brands);
            setCommission(amazonSellerCentralState.storeDetails.commission);
            setDbHost(amazonSellerCentralState.storeDetails.db_host);
            setDbPort(amazonSellerCentralState.storeDetails.db_port);
            setDbPassword(amazonSellerCentralState.storeDetails.db_password);
            setDbUser(amazonSellerCentralState.storeDetails.db_user);
            setDbTable(amazonSellerCentralState.storeDetails.db_table);
            setInventoryUpdate(amazonSellerCentralState.storeDetails.enable_inventory_update);
            setPricingUpdate(amazonSellerCentralState.storeDetails.enable_pricing_update);
            setGoogleIgnoreSheet(amazonSellerCentralState.storeDetails.google_ignore_sheet);
            setSheetsReportingId(amazonSellerCentralState.storeDetails.google_sheets_reporting_id);
            setGoogleStockSheet(amazonSellerCentralState.storeDetails.google_stock_sheet);
            setGoogleIgnoreSheet(amazonSellerCentralState.storeDetails.google_ignore_sheet);
            setGoogleIgnoreSheet(amazonSellerCentralState.storeDetails.google_ignore_sheet);
            setHandlingTime(amazonSellerCentralState.storeDetails.handling_time);
            setHoldBack(amazonSellerCentralState.storeDetails.hold_back);
            setLwaAppID(amazonSellerCentralState.storeDetails.lwa_app_id);
            setLwaClientSecret(amazonSellerCentralState.storeDetails.lwa_client_secret);
            setMarketplace(amazonSellerCentralState.storeDetails.marketplace);
            setProfitMargin(amazonSellerCentralState.storeDetails.profit_margin);
            setRefreshToken(amazonSellerCentralState.storeDetails.refresh_token);
            setRoleArn(amazonSellerCentralState.storeDetails.role_arn);
            setServiceAccountFile(amazonSellerCentralState.storeDetails.service_account_file);
            setServiceAccountFilename(amazonSellerCentralState.storeDetails.service_account_file_name);
            setOriginalServiceAccountFilename(amazonSellerCentralState.storeDetails.service_account_file_name);
            setSkuPrefix(amazonSellerCentralState.storeDetails.sku_prefix);
            setTaxRate(amazonSellerCentralState.storeDetails.tax_rate);
            setHandlingMode(amazonSellerCentralState.storeDetails.handling_time_mode);
            setHandlingTimeNonParcel(amazonSellerCentralState.storeDetails.handling_time_non_parcel);
            setSpapiReportEncoding(amazonSellerCentralState.storeDetails.spapi_report_encoding);
            setSaveDetailsFeed(amazonSellerCentralState.storeDetails.save_details_feed);
            setSaveInventoryFeed(amazonSellerCentralState.storeDetails.save_inventory_feed);
            setSavePricesFeed(amazonSellerCentralState.storeDetails.save_prices_feed);
            setOwnerUserId(amazonSellerCentralState.storeDetails.owner_user_id);
            setMarketServiceType(amazonSellerCentralState.storeDetails.market_service_type);
            setS3AccessKey(amazonSellerCentralState.storeDetails.s3_access_key);
            setS3SecretKey(amazonSellerCentralState.storeDetails.s3_secret_key);
            setS3Bucket(amazonSellerCentralState.storeDetails.s3_bucket);
            setZeroLtl(amazonSellerCentralState.storeDetails.zero_ltl);
            setReportingOnly(amazonSellerCentralState.storeDetails.reporting_only);
            setUseFedexFicupRates(amazonSellerCentralState.storeDetails.use_fedex_ficup_rates);
            setAcendaDefaultShippingMethod(amazonSellerCentralState.storeDetails.acenda_default_shipping_method);
            setAcendaFedexFicupShippingMethod(amazonSellerCentralState.storeDetails.acenda_fedex_ficup_shipping_method);
            setEnableFba(amazonSellerCentralState.storeDetails.enable_fba);
            setAcendaFbaShippingMethod(amazonSellerCentralState.storeDetails.acenda_fba_shipping_method);
            setAcendaMarketplaceName(amazonSellerCentralState.storeDetails.acenda_marketplace_name);
            setAcendaFbaMarketplaceName(amazonSellerCentralState.storeDetails.acenda_fba_marketplace_name);
            setAcendaClientId(amazonSellerCentralState.storeDetails.acenda_client_id);
            setAcendaClientSecret(amazonSellerCentralState.storeDetails.acenda_client_secret);
            setAcendaApiBaseUrl(amazonSellerCentralState.storeDetails.acenda_api_base_url);
            setAcendaReleaseDate(amazonSellerCentralState.storeDetails.acenda_release_date);
            setAcendaReleaseTimezone(amazonSellerCentralState.storeDetails.acenda_release_timezone);
            setSsStoreId(amazonSellerCentralState.storeDetails.ss_store_id);
            setSsApiKey(amazonSellerCentralState.storeDetails.ss_api_key);
            setSsApiSecret(amazonSellerCentralState.storeDetails.ss_api_secret);
            setHasServiceAccountFile(amazonSellerCentralState.storeDetails.has_service_account_file);
            setDeleteServiceAccountFile(false);
            setDsClientNos(amazonSellerCentralState.storeDetails.ds_client_nos);
            setOrigDsClientNos(amazonSellerCentralState.storeDetails.ds_client_nos);
            setReportEmails(amazonSellerCentralState.storeDetails.report_emails);
            setOrigReportEmails(amazonSellerCentralState.storeDetails.report_emails);
            setAccountTypeCode(amazonSellerCentralState.storeDetails.account_type_code);
            setShopifyFbaMarketplaceName(amazonSellerCentralState.storeDetails.shopify_fba_marketplace_name);
            setShopifyMarketplaceName(amazonSellerCentralState.storeDetails.shopify_marketplace_name);
            setShopifyReleaseDate(amazonSellerCentralState.storeDetails.shopify_release_date);
            setShopifyReleaseTimezone(amazonSellerCentralState.storeDetails.shopify_release_timezone);
            setAscSellerId(amazonSellerCentralState.storeDetails.asc_seller_id);
            setEnableWarehouseWemove(amazonSellerCentralState.storeDetails.enable_warehouse_wemove);
            setWarehouseSkuPrefixWemove(amazonSellerCentralState.storeDetails.warehouse_sku_prefix_wemove);
            setShopifyWarehouseMarketplaceNameWemove(amazonSellerCentralState.storeDetails.shopify_warehouse_marketplace_name_wemove);
            setShopifyStoreId(amazonSellerCentralState.storeDetails.shopify_store_id);
            setWholesalePricingMethod(amazonSellerCentralState.storeDetails.wholesale_pricing_method);


            if (amazonSellerCentralState.storeDetails.has_service_account_file) {
                if (loadingServiceAccountFile !== params.id) {
                    let loadingId = params.id;
                    setLoadingServiceAccountFile(loadingId);
                    amazonSellerCentralService.getServiceAccountFile(loadingId).then(response => {
                        setServiceAccountFileData(response.data);
                        if (loadingId === loadingServiceAccountFile) {
                            setLoadingServiceAccountFile(loadingServiceAccountFile);
                        }
                    });
                }

            } else if (serviceAccountFileData != null) {
                setServiceAccountFileData(null);
            }

        }

    }, [amazonSellerCentralState.storeDetails, params.id, loadingServiceAccountFile, serviceAccountFileData, dispatch])


    useEffect(() => {

        console.info("Store Detail Arrived 3");

        if (amazonSellerCentralState.brandAdd) {
            dispatch(sellerCentralClearBrandAddAction())
            success(amazonSellerCentralState.mesg)
        }
        if (amazonSellerCentralState.storeUpdate) {
            dispatch(sellerCentralClearStoreUpdateAction())
            success(amazonSellerCentralState.mesg)
            setEditClick(true)
        }
        if (amazonSellerCentralState.statusCode === 500) {
            dispatch(sellerCentralClearStatusCodeAction())
            error(amazonSellerCentralState.mesg)
            setEditClick(true)
        }
    }, [
        amazonSellerCentralState.brandAdd,
        amazonSellerCentralState.storeUpdate,
        amazonSellerCentralState.statusCode,
        amazonSellerCentralState.mesg,
        dispatch]);

    const data = {
        "store":
        {
            "id": params.id,
            "account_id": accountId,
            "market_service_type": marketServiceType,
            "address_to_code": addressToCode,
            "aws_access_key": awsAccessKey,
            "aws_secret_key": awsSecretKey,
            "brands": brands,
            "ds_client_nos": dsClientNos,
            "report_emails": reportEmails,
            "commission": commission || null,
            "db_host": dbHost,
            "db_password": dbPassword,
            "db_port": dbPort || null,
            "db_table": dbTable,
            "db_user": dbUser,
            "enable_inventory_update": inventoryUpdate,
            "enable_pricing_update": pricingUpdate,
            "google_ignore_sheet": googleIgnoreSheet,
            "google_sheets_reporting_id": sheetsReportingId,
            "google_stock_sheet": googleStockSheet,
            "handling_time": handlingTime,
            "hold_back": holdBack || null,
            "lwa_app_id": lwaAppID,
            "lwa_client_secret": lwaClientSecret,
            "marketplace": marketplace,
            "profit_margin": profitMargin || null,
            "refresh_token": refreshToken,
            "role_arn": roleArn || null,
            "sku_prefix": skuPrefix,
            "tax_rate": taxRate || null,
            "handling_time_non_parcel": handlingTimeNonParcel,
            "handling_time_mode": handlingMode,
            "spapi_report_encoding": spapiReportEncoding || null,
            "save_details_feed": saveDetailsFeed,
            "save_inventory_feed": saveInventoryFeed,
            "save_prices_feed": savePricesFeed,
            "owner_user_id": ownerUserId,
            "s3_access_key": s3AccessKey || null,
            "s3_secret_key": s3SecretKey || null,
            "s3_bucket": s3Bucket || null,
            "zero_ltl": zeroLtl,
            "reporting_only": reportingOnly,
            "use_fedex_ficup_rates": useFedexFicupRates,
            "acenda_default_shipping_method": acendaDefaultShippingMethod,
            "acenda_fedex_ficup_shipping_method": acendaFedexFicupShippingMethod,
            "enable_fba": enableFba || null,
            "acenda_fba_shipping_method": acendaFbaShippingMethod,
            "acenda_marketplace_name": acendaMarketplaceName,
            "acenda_fba_marketplace_name": acendaFbaMarketplaceName,
            "acenda_client_id": acendaClientId,
            "acenda_client_secret": acendaClientSecret,
            "acenda_api_base_url": acendaApiBaseUrl,
            "acenda_release_date": acendaReleaseDate,
            "acenda_release_timezone": acendaReleaseTimezone,
            "shopify_marketplace_name": shopifyMarketplaceName,
            "shopify_fba_marketplace_name": shopifyFbaMarketplaceName,
            "shopify_release_date": shopifyReleaseDate,
            "shopify_release_timezone": shopifyReleaseTimezone,
            "ss_store_id": ssStoreId,
            "ss_api_key": ssApiKey,
            "ss_api_secret": ssApiSecret,
            "account_type_code": accountTypeCode,
            "asc_seller_id": ascSellerId,
            "enable_warehouse_wemove": enableWarehouseWemove,
            "warehouse_sku_prefix_wemove": warehouseSkuPrefixWemove,
            "shopify_warehouse_marketplace_name_wemove": shopifyWarehouseMarketplaceNameWemove,
            "shopify_store_id": shopifyStoreId || null,
            "wholesale_pricing_method": wholesalePricingMethod || null

        }
    }

    const saveUpdate = () => {
        dispatch(updateStoreDataAction(data, serviceAccountFile, deleteServiceAccountFile, params.id))
    }

    const zeroOutClick = () => {
        dispatch(sellerCentralZeroOutAction(params.id))
    }

    const updateInventory = () => {
        dispatch(sellerCentralUpdateInventoryAction(params.id))
    }

    const updatePriceClick = () => {
        dispatch(sellerCentralUpdatePriceAction(params.id))
    }

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const error = (msg: String) =>
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const handleCancelButtonClicked = () => {
        setEditClick(true);
        setDeleteServiceAccountFile(false);
        setDsClientNos(origDsClientNos);
        setReportEmails(origReportEmails);
    };

    useEffect(() => {

        console.info("Store Detail Arrived 4");

        if (amazonSellerCentralState.updateInventory.statsCode === 200 && amazonSellerCentralState.updateInventory.status) {
            dispatch(sellerCentralClearUpdateInventoryStatusCodeAction())
            success(amazonSellerCentralState.updateInventory.msg)
        }
        if (amazonSellerCentralState.updateInventory.statsCode !== null && !amazonSellerCentralState.updateInventory.status) {
            dispatch(sellerCentralClearUpdateInventoryStatusCodeAction())
            error(amazonSellerCentralState.updateInventory.msg)
        }

    }, [amazonSellerCentralState.updateInventory, dispatch]);

    useEffect(() => {

        console.info("Store Detail Arrived 5");

        if (amazonSellerCentralState.zeroOut.statusCode === 200 && amazonSellerCentralState.zeroOut.status) {
            dispatch(sellerCentralClearZeroOutStatusCodeAction())
            success(amazonSellerCentralState.zeroOut.msg)
        }
        if (amazonSellerCentralState.zeroOut.statusCode !== null && !amazonSellerCentralState.zeroOut.status) {
            dispatch(sellerCentralClearZeroOutStatusCodeAction())
            error(amazonSellerCentralState.zeroOut.msg)
        }


    }, [amazonSellerCentralState.zeroOut, dispatch]);


    useEffect(() => {

        console.info("Store Detail Arrived 6");

        if (amazonSellerCentralState.updatePrice.statsCode === 200 && amazonSellerCentralState.updatePrice.status) {
            dispatch(sellerCentralClearUpdatePriceStatusCodeAction())
            success(amazonSellerCentralState.updatePrice.msg)
        }
        if (amazonSellerCentralState.updatePrice.statsCode !== null && !amazonSellerCentralState.updatePrice.status) {
            dispatch(sellerCentralClearUpdatePriceStatusCodeAction())
            error(amazonSellerCentralState.updatePrice.msg)
        }

    }, [amazonSellerCentralState.updatePrice, dispatch]);



    return (
        <>
            <section className="user-sec py-2">
                <div className="container">
                    <div className="inner-sec">
                        <div className="d-md-flex mb-4 align-items-center">
                            <div className="me-4">
                                <button
                                    onClick={() => navigate('/rbmws/home')}
                                    className="btn cancel_btn rounded-pill mb-0"><i className="fas fa-arrow-left me-2"></i>Back</button>
                            </div>
                            <h1 className="mb-0">Store Details</h1>
                        </div>
                        {/* {viewType !== 'readonly' && ( */}
                        <div className="d-flex justify-content-end mb-4">
                            <div className="me-3">
                                {editClick ? (
                                    <div className="">
                                        <button
                                            onClick={() => setEditClick(false)}
                                            className="btn edit_prim_btn rounded-pill">Edit <i className="fas fa-pencil-alt ms-2"></i></button>
                                    </div>
                                ) : (
                                    <div className="">
                                        <button
                                            onClick={handleCancelButtonClicked}
                                            className="btn cancel_btn rounded-pill me-3">Cancel</button>
                                        <button
                                            onClick={saveUpdate}
                                            className="btn save_btn rounded-pill">Save Update</button>
                                    </div>


                                )}
                            </div>
                            <div className="me-3">
                                <button
                                    onClick={zeroOutClick}
                                    className="btn rounded-pill zero_btn">Zero Out</button>
                            </div>
                            <div className="me-3">
                                <button
                                    onClick={updateInventory}
                                    className="btn rounded-pill update_btn">Update Inventory </button>
                            </div>
                            <div className="me-3">
                                <button
                                    onClick={updatePriceClick}
                                    className="btn rounded-pill update_btn">Update Prices </button>
                            </div>
                        </div>
                        {/* )} */}
                        {amazonSellerCentralState.storeDetails ? (<SellerCentralStoreForm
                            storeId={params.id}
                            isCreate={false}
                            editClick={editClick}
                            setAccountID={setAccountID}
                            accountId={accountId}
                            addressToCode={addressToCode}
                            setAddressToCode={setAddressToCode}
                            awsAccessKey={awsAccessKey}
                            setAwsAccessKey={setAwsAccessKey}
                            awsSecretKey={awsSecretKey}
                            setAwsSecretKey={setAwsSecretKey}
                            brands={brands}
                            setBrands={setBrands}
                            dsClientNos={dsClientNos}
                            setDsClientNos={setDsClientNos}
                            reportEmails={reportEmails}
                            setReportEmails={setReportEmails}
                            commission={commission}
                            setCommission={setCommission}
                            dbHost={dbHost}
                            setDbHost={setDbHost}
                            dbPort={dbPort}
                            setDbPort={setDbPort}
                            dbPassword={dbPassword}
                            setDbPassword={setDbPassword}
                            dbUser={dbUser}
                            setDbUser={setDbUser}
                            dbTable={dbTable}
                            setDbTable={setDbTable}
                            inventoryUpdate={inventoryUpdate}
                            setInventoryUpdate={setInventoryUpdate}
                            pricingUpdate={pricingUpdate}
                            setPricingUpdate={setPricingUpdate}
                            googleIgnoreSheet={googleIgnoreSheet}
                            setGoogleIgnoreSheet={setGoogleIgnoreSheet}
                            sheetsReportingId={sheetsReportingId}
                            setSheetsReportingId={setSheetsReportingId}
                            googleStockSheet={googleStockSheet}
                            setGoogleStockSheet={setGoogleStockSheet}
                            handlingTime={handlingTime}
                            setHandlingTime={setHandlingTime}
                            holdBack={holdBack}
                            setHoldBack={setHoldBack}
                            lwaAppID={lwaAppID}
                            setLwaAppID={setLwaAppID}
                            lwaClientSecret={lwaClientSecret}
                            setLwaClientSecret={setLwaClientSecret}
                            marketplace={marketplace}
                            setMarketplace={setMarketplace}
                            profitMargin={profitMargin}
                            setProfitMargin={setProfitMargin}
                            refreshToken={refreshToken}
                            setRefreshToken={setRefreshToken}
                            roleArn={roleArn}
                            setRoleArn={setRoleArn}
                            hasServiceAccountFile={hasServiceAccountFile}
                            serviceAccountFileData={serviceAccountFileData}
                            serviceAccountFile={serviceAccountFile}
                            setServiceAccountFile={setServiceAccountFile}
                            serviceAccountFilename={serviceAccountFilename}
                            setServiceAccountFilename={setServiceAccountFilename}
                            originalServiceAccountFilename={originalServiceAccountFilename}
                            deleteServiceAccountFile={deleteServiceAccountFile}
                            setDeleteServiceAccountFile={setDeleteServiceAccountFile}
                            skuPrefix={skuPrefix}
                            setSkuPrefix={setSkuPrefix}
                            taxRate={taxRate}
                            setTaxRate={setTaxRate}
                            edit={true}
                            handlingMode={handlingMode}
                            setHandlingMode={setHandlingMode}
                            handlingTimeNonParcel={handlingTimeNonParcel}
                            setHandlingTimeNonParcel={setHandlingTimeNonParcel}
                            spapiReportEncoding={spapiReportEncoding}
                            setSpapiReportEncoding={setSpapiReportEncoding}
                            saveDetailsFeed={saveDetailsFeed}
                            setSaveDetailsFeed={setSaveDetailsFeed}
                            saveInventoryFeed={saveInventoryFeed}
                            setSaveInventoryFeed={setSaveInventoryFeed}
                            savePricesFeed={savePricesFeed}
                            setSavePricesFeed={setSavePricesFeed}
                            ownerUserId={ownerUserId}
                            setOwnerUserId={setOwnerUserId}
                            marketServiceType={marketServiceType}
                            setMarketServiceType={setMarketServiceType}
                            isOwner={amazonSellerCentralState.storeDetails.is_owner}
                            userList={amazonSellerCentralState.users}
                            isAdmin={isSuperUser()}
                            s3AccessKey={s3AccessKey}
                            setS3AccessKey={setS3AccessKey}
                            s3SecretKey={s3SecretKey}
                            setS3SecretKey={setS3SecretKey}
                            s3Bucket={s3Bucket}
                            setS3Bucket={setS3Bucket}
                            zeroLtl={zeroLtl}
                            setZeroLtl={setZeroLtl}
                            reportingOnly={reportingOnly}
                            setReportingOnly={setReportingOnly}
                            useFedexFicupRates={useFedexFicupRates}
                            setUseFedexFicupRates={setUseFedexFicupRates}
                            acendaDefaultShippingMethod={acendaDefaultShippingMethod}
                            setAcendaDefaultShippingMethod={setAcendaDefaultShippingMethod}
                            acendaFedexFicupShippingMethod={acendaFedexFicupShippingMethod}
                            setAcendaFedexFicupShippingMethod={setAcendaFedexFicupShippingMethod}
                            enableFba={enableFba}
                            setEnableFba={setEnableFba}
                            acendaFbaMarketplaceName={acendaFbaMarketplaceName}
                            setAcendaFbaMarketplaceName={setAcendaFbaMarketplaceName}
                            acendaMarketplaceName={acendaMarketplaceName}
                            setAcendaMarketplaceName={setAcendaMarketplaceName}
                            acendaFbaShippingMethod={acendaFbaShippingMethod}
                            setAcendaFbaShippingMethod={setAcendaFbaShippingMethod}
                            acendaClientId={acendaClientId}
                            setAcendaClientId={setAcendaClientId}
                            acendaClientSecret={acendaClientSecret}
                            setAcendaClientSecret={setAcendaClientSecret}
                            acendaApiBaseUrl={acendaApiBaseUrl}
                            setAcendaApiBaseUrl={setAcendaApiBaseUrl}
                            acendaReleaseDate={acendaReleaseDate}
                            setAcendaReleaseDate={setAcendaReleaseDate}
                            acendaReleaseTimezone={acendaReleaseTimezone}
                            setAcendaReleaseTimezone={setAcendaReleaseTimezone}
                            ssStoreId={ssStoreId}
                            setSsStoreId={setSsStoreId}
                            ssApiKey={ssApiKey}
                            setSsApiKey={setSsApiKey}
                            ssApiSecret={ssApiSecret}
                            setSsApiSecret={setSsApiSecret}
                            accountTypeCode={accountTypeCode}
                            setAccountTypeCode={setAccountTypeCode}
                            shopifyFbaMarketplaceName={shopifyFbaMarketplaceName}
                            setShopifyFbaMarketplaceName={setShopifyFbaMarketplaceName}
                            shopifyMarketplaceName={shopifyMarketplaceName}
                            setShopifyMarketplaceName={setShopifyMarketplaceName}
                            shopifyReleaseDate={shopifyReleaseDate}
                            setShopifyReleaseDate={setShopifyReleaseDate}
                            shopifyReleaseTimezone={shopifyReleaseTimezone}
                            setShopifyReleaseTimezone={setShopifyReleaseTimezone}
                            ascSellerId={ascSellerId}
                            setAscSellerId={setAscSellerId}
                            enableWarehouseWemove={enableWarehouseWemove}
                            setEnableWarehouseWemove={setEnableWarehouseWemove}
                            warehouseSkuPrefixWemove={warehouseSkuPrefixWemove}
                            setWarehouseSkuPrefixWemove={setWarehouseSkuPrefixWemove}
                            shopifyWarehouseMarketplaceNameWemove={shopifyWarehouseMarketplaceNameWemove}
                            setShopifyWarehouseMarketplaceNameWemove={setShopifyWarehouseMarketplaceNameWemove}
                            shopifyStoreId={shopifyStoreId}
                            setShopifyStoreId={setShopifyStoreId}
                            wholesalePricingMethod={wholesalePricingMethod}
                            setWholesalePricingMethod={setWholesalePricingMethod}

                        />) : null}
                    </div>
                </div>
            </section>

            {editClick === true && (
                <section className="seller_central_logs_section user-sec">
                    {amazonSellerCentralState.logs && amazonSellerCentralState.logs.length > 0 && (
                        <div>
                            <br />
                            <h2 className="log_heading">Seller Central Logs</h2>
                            <div className="container seller_central_logs">
                                <SellerStoreLogs data={amazonSellerCentralState.logs} />
                            </div>
                        </div>

                    )}


                </section>
            )}



            <ToastContainer autoClose={3000} />
            {loading &&
                <div className="spinner">
                    <Audio

                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            }
        </>
    )
};

export default RbmwsDetails;