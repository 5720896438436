
const initialState = {
    status: null,
    data: [],
    mapViolation:[],
    unqualifiedData:[],
    missingBuybox:[],
    statusCode: null,
    duplicate: [],
    mesg: null,
    error:false

}

const amazonBuyBoxTrackerReducer = (state=initialState, action:any) => {
   
    switch(action.type){
        case "GET_AMAZON_BUYBOX_WITH_SKU":
        
            return{
                ...state,
                data: action.payload.data,
                status: action.payload.status,  
            }
            
        case "GET_AMAZON_BUYBOX_MAP_VIOLATIONS":
            return{
                ...state,
                mapViolation: action.payload.data,
                status: action.payload.status,  
            }

        case "GET_AMAZON_BUYBOX_UNQUALIFIED_PRODUCT":
            return{
                ...state,
                unqualifiedData: action.payload.data,
                status: action.payload.status,  
            }

        case "GET_MISSING_BUYBOX_PRODUCT":
            return{
                ...state,
                missingBuybox: action.payload.data,
                status: action.payload.status,
            }
        case "GET_ERROR_BUYBOX":
            return{
                ...state,
                error:true
            }
        default:
            return state
    }

}

export default amazonBuyBoxTrackerReducer;