import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import {
  updateConfig
} from "../../../redux/actions/tradeshowConfigActions";
import { TradeshowConfigService } from "../../../service/tradeshowConfigService";

type ConfigType = {
  [key: string]: any;
};

const tradeshowConfigService = new TradeshowConfigService();

const TradeshowConfigPage = () => {
  const dispatch = useDispatch();
  const configState = useSelector((state: any) => state.tradeshowConfigReducer);
  const config = configState.piAppConfig;

  const [localConfig, setLocalConfig] = useState<ConfigType>({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [showType, setShowType] = useState(localConfig.show_type || 0);

  useEffect(() => {
    setShowType(localConfig.show_type || 0);
  }, [localConfig.show_type]);

  const handleShowTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowType(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await tradeshowConfigService.getConfig();
        dispatch({ type: "SET_CONFIG", payload: data });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (config) {
      setLocalConfig(config);
    }
  }, [config]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setLocalConfig({
      ...localConfig,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const updatedConfig = { ...localConfig, show_type: showType };
      const { data } = await tradeshowConfigService.updateConfig(updatedConfig);
      dispatch(updateConfig(data));
      setSuccessMessage("Configuration updated successfully!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1>Tradeshow Config</h1>
        </Col>
      </Row>
      {loading && (
        <div className="text-center my-4">
          <Spinner animation="border" />
        </div>
      )}
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
          {successMessage}
        </Alert>
      )}
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="3">
            Show Type
          </Form.Label>
          <Col sm="3" className="d-flex justify-content-center">
            <Form.Check
              inline
              label="Express Lead"
              type="radio"
              name="showType"
              id="expressLead"
              value={0}
              checked={showType === 0}
              onChange={handleShowTypeChange}
            />
            <Form.Check
              inline
              label="High Point"
              type="radio"
              name="showType"
              id="highPoint"
              value={1}
              checked={showType === 1}
              onChange={handleShowTypeChange}
            />
          </Col>
        </Form.Group>

        {Object.keys(localConfig).map((key) => {
          if (key === 'enable_voice_notifications') {
            return (
              <Form.Group as={Row} className="mb-3 align-items-center" key={key}>
                <Form.Label column sm="3">
                  Enable Voice Notifications
                </Form.Label>
                <Col sm="1">
                  <Form.Check
                    type="checkbox"
                    name={key}
                    checked={localConfig[key] || false}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            );
          } else if (key !== 'show_type') {
            return (
              <Form.Group as={Row} className="mb-3" key={key}>
                <Form.Label column sm="3">
                  {key}
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    name={key}
                    value={localConfig[key] || ''}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            );
          }
          return null;
        })}
        
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          Update
        </Button>
      </Form>
    </Container>
  );
};

export default TradeshowConfigPage;
