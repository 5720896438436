
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class ProductRestockNotificationsServiceImpl {

    apiSubscriptionNotificationsUrl = `${config.baseUrl}api/subscription-notifications`;

    getSubscriptions() {
        return axios.get(this.apiSubscriptionNotificationsUrl, get_config());
    }

    addSubscriptions(productSkus: string[]) {
        let data = {
            "skus": productSkus,
        }
        return axios.put(this.apiSubscriptionNotificationsUrl, data, get_config())
    }

    removeSubscriptions(productSkus: string[]) {
        const requestConfig = {
            headers: { 
                'Content-Type': 'application/json; charset=utf-8', 
                'Authentication-Token': `${localStorage.getItem("token")}` 
            },
            data: { skus: productSkus }
        };
        return axios.delete(this.apiSubscriptionNotificationsUrl,requestConfig);
    }

}
