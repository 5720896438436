
const initialState = {
    status: null,
    data: [],
    statusCode: null,
    duplicate: [],
    mesg: null

}

const amazonContentVerificationReducer = (state=initialState, action:any) => {
    switch(action.type){
        case "GET_AMAZON_CONTENT_VERIFICATION":
            return{
                ...state,
                data: action.payload.data,
                status: action.payload.status,
                mesg: action.payload.statusText

            }
        case "GET_ALL_AMAZON_CONTENT_VERIFICATION":
            const old_data = [...state.duplicate]
            const updated_data = old_data.concat(action.payload.data.results)
            return{
                ...state,
                duplicate:updated_data
            }
        case "UPDATE_DUPLICATE_DATA":
            return{
                ...state,
                duplicate:action.payload
            }
        default:
            return state
    }

}

export default amazonContentVerificationReducer;