import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createSellerCentralStoreAction, getUserListAction } from '../../../redux/actions/amazonSellerCentralActions';

import { ToastContainer, toast } from "react-toastify";
import { Audio } from "react-loader-spinner";
import SellerCentralStoreForm from '../../shared/sellerCentralStoreForm';
import { AppDispatch } from '../../../redux/Store';
import { isSuperUser } from '../../../utils/Helper';


const AddNewSellerStore = () => {

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const amazonSellerCentralState = useSelector((state: any) => state.amazonSellerCentralReducer);

    const sellerStore = useSelector((state: any) => state.rbmwsReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    const [accountId, setAccountID] = useState('')
    const [addressToCode, setAddressToCode] = useState('')
    const [awsAccessKey, setAwsAccessKey] = useState('')
    const [awsSecretKey, setAwsSecretKey] = useState('')
    const [brands, setBrands] = useState([])
    const [dsClientNos, setDsClientNos] = useState([]);
    const [commission, setCommission]: any = useState(0.0)
    const [dbHost, setDbHost] = useState('')
    const [dbPort, setDbPort]: any = useState('')
    const [dbPassword, setDbPassword] = useState('')
    const [dbUser, setDbUser] = useState('')
    const [dbTable, setDbTable] = useState('')
    const [inventoryUpdate, setInventoryUpdate] = useState(false)
    const [pricingUpdate, setPricingUpdate] = useState(false)
    const [googleIgnoreSheet, setGoogleIgnoreSheet] = useState('')
    const [sheetsReportingId, setSheetsReportingId] = useState('')
    const [googleStockSheet, setGoogleStockSheet] = useState('')
    const [handlingTime, setHandlingTime] = useState('')
    const [holdBack, setHoldBack]: any = useState(0)
    const [lwaAppID, setLwaAppID] = useState('')
    const [lwaClientSecret, setLwaClientSecret] = useState('')
    const [marketplace, setMarketplace] = useState('')
    const [profitMargin, setProfitMargin]: any = useState(0.0)
    const [refreshToken, setRefreshToken] = useState('')
    const [roleArn, setRoleArn] = useState('')
    const [serviceAccountFile, setServiceAccountFile] = useState(null as any)
    const [serviceAccountFilename, setServiceAccountFilename] = useState(null as any);
    const [skuPrefix, setSkuPrefix] = useState('')
    const [taxRate, setTaxRate]: any = useState(0.0)
    const [handlingMode, setHandlingMode] = useState('')
    const [handlingTimeNonParcel, setHandlingTimeNonParcel] = useState('')
    const [spapiReportEncoding, setSpapiReportEncoding] = useState('iso-8859-1')
    const [saveDetailsFeed, setSaveDetailsFeed] = useState(false);
    const [saveInventoryFeed, setSaveInventoryFeed] = useState(false);
    const [savePricesFeed, setSavePricesFeed] = useState(false);
    const [ownerUserId, setOwnerUserId] = useState('');
    const [marketServiceType, setMarketServiceType] = useState('');
    const [s3AccessKey, setS3AccessKey] = useState('');
    const [s3SecretKey, setS3SecretKey] = useState('');
    const [s3Bucket, setS3Bucket] = useState('');
    const [zeroLtl, setZeroLtl] = useState(false);
    const [reportingOnly, setReportingOnly] = useState(false);
    const [useFedexFicupRates, setUseFedexFicupRates] = useState(false);
    const [acendaDefaultShippingMethod, setAcendaDefaultShippingMethod] = useState('');
    const [acendaFedexFicupShippingMethod, setAcendaFedexFicupShippingMethod] = useState('');
    const [enableFba, setEnableFba] = useState(false);
    const [acendaFbaShippingMethod, setAcendaFbaShippingMethod] = useState('');
    const [acendaMarketplaceName, setAcendaMarketplaceName] = useState('');
    const [acendaFbaMarketplaceName, setAcendaFbaMarketplaceName] = useState('');
    const [acendaClientId, setAcendaClientId] = useState('');
    const [acendaClientSecret, setAcendaClientSecret] = useState('');
    const [acendaApiBaseUrl, setAcendaApiBaseUrl] = useState('');
    const [acendaReleaseDate, setAcendaReleaseDate] = useState('');
    const [acendaReleaseTimezone, setAcendaReleaseTimezone] = useState('');
    const [ssStoreId, setSsStoreId] = useState('');
    const [ssApiKey, setSsApiKey] = useState('');
    const [ssApiSecret, setSsApiSecret] = useState('');
    const [accountTypeCode, setAccountTypeCode] = useState('');
    const [shopifyFbaMarketplaceName, setShopifyFbaMarketplaceName] = useState('');
    const [shopifyMarketplaceName, setShopifyMarketplaceName] = useState('');
    const [shopifyReleaseDate, setShopifyReleaseDate] = useState('');
    const [shopifyReleaseTimezone, setShopifyReleaseTimezone] = useState('');
    const [ascSellerId, setAscSellerId] = useState('');
    const [enableWarehouseWemove, setEnableWarehouseWemove] = useState('');
    const [warehouseSkuPrefixWemove, setWarehouseSkuPrefixWemove] = useState('');
    const [shopifyWarehouseMarketplaceNameWemove, setShopifyWarehouseMarketplaceNameWemove] = useState('');
    const [shopifyStoreId, setShopifyStoreId] = useState('');
    const [wholesalePricingMethod, setWholesalePricingMethod] = useState('');
    const [warehouseEnablePricingWemove, setWarehouseEnablePricingWemove] = useState(true);

    const data = {
        "store":
        {
            "account_id": accountId,
            "owner_user_id": ownerUserId,
            "market_service_type": marketServiceType,
            "address_to_code": addressToCode,
            "aws_access_key": awsAccessKey,
            "aws_secret_key": awsSecretKey,
            "brands": brands,
            "ds_client_nos": dsClientNos,
            "commission": commission || null,
            "db_host": dbHost,
            "db_password": dbPassword,
            "db_port": dbPort || null,
            "db_table": dbTable,
            "db_user": dbUser,
            "enable_inventory_update": inventoryUpdate,
            "enable_pricing_update": pricingUpdate,
            "google_ignore_sheet": googleIgnoreSheet,
            "google_sheets_reporting_id": sheetsReportingId,
            "google_stock_sheet": googleStockSheet,
            "handling_time": handlingTime,
            "hold_back": holdBack || null,
            "lwa_app_id": lwaAppID,
            "lwa_client_secret": lwaClientSecret,
            "marketplace": marketplace,
            "profit_margin": profitMargin || null,
            "refresh_token": refreshToken,
            "role_arn": roleArn,
            "sku_prefix": skuPrefix,
            "tax_rate": taxRate || null,
            "handling_time_non_parcel": handlingTimeNonParcel,
            "handling_time_mode": handlingMode,
            "spapi_report_encoding": spapiReportEncoding || null,
            "save_details_feed": saveDetailsFeed,
            "save_inventory_feed": saveInventoryFeed,
            "save_prices_feed": savePricesFeed,
            "s3_access_key": s3AccessKey || null,
            "s3_secret_key": s3SecretKey || null,
            "s3_bucket": s3Bucket || null,
            "zero_ltl": zeroLtl,
            "reporting_only": reportingOnly,
            "use_fedex_ficup_rates": useFedexFicupRates,
            "acenda_default_shipping_method": acendaDefaultShippingMethod || null,
            "acenda_fedex_ficup_shipping_method": acendaFedexFicupShippingMethod || null,
            "enable_fba": enableFba,
            "acenda_fba_marketplace_name": acendaFbaMarketplaceName,
            "acenda_marketplace_name": acendaMarketplaceName,
            "acenda_fba_shipping_method": acendaFbaShippingMethod || null,
            "acenda_client_id": acendaClientId,
            "acenda_client_secret": acendaClientSecret,
            "acenda_api_base_url": acendaApiBaseUrl,
            "acenda_release_date": acendaReleaseDate || null,
            "acenda_release_timezone": acendaReleaseTimezone,
            "ss_store_id": ssStoreId || null,
            "shopify_marketplace_name": shopifyMarketplaceName,
            "shopify_fba_marketplace_name": shopifyFbaMarketplaceName,
            "shopify_release_date": shopifyReleaseDate,
            "shopify_release_timezone": shopifyReleaseTimezone,
            "ss_api_key": ssApiKey,
            "ss_api_secret": ssApiSecret,
            "account_type_code": accountTypeCode,
            "asc_seller_id": ascSellerId || null,
            "enable_warehouse_wemove": !!enableWarehouseWemove,
            "warehouse_sku_prefix_wemove": warehouseSkuPrefixWemove || null,
            "shopify_warehouse_marketplace_name_wemove": shopifyWarehouseMarketplaceNameWemove || null,
            "shopify_store_id": shopifyStoreId ||null,
            "wholesale_pricing_method": wholesalePricingMethod || null,
            "warehouse_enable_pricing_wemove": warehouseEnablePricingWemove || null
        }
    }


    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const error = (msg: String) =>
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const warning = (msg: String) =>
        toast.warning(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    useEffect(() => {
        console.log("%s", "Info : Add Store Arrived")

        if (sellerStore) {
            if (sellerStore.createStatus) {
                success(sellerStore.mesg)
                sellerStore.createStatus = false
                console.log("%s", "SellerStore=%O", sellerStore)
                setTimeout(() => navigate("/rbmws/home"), 5000);

            }
            if (!sellerStore.createdStatus && sellerStore.statusCode === 400) {
                sellerStore.createdStatus = null
                error(sellerStore.mesg)
            }
        }
    }, [sellerStore, navigate])

    useEffect(() => {
        if (amazonSellerCentralState.users.length < 1) {
            dispatch(getUserListAction('new'));
        }
    }, [amazonSellerCentralState.users, dispatch])

    const onSuccess = (store_id: string) => {
        navigate(`/rbmws/details/${store_id}?type=readonly`)
    }

    const addClickHandle = () => {
        if (brands.length === 0) {
            warning("Please select the brands")
        } else {
            dispatch(createSellerCentralStoreAction(data, serviceAccountFile, onSuccess))
        }

    }

    return (
        <>
            <section className="user-sec py-2">
                <div className="container mt-15">
                    <div className="inner-sec">

                        <div className="d-md-flex mb-4 align-items-center">
                            <div className="me-4">
                                <button
                                    onClick={() => navigate('/rbmws/home')}
                                    className="btn cancel_btn rounded-pill mb-0"><i className="fas fa-arrow-left me-2"></i>Back</button>
                            </div>
                            <h1 className="mb-0">Add Store</h1>
                            <button
                                onClick={addClickHandle}
                                className="btn save_btn rounded-pill add_store_btn">Save</button>
                        </div>


                        <SellerCentralStoreForm
                            editClick={false}
                            isCreate={true}
                            setAccountID={setAccountID}
                            accountId={accountId}
                            addressToCode={addressToCode}
                            setAddressToCode={setAddressToCode}
                            awsAccessKey={awsAccessKey}
                            setAwsAccessKey={setAwsAccessKey}
                            awsSecretKey={awsSecretKey}
                            setAwsSecretKey={setAwsSecretKey}
                            brands={brands}
                            setBrands={setBrands}
                            dsClientNos={dsClientNos}
                            setDsClientNos={setDsClientNos}
                            commission={commission}
                            setCommission={setCommission}
                            dbHost={dbHost}
                            setDbHost={setDbHost}
                            dbPort={dbPort}
                            setDbPort={setDbPort}
                            dbPassword={dbPassword}
                            setDbPassword={setDbPassword}
                            dbUser={dbUser}
                            setDbUser={setDbUser}
                            dbTable={dbTable}
                            setDbTable={setDbTable}
                            inventoryUpdate={inventoryUpdate}
                            setInventoryUpdate={setInventoryUpdate}
                            pricingUpdate={pricingUpdate}
                            setPricingUpdate={setPricingUpdate}
                            googleIgnoreSheet={googleIgnoreSheet}
                            setGoogleIgnoreSheet={setGoogleIgnoreSheet}
                            sheetsReportingId={sheetsReportingId}
                            setSheetsReportingId={setSheetsReportingId}
                            googleStockSheet={googleStockSheet}
                            setGoogleStockSheet={setGoogleStockSheet}
                            handlingTime={handlingTime}
                            setHandlingTime={setHandlingTime}
                            holdBack={holdBack}
                            setHoldBack={setHoldBack}
                            lwaAppID={lwaAppID}
                            setLwaAppID={setLwaAppID}
                            lwaClientSecret={lwaClientSecret}
                            setLwaClientSecret={setLwaClientSecret}
                            marketplace={marketplace}
                            setMarketplace={setMarketplace}
                            profitMargin={profitMargin}
                            setProfitMargin={setProfitMargin}
                            refreshToken={refreshToken}
                            setRefreshToken={setRefreshToken}
                            roleArn={roleArn}
                            setRoleArn={setRoleArn}
                            serviceAccountFile={serviceAccountFile}
                            setServiceAccountFile={setServiceAccountFile}
                            hasServiceAccountFile={false}
                            originalServiceAccountFilename={null}
                            serviceAccountFilename={serviceAccountFilename}
                            setServiceAccountFilename={setServiceAccountFilename}
                            skuPrefix={skuPrefix}
                            setSkuPrefix={setSkuPrefix}
                            taxRate={taxRate}
                            setTaxRate={setTaxRate}
                            handlingMode={handlingMode}
                            setHandlingMode={setHandlingMode}
                            handlingTimeNonParcel={handlingTimeNonParcel}
                            setHandlingTimeNonParcel={setHandlingTimeNonParcel}
                            spapiReportEncoding={spapiReportEncoding}
                            setSpapiReportEncoding={setSpapiReportEncoding}
                            saveDetailsFeed={saveDetailsFeed}
                            setSaveDetailsFeed={setSaveDetailsFeed}
                            saveInventoryFeed={saveInventoryFeed}
                            setSaveInventoryFeed={setSaveInventoryFeed}
                            savePricesFeed={savePricesFeed}
                            setSavePricesFeed={setSavePricesFeed}
                            ownerUserId={ownerUserId}
                            setOwnerUserId={setOwnerUserId}
                            marketServiceType={marketServiceType}
                            setMarketServiceType={setMarketServiceType}
                            isOwner={true}
                            userList={amazonSellerCentralState.users}
                            isAdmin={isSuperUser()}
                            s3AccessKey={s3AccessKey}
                            setS3AccessKey={setS3AccessKey}
                            s3SecretKey={s3SecretKey}
                            setS3SecretKey={setS3SecretKey}
                            s3Bucket={s3Bucket}
                            setS3Bucket={setS3Bucket}
                            zeroLtl={zeroLtl}
                            setZeroLtl={setZeroLtl}
                            reportingOnly={reportingOnly}
                            setReportingOnly={setReportingOnly}
                            useFedexFicupRates={useFedexFicupRates}
                            setUseFedexFicupRates={setUseFedexFicupRates}
                            acendaDefaultShippingMethod={acendaDefaultShippingMethod}
                            setAcendaDefaultShippingMethod={setAcendaDefaultShippingMethod}
                            acendaFedexFicupShippingMethod={acendaFedexFicupShippingMethod}
                            setAcendaFedexFicupShippingMethod={setAcendaFedexFicupShippingMethod}
                            enableFba={enableFba}
                            setEnableFba={setEnableFba}
                            acendaFbaMarketplaceName={acendaFbaMarketplaceName}
                            setAcendaFbaMarketplaceName={setAcendaFbaMarketplaceName}
                            acendaMarketplaceName={acendaMarketplaceName}
                            setAcendaMarketplaceName={setAcendaMarketplaceName}
                            acendaFbaShippingMethod={acendaFbaShippingMethod}
                            setAcendaFbaShippingMethod={setAcendaFbaShippingMethod}
                            acendaClientId={acendaClientId}
                            setAcendaClientId={setAcendaClientId}
                            acendaClientSecret={acendaClientSecret}
                            setAcendaClientSecret={setAcendaClientSecret}
                            acendaApiBaseUrl={acendaApiBaseUrl}
                            setAcendaApiBaseUrl={setAcendaApiBaseUrl}
                            acendaReleaseDate={acendaReleaseDate}
                            setAcendaReleaseDate={setAcendaReleaseDate}
                            acendaReleaseTimezone={acendaReleaseTimezone}
                            setAcendaReleaseTimezone={setAcendaReleaseTimezone}
                            ssStoreId={ssStoreId}
                            setSsStoreId={setSsStoreId}
                            ssApiKey={ssApiKey}
                            setSsApiKey={setSsApiKey}
                            ssApiSecret={ssApiSecret}
                            setSsApiSecret={setSsApiSecret}
                            accountTypeCode={accountTypeCode}
                            setAccountTypeCode={setAccountTypeCode}
                            shopifyFbaMarketplaceName={shopifyFbaMarketplaceName}
                            setShopifyFbaMarketplaceName={setShopifyFbaMarketplaceName}
                            shopifyMarketplaceName={shopifyMarketplaceName}
                            setShopifyMarketplaceName={setShopifyMarketplaceName}
                            shopifyReleaseDate={shopifyReleaseDate}
                            setShopifyReleaseDate={setShopifyReleaseDate}
                            shopifyReleaseTimezone={shopifyReleaseTimezone}
                            setShopifyReleaseTimezone={setShopifyReleaseTimezone}
                            ascSellerId={ascSellerId}
                            setAscSellerId={setAscSellerId}
                            enableWarehouseWemove={enableWarehouseWemove}
                            setEnableWarehouseWemove={setEnableWarehouseWemove}
                            warehouseSkuPrefixWemove={warehouseSkuPrefixWemove}
                            setWarehouseSkuPrefixWemove={setWarehouseSkuPrefixWemove}
                            shopifyWarehouseMarketplaceNameWemove={shopifyWarehouseMarketplaceNameWemove}
                            setShopifyWarehouseMarketplaceNameWemove={setShopifyWarehouseMarketplaceNameWemove}
                            shopifyStoreId={shopifyStoreId}
                            setShopifyStoreId={setShopifyStoreId}
                            wholesalePricingMethod={wholesalePricingMethod}
                            setWholesalePricingMethod={setWholesalePricingMethod}
                            warehouseEnablePricingWemove={warehouseEnablePricingWemove}
                            setWarehouseEnablePricingWemove={setWarehouseEnablePricingWemove}
                        />
                    </div>
                </div>
            </section>

            <ToastContainer autoClose={3000} />

            {loading &&
                <div className="spinner">
                    <Audio

                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            }
        </>
    )
};

export default AddNewSellerStore;
