import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../shared/pagesHeader";
import { Button, Tabs } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import DataTable from "react-data-table-component";
import { useMemo } from "react";
import { getBuyboxPricesBySkuAction, getMissingBuyboxProductsBySellerAction, getBuyboxMapViolationsAction, getBuyboxUnqualifiedProductsAction } from "../../../redux/actions/amazonBuyboxTrackerActions";
import { dateTimeFormater } from '../../../utils/Helper';
import { AppDispatch } from "../../../redux/Store";

const customStyles = {
    headRow: {
        style: {
            border: "none",
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: "rgb(230, 244, 244)",
            borderBottomColor: "#FFFFFF",
            borderRadius: "25px",
            outline: "1px solid #FFFFFF",
        },
    },
};

function convertArrayOfObjectsToCSV(array: any) {
    let result: any;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: { [x: string]: any; }) => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            let new_item = JSON.stringify([item[key]]);
            let newFinal = new_item.replace(/null/g, '');
            result += newFinal.substring(1, newFinal.length - 1);
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}


function downloadCSV(data: any) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(data);
    if (csv == null) return;

    const filename = 'amazonveri.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}

const Export = ({ onExport }: { onExport: any }) => <Button className="export_buybox" onClick={(e: any) => onExport(e.target.value)} title="Download">  <i className="fas fa-light fa-download"></i> Export to csv</Button>;

const AmazonBuyboxTrackerHome = () => {

    const dispatch: AppDispatch = useDispatch();
    const [skuId, setSkuId] = useState('');
    const [sellerId, setSellerId] = useState('');
    const [key, setKey] = useState('home');
    const amazonBuyBoxTrackerState = useSelector((state: any) => state.amazonBuyBoxTrackerReducer);
    const [show, setShow] = useState(false)
    const [missingShow, setMissingShow] = useState(false)

    const getBuyboxPrices = (e: any) => {
        e.preventDefault()
        let sku_id = skuId
        if (sku_id.length !== 0) {
            dispatch(getBuyboxPricesBySkuAction(sku_id));
            setShow(true)
        }
    }

    const getMissingBuybox = (e: any) => {
        e.preventDefault()
        let seller_id = sellerId
        console.log("seller_id", seller_id);
        if (seller_id.length !== 0) {
            dispatch(getMissingBuyboxProductsBySellerAction(seller_id))
            setMissingShow(true)
        }
    }

    useEffect(() => {
        if (key === 'Map-Voilations') {
            if (amazonBuyBoxTrackerState.mapViolation.length === 0) {
                dispatch(getBuyboxMapViolationsAction())
            }
        } else if (key === 'Unqualified-Products') {
            if (amazonBuyBoxTrackerState.unqualifiedData.length === 0) {
                dispatch(getBuyboxUnqualifiedProductsAction())
            }
        }
    }, [key, dispatch, amazonBuyBoxTrackerState])

    const actionsMemo1 = useMemo(() => <Export onExport={() => downloadCSV(amazonBuyBoxTrackerState.data.results)} />, [amazonBuyBoxTrackerState.data.results]);
    const actionsMemo2 = useMemo(() => <Export onExport={() => downloadCSV(amazonBuyBoxTrackerState.missingBuybox.results)} />, [amazonBuyBoxTrackerState.missingBuybox.results]);
    const actionsMemo3 = useMemo(() => <Export onExport={() => downloadCSV(amazonBuyBoxTrackerState.mapViolation.results)} />, [amazonBuyBoxTrackerState.mapViolation.results]);
    const actionsMemo4 = useMemo(() => <Export onExport={() => downloadCSV(amazonBuyBoxTrackerState.unqualifiedData.results)} />, [amazonBuyBoxTrackerState.unqualifiedData.results]);

    const columns = [
        {
            name: "ASIN",
            selector: (row: any) => row.asin,
            sortable: true,
        },
        {
            name: 'Amazon',
            selector: (row: any) => {
                if (row.buybox_is_amazon) {
                    return "True"
                } else {
                    return "False"
                }
            },
            sortable: true,
        },
        {
            name: 'FBA',
            selector: (row: any) => {
                if (row.buybox_is_fba) {
                    return "True"
                } else {
                    return "False"
                }
            },
            sortable: true,
        },
        {
            name: 'UNQUALIFIED',
            selector: (row: any) => {
                if (row.buybox_is_unqualified) {
                    return "True"
                } else {
                    return "False"
                }
            },
            sortable: true,
        },
        {
            name: "PRICE",
            selector: (row: any) => row.buybox_price,
            sortable: true,
        },
        {
            name: "SELLER ID",
            selector: (row: any) => row.buybox_seller_id,
            sortable: true,
        },
        {
            name: "CREATED AT",
            selector: (row: any) => dateTimeFormater(row.created_at),
            sortable: true,
        },
        {
            name: "LAST SEEN",
            selector: (row: any) => dateTimeFormater(row.last_seen),
            sortable: true,
        },
        {
            name: "MAP",
            selector: (row: any) => row.map,
            sortable: true,
        },
        {
            name: "SKU",
            selector: (row: any) => row.sku,
            sortable: true,
        },

    ];

    return (
        <>
            <div className="container">
                <PageHeader
                    heading={"Amazon Buybox Tracker"}
                    backUrl={"/dashboard"}
                />
                <div className="tabs-verify">
                    <Tabs
                        id="controlled-tab-example"
                        defaultActiveKey="Buybox-Prices"
                        onSelect={(k: any) => setKey(k)}
                        className="mb-5"
                    >
                        <Tab eventKey="Buybox-Prices" title="Buybox Prices">

                            <Form onSubmit={getBuyboxPrices}>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>
                                        <h2 className="buybox_heading"> Enter SKU</h2>
                                    </Form.Label>
                                    <Form.Control
                                        value={skuId}
                                        onChange={(e) => setSkuId(e.target.value)}
                                        type="text" placeholder="Enter SKU" />
                                </Form.Group>
                                <Button className="lookup_button" variant="primary" type="submit">
                                    Lookup
                                </Button>
                            </Form>
                            <div className='buybox_table' style={{ display: show ? 'block' : 'none' }}>
                                <DataTable
                                    columns={columns}
                                    data={amazonBuyBoxTrackerState.data.results}
                                    // selectableRows
                                    pagination
                                    highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    actions={actionsMemo1}
                                // onRowClicked={(row: any, event) => rowClicked(row, event)}
                                // onSelectedRowsChange={filteredItems}
                                />
                            </div>
                        </Tab>
                        <Tab className="buybox_heading" eventKey="Missing-Buybox" title="Missing Buybox">

                            <Form onSubmit={getMissingBuybox}>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>
                                        <h2 className="buybox_heading"> Enter Seller ID</h2>
                                    </Form.Label>
                                    <Form.Control
                                        value={sellerId}
                                        onChange={(e) => setSellerId(e.target.value)}
                                        type="text" placeholder="Enter Seller ID" />
                                </Form.Group>
                                <Button className="lookup_button" variant="primary" type="submit">
                                    Lookup
                                </Button>
                            </Form>
                            <div className='buybox_table' style={{ display: missingShow ? 'block' : 'none' }} >
                                <DataTable
                                    columns={columns}
                                    data={amazonBuyBoxTrackerState.missingBuybox.results}
                                    // selectableRows
                                    pagination
                                    highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    actions={actionsMemo2}
                                // onRowClicked={(row: any, event) => rowClicked(row, event)}
                                // onSelectedRowsChange={filteredItems}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="Map-Voilations" title="Map Voilations">
                            <div className='buybox_table'>
                                <DataTable
                                    columns={columns}
                                    data={amazonBuyBoxTrackerState.mapViolation.results}
                                    // selectableRows
                                    pagination
                                    highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    actions={actionsMemo3}
                                // onRowClicked={(row: any, event) => rowClicked(row, event)}
                                // onSelectedRowsChange={filteredItems}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="Unqualified-Products" title="Unqualified Products">
                            <div className='buybox_table'>
                                <DataTable
                                    columns={columns}
                                    data={amazonBuyBoxTrackerState.unqualifiedData.results}
                                    // selectableRows
                                    pagination
                                    highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    actions={actionsMemo4}
                                // onRowClicked={(row: any, event) => rowClicked(row, event)}
                                // onSelectedRowsChange={filteredItems}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default AmazonBuyboxTrackerHome;