import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { fedexShippingCostService } from "../../service";


export const getCountryListAction = () => (dispatch: any) => {
  dispatch(startLoadingAction());
  fedexShippingCostService.getCountryList().then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "SET_COUNTRIES",
      payload: { countries: response.data }
    });
  });
};
