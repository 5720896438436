import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSellerCentralStoresAction, deleteSellerCentralStoreAction, sellerCentralSetDeleteStatusAction } from "../../../redux/actions/amazonSellerCentralActions";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Audio } from "react-loader-spinner";
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";


const RbmwsHome = () => {

    const [show, setShow] = useState(false);
    const [deleteID, setDeleteID]: any = useState(null);

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const amazonSellerCentralState = useSelector((state: any) => state.amazonSellerCentralReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    useEffect(() => {
        console.info("Seller Central Store Arrived")
        if (amazonSellerCentralState.stores === null) {
            dispatch(getAllSellerCentralStoresAction(true));
        }
    }, [dispatch, amazonSellerCentralState.stores])

    useEffect(() => {
        if (amazonSellerCentralState.mesg && amazonSellerCentralState.deleteStatus) {

            dispatch(sellerCentralSetDeleteStatusAction(false));
            setShow(false)
            success(amazonSellerCentralState.mesg)
        }

    }, [amazonSellerCentralState, dispatch])

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const handleClose = () => {
        setShow(false)
    }

    const confirmDelete = () => {
        dispatch(deleteSellerCentralStoreAction(deleteID))
    }

    const deleteClick = (id: String) => {
        setShow(true);
        setDeleteID(id);
    }



    return (
        <>
            <section className="main_section">
                <div className="container">
                    <div className="inner-sec seller_central_section">
                        <PageHeader
                            heading={"All Seller Store"}
                            backUrl={"/dashboard"}
                            buttonName={"Add New"}
                            handleClick={() => navigate("/rbmws/add-new")}
                        />
                        <div className="table_layout">
                            <div className="table-responsive">
                                {amazonSellerCentralState.stores ? (
                                    <table className="table mb-2" cellSpacing="20">
                                        <thead>
                                            <tr>
                                                <th className="cstm-td table_head text-center seller_head">No</th>
                                                <th className="cstm-td table_head text-center seller_head">Acount ID</th>
                                                <th className="cstm-td table_head text-center seller_head">Address Code</th>
                                                <th className="cstm-td table_head text-center seller_head">Enable Inventory Update</th>
                                                <th className="cstm-td table_head text-center seller_head">Enable Pricing Update</th>
                                                <th className="cstm-td table_head text-center seller_head">Marketplace</th>
                                                <th className="cstm-td table_head text-center seller_head">Operation</th>
                                                <th className="cstm-td table_head text-center seller_head">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {amazonSellerCentralState.stores.map((data: any, index: any) => (
                                                <tr key={index}>
                                                    <td className="cstm-td text-center seller_data">{index + 1}</td>
                                                    <td className="cstm-td text-center seller_data account_id"
                                                        onClick={() => navigate(`/rbmws/details/${data.id}?type=readonly`)}
                                                    >{data.account_id}</td>
                                                    <td className="cstm-td text-center seller_data">{data.address_to_code}</td>
                                                    <td className="seller_data text-center">
                                                        {data.enable_inventory_update ? (
                                                            <span className="badge status_badge success">Active</span>
                                                        ) : (
                                                            <span className="badge status_badge danger">Inactive</span>
                                                        )}
                                                    </td>
                                                    <td className="seller_data text-center">
                                                        {data.enable_pricing_update ? (
                                                            <span className="badge status_badge success">Active</span>
                                                        ) : (
                                                            <span className="badge status_badge danger">Inactive</span>
                                                        )}
                                                    </td>
                                                    <td className="seller_data text-center">{data.marketplace}</td>

                                                    <td className="cstm-td text-center seller_data">
                                                        <i
                                                            onClick={() => deleteClick(data.id)}
                                                            className="fas fa-trash delete_icon"></i>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => navigate(`/rbmws/details/${data.id}`)}
                                                            className="btn rounded-pill update_btn">View</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div>No Store data found...!!!</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>


                <Modal show={show} onHide={handleClose} className="delete_list_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Store</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this store?</Modal.Body>
                    <Modal.Footer>
                        <Button className="mx-0" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="mx-2" variant="primary" onClick={() => confirmDelete()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>



                <ToastContainer autoClose={3000} />
            </section>

            {loading &&
                <div className="spinner">
                    <Audio

                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            }
        </>
    )
}

export default RbmwsHome;