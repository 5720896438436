
const initialState = {
    report_requests: null
}


const inventoryHoldsReducer = (state = initialState, action: any) => {
    switch (action.type) {
            case "SET_REPORT_REQUESTS":
                return {
                    ...state,
                    report_requests: action.payload.report_requests
                }
            default:
                return state            
    }
}


export default inventoryHoldsReducer
