import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from "react-toastify";
import { Audio } from "react-loader-spinner";
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";
import { getAllShopifyStoresAction, deleteShopifyStoreAction, shopifyStoreSetDeleteStatusAction } from "../../../redux/actions/shopifyStoreActions";

const ShopifyHome = () => {
    const [show, setShow] = useState(false);
    const [deleteID, setDeleteID]: any = useState(null);

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const shopifyStoreState = useSelector((state: any) => state.shopifyStoreReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    useEffect(() => {
        console.info("Shopify stores data arrived");
        if (shopifyStoreState.stores === null) {
            dispatch(getAllShopifyStoresAction(true));
        }
    }, [dispatch, shopifyStoreState.stores]);

    useEffect(() => {
        if (shopifyStoreState.mesg && shopifyStoreState.deleteStatus) {
            dispatch(shopifyStoreSetDeleteStatusAction(false));
            setShow(false);
            success(shopifyStoreState.mesg);
        }
    }, [shopifyStoreState, dispatch]);

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const handleClose = () => {
        setShow(false);
    };

    const confirmDelete = () => {
        dispatch(deleteShopifyStoreAction(deleteID));
    };

    const deleteClick = (id: String) => {
        setShow(true);
        setDeleteID(id);
    };

    return (
        <>
            <section className="main_section">
                <div className="container">
                    <div className="inner-sec seller_central_section">
                        <PageHeader
                            heading={"All Shopify Store"}
                            backUrl={"/dashboard"}
                            buttonName={"Add New"}
                            handleClick={() => navigate("/shopify-store/add-new")}
                        />
                        <div className="table_layout">
                            <div className="table-responsive">
                                {shopifyStoreState.stores ? (
                                    <table className="table mb-2" cellSpacing="20">
                                        <thead>
                                            <tr>
                                                <th className="cstm-td table_head text-center seller_head">No</th>
                                                <th className="cstm-td table_head text-center seller_head">Acount ID</th>
                                                <th className="cstm-td table_head text-center seller_head">Hold Back</th>
                                                <th className="cstm-td table_head text-center seller_head">Save Inventory Feed</th>
                                                <th className="cstm-td table_head text-center seller_head">Shopify Shop Name</th>
                                                <th className="cstm-td table_head text-center seller_head">SKU Prefix</th>
                                                <th className="cstm-td table_head text-center seller_head">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {shopifyStoreState.stores.map((data: any, index: any) => (
                                                <tr key={index}>
                                                    <td className="cstm-td text-center seller_data">{index + 1}</td>
                                                    <td className="cstm-td text-center seller_data account_id"
                                                        onClick={() => navigate(`/shopify-store/${data.id}`)}
                                                    >{data.account_id}</td>
                                                    <td className="cstm-td text-center seller_data">{data.hold_back}</td>
                                                    <td className="seller_data text-center">
                                                        {data.save_inventory_feed ? (
                                                            <span className="badge status_badge success">Active</span>
                                                        ) : (
                                                            <span className="badge status_badge danger">Inactive</span>
                                                        )}
                                                    </td>
                                                    <td className="cstm-td text-center seller_data">{data.shopify_shop_name}</td>
                                                    <td className="cstm-td text-center seller_data">
                                                        <i
                                                            onClick={() => deleteClick(data.id)}
                                                            className="fas fa-trash delete_icon"></i>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => navigate(`/shopify-store/${data.id}?type=readonly`)}
                                                            className="btn rounded-pill update_btn">View</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div>No Store data found...!!!</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={show} onHide={handleClose} className="delete_list_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Store</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this store?</Modal.Body>
                    <Modal.Footer>
                        <Button className="mx-0" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="mx-2" variant="primary" onClick={() => confirmDelete()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ToastContainer autoClose={3000} />

            </section>

            {loading &&
                <div className="spinner">
                    <Audio
                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            }
        </>
    );
}

export default ShopifyHome;
