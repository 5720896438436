
import PageHeader from "../../shared/pagesHeader";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';

import { AppDispatch } from "../../../redux/Store";

import { startLoadingAction } from "../../../redux/actions/loaderActions";
import { getReportedMapViolationsAction } from "../../../redux/actions/mapViolationsActions";

import { mapViolationsService } from "../../../service";

import Modal from "react-bootstrap/Modal";


export const MapViolationsIndexPage = () => {

    const RECORDS_PER_PAGE = 100;
    const dispatch: AppDispatch = useDispatch();
    const mapViolationsState = useSelector((state: any) => state.mapViolationsReducer);

    const [showMAPReportModal, setShowMAPReportModal] = useState(false);
    const [currentReportedViolation, setCurrentReportedViolation] = useState(null as any);

    const [showClosed, setShowClosed] = useState(false);

    useEffect(() => {
        console.info("Map Violations arrived");
        if (mapViolationsState.reportedViolations.limit === null) {
            dispatch(getReportedMapViolationsAction(true, RECORDS_PER_PAGE, 0, false));
        }
    }, [dispatch, mapViolationsState.reportedViolations]);


    const onPrevClicked = () => {
        if (mapViolationsState.reportedViolations.offset > 0) {
            let offset = mapViolationsState.reportedViolations.offset - RECORDS_PER_PAGE
            if (offset < 0) {
                offset = 0;
            }
            dispatch(getReportedMapViolationsAction(true, RECORDS_PER_PAGE, offset, mapViolationsState.reportedViolations.is_closed));
        }
    };

    const onNextClicked = () => {
        const nextOffset = mapViolationsState.reportedViolations.offset + RECORDS_PER_PAGE;
        if (nextOffset <= mapViolationsState.reportedViolations.data.total) {
            dispatch(getReportedMapViolationsAction(true, RECORDS_PER_PAGE, nextOffset, mapViolationsState.reportedViolations.is_closed));
        }
    };

    const onReportedViolationClicked = (reportedViolation: any) => (event: any) => {
        setShowMAPReportModal(true);
        setCurrentReportedViolation(reportedViolation);
    };

    const onReOpenReportClicked = (id: number) => (event: any) => {
        dispatch(startLoadingAction());
        mapViolationsService.reopenReportedViolation(id).then((response) => {
            setShowMAPReportModal(false);
            dispatch(getReportedMapViolationsAction(true, RECORDS_PER_PAGE, mapViolationsState.reportedViolations.offset, mapViolationsState.reportedViolations.is_closed));
        });
    };

    const onCloseReportClicked = (id: number) => (event: any) => {

        dispatch(startLoadingAction());
        mapViolationsService.closeReportedViolation(id).then((response) => {
            setShowMAPReportModal(false);
            dispatch(getReportedMapViolationsAction(true, RECORDS_PER_PAGE, mapViolationsState.reportedViolations.offset, mapViolationsState.reportedViolations.is_closed));
        });
    };

    const onShowClosedChanged = () => (event: any) => {
        setShowClosed(!showClosed)
        let is_closed = null;
        if (showClosed) {
            is_closed = false;
        }
        dispatch(getReportedMapViolationsAction(true, RECORDS_PER_PAGE, mapViolationsState.reportedViolations.offset, is_closed));
    }

    let hasPrev = false;
    let hasNext = false;

    if (mapViolationsState.reportedViolations.limit) {
        if (mapViolationsState.reportedViolations.offset > 0) {
            hasPrev = true;
        }

        const nextOffset = mapViolationsState.reportedViolations.offset + RECORDS_PER_PAGE;
        if (nextOffset <= mapViolationsState.reportedViolations.data.total) {
            hasNext = true;
        }
    }

    return (

        <>
            <div className="container">

                <PageHeader
                    heading={"Reported MAP Violations"}
                    backUrl={"/dashboard"}
                />
                <div className="container">


                    {mapViolationsState.reportedViolations.limit && (<>



                        <div className="row justify-content-center align-items-center mt-3">


                            <div className="table_layout">
                                <div className="left">
                                    <p><input type="checkbox" name="show-closed" id="show-closed" checked={showClosed} onChange={onShowClosedChanged()} /> <label htmlFor="show-closed">Show closed</label></p>

                                    {mapViolationsState.reportedViolations.data.total > 0 && (
                                        <p>Showing records {mapViolationsState.reportedViolations.offset + 1} to {mapViolationsState.reportedViolations.offset + mapViolationsState.reportedViolations.data.records.length} of {mapViolationsState.reportedViolations.data.total}</p>
                                    )}

                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                </th>
                                                <th>
                                                    Date Reported
                                                </th>
                                                <th>
                                                    Product SKU
                                                </th>

                                                <th>
                                                    Product Name
                                                </th>

                                                <th>
                                                    Company Name
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {mapViolationsState.reportedViolations.data.records.length > 0 || (
                                                <tr>
                                                    {showClosed ? (<td colSpan={5}>No reports</td>) : (<td colSpan={5}>No open reports</td>)}

                                                </tr>


                                            )}

                                            {mapViolationsState.reportedViolations.data.records.map((reportedViolation: any) => (

                                                <tr key={reportedViolation.Id} onClick={onReportedViolationClicked(reportedViolation)} style={{ cursor: 'pointer' }}>
                                                    <td><p>{reportedViolation.is_closed ? (<u className="far fa-check-square"></u>) : (<u className="far fa-square"></u>)} </p></td>
                                                    <td className="left">{new Date(reportedViolation.created_at).toLocaleDateString()}</td>
                                                    <td className="left">{reportedViolation['Product Sku']}</td>
                                                    <td className="left">{reportedViolation['Product Name']}</td>
                                                    <td className="left">{reportedViolation['CompanyName']}</td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>

                                </div>


                                <p>
                                    <span>
                                        {hasPrev ? (<button className="btn btn-primary" onClick={onPrevClicked}><i className="fas fa-chevron-left"></i> Previous</button>) : (<button className="btn btn-secondary" disabled><i className="fas fa-chevron-left"></i> Previous</button>)}
                                    </span>
                                    <span style={{ marginLeft: '1ex' }}>
                                        {hasNext ? (<button className="btn btn-primary" onClick={onNextClicked}>Next <i className="fas fa-chevron-right"></i></button>) : (<button className="btn btn-secondary" disabled>Next <i className="fas fa-chevron-right"></i></button>)}
                                    </span>


                                </p>


                            </div>


                        </div>

                    </>)}

                </div>
            </div>
            <Modal
                show={showMAPReportModal}
                dialogClassName="modal-lg"
                aria-labelledby="example-modal-sizes-title-lg"
                onHide={() => setShowMAPReportModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        MAP Violation Report (#{currentReportedViolation?.Id})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentReportedViolation && (<>


                        <div className="row">
                            <div className="col-md-4">
                                Status
                            </div>

                            <div className="col-md-4">

                                <div className="form-control">
                                    {currentReportedViolation.is_closed ? (
                                        <>
                                            <span style={{ marginRight: '2ex' }}>Closed</span>
                                            <button className="btn btn-primary" onClick={onReOpenReportClicked(currentReportedViolation.Id)}>Re-Open</button>
                                        </>) : (
                                        <>
                                            <span style={{ marginRight: '2ex' }}>Open</span>
                                            <button className="btn btn-primary" onClick={onCloseReportClicked(currentReportedViolation.Id)}>Close</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                Reported
                            </div>

                            <div className="col-md-4">
                                <input className="form-control" value={new Date(currentReportedViolation['created_at']).toLocaleString()} readOnly={true} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                Product
                            </div>

                            <div className="col-md-4">
                                <input className="form-control" value={currentReportedViolation['Product Name']} readOnly={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                SKU
                            </div>

                            <div className="col-md-4">
                                <input className="form-control" value={currentReportedViolation['Product Sku']} readOnly={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                MAP
                            </div>

                            <div className="col-md-4">
                                <input className="form-control" value={'$' + currentReportedViolation.CurrentMap.toFixed(2)} readOnly={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                Company Name
                            </div>

                            <div className="col-md-4">
                                <input className="form-control" value={'$' + currentReportedViolation.CompanyName} readOnly={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                URL
                            </div>

                            <div className="col-md-4">
                                <div className="input-group">
                                    <input className="form-control" value={currentReportedViolation.WebsiteUrl} readOnly={true} />

                                    <div className="input-group-postpend" style={{ cursor: 'pointer' }}>
                                        <div className="input-group-text" onClick={() => { window.open(currentReportedViolation.WebsiteUrl) }}><span><i className="fas fa-link"></i></span></div>
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                Screenshot
                            </div>

                            <div className="col-md-4">
                                {currentReportedViolation.WebsiteScreenshot && currentReportedViolation.WebsiteScreenshot.length > 0 && (

                                    <img src={currentReportedViolation.WebsiteScreenshot} alt="[screenshot]" style={{ cursor: 'pointer' }} onClick={() => { window.open(currentReportedViolation.WebsiteScreenshot) }} />

                                )}
                            </div>
                        </div>

                    </>)}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>)
}

export default MapViolationsIndexPage;
