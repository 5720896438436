
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { hmProductImagesService } from "../../service";
import { dateFormat, daysAdd } from "../../utils/Helper"

export const getImagesAction = (offset: number, itemsPerPage: number, filterTimeframe: string) => (dispatch: any) => {
        
    const dayOffset : any = {
        'day': -1,
        'week': -7,
        'month': -31,
    }
    
    const fromDate = dateFormat(daysAdd(new Date(), dayOffset[filterTimeframe]));
    dispatch(startLoadingAction());
    
    hmProductImagesService.getAmazonImages(offset,itemsPerPage, fromDate).then(response => {        
        dispatch({
            type: "SET_IMAGES",
            payload: {
                totalRecords: response.data.total,
                filterTimeframe: filterTimeframe,
                records: response.data.amazon_images,
                offset,
                itemsPerPage,                
                fromDate
            }
        });
        dispatch(stopLoadingAction());
    });
};
