const initialState = {
    job_code: null,
    job_request_sent: null,
    pending: null,
    shipping_costs: [],
    status: false
}

const shippingCostReducer = (state=initialState, action:any) =>{
    switch(action.type){
        case "GET_SHIPPING_COSTS":
            return {
                ...state,
                shipping_costs:action.payload.shipping_costs,
                job_code:action.payload.job_status,
                job_request_sent:action.payload.job_request_sent,
                pending:action.payload.pending,
                status:true
              };
        default:
            return state;
    }
}

export default shippingCostReducer;