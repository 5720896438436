
import { useState } from "react";
import { useParams } from "react-router-dom";

import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";
import WayfairOrderSkuExceptionMapForm from "../../../common/admin/wayfair/WayfairOrderSkuExceptionMapForm";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch } from "react-redux";

const WayfairOrderCreateSkuExceptionMap = (props: any) => {

    const params: any = useParams();
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());

        let wayfairOrderSkuExceptionMap = {
            "wayfair_customer_po": params.wayfair_customer_po,
            "wayfair_supplier_part_number": params.wayfair_supplier_part_number,
            "hm_order_sku": value.hm_order_sku
        };

        wayfairService.createOrUpdateWayfairOrderSkuExceptionMap(wayfairOrderSkuExceptionMap).then(response => {
            dispatch(stopLoadingAction());
            navigate('/admin/wayfair/order-errors');
        }).catch((response) => {
            dispatch(stopLoadingAction());
            setErrorMessage(response.message);
        });

    }

    return (
        <>
            <div className="wayfair-admin container">
                <PageHeader
                    heading={`Wayfair Order (${params.wayfair_customer_po}) - Create SKU Map`}
                    backUrl={"/admin/wayfair/order-errors"}
                />

                <WayfairOrderSkuExceptionMapForm handleSubmit={handleSubmit} errorMessage={errorMessage} wayfairCustomerPo={params.wayfair_customer_po} wayfairSupplierPartNumber={params.wayfair_supplier_part_number} />

            </div>

        </>)
};


export default WayfairOrderCreateSkuExceptionMap;
