
import { temporalService } from "../../service";

export const getTimezonesAction = () => (dispatch: any) => {

    temporalService.getTimezones().then(response => {
    
        dispatch({
            type: "SET_TIMEZONES",
            payload: response.data.timezones                
        });

    });    
}
