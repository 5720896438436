
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/Store";

import PageHeader from "../../shared/pagesHeader";
import { 
    getProductAction, 
    getTranslatedProductAction, 
    getSuggestedTranslationAction, 
    updateTranslationAction,
    resetTranslationAction,
    clearProductAction } from "../../../redux/actions/translationsActions";


const LocaleEditor = () => {

    const dispatch: AppDispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);
    const [productTranslationForm, setProductTranslationForm] = useState(null as any);

    const params: any = useParams();

    const translationsState = useSelector((state: any) => state.translationsReducer);


    const loadForm = () => {
        setProductTranslationForm({
            ...translationsState.product_translated
        })
    };

    const editButtonClicked = () => {
        loadForm();
        setEditMode(true);
    };

    const cancelButtonClicked = () => {
        setEditMode(false);
    };

    const saveButtonClicked = () => {
        dispatch(updateTranslationAction(
            true, 
            productTranslationForm.locale_code, 
            productTranslationForm.item_number, 
            productTranslationForm))
        setEditMode(false);
    };

    const resetButtonClicked = () => {
        dispatch(resetTranslationAction(
            true, 
            productTranslationForm.locale_code, 
            productTranslationForm.item_number))
        setEditMode(false);
    };    

    const setProductTranslationFieldValue = (fieldName: string, value: string) => {

        const fieldValue: any = {}
        fieldValue[fieldName] = value;

        const newValue = {
            ...productTranslationForm,
            ...fieldValue
        }

        setProductTranslationForm(newValue);

    }

    useEffect(() => {
        console.info("LocaleEditor Arrived");
        if (
            !translationsState.product_request_in_progress &&
            translationsState.product == null &&
            translationsState.suggested_translation == null &&
            translationsState.product_translated == null) {
            dispatch(getProductAction(false, params.locale_code, params.sku));
            dispatch(getTranslatedProductAction(true, params.locale_code, params.sku));
            dispatch(getSuggestedTranslationAction(false, params.locale_code, params.sku));
        } else if ((translationsState.item_number !== params.sku || translationsState.locale_code !== params.locale_code) && !translationsState.product_request_in_progress) {
            dispatch(clearProductAction(true));
        }

    }, [
        dispatch,
        translationsState.product,
        translationsState.suggested_translation,
        translationsState.product_translated,
        translationsState.item_number,
        translationsState.locale_code,
        translationsState.product_request_in_progress,
        params.locale_code,
        params.sku
    ]);

    //console.log("translationsState", translationsState);

    // console.log('suggested_translation',translationsState.suggested_translation);

    return (
        <>
            <div className='container'>
                {translationsState.product_translated === null ? (
                    <PageHeader
                        heading={(<>
                            Translation of product<br />
                            <span style={{ fontSize: '50%', display: 'inline-block' }}><span>Locale</span>: <span>{params.locale_code}</span></span> <br />
                            <span style={{ fontSize: '50%', display: 'inline-block' }}><span>SKU</span>: <span>{params.sku}</span></span>
                        </>)}
                        backUrl={`/locales/${params.locale_code}`}
                    />) : (
                    <PageHeader
                        heading={(<>
                            Translation of product
                        </>)}
                        backUrl={`/locales/${params.locale_code}`}
                    />)}

                {translationsState.product_translated !== null && !editMode && (
                    <>

                        <div className="d-flex justify-content-end mb-4">
                            <div className="me-3">
                                <div className="">
                                    <button
                                        onClick={() => editButtonClicked()}
                                        className="btn edit_prim_btn rounded-pill">Edit <i className="fas fa-pencil-alt ms-2"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="table_layout text-left">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>item_number</label>
                                        <div className="form-control disabled mb-30">{translationsState.product_translated.item_number}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>locale_code</label>
                                        <div className="form-control disabled mb-30">{translationsState.product_translated.locale_code}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>verified</label>
                                        <div className="form-control disabled mb-30">{translationsState.product_translated.verified ? "TRUE" : "FALSE"}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>verified_by_email</label>
                                        <div className="form-control disabled mb-30">{translationsState.product_translated.verified_by_email === null ? (<span>NULL</span>) : translationsState.product_translated.verified_by_email}</div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>display_name</label>
                                        <div className="form-control disabled mb-30">{translationsState.product_translated.display_name}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>short_description</label>
                                        <div className="form-control disabled mb-30">{translationsState.product_translated.short_description}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                )}

                {translationsState.product_translated !== null && productTranslationForm !== null && editMode && (
                    <>

                        <div className="d-flex justify-content-end mb-4">
                            <div className="me-3">
                                <div className="">
                                    <button
                                        onClick={() => cancelButtonClicked()}
                                        className="btn cancel_btn rounded-pill me-3">Cancel</button>
                                </div>
                            </div>
                            <div className="me-3">
                                <div className="">
                                    <button
                                        onClick={() => resetButtonClicked()}
                                        className="btn save_btn rounded-pill me-3">Reset</button>
                                </div>
                            </div>                            
                            <div className="me-3">
                                <div className="">
                                    <button
                                        onClick={() => saveButtonClicked()}
                                        className="btn save_btn rounded-pill me-3">Save</button>
                                </div>
                            </div>                            
                        </div>

                        <div className="table_layout text-left">

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>item_number</label>
                                        <div className="form-control disabled mb-30">{productTranslationForm.item_number}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>locale_code</label>
                                        <div className="form-control disabled mb-30">{productTranslationForm.locale_code}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>verified</label>
                                        <div className="form-control disabled mb-30">{productTranslationForm.verified ? "TRUE" : "FALSE"}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>verified_by_email</label>
                                        <div className="form-control disabled mb-30">{productTranslationForm.verified_by_email === null ? (<span>NULL</span>) : translationsState.product_translated.verified_by_email}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>display_name</label>
                                        <input onChange={(e) => setProductTranslationFieldValue('display_name', e.target.value)} type="text" className="form-control mb-2 detail_content" value={productTranslationForm.display_name} />
                                        <div className="form-control info mb-30">{translationsState.suggested_translation.display_name}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>short_description</label>
                                        <input onChange={(e) => setProductTranslationFieldValue('short_description', e.target.value)} type="text" className="form-control mb-2 detail_content" value={productTranslationForm.short_description} />
                                        <div className="form-control info mb-30">{translationsState.suggested_translation.short_description}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                )}

            </div>
        </>
    )
}

export default LocaleEditor;
