

import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";

export class TemporalServiceImpl {

    apiBaseUrl = `${config.baseUrl}api`;

    getTimezones() {
        return axios.get(`${this.apiBaseUrl}/timezones`, get_config());
    }

}