
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { userProfileService } from "../../service";


export const getAllUserAction = (loading_status: any = null) => (dispatch: any) => {
    if (loading_status) {
        dispatch(startLoadingAction());
    }
    userProfileService.getUserList().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_ALL_USERS",
            payload: response.data
        });
    });
};


export const createNewUserAction = (email: any, password: any, username: any, roles: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    userProfileService.createUserAccount(email, password, username, roles).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "ADD_NEW_USER",
            payload: response.data
        });
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "ADD_NEW_USER_ERROR",
            payload: err.response,
            status: err.response.status
        });
    });
};


export const adminUpdateUserDetailsAction = (email: any, password: any, user_id: any, roles: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    userProfileService.updateUserAccount(email, password, user_id, roles).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "ADMIN_UPDATE_USER",
            payload: response.data
        });
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "ADMIN_UPDATE_USER_ERROR",
            payload: { data: err.response.data, status: err.response.status },
            status: err.response.status
        });
    });
};


export const adminUserClearNewUserCreateAction = () => (dispatch: any) => {
    dispatch({
        type: "ADMIN_CLEAR_NEW_USER_CREATE"
    });
};


export const adminUserClearUserUpdateAction = () => (dispatch: any) => {
    dispatch({
        type: "ADMIN_CLEAR_USER_UPDATE"
    });
};


export const adminUserClearStatusCodeAction = () => (dispatch: any) => {
    dispatch({
        type: "ADMIN_CLEAR_STATUS_CODE"
    });
};
