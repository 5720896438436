
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class OverstockPPCServiceImpl {
  apiUrl = `${config.baseUrl}api/overstock-ppc`;

  uploadReport(company: string, startDate: string, endDate: string, reportType: string, reportFile: File) {

    const url = `${this.apiUrl}/upload-report`;
    const formData = new FormData();

    formData.append("report_type", reportType);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("report", reportFile);
    formData.append("company", company);

    return axios.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authentication-Token': localStorage.getItem("token")
        },
      }
    );

  }


  uploadCatalog(company: string, catalogFile: File) {

    const url = `${this.apiUrl}/upload-catalog`;
    const formData = new FormData();
    formData.append("catalog", catalogFile);
    formData.append("company", company);

    return axios.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authentication-Token': localStorage.getItem("token")
        },
      }
    );

  }


  getSponsoredProductConversionWeeklyReports(company: string, offset: number, limit: number) {
    const url = `${this.apiUrl}/reports/${company}/sponsored-product-conversion-weekly`;
    const params = `?ordering=-start_date&offset=${offset}&limit=${limit}`;
    return axios.get(
      `${url}${params}`,
      get_config()
    );
  }


  getSponsoredProductConversionMonthlyReports(company: string, offset: number, limit: number) {
    const url = `${this.apiUrl}/reports/${company}/sponsored-product-conversion-monthly`;
    const params = `?ordering=-start_date&offset=${offset}&limit=${limit}`;
    return axios.get(
      `${url}${params}`,
      get_config()
    );
  }


  getSponsoredProductAdvertisingWeeklyReports(company: string, offset: number, limit: number) {
    const url = `${this.apiUrl}/reports/${company}/sponsored-product-advertising-weekly`;
    const params = `?ordering=-start_date&offset=${offset}&limit=${limit}`;
    return axios.get(
      `${url}${params}`,
      get_config()
    );
  }


  getSponsoredProductAdvertisingMonthlyReports(company: string, offset: number, limit: number) {
    const url = `${this.apiUrl}/reports/${company}/sponsored-product-advertising-monthly`;
    const params = `?ordering=-start_date&offset=${offset}&limit=${limit}`;
    return axios.get(
      `${url}${params}`,
      get_config()
    );
  }


  getOrdersDashboardWeeklyReports(company: string, offset: number, limit: number) {
    const url = `${this.apiUrl}/reports/${company}/orders-dashboard-weekly`;
    const params = `?ordering=-start_date&offset=${offset}&limit=${limit}`;
    return axios.get(
      `${url}${params}`,
      get_config()
    );
  }


  getOrdersDashboardMonthlyReports(company: string, offset: number, limit: number) {
    const url = `${this.apiUrl}/reports/${company}/orders-dashboard-monthly`;
    const params = `?ordering=-start_date&offset=${offset}&limit=${limit}`;
    return axios.get(
      `${url}${params}`,
      get_config()
    );
  }


  getStrategies(company: string) {
    const url = `${this.apiUrl}/strategies?company=${company}`;
    return axios.get(
      url,
      get_config()
    );
  }


  applyStrategy(company: string, excelFile: File, strategy: string) {
    const url = `${this.apiUrl}/apply-strategy`;
    const formData = new FormData();


    formData.append("strategy", strategy);
    formData.append("file", excelFile);
    formData.append("company", company);
    return axios.post(
      url,
      formData,
      {
        responseType: 'arraybuffer',
        headers: {
          "Content-Type": "multipart/form-data",
          'Authentication-Token': localStorage.getItem("token")
        },
      }
    );
  }

  
  getSalesReports(company: string) {
    const url = `${this.apiUrl}/hm-reports/${company}/sales-report`
    return axios.get(
      url,
      get_config()
    );
  }

  
  getConfigurationList() {
    const url = `${this.apiUrl}/account-config`
    return axios.get(
      url,
      get_config()
    );
  }


  getConfiguration(id: string) {
    return axios.get(`${this.apiUrl}/account-config?id=${id}`, get_config());
  }


  deleteConfigurations(overstock_ppc_ids: string[]) {
    const currentConfig = get_config();
    let cfg = {
      ...currentConfig,
      data: { overstock_ppc_ids: overstock_ppc_ids }
    }
    return axios.delete(`${this.apiUrl}/account-config`, cfg);
  }

  createOrUpdateConfiguration(data: any) {
    return axios.post(`${this.apiUrl}/account-config`, data, get_config());
  }


}