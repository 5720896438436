import { TradeshowConfigService } from "../../service/tradeshowConfigService";

const tradeshowConfigService = new TradeshowConfigService();

export const fetchConfig = () => async (dispatch: any) => {
  try {
    const { data } = await tradeshowConfigService.getConfig();
    dispatch({ type: "SET_CONFIG", payload: data });
  } catch (error) {
    console.error("Error fetching config:", error);
  }
};

export const updateConfig = (configData: any) => ({
  type: "UPDATE_CONFIG",
  payload: configData,
});
