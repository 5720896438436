const initialState = {
    ficup_rates: null,
    label_status: null,
}

const fedexFicupReducer = (state=initialState, action:any) =>{
    switch(action.type){

        case "GET_FEDEX_FICUP_RATES":
            return{
                ...state,
                ficup_rates:action.payload.rates,
            }
        case "GET_FEDEX_FICUP_LABEL_STATUS":
            return {
                ...state,
                label_status:action.payload,
            }
        default:
            return state;
    }
}

export default fedexFicupReducer;