
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/Store";

import PageHeader from "../../shared/pagesHeader";
import { getTranslatedProductsAction } from "../../../redux/actions/translationsActions";




const TranslationList = () => {

    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();

    const params: any = useParams();

    const translationsState = useSelector((state: any) => state.translationsReducer);


    useEffect(() => {
        console.info("TranslationList Arrived");
        if ((translationsState.table == null || translationsState.locale_code !== params.locale_code) && !translationsState.table_request_in_progress) {
            dispatch(getTranslatedProductsAction(true, params.locale_code));
        }
    }, [
        translationsState.table,
        translationsState.table_request_in_progress,
        translationsState.locale_code,
        translationsState.products, 
        translationsState.translated_products, 
        dispatch, 
        params.locale_code]);

    //console.log("translationsState", translationsState);

    return (
        <>
            <div className='container'>
                <PageHeader
                    heading={`Translations (${params.locale_code})`}
                    backUrl={"/locales"}
                />

                { translationsState.table ? (
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="cstm-td table_head">SKU</th>
                                <th className="cstm-td table_head">Product</th>
                                <th className="cstm-td table_head">{params.locale_code}</th>
                            </tr>

                        </thead>
                        <tbody>
                            { translationsState.table.map((row: any) => {

                                const url = `/locales/${params.locale_code}/${row.product.item_number}`;
                                return (
                                    <tr className="cstm-tr table_body" key={row.product.item_number}>
                                        <td><button className="btn btn-link" onClick={() => navigate(url)}>{row.product.item_number}</button></td>
                                        <td><button className="btn btn-link" onClick={() => navigate(url)}>{row.product.display_name}</button></td>
                                        <td><button className="btn btn-link" onClick={() => navigate(url)}>{row.translated.display_name}</button></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : null}

            </div>
        </>
    )
}

export default TranslationList;
