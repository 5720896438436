

import Modal from "react-bootstrap/Modal";

const OverstockForecastModal = (props: any) => {


    const resetErrorMessages = () => {
        props.setErrorMessage(null);
        props.setWarningMessages([]);
    }


    const handleDateStartChange = (e: any) => {

        if (props.errorMessage !== null) {
            resetErrorMessages();
        }

        let start_date = e.target.value;
        let end_date = props.uploadFormEndDate;

        let start = Date.parse(start_date);
        let end = Date.parse(end_date);

        if (start < end) {

            props.setUploadFormStartDate(start_date);
            props.setUploadFormEndDate(end_date);

        } else {
            props.setUploadFormStartDate(end_date);
            props.setUploadFormEndDate(start_date);
        }

    };


    const handleDateEndChange = (e: any) => {

        if (props.errorMessage !== null) {
            resetErrorMessages();
        }

        let start_date = props.uploadFormStartDate;
        let end_date = e.target.value;

        let start = Date.parse(start_date);
        let end = Date.parse(end_date);

        if (start < end) {

            props.setUploadFormStartDate(start_date);
            props.setUploadFormEndDate(end_date);

        } else {
            props.setUploadFormStartDate(end_date);
            props.setUploadFormEndDate(start_date);
        }

    };

    const handleDateStartKeyDown = (e: any) => {
        if (e.keyCode === 38 || e.keyCode === 40) {

            if (props.errorMessage !== null) {
                resetErrorMessages();
            }

            let start_date = e.target.value;
            let end_date = '';


            props.setUploadFormStartDate(start_date);
            props.setUploadFormEndDate(end_date);


        }
    };

    const handleDateEndKeyDown = (e: any) => {

        if (e.keyCode === 38 || e.keyCode === 40) {

            if (props.errorMessage !== null) {
                resetErrorMessages();
            }

            let start_date = '';
            let end_date = e.target.value;

            props.setUploadFormStartDate(start_date);
            props.setUploadFormEndDate(end_date);
        }
    };


    const handleReportChange = (e: any) => {

        if (props.errorMessage !== null) {
            resetErrorMessages();
        }

        props.setUploadFormReportFile(e.target.files[0]);

    };


    return (

        <Modal
            show={props.show}
            dialogClassName="modal-lg"
            aria-labelledby="example-modal-sizes-title-lg"
            onHide={() => props.setShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Process Wayfair Forecast
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.errorMessage ? (<>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 alert alert-danger">
                            {props.errorMessage}
                        </div>
                    </div>
                    {props.warningMessages.map(((warningMessage: string, idx: number) => {
                        return (
                            <div className="row" key={idx}>
                                <div className="col-md-10 offset-md-1 alert alert-warning">
                                    {warningMessage}
                                </div>
                            </div>)
                    }))}

                </>) : null}

                {props.formStatus !== null && (<>
                    <div className="row">
                        <div className="col-md-4">
                            <p>Request Status</p>
                        </div>
                        <div className="col-md-8">
                            {props.formStatus}
                        </div>
                    </div>
                </>)}
                {props.formStatus === null && (<>
                    <div className="row">
                        <div className="col-md-4">
                            <p>Date Start</p>
                        </div>
                        <div className="col-md-8">
                            <div className="md-form md-outline input-with-post-icon datepicker">
                                <input placeholder="Select date" type="date" onKeyDown={handleDateStartKeyDown} onChange={handleDateStartChange} value={props.uploadFormStartDate} className="form-control mb-30" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <p>Date End</p>
                        </div>
                        <div className="col-md-8">
                            <div className="md-form md-outline input-with-post-icon datepicker">
                                <input placeholder="Select date" type="date" onKeyDown={handleDateEndKeyDown} onChange={handleDateEndChange} value={props.uploadFormEndDate} className="form-control mb-30" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <p>Forecast</p>
                        </div>
                        <div className="col-md-8">
                            <input
                                onChange={handleReportChange}
                                type="file" className="form-control mb-30 detail_content" />
                        </div>
                    </div>
                </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {props.formStatus === null && (
                    <button className="btn btn-primary" onClick={props.onUploadProcessClicked}>Process</button>
                )}
            </Modal.Footer>
        </Modal>)
};

export default OverstockForecastModal;