import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface ShopifyStoreFormProps {
    editClick: boolean;
    setShopName: (value: string) => void;
    shopName: string;
    setAccountId: (value: string) => void;
    accountId: string;
    setApiKey: (value: string) => void;
    apiKey: string;
    setApiSecret: (value: string) => void;
    apiSecret: string;
    setAccessToken: (value: string) => void;
    accessToken: string;
    setSkuPrefix: (value: string) => void;
    skuPrefix: string;
    setHoldBack: (value: number) => void;
    holdBack: number;
    setSaveInventoryFeed: (value: boolean) => void;
    saveInventoryFeed: boolean;
    setBrands: (value: string[]) => void;
    brands: string[];
    setOwnerUserId: (value: string) => void;
    ownerUserId: string;
    isAdmin: boolean;
    user: any; 
}

const ShopifyStoreForm: React.FC<ShopifyStoreFormProps> = (props: any) => {
    // const [brands, setBrands] = useState<string[]>(props.brands || []);
    const [howardMiller, setHowardMiller] = useState(false);
    const [hekman, sethekman] = useState(false);
    
    const onBrandChange = (e: any) => {
        if (e.target.value === 'howard miller') {
            setHowardMiller(!howardMiller)
        } else {
            sethekman(!hekman)
        }
    }

    useEffect(() => {
        if (props.brands) {
            props.brands.map((data: any) => {
                if (data === "howard miller") {
                    setHowardMiller(true)
                } else if (data === "hekman") {
                    sethekman(true)
                }
                return null;
            })
        }
    }, [props.brands])

    useEffect(() => {

        if (props.brands) {
            if (howardMiller) {
                if (!props.brands.includes('howard miller')) {
                    props.setBrands(
                        [...props.brands, 'howard miller']
                    )
                }

            } else {
                const index = props.brands.indexOf('howard miller');
                if (index > -1) {
                    const brands = [...props.brands];
                    brands.splice(index, 1)
                    props.setBrands(brands)
                }
            }
        }
    }, [howardMiller, props])

    useEffect(() => {

        if (props.brands) {
            if (hekman) {
                if (!props.brands.includes('hekman')) {
                    props.setBrands(
                        [...props.brands, 'hekman']
                    )
                }

            } else {
                const index = props.brands.indexOf('hekman');
                if (index > -1) {
                    const brands = [...props.brands];
                    brands.splice(index, 1)
                    props.setBrands(brands)
                }
            }
        }
    }, [hekman, props])

    // const handleBrandChange = (brand: string) => {
    //     props.setBrands((prevBrands: any) =>
    //         prevBrands.includes(brand)
    //             ? prevBrands.filter((b: any) => b !== brand)
    //             : [...prevBrands, brand]
    //     );
    // };

    return (
        <div className="shopify-store-form">
            <div className="table_layout p-4">
                <div className="row">
                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">Shop Name</label>
                            <input
                                onChange={(e) => props.setShopName(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.shopName} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">Account ID</label>
                            <input
                                onChange={(e) => props.setAccountId(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.accountId} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">Owner User ID</label>
                            <select
                                onChange={(e) => props.setOwnerUserId(e.target.value)}
                                className="form-control mb-30 detail_content"
                                value={props.ownerUserId}
                                disabled={props.editClick}
                            >
                                <option value="">Select owner</option>
                                {props.user?.map((user: any) => (
                                    <option value={user.id} key={user.id}>{user.email}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">API Key</label>
                            <input
                                onChange={(e) => props.setApiKey(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.apiKey} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">API Secret</label>
                            <input
                                onChange={(e) => props.setApiSecret(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.apiSecret} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">Access Token</label>
                            <input
                                onChange={(e) => props.setAccessToken(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.accessToken} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">SKU Prefix</label>
                            <input
                                onChange={(e) => props.setSkuPrefix(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.skuPrefix} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">Hold Back</label>
                            <input
                                onChange={(e) => props.setHoldBack(Number(e.target.value))}
                                type="number" className="form-control mb-30 detail_content" readOnly={props.editClick} value={String(props.holdBack) || ''} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="">
                            <label className="detail_head">Save Inventory Feed</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setSaveInventoryFeed(!props.saveInventoryFeed)}
                                    checked={props.saveInventoryFeed}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">

                        <div className="col-md-12">
                            <div className="mb-30">

                                <label className="detail_head">Brands</label>
                                <div className="form-check clearfix">

                                    <input className="form-check-input" type="checkbox" onChange={(e) => onBrandChange(e)} value="howard miller" id="flexCheckDefault"
                                        checked={howardMiller}
                                        disabled={props.editClick}
                                    />
                                    <label className="form-check-label brand float-start" htmlFor="flexCheckDefault">
                                        howard miller
                                    </label>
                                </div>
                                <div className="form-check clearfix">
                                    <input className="form-check-input" onChange={(e) => onBrandChange(e)} type="checkbox" value="hekman" id="flexCheckChecked"
                                        checked={hekman}
                                        disabled={props.editClick}
                                    />
                                    <label className="form-check-label brand float-start" htmlFor="flexCheckChecked">
                                        hekman
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
        </div>
    );
};

export default ShopifyStoreForm;
