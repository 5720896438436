import { getAllUserAction } from "./adminUserActions";
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { userProfileService } from "../../service";


export const deleteUserProfileAction = (id: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    userProfileService.deleteUserAccount(id).then(response => {
        dispatch(stopLoadingAction());
        dispatch(getAllUserAction())
        dispatch({
            type: "DELETE_USER_SUCCESS",
            payload: { data: response.data, status: response.status }
        });
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "DELETE_USER_ERROR",
            payload: { data: err.response.data, status: err.response.status }
        });
    });
};


export const deleteUserClearStatusCodeAction = () => (dispatch: any) => {
    dispatch({
        type: "DELETE_USER_CLEAR_STATUS_CODE"
    });
};
