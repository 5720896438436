import { useState } from "react";

import React from "react";

import { ficService } from "../../../service";
import PageHeader from "../../shared/pagesHeader";
import CustomerForm from "../../common/customers/CustomerForm";
import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../redux/actions/loaderActions";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/Store";
import { useDispatch } from "react-redux";

const HmCreateCustomer = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = (value: any) => {
    setErrorMessage("");
    dispatch(startLoadingAction());
    let customer = {
      customer_name: value.customer_name,
      company: value.company,
      jba_customer_no: value.customer_no,
      contact_name: value.contact_name,
      contact_addr_1: value.contact_addr_1,
      contact_addr_2: value.contact_addr_2,
      contact_city: value.contact_city,
      contact_state: value.contact_state,
      contact_country: value.contact_country,
      postal_code: value.postal_code,
      shipping_contact_email: value.shipping_contact_email,
      memo: value.memo,
    };

    ficService
      .createOrUpdateCustomer(customer)
      .then((response) => {
        dispatch(stopLoadingAction());
        navigate("/customer");
      })
      .catch((response) => {
        dispatch(stopLoadingAction());
        setErrorMessage(response.message);
      });
  };

  return (
    <>
      <div className="fic-admin container">
        <PageHeader
          heading={"FIC - Create Customer"}
          backUrl={"/customer"}
        />

        <CustomerForm handleSubmit={handleSubmit} errorMessage={errorMessage} />
      </div>
    </>
  );
};

export default HmCreateCustomer;
