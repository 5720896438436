
import { useEffect } from "react";

import PageHeader from "../../../shared/pagesHeader";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import WayfairAdminMenu from "../../../common/admin/wayfair/WayfairAdminMenu";
import { getWayfairOrderItemTrackingPendingAction, getAllSuppliersAction, getAllSupplierWarehousesAction } from "../../../../redux/actions/wayfairActions";

const WayfairPendingShipments = (props: any) => {

    const dispatch: AppDispatch = useDispatch();
    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    useEffect(() => {
        console.info("Wayfair Order Errors Arrived");
        dispatch(getWayfairOrderItemTrackingPendingAction(!!wayfairState.wayfairSandbox, 0, 100));
    }, [dispatch, wayfairState.wayfairSandbox]);

    useEffect(() => {
        console.info("Wayfair Supplier List Arrived");
        if (!wayfairState.suppliers) {
            dispatch(getAllSuppliersAction());
        }
    }, [dispatch, wayfairState.suppliers]);


    useEffect(() => {
        console.info("Wayfair Warehouse List Arrived");
        if (!wayfairState.wayfairAllSupplierWarehouses) {
            dispatch(getAllSupplierWarehousesAction(false));
        }
    }, [dispatch, wayfairState.wayfairAllSupplierWarehouses]);

    
    const onToggleSandboxClicked = () => () => {
        dispatch(getWayfairOrderItemTrackingPendingAction(!wayfairState.wayfairSandbox, 0, 100));
    }

    const formatTimestamp = (ts: number) => {
        return (new Date(ts)).toLocaleString();
    }

    const formatCompany = (company: string) => {
        if (company === "HP") {
            return "Howard Miller";
        }
        if (company === "HB") {
            return "Hekman";
        }
        return company;
    }

    const formatSupplier = (supplier_id: number) => {
        if (wayfairState.suppliers) {
            let lst = wayfairState.suppliers.records.filter((ent: any) => ent.wayfair_supplier_id === supplier_id);
            if (lst.length > 0) {
                return `${lst[0].name} [${supplier_id}]`
            }
        }
        return supplier_id;
    }


    const formatWarehouse = (warehouse_id: number) => {
        if (wayfairState.wayfairAllSupplierWarehouses) {
            let lst = wayfairState.wayfairAllSupplierWarehouses.records.filter((ent: any) => ent.child_supplier_id === warehouse_id);
            if (lst.length > 0) {
                return `${lst[0].warehouse_name} [${warehouse_id}]`
            }
        }
        return warehouse_id;
    }    

    const prevPage = () => (evt: any) => {

        let newOffset = wayfairState.wayfairOrderTrackingPending.offset - 100;
        if (newOffset > 0) {
            dispatch(getWayfairOrderItemTrackingPendingAction(!!wayfairState.wayfairSandbox, newOffset, 100));
        } else {
            dispatch(getWayfairOrderItemTrackingPendingAction(!!wayfairState.wayfairSandbox, 0, 100));
        }
    }

    const nextPage = () => (evt: any) => {
        let newOffset = wayfairState.wayfairOrderTrackingPending.offset + 100;
        if (newOffset <= wayfairState.wayfairOrderTrackingPending.total) {
            dispatch(getWayfairOrderItemTrackingPendingAction(!!wayfairState.wayfairSandbox, newOffset, 100));
        }
    }

    return (
        <>
            <div className="wayfair-admin container">

                <WayfairAdminMenu tab="pending-shipments" />

                <PageHeader
                    heading={"Pending Shipments"}
                    backUrl={"/admin"}
                />

                <div className="cstm-tr">

                    <div style={{ marginBottom: '1em' }} className="row">
                        <div className="form-check col-md-4 offset-md-8" style={{ textAlign: 'right' }}>
                            <div style={{ display: 'inline-block' }}>
                                <input className="form-check-input" id="sandboxCheckbox" type="checkbox" checked={!!wayfairState.wayfairSandbox} onClick={onToggleSandboxClicked()} />
                                <label className="form-check-label" htmlFor="sandboxCheckbox">Sandbox</label>
                            </div>
                        </div>
                    </div>

                    {wayfairState.wayfairOrderTrackingPending && (
                        <>

                            {wayfairState.wayfairOrderTrackingPending.total > 0 ? (
                                <>
                                    <p className="alert alert-info">Found {wayfairState.wayfairOrderTrackingPending.total} items waiting for tracking</p>

                                    <div className="row">
                                        <div className="col-md-2">
                                            {wayfairState.wayfairOrderTrackingPending.offset > 0 ? (
                                                <button className="btn btn-link" onClick={prevPage()}><i className="fas fa-chevron-left"></i></button>
                                            ) : (
                                                <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                                            )}

                                        </div>
                                        <div className="col-md-4" style={{ "lineHeight": '2em' }}>Records {wayfairState.wayfairOrderTrackingPending.offset + 1} - {wayfairState.wayfairOrderTrackingPending.offset + wayfairState.wayfairOrderTrackingPending.records.length} of {wayfairState.wayfairOrderTrackingPending.total}</div>

                                        <div className="col-md-2">

                                            {(wayfairState.wayfairOrderTrackingPending.offset + wayfairState.wayfairOrderTrackingPending.limit) < wayfairState.wayfairOrderTrackingPending.total ? (
                                                <button className="btn btn-link" onClick={nextPage()}><i className="fas fa-chevron-right"></i></button>
                                            ) : (
                                                <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                                            )}
                                        </div>
                                    </div>


                                    <table className="table">
                                        <tr>
                                            <th>Processed</th>
                                            <th>PO#</th>
                                            <th>Supplier</th>
                                            <th>Warehouse</th>
                                            <th>Supplier Part#</th>
                                            <th>Company</th>
                                            <th>Order#</th>
                                            <th>SKU</th>
                                            <th>Quantity</th>
                                        </tr>


                                        {wayfairState.wayfairOrderTrackingPending.records.map((ent: any) => (

                                            <tr>
                                                <td>
                                                    {formatTimestamp(ent.created_at)}
                                                </td>
                                                <td>
                                                    {ent.wayfair_customer_po}
                                                </td>
                                                <td>
                                                    {formatSupplier(ent.wayfair_supplier_id)}
                                                </td>
                                                <td>
                                                    {formatWarehouse(ent.wayfair_warehouse_id)}
                                                </td>
                                                <td>
                                                    {ent.wayfair_supplier_part_number}
                                                </td>
                                                <td>
                                                    {formatCompany(ent.hm_company)}
                                                </td>
                                                <td>
                                                    {ent.hm_order_no}
                                                </td>
                                                <td>
                                                    {ent.hm_sku}
                                                </td>
                                                <td>
                                                    {ent.quantity}
                                                </td>
                                            </tr>


                                        ))}

                                    </table>

                                    <div className="row">
                                        <div className="col-md-2">
                                            {wayfairState.wayfairOrderTrackingPending.offset > 0 ? (
                                                <button className="btn btn-link" onClick={prevPage()}><i className="fas fa-chevron-left"></i></button>
                                            ) : (
                                                <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                                            )}

                                        </div>
                                        <div className="col-md-4" style={{ "lineHeight": '2em' }}>Records {wayfairState.wayfairOrderTrackingPending.offset + 1} - {wayfairState.wayfairOrderTrackingPending.offset + wayfairState.wayfairOrderTrackingPending.records.length} of {wayfairState.wayfairOrderTrackingPending.total}</div>

                                        <div className="col-md-2">

                                            {(wayfairState.wayfairOrderTrackingPending.offset + wayfairState.wayfairOrderTrackingPending.limit) < wayfairState.wayfairOrderTrackingPending.total ? (
                                                <button className="btn btn-link" onClick={nextPage()}><i className="fas fa-chevron-right"></i></button>
                                            ) : (
                                                <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                                            )}
                                        </div>
                                    </div>



                                </>
                            ) : (

                                <>
                                    <p className="alert alert-success">No shipment errors!</p>
                                </>
                            )}


                        </>

                    )}

                </div>

            </div>
        </>
    )

};

export default WayfairPendingShipments;