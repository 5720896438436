import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    getCarrierExclusionsAction,
    addCarrierExclusionsAction,
    deleteCarrierExclusionsAction,
    freightClubClearCarrierExclusionsAddStatusAction,
    freightClubClearCarrierExclusionsErrorAction,
    freightClubClearCarrierExclusionsDeleteStatusAction
} from '../../../redux/actions/freightClubActions';
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { ToastContainer, toast } from "react-toastify";
import { Audio } from "react-loader-spinner";
import PageHeader from '../../shared/pagesHeader';
import { AppDispatch } from '../../../redux/Store';


const CarrierExclusionsHome = () => {

    const [show, setShow] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [carrierName, setCarrierName] = useState('')
    const [deleteCarrierID, setDeleteCarrierID] = useState('')

    const dispatch: AppDispatch = useDispatch();

    const freightClubState = useSelector((state: any) => state.freightClubReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    const error = (msg: String) =>
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    useEffect(() => {
        console.info("Carrier Exclusions Arrived")
        dispatch(getCarrierExclusionsAction(true))
    }, [dispatch])

    useEffect(() => {
        if (freightClubState.carrierExclusions.addStatus) {
            dispatch(freightClubClearCarrierExclusionsAddStatusAction());
            setShow(false);
            setCarrierName('');
            success("New carrier succesfully added")
        }
        if (freightClubState.carrierExclusions.error) {
            dispatch(freightClubClearCarrierExclusionsErrorAction());
            setShow(false)
            setCarrierName('')
            error("Some error occurred...!!! Please try again")
        }
        if (freightClubState.carrierExclusions.deleteStatus) {
            dispatch(freightClubClearCarrierExclusionsDeleteStatusAction());
            setDeleteShow(false)
            setDeleteCarrierID('')
            success("Carrier succesfully delete")
        }

    }, [freightClubState.carrierExclusions, dispatch])

    const saveCarrier = () => {
        dispatch(addCarrierExclusionsAction(carrierName))
    }

    const deleteCarrier = (id: any) => {
        setDeleteCarrierID(id)
        setDeleteShow(true)
    }

    const confirmDeleteCarrier = () => {
        dispatch(deleteCarrierExclusionsAction(deleteCarrierID))
    }

    return (
        <>
            <section className="user-sec py-2">
                <div className="container">
                    <PageHeader
                        heading={"Carrier Exclusions"}
                        backUrl={"/admin"}
                        handleClick={() => setShow(true)}
                        buttonName={"Add carrier exclusion"}
                    />

                    <div className="inner-sec max-width-900 mx-auto mt-4">
                        <div className="table_layout">
                            <div className="table-responsive">
                                {freightClubState.carrierExclusions.status ? (
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Carrier</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {freightClubState.carrierExclusions.carrierExclusionList.map((data: any, index: any) => (
                                                <tr key={data.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.carrier}</td>
                                                    <td className="cstm-td text-center">
                                                        <i className="fas fa-trash me-3"
                                                            onClick={() => deleteCarrier(data.id)}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div>No data</div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show} size="lg" onHide={() => setShow(false)} className="add_user_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Carrier Exclusion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-1" controlId="formBasicUsername">
                            <Form.Label>Carrier Name</Form.Label>
                            <Form.Control
                                value={carrierName}
                                onChange={(e) => setCarrierName(e.target.value)}
                                type="text" placeholder="Enter Carrier Name" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveCarrier}>
                        Add Carrier Exclusion
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={deleteShow} onHide={() => setDeleteShow(false)} className="delete_list_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Carrier</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this carrier?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={confirmDeleteCarrier}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer autoClose={3000} />

            {loading &&
                <div className="spinner">
                    <Audio

                        height={100}
                        width={100}
                        color="#00BFFF"
                        ariaLabel="loading"
                    />
                </div>
            }

        </>
    )
}

export default CarrierExclusionsHome