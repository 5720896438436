

import { useEffect } from "react";
import PageHeader from "../../shared/pagesHeader";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/Store";
import Select, { SingleValue } from 'react-select'
import { useState } from "react";

import { getImagesAction } from '../../../redux/actions/imagesActions';


type optionType = {
    value: string,
    label: string
}

export const ImagesPage = () => {

    const timeframeOptions: optionType[] = [
        { value: 'day', label: 'Last Day' },
        { value: 'week', label: 'Last Week' },
        { value: 'month', label: 'Last Month' }
    ];

    const imageFields = [
        'alternate_image',
        'alternate_image_1',
        'alternate_image_2',
        'alternate_image_3',
        'alternate_image_4',
        'alternate_image_5',
        'alternate_image_6',
        'alternate_image_7',
        'alternate_image_8',
        'alternate_image_9',
        'e_commerce_image',
        'main_image',
        'main_image_1',
        'main_image_2',
        'room_image',
        'room_image_1',
        'room_image_2',
        'room_image_3',
        'room_image_4',
        'room_image_5',
        'scale_image',
        'scale_image_1',
        'scale_image_2',
    ];

    const dispatch: AppDispatch = useDispatch();
    const imagesState = useSelector((state: any) => state.imagesReducer);

    const [selectedTimeframe, setSelectedTimeframe] = useState(timeframeOptions[0]);


    useEffect(() => {

        console.info("ImagesPage Arrived");

        const timeframeOptions: optionType[] = [
            { value: 'day', label: 'Last Day' },
            { value: 'week', label: 'Last Week' },
            { value: 'month', label: 'Last Month' }
        ];

        if (!imagesState.records) {
            dispatch(getImagesAction(imagesState.offset, imagesState.itemsPerPage, imagesState.filterTimeframe));
        }

        const selectedTimeframeOption: any[] = timeframeOptions.filter((item) => item.value === imagesState.filterTimeframe);
        if (selectedTimeframeOption.length > 0) {
            setSelectedTimeframe(selectedTimeframeOption[0]);
        }

    }, [imagesState, dispatch]);

    const handleChange = (option: SingleValue<optionType>) => {
        if (option) {
            dispatch(getImagesAction(0, imagesState.itemsPerPage, option.value));
        }
    };

    const displayDateTime = (rfcDate: string) => {        
        const d = new Date(Date.parse(rfcDate));
        console.log("D",d);
        return d.toLocaleString();

    };

    const fromDate = Date.parse(`${imagesState.fromDate}T00:00:00.000000+00:00`);

    const goPrev = () => {
        dispatch(getImagesAction(imagesState.offset - imagesState.itemsPerPage, imagesState.itemsPerPage, imagesState.filterTimeframe));
    };

    const goNext = () => {
        dispatch(getImagesAction(imagesState.offset + imagesState.itemsPerPage, imagesState.itemsPerPage, imagesState.filterTimeframe));
    };

    const displayUpdatedImages = (record: any) => {
        
        const records = imageFields.reduce((recordList: any[], field: string) => {
            const timeField = `${field}_mtime`;
            if (record[timeField] && Date.parse(record[timeField]) >= fromDate) {
                return [
                    ...recordList,
                    {
                        label: field,
                    }
                ];
            }
            return recordList;
        },[]);

        if (records.length > 0) {
            return records.map((image)=> (<span> <span className="badge bg-info text-dark">{image.label}</span> </span>));
        }
        return null;
    };

    const lastItemIdx = imagesState.offset + (imagesState.records ? imagesState.records.length : 0);

    return (<div className="container">

        <PageHeader
            heading={"Updated Images"}
            backUrl={"/dashboard"}
        />

        <div className="row">
            <div className="col-md-3 d-flex align-items-center justify-content-start">
                Showing records {imagesState.offset} - {lastItemIdx} of {imagesState.totalRecords}
            </div>
            <div className="col-md-3 offset-md-3 d-flex align-items-center justify-content-end">
                <label className="inline-block" htmlFor="select-timeframe">Show:</label>
            </div>
            <div className="col-md-3">
                <Select id="select-timeframe" value={selectedTimeframe} onChange={handleChange} options={timeframeOptions} />
            </div>
        </div>

        <div className="table_layout" style={{marginTop: '2ex'}}>
            <div className="table-responsive">
                <table className="table mb-2">
                    <thead>
                        <tr>
                            <th className="cstm-td table_head">Last Modified</th>
                            <th className="cstm-td table_head">Product</th>
                            <th className="cstm-td table_head">Updated</th>
                        </tr>

                    </thead>
                    {imagesState.records && (
                        <tbody>
                            {imagesState.records.map((record: any) => {
                                return (<tr key={record.id}>
                                    <td className="text-left" style={{minWidth: '25ex'}}>
                                        {displayDateTime(record.updated_at)}
                                    </td>
                                    <td className="text-left">
                                        {record.item_number}
                                    </td>
                                    <td className="text-left">
                                        {displayUpdatedImages(record)}
                                    </td>
                                </tr>)
                            })}

                        </tbody>
                    )}
                </table>
            </div>
        </div>

        <div className="row" style={{marginTop: '2ex'}}>
            <div className="col-md-3 d-flex align-items-center justify-content-start">
                Showing records {imagesState.offset} - {lastItemIdx} of {imagesState.totalRecords}
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-start">
                { imagesState.offset > 0 ? (<button className="btn btn-link" onClick={goPrev}>&lt;&lt;Prev</button>) : (<span>&lt;&lt;Prev</span>)}
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-start">
            { (imagesState.offset + imagesState.itemsPerPage) < imagesState.totalRecords ? (<button className="btn btn-link" onClick={goNext}>Next &gt;&gt;</button>) : (<span>Next &gt;&gt;</span>)}
            </div>              
        </div>                

    </div>)
}

export default ImagesPage;
