import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { amazonBuyboxTrackerService } from "../../service";


export const getBuyboxPricesBySkuAction = (sku_id: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    amazonBuyboxTrackerService.getBuyboxBySku(sku_id).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_AMAZON_BUYBOX_WITH_SKU",
            payload: { data: response.data, status: response.status }
        })
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_ERROR_BUYBOX",
        })
    })


}

export const getBuyboxMapViolationsAction = () => (dispatch: any) => {
    dispatch(startLoadingAction());
    amazonBuyboxTrackerService.getMapViolations().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_AMAZON_BUYBOX_MAP_VIOLATIONS",
            payload: { data: response.data, status: response.status }
        })
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_ERROR_BUYBOX",
        })
    })
}

export const getBuyboxUnqualifiedProductsAction = () => (dispatch: any) => {
    dispatch(startLoadingAction());
    amazonBuyboxTrackerService.getUnqualifiedProducts().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_AMAZON_BUYBOX_UNQUALIFIED_PRODUCT",
            payload: { data: response.data, status: response.status }
        })
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_ERROR_BUYBOX",
        })
    })
}


export const getMissingBuyboxProductsBySellerAction = (seller_id: any) => (dispatch: any) => {
    amazonBuyboxTrackerService.getBuyboxBySellerId(seller_id).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_MISSING_BUYBOX_PRODUCT",
            payload: { data: response.data, status: response.status }
        })
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_ERROR_BUYBOX",
        })
    })
}
