import { getUserTypeAction } from "./userRoleActions";
import { authService } from "../../service";
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import config from "../../constants/config";


export const loginAction = (email: string, password: string) => (dispatch: any) => {

  dispatch(startLoadingAction());

  authService.userLogin(email, password).then(response => {

    if (config.useCsrf) {
      localStorage.setItem('csrf_token', response.data['response']['csrf_token']);
    }
    dispatch({
      type: "LOGIN",
      payload: response.data
    });
    dispatch(getUserTypeAction());
  }).catch((err) => {
    console.error(err);
    dispatch(stopLoadingAction());

    if (err.response) {
      dispatch({
        type: "LOGOUT_ERROR",
        payload: err.response.data
      });
    } else {
      dispatch({
        type: "LOGOUT_ERROR",
        payload: {
          response: {
            errors: [err.message, err.code]
          }
        }
      });
    }
  });
};

export const logoutAction = () => (dispatch: any) => {
  dispatch({
    type: "LOGOUT",
  });
  dispatch({
    type: "SET_ROLE_INACTIVE"
  });
  localStorage.clear();
};

export const authClearErrorsAction = () => {
  return {
    type: "CLEAR_AUTH_ERROR"
  }
}

export const setCsrfTokenAction = (csrf_token: string) => (dispatch: any) => {
  dispatch({
    type: "SET_CSRF_TOKEN",
    payload: csrf_token
  })
}


