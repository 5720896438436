
const reportsDetailsinitialState = {
    freight_club_reorts_details: [],
    status: false,
    error_code: null,
    error_message: null,
    error: false
}

export const freightClubReportDetailsReducer = (state = reportsDetailsinitialState, action: any) => {
    switch (action.type) {
        case "FREIGHT_CLUB_ORDER_DETAILS":

            return {
                ...state,
                freight_club_reorts_details: action.payload,
                status: true
            };
        case "FREIGHT_CLUB_ORDER_DETAILS_ERROR":
            return {
                ...state,
                error_code: 500,
                error: true,
                error_message: "An unexpected error has occurred"
            };
        case "DOWNLOAD_FREIGHTCLUB_REPORT_XLSX_ERROR":
            return {
                ...state,
                error_code: 500,
                error: true,
                error_message: "An unexpected error has occurred...!!!. Please try again"
            }
        default:
            return state;
    }
}

export default freightClubReportDetailsReducer;
