
import { inventoryHoldsService } from "../../service";

import { startLoadingAction, stopLoadingAction } from "./loaderActions";

export const getReportRequestsAction = (showSpinner: boolean) => (dispatch: any) => {
    


    if (showSpinner) {
        dispatch(startLoadingAction());
    }

    inventoryHoldsService.getReportRequests().then(response => {    
        if (showSpinner) {
            dispatch(stopLoadingAction());
        }
        dispatch({
            type: "SET_REPORT_REQUESTS",
            payload: {
                report_requests: response.data.report_requests
            }                
                
        });

    });
};