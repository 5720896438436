// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 22px;
    outline: none;
  }
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    transform: translateX(21px);
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/NotificationSubscriptions/subscription.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,aAAa;EACf;EACA;IACE,kBAAkB;IAClB,aAAa;IACb,cAAc;EAChB;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,sBAAsB;IAEtB,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,WAAW;IACX,uBAAuB;IAEvB,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,2BAA2B;EAC7B;EACA;IAGE,2BAA2B;EAC7B","sourcesContent":[".switch {\n    position: relative;\n    display: inline-block;\n    width: 43px;\n    height: 22px;\n    outline: none;\n  }\n  .switch input {\n    position: absolute;\n    top: -99999px;\n    left: -99999px;\n  }\n  .slider {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #ccc;\n    -webkit-transition: .4s;\n    transition: .4s;\n    border-radius: 34px;\n  }\n  .slider:before {\n    position: absolute;\n    content: \"\";\n    height: 14px;\n    width: 14px;\n    left: 4px;\n    bottom: 4px;\n    background-color: white;\n    -webkit-transition: .4s;\n    transition: .4s;\n    border-radius: 50%;\n  }\n  input:checked + .slider {\n    background-color: #2196F3;\n  }\n  input:focus + .slider {\n    box-shadow: 0 0 1px #2196F3;\n  }\n  input:checked + .slider:before {\n    -webkit-transform: translateX(21px);\n    -ms-transform: translateX(21px);\n    transform: translateX(21px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
