
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class MapViolationsServiceImpl {
    apiUrl = `${config.baseUrl}api/map-violations`;

    getReportedViolations(limit: number, offset: number, is_closed: boolean|null) {

        let optionalArgs = '';
        if (is_closed != null) {
            optionalArgs = `&is_closed=${is_closed}`
        }

        const url = `${this.apiUrl}/reported-violations?limit=${limit}&offset=${offset}&ordering=-created_at${optionalArgs}`;
        return axios.get(url, get_config());
    }

    closeReportedViolation(id: number) {
        const url = `${this.apiUrl}/reported-violations/close`;
        return axios.post(url, { id }, get_config());
    }

    reopenReportedViolation(id: number) {
        const url = `${this.apiUrl}/reported-violations/reopen`;
        return axios.post(url, { id }, get_config());
    }
}