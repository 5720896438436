import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class FreightClubServiceImpl {

    apiCarrierExclusionsUrl = `${config.baseUrl}freight-club/HM/CarrierExclusions`;
    apiOrdersReportUrl = `${config.baseUrl}freight-club/HM/OrdersReport`;
    apiCreateOrdersReportUrl = `${config.baseUrl}freight-club/HM/CreateOrdersReport`;

    createOrdersReport(orderIds: string[]) {
        const data = {
            "order_ids": orderIds
        };
        return axios.post(this.apiCreateOrdersReportUrl, data, get_config());
    }

    getOrdersReportList() {
        return axios.get(this.apiOrdersReportUrl, get_config());
    }

    getOrdersReport(reportId: string) {
        return axios.get(`${this.apiOrdersReportUrl}/${reportId}`, get_config());
    }

    getOrdersReportXls(reportId: string) {
        return axios({
            url: `${this.apiOrdersReportUrl}/${reportId}/xls`,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authentication-Token': `${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
        })
    }

    getCarrierExclusions() {
        return axios.get(this.apiCarrierExclusionsUrl, get_config());
    }

    addCarrierExclusion(carrier_name: string) {
        const data = {
            "carrier": carrier_name
        }
        return axios.post(this.apiCarrierExclusionsUrl, data, get_config());
    }

    deleteCarrierExclusion(carrier_id: string) {
        return axios.delete(`${this.apiCarrierExclusionsUrl}/${carrier_id}`, get_config())
    }
}
