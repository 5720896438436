import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { freightClubService } from "../../service";


export const getReportDetailsAction = (reportId: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    freightClubService.getOrdersReport(reportId).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "FREIGHT_CLUB_ORDER_DETAILS",
            payload: response.data
        })
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "FREIGHT_CLUB_ORDER_DETAILS_ERROR",
            status: err.response.status
        })
    })
};
