const wayfairState = {
    suppliers: null,
    currentSupplier: null,
    currentWarehouse: null,
    supplierWarehousesLoading: false,
    supplierWarehouses: null,
    warehouseTypes: null,
    fixedInventoryRecords: null,
    currentFixedInventory: null,
    unresolvedItems: {
        data: null,
        offset: 0,
        limit: 10000
    },
    currentFabricXref: null,
    currentWayfairOrderSkuExceptionMap: null,
    wayfairOrderErrors: null,
    allWayfairInventoryPerWarehouse: null,
    allExcludedProductsPerWarehouse: null,
    inventoryLoading: false,
    totalSearchResult: 0,
    warehouses: [],
    wayfairSandbox: false,
    wayfairOrderShipmentErrors: null,
    wayfairOrderTrackingPending: null,
    wayfairAllSupplierWarehouses: null
}

export const wayfairReducer = (state = wayfairState, action: any) => {
    switch (action.type) {
        case "GET_ALL_WAYFAIR_SUPPLIERS":
            return {
                ...state,
                suppliers: action.payload
            };
        case "GET_CURRENT_WAYFAIR_SUPPLIER":
            return {
                ...state,
                currentSupplier: action.payload
            }
        case "GET_WAYFAIR_SUPPLIER_WAREHOUSES_LOADING":
            return {
                ...state,
                supplierWarehousesLoading: action.payload
            }

        case "GET_WAYFAIR_SUPPLIER_WAREHOUSES":
            return {
                ...state,
                supplierWarehouses: action.payload,
                supplierWarehousesLoading: false
            }
        case "GET_WAYFAIR_WAREHOUSE_TYPES":
            return {
                ...state,
                warehouseTypes: action.payload
            }
        case "GET_CURRENT_WAYFAIR_WAREHOUSE":
            return {
                ...state,
                currentWarehouse: action.payload
            }
        case "GET_ALL_WAYFAIR_FIXED_INVENTORY":
            return {
                ...state,
                fixedInventoryRecords: action.payload
            };

        case "GET_CURRENT_WAYFAIR_FIXED_INVENTORY":
            return {
                ...state,
                currentFixedInventory: action.payload
            }
        case "GET_WAYFAIR_UNRESOLVED_ITEMS":
            return {
                ...state,
                unresolvedItems: {
                    data: action.payload.data,
                    offset: action.payload.offset,
                    limit: action.payload.limit,
                }
            }
        case "GET_WAYFAIR_FABRIC_XREF":
            return {
                ...state,
                currentFabricXref: action.payload
            }
        case "GET_WAYFAIR_ORDER_SKU_EXCEPTION_MAP":
            return {
                ...state,
                currentWayfairOrderSkuExceptionMap: action.payload
            }
        case "SET_WAYFAIR_SANDBOX":
            return {
                ...state,
                wayfairSandbox: action.payload.wayfairSandbox,
                wayfairOrderErrors: null,
                wayfairOrderShipmentErrors: null,
                wayfairOrderTrackingPending: null
            }
        case "GET_WAYFAIR_ORDER_ERRORS":
            return {
                ...state,
                wayfairOrderErrors: action.payload
            }
        case "GET_ALL_WAYFAIR_INVENTORY_PER_WAREHOUSE":
            return {
                ...state,
                allWayfairInventoryPerWarehouse: action.payload.inventory,
                totalSearchResult: action.payload.total
            };
        case "GET_ALL_EXCLUDED_PRODUCTS_PER_WAREHOUSE":
            return {
                ...state,
                allExcludedProductsPerWarehouse: action.payload.inventory
            };
        case "GET_ALL_WAREHOUSES":
            return {
                ...state,
                warehouses: action.payload
            };
        case "START_INVENTORY_LOADING":
            return {
                ...state,
                inventoryLoading: true
            };
        case "STOP_INVENTORY_LOADING":
            return {
                ...state,
                inventoryLoading: false
            };

        case "GET_WAYFAIR_ORDER_SHIPMENT_ERRORS":
            return {
                ...state,
                wayfairOrderShipmentErrors: action.payload
            }
        case "GET_WAYFAIR_ORDER_ITEM_TRACKING_PENDING":
            return {
                ...state,
                wayfairOrderTrackingPending: action.payload
            }
        case "GET_ALL_WAYFAIR_SUPPLIER_WAREHOUSES":
            return {
                ...state,
                wayfairAllSupplierWarehouses: action.payload
            }
        default:
            return state;
    }
}

export default wayfairReducer;
