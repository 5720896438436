
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class UserProfileServiceImpl {

    apiUpdateUserProfileUrl = `${config.baseUrl}api/edit-user`;
    apiUserProfileUrl = `${config.baseUrl}api/user-profile`;
    apiUpdateUserAccountUrl = `${config.baseUrl}api/admin/update-user-details`;
    apiCreateUserAccountUrl = `${config.baseUrl}api/add-new-user`;
    apiUserListUrl = `${config.baseUrl}api/users`;
    apiDeleteUserUrl = `${config.baseUrl}api/delete-users`;

    updateUserProfile(email: string, password: string) {
        const data = {
            'email': email,
            'password': password
        }
        return axios.put(this.apiUpdateUserProfileUrl, data, get_config());
    }

    getUserProfile() {
        return axios.get(this.apiUserProfileUrl, get_config());
    }

    getUserList() {
        return axios.get(this.apiUserListUrl, get_config());
    }

    updateUserAccount(email: any, password: any, user_id: any, roles: any) {
        const data = {
            "email": email,
            "password": password,
            "user_id": user_id,
            "roles": roles
        };
        return axios.post(this.apiUpdateUserAccountUrl, data, get_config());
    }

    createUserAccount(email: any, password: any, username: any, roles: any) {
        const data = {
            "email": email,
            "password": password,
            "username": username,
            "roles": roles
        };
        return axios.post(this.apiCreateUserAccountUrl, data, get_config());
    }

    deleteUserAccount(user_id: any) {
        const data = {
            "user_id": user_id
        };
        return axios.post(this.apiDeleteUserUrl, data, get_config());
    }

}