import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { AppDispatch } from "../../../../redux/Store";


import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { getFixedInventoryAction } from "../../../../redux/actions/wayfairActions";
import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";

import FixedInventoryForm from "../../../common/admin/wayfair/FixedInventoryForm";

const WayfairFixedInventoryRecord = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const params: any = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getBackUrl = () => {
        if (searchParams.get('returnTo') === 'order-errors') {
            return `/admin/wayfair/order-errors`;
        }
        return `'/admin/wayfair/fixed-inventory'`;
    };

    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());
        let fixedInventory = {
            "id": value.id,
            "wayfair_supplier_part_number": value.wayfair_supplier_part_number,
            "name": value.name,
            "wayfair_quantity_on_hand": parseInt(value.wayfair_quantity_on_hand),
            "hm_order_sku": value.hm_order_sku
        };

        wayfairService.createOrUpdateFixedInventory(fixedInventory).then(response => {
            dispatch(stopLoadingAction());
            navigate(getBackUrl());
        }).catch((response) => {
            setErrorMessage(response.message);
            dispatch(stopLoadingAction());
        });
    };


    useEffect(() => {
        console.info("Wayfair Warehouse Arrived");
        dispatch(getFixedInventoryAction(params.id))
    }, [dispatch, params.id]);

    return (
        <>
            <div className="container">
                <PageHeader
                    heading={"Wayfair Fixed Inventory"}
                    backUrl={getBackUrl()}
                />

                <FixedInventoryForm handleSubmit={handleSubmit} errorMessage={errorMessage} fixedInventory={wayfairState.currentFixedInventory} />

            </div>

        </>)
};

export default WayfairFixedInventoryRecord;

