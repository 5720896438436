
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class AmazonSellerCentralServiceImpl {

    apiStoresUrl = `${config.baseUrl}api/seller-central-stores`;

    getStoreList() {
        return axios.get(this.apiStoresUrl, get_config());
    }

    getUserList(store_id: string) {
        return axios.get(`${this.apiStoresUrl}/${store_id}/user-list`, get_config());
    }

    getStore(id: string) {
        return axios.get(`${this.apiStoresUrl}/${id}`, get_config());
    }

    createStore(storeDetails: any) {
        return axios.post(this.apiStoresUrl, storeDetails, get_config());
    }

    updateStore(storeDetails: any) {
        return axios.put(this.apiStoresUrl, storeDetails, get_config());
    }

    deleteServiceAccountFile(store_id: string) {
        return axios.delete(`${this.apiStoresUrl}/${store_id}/service-account-file`, get_config());
    }

    getServiceAccountFile(store_id: string) {
        let cfg = get_config();
        cfg.responseType = 'arraybuffer';
        return axios.get(`${this.apiStoresUrl}/${store_id}/service-account-file`, cfg);
    }

    updateServiceAccountFile(store_id: string, file: any) {
        var formData = new FormData();
        formData.append("file", file);
        let cfg = get_config();
        cfg['headers']["Content-Type"] = "multipart/form-data";
        return axios.post(`${this.apiStoresUrl}/${store_id}/service-account-file`, formData, cfg);
    }

    deleteStore(storeId: string) {
        return axios.delete(
            `${this.apiStoresUrl}/${storeId}`,
            get_config()
        );
    }

    zeroAllInventory(storeId: string) {
        const data = {};
        return axios.post(
            `${this.apiStoresUrl}/${storeId}/action/zero-out`,
            data,
            get_config())
    }

    syncInventory(storeId: string) {
        const apiUrl = `${this.apiStoresUrl}/${storeId}/action/update-inventory`;
        const data = {}
        return axios.post(apiUrl, data, get_config());
    }

    syncPrices(storeId: string) {
        const apiUrl = `${this.apiStoresUrl}/${storeId}/action/update-prices`;
        const data = {}
        return axios.post(apiUrl, data, get_config());
    }

    getLogs(storeId: string) {
        return axios.get(`${this.apiStoresUrl}/${storeId}/get-all-logs`, get_config());
    }

    uploadDirectFulfillmentInventory(storeId: string,inventoryExport: File) {
        const formData = new FormData();
        formData.append("inventory_export", inventoryExport);
        return axios.post(
            `${this.apiStoresUrl}/${storeId}/action/upload-direct-fulfillment-inventory`, 
            formData,
            {
                headers: {
                  "Content-Type": "multipart/form-data",
                  'Authentication-Token': localStorage.getItem("token")
                },
            });
    }

}
