import { logoutAction } from './actions/authActions';

const handleUnauthorised = ({dispatch}:any)  => (next:any) => (action:any) => {
  if (action.status === 401) {
    dispatch(logoutAction());
    window.location.replace("/login")
    // window.history.replaceState('','', 'http://localhost:3000/login')
  } else {
    next(action);
  }
};

export default handleUnauthorised;