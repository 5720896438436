
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


import { AppDispatch } from "../../../../redux/Store";
import { wayfairService } from "../../../../service";
import { getAllSuppliersAction } from "../../../../redux/actions/wayfairActions";
import PageHeader from "../../../shared/pagesHeader";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";

import { WayfairAdminMenu } from "../../../common/admin/wayfair/WayfairAdminMenu"

const WayfairAdmin = (props: any) => {

    const dispatch: AppDispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);


    const handleAddSupplierClick = () => {
        navigate('/admin/wayfair/create-supplier');
    }

    const handleDeleteSupplierClick = (id: string) => (event: any) => {

        if (window.confirm("Are you sure you want to delete this supplier?")) {
            dispatch(startLoadingAction());
            wayfairService.deleteSuppliers([id]).then(response => {
                dispatch(getAllSuppliersAction());
            }).catch((response) => {
                dispatch(stopLoadingAction());
                setErrorMessage(response.message);
            });
        }

    }

    useEffect(() => {
        console.info("Wayfair Supplier List Arrived");
        dispatch(getAllSuppliersAction())
    }, [dispatch]);

    console.log("wayfair", wayfairState);

    return (
        <>
            <div className="wayfair-admin container">

                <WayfairAdminMenu tab="suppliers" />

                <PageHeader
                    heading={"Suppliers"}
                    backUrl={"/admin"}
                    buttonName={"Add Supplier"}
                    buttonIcon={"fa-plus"}
                    handleClick={handleAddSupplierClick}
                />

                {errorMessage && (
                    <Alert variant={"danger"}>
                        {errorMessage}
                    </Alert>
                )}

                <div className="form-align">

                    
                    {wayfairState.suppliers && (
                        <>

                            {
                                wayfairState.suppliers.total > 0 ? (
                                    <>

                                        {wayfairState.suppliers.records.map((supplier: any) => {

                                            return (

                                                <div className="row" key={supplier.id}>
                                                    <div className="col-md-12">
                                                        <button className="btn btn-link" onClick={handleDeleteSupplierClick(supplier.id)}><i className="fa fa-trash"></i></button>
                                                        <button className="btn btn-link" onClick={() => navigate(`/admin/wayfair/supplier/${supplier.id}`)}>{supplier.name}</button>
                                                    </div>
                                                </div>)
                                        })}

                                    </>
                                ) : (
                                    <p>No suppliers found</p>
                                )
                            }

                        </>


                    )}

                </div>

            </div>


        </>)
};

export default WayfairAdmin;