

import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";

import { dateFormat } from "../utils/Helper"

export class ShipmentTrackingServiceImpl {

  apiUrl = `${config.baseUrl}api/shipping`;

  getShipmentTrackingList(fromDate: Date) {
    const fromDateStr = dateFormat(fromDate);
    return axios.get(`${this.apiUrl}/order-tracking?created_at_gte=${fromDateStr}&ordering=-created_at`, get_config());
  }

  getFedexFicupRates() {
    return axios.get(`${this.apiUrl}/fedex-ficup-rates`, get_config());
  }

  getHmToshipQueueByCarrier(carrier_1: number) {
    const limit = 20;
    return axios.get(`${this.apiUrl}/hm-toship-queue?ordering=-created_at,-updated_at&limit=${limit}&carrier_1=${carrier_1}`, get_config());
  }

  forceGenerateFicLabel(recordId: string) {
    const url = `${this.apiUrl}/force-generate-fic-label`;
    return axios.post(
      url, { id: recordId }, get_config());
  }

  uploadFedexFicupRates(excelFile: File) {

    const url = `${this.apiUrl}/fedex-ficup-rates`;
    const formData = new FormData();

    formData.append("excel_file", excelFile);

    return axios.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authentication-Token': localStorage.getItem("token")
        },
      }
    );

  }

}