
import { authService } from "../../service";
import { stopLoadingAction } from "./loaderActions";


export const getUserTypeAction = () => (dispatch: any) => {

  authService.getUserRoles().then(response => {
    dispatch({
      type: "GET_USER_ROLE",
      payload: response.data
    })
    dispatch(stopLoadingAction());
  })
}


export const setRoleInactiveAction = () => (dispatch: any) => {
  return {
    type: "SET_ROLE_INACTIVE"
  };
}
