import { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const AccountForm = (props: any) => {
  const [showValidationGlyphs, setShowValidationGlyphs] = useState(false);
  const [accountFormData, setAccountFormData] = useState({} as any);

  useEffect(() => {
    console.info("AccountForm Arrived");
    setAccountFormData(props.account ? props.account : {});
  }, [props]);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    let isValid = true;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    }

    setShowValidationGlyphs(true);

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    if (isValid && props.handleSubmit) {
      event.preventDefault();
      event.stopPropagation();
      props.handleSubmit(formDataObj);
    }
  };

  const handleFormFieldUpdate = (event: any) => {
    let formData = { ...accountFormData };
    formData[event.target.name] = event.target.value;
    setAccountFormData(formData);    
  };

  const handleFormFieldToggle = (event: any) => {
    let formData = { ...accountFormData };
    formData[event.target.name] = !accountFormData[event.target.name];
    setAccountFormData(formData);    
  }

  useEffect(() => {
    console.info("HM Customer List Arrived");
  });

  return (
    <div className="form-align">
      {props.errorMessage && (
        <Alert variant={"danger"}>{props.errorMessage}</Alert>
      )}
      <Form noValidate validated={showValidationGlyphs} onSubmit={handleSubmit}>
        <Form.Control type="hidden" name="id" value={accountFormData.id} />
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_no">
              <Form.Label>Customer Number</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter customer number"
                name="customer_no"
                onChange={handleFormFieldUpdate}
                value={accountFormData.customer_no}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                maxLength={2}
                required={true}
                type="text"
                placeholder="Enter company"
                name="company"
                onChange={handleFormFieldUpdate}
                value={accountFormData.company}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_dseq">
              <Form.Label>Sequence Number</Form.Label>
              <Form.Control
                maxLength={16}
                required
                type="text"
                placeholder="Enter sequence number"
                name="customer_dseq"
                onChange={handleFormFieldUpdate}
                value={accountFormData.customer_dseq}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_vat_no">
              <Form.Label>VAT Number</Form.Label>
              <Form.Control
                maxLength={17}
                type="text"
                placeholder="Enter VAT number"
                name="customer_vat_no"
                onChange={handleFormFieldUpdate}
                value={accountFormData.customer_vat_no}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_eori_no">
              <Form.Label>EORI Number</Form.Label>
              <Form.Control
                maxLength={15}
                type="text"
                placeholder="Enter EORI number"
                name="customer_eori_no"
                onChange={handleFormFieldUpdate}
                value={accountFormData.customer_eori_no}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_ioss_no">
              <Form.Label>IOSS Number</Form.Label>
              <Form.Control
                maxLength={15}
                type="text"
                placeholder="Enter IOSS number"
                name="customer_ioss_no"
                onChange={handleFormFieldUpdate}
                value={accountFormData.customer_ioss_no}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_tax_id">
              <Form.Label>Tax ID Number</Form.Label>
              <Form.Control
                maxLength={15}
                type="text"
                placeholder="Enter Tax ID Number"
                name="customer_tax_id"
                onChange={handleFormFieldUpdate}
                value={accountFormData.customer_tax_id}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_arn">
              <Form.Label>ARN (Australia)</Form.Label>
              <Form.Control
                maxLength={20}
                type="text"
                placeholder="Enter ATO Reference Number"
                name="customer_arn"
                onChange={handleFormFieldUpdate}
                value={accountFormData.customer_arn}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="customer_ddp">
              <Form.Label>Delivery Duty Paid</Form.Label>
              <Form.Check
                type="checkbox"
                name="customer_ddp"
                onChange={handleFormFieldToggle}
                checked={accountFormData.customer_ddp}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="enable_fic">
              <Form.Label>Enable FIC</Form.Label>
              <Form.Check
                type="checkbox"
                name="enable_fic"
                onChange={handleFormFieldToggle}
                checked={accountFormData.enable_fic}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="mb-3" controlId="memo">
              <Form.Label>Memo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                name="memo"
                onChange={handleFormFieldUpdate}
                value={accountFormData.memo}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Button variant="primary" type="submit" className="mb-3">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AccountForm;
