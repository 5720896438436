
import { useState } from "react";

import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";
import FixedInventoryForm from "../../../common/admin/wayfair/FixedInventoryForm";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch } from "react-redux";

const WayfairCreateFixedInventory = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getBackUrl = () => {
        if (searchParams.get('returnTo') === 'order-errors') {
            return `/admin/wayfair/order-errors`;
        }
        return `'/admin/wayfair/fixed-inventory'`;
    };

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());
        let fixedInventory = {
            "wayfair_supplier_part_number": value.wayfair_supplier_part_number,
            "name": value.name,
            "wayfair_quantity_on_hand": parseInt(value.wayfair_quantity_on_hand),
            "hm_order_sku": value.hm_order_sku
        };

        wayfairService.createOrUpdateFixedInventory(fixedInventory).then(response => {
            dispatch(stopLoadingAction());
            navigate(getBackUrl());
        }).catch((response) => {
            dispatch(stopLoadingAction());
            setErrorMessage(response.message);
        });

    }

    const formData: any = {}

    if (searchParams.get('wayfair_supplier_part_number')) {
        formData['wayfair_supplier_part_number'] = searchParams.get('wayfair_supplier_part_number');
    }    

    return (
        <>
            <div className="wayfair-admin container">
                <PageHeader
                    heading={"Wayfair - Create Fixed Inventory"}
                    backUrl={getBackUrl()}
                />

                <FixedInventoryForm handleSubmit={handleSubmit} errorMessage={errorMessage} fixedInventory={formData}/>

            </div>

        </>)
};


export default WayfairCreateFixedInventory;
