import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFreightClubOrderReportXLSAction } from "../../../redux/actions/freightClubActions";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormater } from "../../../utils/Helper";
import {Audio} from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";

import './freightClub.css'
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";
import { getReportDetailsAction } from "../../../redux/actions/freightClubReportDetailsActions";

const ReportDetail = () => {


    const [logs, setLogs] = useState(false)

    const params: any = useParams()
    const freightReportDetails = useSelector((state: any) => state.freightClubReportDetailsReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);

    const dispatch : AppDispatch = useDispatch();

    const error = (msg: String) =>
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    useEffect(() => {
        console.info("Report Detail Arrived");
        dispatch(getReportDetailsAction(params.report_id));
    }, [dispatch, params.report_id])

    useEffect(() => {
        if(freightReportDetails.error){
            freightReportDetails.error = false
            error(freightReportDetails.error_message)
        }
    },[freightReportDetails])

    const download = () => {
        dispatch(getFreightClubOrderReportXLSAction(params.report_id))
    }

    return (
        <>
            <section className="user-sec py-2">
                {freightReportDetails.freight_club_reorts_details.data && (
                    <div className="container">
                        <PageHeader
                        heading={"Order Details"}
                        backUrl={"/dashboard"}
                        />
                        <div className="inner-sec">
                            <div className="d-md-flex justify-content-between mb-4 align-items-center">
                                
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table_layout">
                                        <div className="table-responsive">
                                            {freightReportDetails.freight_club_reorts_details.headers && (
                                                <table className="table mb-2">
                                                    <thead>
                                                        {freightReportDetails.freight_club_reorts_details.headers.map((data: any, index: any) => (
                                                            <th className="cstm-td table_head">{data}</th>
                                                        ))}

                                                    </thead>
                                                    <tbody>
                                                        {freightReportDetails.freight_club_reorts_details.data.map((data: any, index: any) => (
                                                            <tr>
                                                                {data.map((dta: any, index: any) => (
                                                                    <td className="cstm-td">{dta}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-details-buttom">
                            <button
                                onClick={download}
                                className="btn btn-primary">Download</button>
                            {logs ? (
                                <button
                                    onClick={() => setLogs(!logs)}
                                    className="btn btn-info">Hide Logs
                                </button>
                            ) : (
                                <button
                                    onClick={() => setLogs(!logs)}
                                    className="btn btn-info">Show Logs
                                </button>
                            )}


                            {logs && (
                                <div className="col-md-12 logs">
                                    <div className="table_layout h-100">
                                        <div className="table-responsive">
                                            {freightReportDetails.freight_club_reorts_details.logs && (
                                                <table className="table mb-2">
                                                    <thead>
                                                        <th className="cstm-td table_head">Level</th>
                                                        <th className="cstm-td table_head">Message</th>
                                                        <th className="cstm-td table_head">Date</th>
                                                    </thead>
                                                    <tbody>
                                                        {freightReportDetails.freight_club_reorts_details.logs.map((data: any, index: any) => (
                                                            <tr>
                                                                <td className="cstm-td"><span className={"badge status_badge bg-" + (data.level.toLowerCase())}>{data.level}</span></td>
                                                                <td className="cstm-td">{data.message}</td>
                                                                <td className="cstm-td">{dateTimeFormater(data.timestamp)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                )}

            </section>

            <ToastContainer autoClose={3000} />
            {loading &&
                <div className="spinner">
            <Audio
            
            height={100}
            width={100}
            color="#00BFFF"
            ariaLabel="loading"          
        />
                </div>
            }
        </>
    )
};

export default ReportDetail;