import { useNavigate } from "react-router-dom";

const SuperUserDashboard = () => {
  console.info("Admin Dashboard Arrived");
  const navigate = useNavigate();

  return (
    <>
      <section className="Country-info">
        <h1 className="dash text-center m-3">ADMIN DASHBOARD</h1>
        <div className="container dashboard d-block">
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Settings</h5>
                  <i className="fas fa-cog dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/settings")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Users List</h5>
                  <i className="fas fa-users dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/user-list")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Carrier Exclusions</h5>
                  <i className="fas fa-plane-departure dashboard-icon"></i>
                  <h6
                    onClick={() =>
                      navigate("/admin/freight-club/carrierExclusions")
                    }
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Fedex Debug</h5>
                  <i className="fab fa-fedex dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/fedex/debug")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Tradeshow Config</h5>
                  <i className="fas fa-cog dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/tradeshow-config")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Fedex ficup rates</h5>
                  <i className="fab fa-fedex dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/fedex/ficup-rates")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Fedex ficup label status</h5>
                  <i className="fab fa-fedex dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/fedex/ficup-label-status")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Wayfair Administration</h5>
                  <i className="fa fa-shopping-cart dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/wayfair")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">FIC Country Administration</h5>
                  <i className="fas fa-globe-americas dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/fic/country")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Overstock PPC Administration</h5>
                  <i className="fa fa-cogs dashboard-icon"></i>
                  <h6
                    onClick={() => navigate("/admin/overstock-ppc")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>                    

          </div>
        </div>
      </section>
    </>
  );
};

export default SuperUserDashboard;
