import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { AppDispatch } from "../../../../redux/Store";


import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { getFabricXrefAction } from "../../../../redux/actions/wayfairActions";
import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";

import WayfairOrderSkuExceptionMapForm from "../../../common/admin/wayfair/WayfairOrderSkuExceptionMapForm";

const WayfairOrderUpdateSkuExceptionMap = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const params: any = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());

        let wayfairOrderSkuExceptionMap = {
            "id": value.id,
            "wayfair_customer_po": params.wayfair_customer_po,
            "wayfair_supplier_part_number": params.wayfair_supplier_part_number,
            "hm_order_sku": value.hm_order_sku
        };

        wayfairService.createOrUpdateWayfairOrderSkuExceptionMap(wayfairOrderSkuExceptionMap).then(response => {
            dispatch(stopLoadingAction());
            navigate('/admin/wayfair/order-errors');
        }).catch((response) => {
            setErrorMessage(response.message);
            dispatch(stopLoadingAction());
        });

    };

    useEffect(() => {
        console.info("WayfairOrderUpdateSkuExceptionMap Arrived", params.supplierpartnumber);
        dispatch(getFabricXrefAction(params.supplierpartnumber))
    }, [dispatch, params.supplierpartnumber]);

    return (
        <>
            <div className="container">
                <PageHeader
                    heading={`Wayfair Order (${params.wayfair_customer_po}) - Update SKU Map`}
                    backUrl={'/admin/wayfair/order-errors'}
                />

                <WayfairOrderSkuExceptionMapForm handleSubmit={handleSubmit} errorMessage={errorMessage} wayfairOrderSkuExceptionMap={wayfairState.currentWayfairOrderSkuExceptionMap} wayfairCustomerPo={params.wayfair_customer_po} wayfairSupplierPartNumber={params.wayfair_supplier_part_number} />

            </div>

        </>)
};

export default WayfairOrderUpdateSkuExceptionMap;

