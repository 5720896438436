
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class AmazonContentVerificationServiceImpl {

    apiUrl = `${config.baseUrl}api/amazon-content-verification`;

    getContentDiff() {
        return axios.get(`${this.apiUrl}/diff`, get_config());
    }

    getLikelyDuplicates(page: Number, colorBias: any, pageSize: Number) {
        return axios.get(
            `${this.apiUrl}/likely-duplicates?page=${page}&color_bias=${colorBias}&page_size=${pageSize}`,
            get_config());
    }
}
