
const initialState = {
    filterTimeframe: 'day',
    fromDate: null,
    records: null,
    totalRecords: 0,
    itemsPerPage: 10,
    offset: 0
}

const imagesReducer = (state=initialState, action:any) => {
    switch(action.type){

        case 'SET_IMAGES':
            return {
                ...state,
                totalRecords: action.payload.totalRecords,
                offset: action.payload.offset,
                itemsPerPage: action.payload.itemsPerPage,
                filterTimeframe: action.payload.filterTimeframe,
                records: action.payload.records,
                fromDate: action.payload.fromDate
            }
        default:
            return state;
    }
};

export default imagesReducer;
