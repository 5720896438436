import { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../redux/Store";

import { getTimezonesAction } from "../../redux/actions/temporalActions";
import { getAllShopifyStoresAction } from "../../redux/actions/shopifyStoreActions";


import '../pages/AmazonSellerCentral/rbmws.css'

import { toast } from "react-toastify";

const SellerCentralStoreForm = (props: any) => {

    const dispatch: AppDispatch = useDispatch();

    const serviceAccuntFileInput = useRef(null);

    const [howardMiller, setHowardMiller] = useState(false);
    const [hekman, sethekman] = useState(false);
    const [newClientNo, setNewClientNo] = useState("");
    const [clientNoCache, setClientNoCache] = useState([] as any[]);
    const [newReportEmail, setNewReportEmail] = useState("");
    const [reportEmailsCache, setReportEmailsCache] = useState([] as any[]);

    const [timezoneOptions, setTimezoneOptions] = useState([{ value: "", label: "" }] as any[]);

    const temporalState = useSelector((state: any) => state.temporalReducer);
    const shopifyStoreState = useSelector((state: any) => state.shopifyStoreReducer);

    const handleDeleteServiceAcccountFileClick = (e: any) => {

        let elm: any = serviceAccuntFileInput.current;

        let hasFile = elm != null && elm.files.length > 0;
        if (hasFile) {
            elm.value = '';
            props.setServiceAccountFile(null);
            props.setServiceAccountFilename(props.originalServiceAccountFilename);
        }
        else if (props.hasServiceAccountFile && props.setDeleteServiceAccountFile) {
            props.setDeleteServiceAccountFile(!props.deleteServiceAccountFile);

        }
    }

    const handleClickServiceAccountFile = (e: any) => {
        if (props.serviceAccountFileData && props.editClick) {
            var blob = new Blob([props.serviceAccountFileData], { type: 'application/octet-stream' });

            const anchorElement = document.createElement('a');
            document.body.appendChild(anchorElement);
            anchorElement.style.display = 'none';
            const url = window.URL.createObjectURL(blob);
            anchorElement.href = url;
            anchorElement.download = props.serviceAccountFilename;
            anchorElement.click();
            window.URL.revokeObjectURL(url);

        }
    };

    const onBrandChange = (e: any) => {
        if (e.target.value === 'howard miller') {
            setHowardMiller(!howardMiller)
        } else {
            sethekman(!hekman)
        }
    }

    const info = (msg: String) =>
        toast.info(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });


    const checkListEqual = (arr1: any[], arr2: any[]) => {

        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let idx = 0; idx < arr1.length; idx++) {
            if (arr1[idx][0] !== arr2[idx][0]) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        console.log("sellerCentralStoreForm temporalState arrived");
        if (temporalState.timezones === null) {
            dispatch(getTimezonesAction());
        } else {
            let tzOptions = temporalState.timezones.map((valueIn: string) => { return { value: valueIn, label: valueIn } });
            let options = [{ value: "", label: "" }, ...tzOptions]
            setTimezoneOptions(options);
        }

    }, [temporalState, dispatch]);

    useEffect(() => {

        if (typeof props.dsClientNos !== 'undefined') {

            let out = [...clientNoCache]

            // add missing
            props.dsClientNos.forEach((value: string) => {

                let found = false;

                for (let idx = 0; idx < out.length; idx++) {

                    if (out[idx][1] === value) {
                        found = true;
                    }
                }

                if (!found) {
                    out = [...out, [true, value]];
                }
            });


            // set checks
            out = out.map((value) => {
                if (props.dsClientNos.indexOf(value[1]) < 0) {
                    return [false, value[1]];
                }
                return [true, value[1]];
            });

            out = out.filter((value) => value[0]);


            if (!checkListEqual(clientNoCache, out)) {
                setClientNoCache(out);
            }

        } else {
            if (typeof clientNoCache === 'undefined' || clientNoCache.length !== 0) {
                setClientNoCache([]);
            }
        }

    }, [clientNoCache, props.dsClientNos])

    useEffect(() => {

        if (typeof props.reportEmails !== 'undefined') {

            let out = [...reportEmailsCache]

            // add missing
            props.reportEmails.forEach((value: string) => {

                let found = false;

                for (let idx = 0; idx < out.length; idx++) {

                    if (out[idx][1] === value) {
                        found = true;
                    }
                }

                if (!found) {
                    out = [...out, [true, value]];
                }
            });


            // set checks
            out = out.map((value) => {
                if (props.reportEmails.indexOf(value[1]) < 0) {
                    return [false, value[1]];
                }
                return [true, value[1]];
            });

            out = out.filter((value) => value[0]);

            if (!checkListEqual(reportEmailsCache, out)) {
                setReportEmailsCache(out);
            }

        } else {
            if (typeof reportEmailsCache === 'undefined' || reportEmailsCache.length !== 0) {
                setReportEmailsCache([]);
            }
        }

    }, [reportEmailsCache, props.reportEmails])


    useEffect(() => {
        if (props.editClick) {
            let elm: any = serviceAccuntFileInput.current;

            let hasFile = elm != null && elm.files.length > 0;
            if (hasFile) {
                elm.value = '';
                props.setServiceAcountFile(null);
                props.setServiceAccountFilename(props.originalServiceAccountFilename);
            }
        }
    }, [props]);


    useEffect(() => {
        if (props.brands) {
            props.brands.map((data: any) => {
                if (data === "howard miller") {
                    setHowardMiller(true)
                } else if (data === "hekman") {
                    sethekman(true)
                }
                return null;
            })
        }
    }, [props.brands])

    useEffect(() => {

        if (props.brands) {
            if (howardMiller) {
                if (!props.brands.includes('howard miller')) {
                    props.setBrands(
                        [...props.brands, 'howard miller']
                    )
                }

            } else {
                const index = props.brands.indexOf('howard miller');
                if (index > -1) {
                    const brands = [...props.brands];
                    brands.splice(index, 1)
                    props.setBrands(brands)
                }
            }
        }
    }, [howardMiller, props])


    useEffect(() => {

        if (props.brands) {
            if (hekman) {
                if (!props.brands.includes('hekman')) {
                    props.setBrands(
                        [...props.brands, 'hekman']
                    )
                }

            } else {
                const index = props.brands.indexOf('hekman');
                if (index > -1) {
                    const brands = [...props.brands];
                    brands.splice(index, 1)
                    props.setBrands(brands)
                }
            }
        }
    }, [hekman, props])


    useEffect(() => {
        console.info("Shopify stores data arrived");
        if (shopifyStoreState.stores === null) {
            dispatch(getAllShopifyStoresAction(false));
        }
    }, [dispatch, shopifyStoreState.stores]);


    const handleChangeServiceAccountFile = (e: any) => {
        let file = (!!e.target.files && !!e.target.files[0]) ? e.target.files[0] : null;
        props.setServiceAccountFilename(file.name);
        if (props.setDeleteServiceAccountFile) {
            props.setDeleteServiceAccountFile(false);
        }
        props.setServiceAccountFile(file);
    };

    const handlingNonPercialFocus = (e: any) => {
        if (props.handlingMode === '') {
            info("Please select Handling time mode first")
            e.target.blur()
        }
    }

    const handlingTimeFoucs = (e: any) => {
        if (props.handlingMode === '') {
            info("Please select Handling time mode first")
            e.target.blur()
        }
    }

    const onAddNewClientNo = () => (e: any) => {
        if (newClientNo.length > 0) {
            let found = false;
            for (let i = 0; i < clientNoCache.length; i++) {
                if (clientNoCache[i][1] === newClientNo) {
                    found = true;
                }
            }
            if (!found) {
                let out = [...clientNoCache, [true, newClientNo]];
                setClientNoCache(out);
                let newClientNos = out.filter((v) => v[0]).map((v) => v[1]);
                props.setDsClientNos(newClientNos);
                setNewClientNo("");
            }
        }
    }


    const onAddNewReportEmail = () => (e: any) => {
        if (newReportEmail.length > 0) {
            let found = false;
            for (let i = 0; i < reportEmailsCache.length; i++) {
                if (reportEmailsCache[i][1] === newReportEmail) {
                    found = true;
                }
            }
            if (!found) {
                let out = [...reportEmailsCache, [true, newReportEmail]];
                setReportEmailsCache(out);
                let newReportEmails = out.filter((v) => v[0]).map((v) => v[1]);
                props.setReportEmails(newReportEmails);
                setNewReportEmail("");
            }
        }
    }

    const onClientNoChange = (idx: number) => (e: any) => {
        let out = [...clientNoCache]
        out[idx][0] = !out[idx][0]

        let newClientNos = out.filter((v) => v[0]).map((v) => v[1]);
        setClientNoCache(out);
        props.setDsClientNos(newClientNos);
    }

    const onReportEmailChange = (idx: number) => (e: any) => {
        let out = [...reportEmailsCache]
        out[idx][0] = !out[idx][0]
        let newReportEmails = out.filter((v) => v[0]).map((v) => v[1]);
        setReportEmailsCache(out);
        props.setReportEmails(newReportEmails);
    }

    return (
        <>
            <div className="seller-central-form">

                <div className="table_layout p-4">
                    {
                        props.storeId ? (
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="">
                                        <label className="detail_head">Store ID</label>
                                        <div className="form-control disabled mb-30">{props.storeId}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="row">
                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Account Id</label>
                                <input
                                    onChange={(e) => props.setAccountID(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.accountId} />

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Seller Id</label>
                                <input
                                    onChange={(e) => props.setAscSellerId(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" placeholder="AXXXXXXXXXXXXX" readOnly={props.editClick} value={props.ascSellerId || ''} />

                            </div>
                        </div>
                        {props.isAdmin || !props.isCreate ? (
                            <div className="col-md-4">
                                <div className="">
                                    <label className="detail_head">Owner</label>

                                    <select className="form-select mb-30 detail_content" disabled={props.editClick || !props.isAdmin} aria-label="Default select example"
                                        onChange={(e) => props.setOwnerUserId(e.target.value)} value={props.ownerUserId}

                                    >
                                        {props.isCreate && props.ownerUserId === '' ? (
                                            <option value="">Select owner</option>
                                        ) : null}
                                        {props.userList.map((user: any) => (
                                            <option value={user.id} key={user.id}>{user.email}</option>
                                        ))}
                                    </select>

                                </div>
                            </div>) : null}
                    </div>

                    <div className="row">

                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Service Type {props.marketServiceType}</label>
                                <select className="form-select mb-30 detail_content" disabled={props.editClick} aria-label="Default select example"
                                    onChange={(e) => props.setMarketServiceType(e.target.value)} value={props.marketServiceType}
                                >
                                    <option value=''>Select Service Type</option>
                                    <option value="sp-api">SP-API Celery (Default)</option>
                                    <option value="sp-api-ext">SP-API Jenkins</option>
                                </select>
                            </div>
                        </div>



                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Hold Back</label>
                                <input
                                    onChange={(e) => props.setHoldBack(e.target.value)}
                                    type="number" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.holdBack || ''} />
                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Wholesale Pricing</label>
                                <select className="form-select mb-30 detail_content" disabled={props.editClick} aria-label="Default select example"
                                    onChange={(e) => props.setWholesalePricingMethod(e.target.value || null)} value={props.wholesalePricingMethod || ''}
                                >
                                    <option value=''>Default</option>
                                    <option value='preferred_price_group'>Preferred Price Group</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Tax Rate</label>
                                <input
                                    onChange={(e) => props.setTaxRate(e.target.value)}
                                    type="number" step="0.01" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.taxRate} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">

                                <label className="detail_head">Commission</label>
                                <input
                                    onChange={(e) => props.setCommission(e.target.value)}
                                    type="number" step="0.01" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.commission} />

                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Profit Margin</label>
                                <input
                                    onChange={(e) => props.setProfitMargin(e.target.value)}
                                    type="number" step="0.01" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.profitMargin} />
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="mb-30">

                                <label className="detail_head">Brands</label>
                                <div className="form-check clearfix">

                                    <input className="form-check-input" type="checkbox" onChange={(e) => onBrandChange(e)} value="howard miller" id="flexCheckDefault"
                                        checked={howardMiller}
                                        disabled={props.editClick}
                                    />
                                    <label className="form-check-label brand float-start" htmlFor="flexCheckDefault">
                                        howard miller
                                    </label>
                                </div>
                                <div className="form-check clearfix">
                                    <input className="form-check-input" onChange={(e) => onBrandChange(e)} type="checkbox" value="hekman" id="flexCheckChecked"
                                        checked={hekman}
                                        disabled={props.editClick}
                                    />
                                    <label className="form-check-label brand float-start" htmlFor="flexCheckChecked">
                                        hekman
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">

                            <label className="detail_head">Set LTL inventory to Zero</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setZeroLtl(!props.zeroLtl)}
                                    checked={props.zeroLtl}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="detail_head">Enable FBA</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setEnableFba(!props.enableFba)}
                                    checked={props.enableFba}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>

                    </div>

                </div>

                <div className="table_layout p-4">

                    <div className="row">
                        <div className="col-md-12 left">
                            <h4>Warehouses</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 middle">
                            <input type="checkbox" checked={true} readOnly={true} disabled />
                            <label className="checkbox-label-right">Default</label>
                        </div>
                        <div className="col-md-4">
                            <div className="">

                                <label className="detail_head">SKU Prefix</label>
                                <input
                                    onChange={(e) => props.setSkuPrefix(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.skuPrefix || ''} />


                            </div>
                        </div>

                        <div className="col-md-2">
                            <label className="detail_head">Enable Pricing</label>
                            <div className="form-check form-switch mb-30" style={props.editClick ? {} : { "cursor": "not-allowed" }}>
                                <input
                                    style={{ "backgroundColor": '#aaa', "borderColor": '#aaa', "cursor": "not-allowed" }}
                                    checked={true}
                                    disabled={true}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2 middle">
                            <input id="enableWarehouseWemove" type="checkbox" checked={props.enableWarehouseWemove} onChange={() => props.setEnableWarehouseWemove(!props.enableWarehouseWemove)} disabled={props.editClick} />
                            <label htmlFor="enableWarehouseWemove" className="checkbox-label-right">WeMove</label>
                        </div>
                        <div className="col-md-4">
                            <div className="">

                                <label className="detail_head">SKU Prefix</label>
                                <input
                                    onChange={(e) => props.setWarehouseSkuPrefixWemove(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.warehouseSkuPrefixWemove || ''} />


                            </div>
                        </div>

                        <div className="col-md-2">
                            <label className="detail_head">Enable Pricing</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setWarehouseEnablePricingWemove(!props.warehouseEnablePricingWemove)}
                                    checked={props.warehouseEnablePricingWemove}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>

                    </div>

                </div>


                <div className="table_layout p-4">

                    <div className="row">

                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Address to code</label>
                                <input
                                    onChange={(e) => props.setAddressToCode(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.addressToCode || ''} />

                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="detail_head">Use Fedex ficup rates</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setUseFedexFicupRates(!props.useFedexFicupRates)}
                                    checked={props.useFedexFicupRates}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Handling time mode</label>
                                <select className="form-select mb-30 detail_content" disabled={props.editClick} aria-label="Default select example"
                                    onChange={(e) => props.setHandlingMode(e.target.value)} value={props.handlingMode}
                                >
                                    <option value=''>Select Time Mode</option>
                                    <option value="None">None</option>
                                    <option value="Normal">Normal</option>
                                    <option value="ParcelAndNonParcel">Parcel and non parcel</option>
                                </select>

                                {(props.handlingMode === '' ||
                                    props.handlingMode === 'ParcelAndNonParcel') && (
                                        <div>
                                            <label className="detail_head">Handling time non percial</label>
                                            <input
                                                onFocus={(e) => handlingNonPercialFocus(e)}
                                                onChange={(e) => props.setHandlingTimeNonParcel(e.target.value)}
                                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.handlingTimeNonParcel} />
                                        </div>

                                    )}

                                {(props.handlingMode === '' || props.handlingMode === 'Normal' ||
                                    props.handlingMode === "ParcelAndNonParcel") && (
                                        <div>
                                            <label className="detail_head">Handling Time</label>
                                            <input
                                                onFocus={(e) => handlingTimeFoucs(e)}
                                                onChange={(e) => props.setHandlingTime(e.target.value)}
                                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.handlingTime} />
                                        </div>
                                    )}

                            </div>
                        </div>

                    </div>

                </div>


                <div className="table_layout p-4">
                    <div className="row">

                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Account type</label>
                                <select className="form-select mb-30 detail_content" disabled={props.editClick} aria-label="Default select example"
                                    onChange={(e) => props.setAccountTypeCode(e.target.value)} value={props.accountTypeCode}
                                >
                                    <option value=''>Select Account Type</option>
                                    <option value="SC">Seller Central</option>
                                    <option value="VC">Vendor Central</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">

                                <label className="detail_head">marketplace</label>
                                <input
                                    onChange={(e) => props.setMarketplace(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.marketplace} />

                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">Spapi Report Encoding</label>
                                <input
                                    onChange={(e) => props.setSpapiReportEncoding(e.target.value)}
                                    type="text" step="0.01" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.spapiReportEncoding} />

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="">


                                <label className="detail_head">AWS Access key</label>
                                <input
                                    onChange={(e) => props.setAwsAccessKey(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.awsAccessKey} />

                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="">

                                <label className="detail_head">Aws Secret Key</label>
                                <input
                                    onChange={(e) => props.setAwsSecretKey(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.awsSecretKey} />

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="">

                                <label className="detail_head">Role ARN</label>
                                <input
                                    onChange={(e) => props.setRoleArn(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.roleArn || ''} />


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">LWA App Id</label>
                                <input
                                    onChange={(e) => props.setLwaAppID(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.lwaAppID} />


                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">
                                <label className="detail_head">LWA Client Secret</label>
                                <input
                                    onChange={(e) => props.setLwaClientSecret(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.lwaClientSecret} />


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <label className="detail_head">Refresh Token</label>
                                <input
                                    onChange={(e) => props.setRefreshToken(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.refreshToken} />


                            </div>
                        </div>
                    </div>
                </div>


                <div className="table_layout p-4">
                    <div className="row">
                        <div className="col-md-8">
                            <label className="detail_head">Service Account File</label>
                            <div className="input-group hm-action-group service_account_filename">
                                <div className={
                                    "form-control" +
                                    (props.deleteServiceAccountFile ? " delete" : "") +
                                    (props.editClick && props.serviceAccountFileData ? " download" : "") +
                                    (!props.hasServiceAccountFile && props.serviceAccountFilename === null ? " disabled" : "")} onClick={handleClickServiceAccountFile}>{props.serviceAccountFilename}</div>

                                {!props.editClick && (props.hasServiceAccountFile || props.serviceAccountFilename !== null) && (<div className="input-group-append" onClick={handleDeleteServiceAcccountFileClick}><span className="input-group-text">{
                                    props.deleteServiceAccountFile ? (<i className="fas fa-redo-alt"></i>) : (<i className="fas fa-trash-alt"></i>)}
                                </span></div>)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <input
                                ref={serviceAccuntFileInput}
                                onChange={handleChangeServiceAccountFile}
                                type="file" className="form-control mb-30 detail_content" disabled={props.editClick} />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <label className="detail_head">Google Ignore Sheet</label>
                            <input
                                onChange={(e) => props.setGoogleIgnoreSheet(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.googleIgnoreSheet} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <label className="detail_head">Google Sheets Reporting Id</label>

                            <input
                                onChange={(e) => props.setSheetsReportingId(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.sheetsReportingId} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <label className="detail_head">Google Stock Sheet</label>
                            <input
                                onChange={(e) => props.setGoogleStockSheet(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.googleStockSheet} />
                        </div>
                    </div>
                </div>


                <div className="table_layout p-4">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="detail_head">DB Host</label>
                            <input
                                onChange={(e) => props.setDbHost(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.dbHost} />

                        </div>
                        <div className="col-md-4">
                            <label className="detail_head">DB Port</label>
                            <input
                                onChange={(e) => props.setDbPort(e.target.value)}
                                type="number" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.dbPort} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">

                            <label className="detail_head">DB User</label>
                            <input
                                onChange={(e) => props.setDbUser(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.dbUser} />

                        </div>
                        <div className="col-md-4">
                            <label className="detail_head">DB Password</label>
                            <input
                                onChange={(e) => props.setDbPassword(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.dbPassword} />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4">

                            <label className="detail_head">DB Table</label>
                            <input
                                onChange={(e) => props.setDbTable(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.dbTable} />

                        </div>
                    </div>
                </div>


                <div className="table_layout p-4">

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Reporting</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label className="detail_head">Emails</label>

                            {reportEmailsCache.length < 1 ? (<div className="text-left"><span className="null">None</span></div>) : null}
                            {!props.editClick ? (

                                <>

                                    {reportEmailsCache.map((v, i) => (

                                        <div className="text-left" key={'clientNo' + i}>

                                            <input type="checkbox" id={'client-no-' + i} checked={v[0]} onChange={onReportEmailChange(i)}></input>
                                            <label htmlFor={'client-no-' + i} style={{ marginLeft: '1ex' }}>
                                                {v[1]}
                                            </label>
                                        </div>

                                    ))}
                                    <div className="input-group hm-action-group">
                                        <input
                                            onChange={(e) => setNewReportEmail(e.target.value)}
                                            type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={newReportEmail} />

                                        <div className="input-group-append" onClick={onAddNewReportEmail()}>
                                            <span className="input-group-text">+</span>
                                        </div>

                                    </div>
                                </>
                            ) : (

                                <>
                                    {reportEmailsCache.filter((v) => v[0]).map((v, i) => (
                                        <div className="text-left" key={'reportEmail' + i}>
                                            <label htmlFor={'report-email-' + i} style={{ marginLeft: '1ex' }}>
                                                {v[1]}
                                            </label>
                                        </div>

                                    ))}
                                </>
                            )}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="detail_head">Reporting only</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setReportingOnly(!props.reportingOnly)}
                                    checked={props.reportingOnly}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="table_layout p-4">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="detail_head">Dealer Services Client Numbers</label>

                            {clientNoCache.length < 1 ? (<div className="text-left"><span className="null">None</span></div>) : null}
                            {!props.editClick ? (

                                <>

                                    {clientNoCache.map((v, i) => (

                                        <div className="text-left" key={'clientNo' + i}>

                                            <input type="checkbox" id={'client-no-' + i} checked={v[0]} onChange={onClientNoChange(i)}></input>
                                            <label htmlFor={'client-no-' + i} style={{ marginLeft: '1ex' }}>
                                                {v[1]}
                                            </label>
                                        </div>

                                    ))}
                                    <div className="input-group hm-action-group">
                                        <input
                                            onChange={(e) => setNewClientNo(e.target.value)}
                                            type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={newClientNo} />

                                        <div className="input-group-append" onClick={onAddNewClientNo()}>
                                            <span className="input-group-text">+</span>
                                        </div>

                                    </div>
                                </>
                            ) : (

                                <>
                                    {clientNoCache.filter((v) => v[0]).map((v, i) => (
                                        <div className="text-left" key={'clientNo' + i}>
                                            <label htmlFor={'client-no-' + i} style={{ marginLeft: '1ex' }}>
                                                {v[1]}
                                            </label>
                                        </div>

                                    ))}
                                </>
                            )}

                        </div>
                    </div>
                </div>
                {props.marketServiceType === 'sp-api-ext' ? (
                    <div className="table_layout p-4">
                        <div className="row">
                            <div className="col-md-4">
                                <label className="detail_head">S3 Bucket</label>
                                <input
                                    onChange={(e) => props.setS3Bucket(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.s3Bucket} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="detail_head">S3 Access Key</label>
                                <input
                                    onChange={(e) => props.setS3AccessKey(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.s3AccessKey} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="detail_head">S3 Secret Key</label>
                                <input
                                    onChange={(e) => props.setS3SecretKey(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.s3SecretKey} />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="table_layout p-4">

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Acenda</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">

                            <label className="detail_head">Default Shipping Method</label>
                            <input
                                onChange={(e) => props.setAcendaDefaultShippingMethod(e.target.value)}
                                type="number" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaDefaultShippingMethod || ''} />

                        </div>
                        <div className="col-md-4">
                            <label className="detail_head">Fedex Ficup Shipping Method</label>
                            <input
                                onChange={(e) => props.setAcendaFedexFicupShippingMethod(e.target.value)}
                                type="number" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaFedexFicupShippingMethod || ''} />
                        </div>
                        {props.enableFba && (
                            <div className="col-md-4">
                                <label className="detail_head">FBA Shipping Method</label>
                                <input
                                    onChange={(e) => props.setAcendaFbaShippingMethod(e.target.value)}
                                    type="number" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaFbaShippingMethod || ''} />
                            </div>)}
                    </div>

                    <div className="row">

                        <div className="col-md-4">
                            <label className="detail_head">Marketplace name</label>
                            <input
                                onChange={(e) => props.setAcendaMarketplaceName(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaMarketplaceName || ''} />
                        </div>
                        {props.enableFba && (
                            <div className="offset-md-4 col-md-4">
                                <label className="detail_head">FBA marketplace name</label>
                                <input
                                    onChange={(e) => props.setAcendaFbaMarketplaceName(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaFbaMarketplaceName || ''} />
                            </div>)}
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Acenda Client ID</label>
                            <input
                                onChange={(e) => props.setAcendaClientId(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaClientId || ''} />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Acenda Client Secret</label>
                            <input
                                onChange={(e) => props.setAcendaClientSecret(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaClientSecret || ''} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Acenda API Base URL</label>
                            <input
                                onChange={(e) => props.setAcendaApiBaseUrl(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.acendaApiBaseUrl || ''} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">

                            <label className="detail_head">Release Date</label>

                            <div className="md-form md-outline input-with-post-icon datepicker">
                                <input placeholder="Select date" type="date" readOnly={props.editClick} onChange={(e) => props.setAcendaReleaseDate(e.target.value)} value={props.acendaReleaseDate || ''} className="form-control mb-30" />
                            </div>

                        </div>
                        <div className="col-md-4">
                            <label className="detail_head">Release Timezone</label>

                            <select className="form-select mb-30 detail_content" disabled={props.editClick} aria-label="Release timezone"
                                onChange={(e) => props.setAcendaReleaseTimezone(e.target.value)} value={props.acendaReleaseTimezone || ''}
                            >

                                {timezoneOptions && timezoneOptions.map((ent) => {
                                    return (
                                        <option value={ent.value} key={ent.value}>{ent.label}</option>
                                    )
                                })}
                            </select>

                        </div>
                    </div>
                </div>

                <div className="table_layout p-4">

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Shopify</label>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-4">
                            <label className="detail_head">Marketplace name</label>
                            <input
                                onChange={(e) => props.setShopifyMarketplaceName(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.shopifyMarketplaceName || ''} />
                        </div>
                        <div className="col-md-4">
                            <label className="detail_head">WeMove Marketplace name</label>
                            <input
                                onChange={(e) => props.setShopifyWarehouseMarketplaceNameWemove(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.shopifyWarehouseMarketplaceNameWemove || ''} />
                        </div>
                        {props.enableFba && (
                            <div className="col-md-4">
                                <label className="detail_head">FBA marketplace name</label>
                                <input
                                    onChange={(e) => props.setShopifyFbaMarketplaceName(e.target.value)}
                                    type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.shopifyFbaMarketplaceName || ''} />
                            </div>)}
                    </div>


                    <div className="row">
                        <div className="col-md-4">

                            <label className="detail_head">Release Date</label>

                            <div className="md-form md-outline input-with-post-icon datepicker">
                                <input placeholder="Select date" type="date" readOnly={props.editClick} onChange={(e) => props.setShopifyReleaseDate(e.target.value)} value={props.shopifyReleaseDate || ''} className="form-control mb-30" />
                            </div>

                        </div>
                        <div className="col-md-4">
                            <label className="detail_head">Release Timezone</label>

                            <select className="form-select mb-30 detail_content" disabled={props.editClick} aria-label="Release timezone"
                                onChange={(e) => props.setShopifyReleaseTimezone(e.target.value)} value={props.shopifyReleaseTimezone || ''}
                            >

                                {timezoneOptions && timezoneOptions.map((ent) => {
                                    return (
                                        <option value={ent.value} key={ent.value}>{ent.label}</option>
                                    )
                                })}
                            </select>

                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <label className="detail_head">Shopify Store</label>


                            <select className="form-select mb-30 detail_content" disabled={props.editClick} aria-label="Release timezone"
                                onChange={(e) => props.setShopifyStoreId(e.target.value)} value={props.shopifyStoreId || ''}
                            >

                                <option value="">None</option>
                                {shopifyStoreState.stores && shopifyStoreState.stores.map((store: any) => (
                                    <option value={store.id} key={store.id}>{store.account_id}</option>
                                ))}
                            </select>

                        </div>
                    </div>



                </div>

                <div className="table_layout p-4">

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Shipstation</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Shipstation Store ID</label>
                            <input
                                onChange={(e) => props.setSsStoreId(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.ssStoreId || ''} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Shipstation API Key</label>
                            <input
                                onChange={(e) => props.setSsApiKey(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.ssApiKey || ''} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label className="detail_head">Shipstation API Secret</label>
                            <input
                                onChange={(e) => props.setSsApiSecret(e.target.value)}
                                type="text" className="form-control mb-30 detail_content" readOnly={props.editClick} value={props.ssApiSecret || ''} />
                        </div>
                    </div>

                </div>

                <div className="table_layout p-4">
                    <div className="row">
                        <div className="col-md-4">

                            <label className="detail_head">Enable Inventory Update</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setInventoryUpdate(!props.inventoryUpdate)}
                                    checked={props.inventoryUpdate}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label className="detail_head">Enable Pricing Update</label>
                            <div className="form-check form-switch mb-30">
                                <input
                                    onChange={() => props.setPricingUpdate(!props.pricingUpdate)}
                                    checked={props.pricingUpdate}
                                    disabled={props.editClick}
                                    className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                    </div>
                    {props.marketServiceType === 'sp-api-ext' ? (
                        <div className="row">
                            <div className="col-md-4">

                                <label className="detail_head">Save details feed</label>
                                <div className="form-check form-switch mb-30">
                                    <input
                                        onChange={() => props.setSaveDetailsFeed(!props.saveDetailsFeed)}
                                        checked={props.saveDetailsFeed}
                                        disabled={props.editClick}
                                        className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="detail_head">Save inventory feed</label>
                                <div className="form-check form-switch mb-30">
                                    <input
                                        onChange={() => props.setSaveInventoryFeed(!props.saveInventoryFeed)}
                                        checked={props.saveInventoryFeed}
                                        disabled={props.editClick}
                                        className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="detail_head">Save prices feed</label>
                                <div className="form-check form-switch mb-30">
                                    <input
                                        onChange={() => props.setSavePricesFeed(!props.savePricesFeed)}
                                        checked={props.savePricesFeed}
                                        disabled={props.editClick}
                                        className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                </div>
                            </div>
                        </div>) : null}
                </div>
            </div>

        </>)
};

export default SellerCentralStoreForm;