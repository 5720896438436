import { get_config } from "../utils/Helper";
import axios from "axios";
import config from "../constants/config";

export class FicServiceImpl {
  ficShippingApiBaseUrl = `${config.baseUrl}api/shipping`;

  //

  getAllCustomers() {
    return axios.get(
      `${this.ficShippingApiBaseUrl}/hm_customers`,
      get_config()
    );
  }

  createOrUpdateCustomer(data: any) {
    return axios.post(
      `${this.ficShippingApiBaseUrl}/hm_customers`,
      data,
      get_config()
    );
  }

  getCustomer(id: string) {
    return axios.get(
      `${this.ficShippingApiBaseUrl}/hm_customers?id=${id}`,
      get_config()
    );
  }

  deleteCustomers(hm_customer_ids: string[]) {
    const currentConfig = get_config();
    let cfg = {
      ...currentConfig,
      data: { hm_customer_ids: hm_customer_ids },
    };
    return axios.delete(`${this.ficShippingApiBaseUrl}/hm_customers`, cfg);
  }

  getAllCustomerAccounts() {
    return axios.get(
      `${this.ficShippingApiBaseUrl}/hm_customer_accounts`,
      get_config()
    );
  }

  createOrUpdateCustomerAccount(data: any) {
    return axios.post(
      `${this.ficShippingApiBaseUrl}/hm_customer_accounts`,
      data,
      get_config()
    );
  }

  getCustomerAccounts(hm_customer_id: string) {
    // This function returns all associated customer_accounts by hm_customer_id
    return axios.get(
      `${this.ficShippingApiBaseUrl}/hm_customer_accounts?hm_customer_id=${hm_customer_id}`,
      get_config()
    );
  }

  getAccount(id: string) {
    // This function returns a single hm_customer_account by its id
    return axios.get(
      `${this.ficShippingApiBaseUrl}/hm_customer_accounts?id=${id}`,
      get_config()
    );
  }

  deleteAccounts(ids: string[]) {
    // Delete customer accounts by account ids
    const currentConfig = get_config();
    let cfg = {
      ...currentConfig,
      data: { ids: ids },
    };
    return axios.delete(
      `${this.ficShippingApiBaseUrl}/hm_customer_accounts`,
      cfg
    );
  }

  /*deleteAllCustomerAccounts(hm_customer_account_ids: string[]) {
    // Delete all customer accounts by customer id
    const currentConfig = get_config();
    let cfg = {
      ...currentConfig,
      data: { hm_customer_ids: hm_customer_ids },
    };
    return axios.delete(
      `${this.ficShippingApiBaseUrl}/hm_customer_accounts`,
      cfg
    );
  }*/

  getAllCountries() {
    return axios.get(
      `${this.ficShippingApiBaseUrl}/country_configs`,
      get_config()
    );
  }

  createOrUpdateCountry(data: any) {
    return axios.post(
      `${this.ficShippingApiBaseUrl}/country_configs`,
      data,
      get_config()
    );
  }

  getCountry(id: string) {
    return axios.get(
      `${this.ficShippingApiBaseUrl}/country_configs?id=${id}`,
      get_config()
    );
  }

  deleteCountries(country_ids: string[]) {
    const currentConfig = get_config();
    let cfg = {
      ...currentConfig,
      data: { country_config_ids: country_ids },
    };
    return axios.delete(`${this.ficShippingApiBaseUrl}/country_configs`, cfg);
  }

  uploadConfigurationExport(ficCustomerId: string, configFile: File) {
    const url = `${this.ficShippingApiBaseUrl}/upload-inventory-export`;
    const formData = new FormData();

    formData.append("fic_customer_id", ficCustomerId);
    formData.append("configuration_export", configFile);

    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authentication-Token": localStorage.getItem("token"),
      },
    });
  }
}
