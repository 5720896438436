
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { fedexShippingCostService } from "../../service";


export const getShippingCostsAction = (country: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  fedexShippingCostService.getShippingCosts(country).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_SHIPPING_COSTS",
      payload: response.data
    })
  })
}
