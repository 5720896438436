
import { useEffect, useState } from "react";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const FixedInventoryForm = (props: any) => {

    const [validated, setValidated] = useState(false);
    const [fixedInventoryFormData, setFixedInventoryFormData] = useState({} as any);

    useEffect(() => {
        console.info("FixedInventoryForm Arrived");
        setFixedInventoryFormData(props.fixedInventory ? props.fixedInventory : {});
    }, [props.fixedInventory]);


    const handleSubmit = (event: any) => {
        const form = event.currentTarget;

        let isValid = true;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            isValid = false;
        }

        setValidated(true);

        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries());

        if (isValid && props.handleSubmit) {
            event.preventDefault();
            event.stopPropagation();
            props.handleSubmit(formDataObj);
        }
    };

    const handleFormFieldUpdate = (event: any) => {
        let formData = { ...fixedInventoryFormData }
        formData[event.target.name] = event.target.value
        setFixedInventoryFormData(formData);
    };

    return (
        <div className="form-align">


            {props.errorMessage && (
                <Alert variant={"danger"}>
                    {props.errorMessage}
                </Alert>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Control type="hidden" name="id" value={fixedInventoryFormData.id} />
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="wayfair_supplier_part_number">
                            <Form.Label>Supplier Part Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter supplier part number" name="wayfair_supplier_part_number" onChange={handleFormFieldUpdate} value={fixedInventoryFormData.wayfair_supplier_part_number} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Product Name" name="name" onChange={handleFormFieldUpdate} value={fixedInventoryFormData.name} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="wayfair_quantity_on_hand">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control type="number" placeholder="Enter quantity" name="wayfair_quantity_on_hand" onChange={handleFormFieldUpdate} value={fixedInventoryFormData.wayfair_quantity_on_hand} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="hm_order_sku">
                            <Form.Label>Howard Miller Order SKU</Form.Label>
                            <Form.Control type="text" placeholder="Enter SKU" name="hm_order_sku" onChange={handleFormFieldUpdate} value={fixedInventoryFormData.hm_order_sku} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6" style={{ "marginTop": "2ex" }}>
                        <Button variant="primary" type="submit" className="mb-3">
                            Save
                        </Button>
                    </div>
                </div>
            </Form >
        </div >
    )
}

export default FixedInventoryForm;