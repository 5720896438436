
const managedRoles: string[] = [
    "superuser",
    "freightclubuser",
    "amazonverificationuser",
    "shipmenttrackinguser",
    "localeeditor",
    "overstockppcuser",
    "tradeshowuser",
    "mapprocessor"
];

export default managedRoles;
