const initialState = {
    subscriptions:[]
}

const subscriptionsReducer = (state=initialState, action:any) =>{
    switch(action.type){
        case "GET_SUBSCRIPTIONS":
            return {
                ...state,
                subscriptions:action.payload.subscriptions
              };
        case "ADD_SUBSCRIPTIONS":
            console.log("ADD SUBSCRIPTIONS", action.payload);
            return {
                ...state,
                subscriptions: state.subscriptions.map((value: any,index) => {
                    if (action.payload.skus.indexOf(value.SKU) !== -1) {
                        return {
                            ...value,
                            Subscribed: true
                        }
                    }
                    return value;
                })
            }
        case "REMOVE_SUBSCRIPTIONS":
            console.log("REMOVE SUBSCRIPTIONS", action.payload);
            return {
                ...state,
                subscriptions: state.subscriptions.map((value: any,index) => {
                    if (action.payload.skus.indexOf(value.SKU) !== -1) {
                        return {
                            ...value,
                            Subscribed: false
                        }
                    }
                    return value;
                })
            }            
        default:
            return state;
    }
}

export default subscriptionsReducer;