interface StoreState {
    stores: [] | null;
    storeDetails: [] | null;
    status: boolean;
    statusCode: number | null;
    createdStatus: boolean | null;
    mesg: string | null;
    deleteStatus: boolean;
    createStatus: boolean | null;
    storeUpdate: boolean;
    user: any | null; // Add a field for the user details
}

const initialState: StoreState = {
    stores: null,
    storeDetails: [],
    status: false,
    statusCode: null,
    createdStatus: null,
    mesg: null,
    deleteStatus: false,
    createStatus: false,
    storeUpdate: false,
    user: null // Initialize the user details state
}

const shopifyStoreReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "GET_ALL_SHOPIFY_STORES_DATA":
            return {
                ...state,
                stores: action.payload,
                status: true
            };
        case "GET_ALL_SHOPIFY_STORES_DATA_ERROR":
            return {
                ...state,
                statusCode: action.status
            };
        case "GET_SHOPIFY_STORE_DATA":
            return {
                ...state,
                storeDetails: action.payload
            };
        case "GET_SHOPIFY_STORE_DATA_ERROR":
            return {
                ...state,
                statusCode: action.status
            };
        case "CREATE_SHOPIFY_STORE":
            return {
                ...state,
                createStatus: true,
                mesg: "Shopify store created successfully",
                createdStatus: true,
                stores: null
            };
        case "CREATE_SHOPIFY_STORE_ERRORS":
            return {
                ...state,
                statusCode: action.payload.status,
                status: false,
                mesg: action.payload.data.result,
                createdStatus: false
            };
        case "SET_SHOPIFY_STORE_CREATE_STATUS":
            return {
                ...state,
                createStatus: action.payload,

            };
        case "SET_SHOPIFY_STORE_CREATE_STATUS_NULL":
            return {
                ...state,
                createStatus: null
            };
        case "DELETE_SHOPIFY_STORE":
            return {
                ...state,
                stores: state.stores?.filter(store => store !== action.payload),
                mesg: "Store successfully deleted",
                deleteStatus: true
            };
        case "SET_SHOPIFY_STORE_DELETE_STATUS":
            return {
                ...state,
                deleteStatus: action.payload
            };
        case "DELETE_SHOPIFY_STORE_ERROR":
            return {
                ...state,
                statusCode: action.status,
                status: false
            };
        case "UPDATE_SHOPIFY_STORE":
            return {
                ...state,
                storeUpdate: true,
                mesg: "Store updated successfully...!!!"
            };
        case "UPDATE_SHOPIFY_STORE_ERRORS":
            return {
                ...state,
                statusCode: 500,
                mesg: "Unexpected server error...!!!"
            };
        case "GET_SHOPIFY_USER_LIST_SUCCESS":
            return {
                ...state,
                user: action.payload,
                status: true
            };
        case "GET_SHOPIFY_USER_LIST_ERROR":
            return {
                ...state,
                statusCode: action.status,
                status: false
            };
        default:
            return state;
    }
}

export default shopifyStoreReducer;
