import { useNavigate } from "react-router-dom";
import "./common.css";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/actions/authActions";
import logo from '../../logo.png'
import { AppDispatch } from "../../redux/Store";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutAction());
    navigate("/");
  };

  return (
    <div>
      <section className="header">
        <nav className="navbar cus-nav navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div
              className="me-3"
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={logo} className="logo" alt="logo" />
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mb-2 mb-lg-0">
                {localStorage.getItem("token") && (
                  <li className="nav-item cus-nav-item me-3">
                    <button
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                      className="nav-link cus-nav-link px-4 border-0 active" aria-current="page">
                      Dashboard
                    </button>
                  </li>
                )}
                {localStorage.getItem("superuser") === "true" && (
                  <li className="nav-item cus-nav-item">
                    <button
                      onClick={() => {
                        navigate("/admin");
                      }}
                      className="nav-link cus-nav-link px-4 border-0 active" aria-current="page">
                      Admin
                    </button>
                  </li>
                )}
              </ul>
              <div className="button-section ms-auto">
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  type="button"
                  className="btn btn-org me-2"

                >
                  <div className="d-flex">
                    <div className=""><i className="fad fa-home-lg-alt me-2"></i></div>
                    Home
                  </div>
                </button>

                {!localStorage.getItem("token") ? (
                  <button
                    onClick={() => {
                      navigate("/login");
                    }}
                    type="button"
                    className="btn btn-org me-2"
                  ><div className="d-flex">
                      <div className=""><i className="fad fa-sign-out-alt me-2"></i></div>
                      Login
                    </div>
                  </button>
                ) : (
                  <button
                    onClick={() => handleLogout()}
                    type="button"
                    className="btn btn-org"
                  ><div className="d-flex">
                      <div className=""><i className="fad fa-sign-out-alt me-2"></i></div>
                      Logout
                    </div>

                  </button>
                )}
              </div>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

export default NavBar;
