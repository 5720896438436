import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { amazonSellerCentralService } from "../../service";


// RBMWS system actions
export const getAllSellerCentralStoresAction = (loader: boolean) => (dispatch: any) => {

  if (loader) {
    dispatch(startLoadingAction());
  }
  amazonSellerCentralService.getStoreList().then(response => {
    if (loader) {
      dispatch(stopLoadingAction());
    }
    dispatch({
      type: "GET_ALL_SELLER_CENTRAL_STORES_DATA",
      payload: response.data
    });
  }).catch(err => {

    if (loader) {
      dispatch(stopLoadingAction());
    }
    dispatch({
      type: "GET_ALL_SELLER_CENTRAL_STORES_DATA_ERROR",
      status: err.response.status
    });
  });
};


export const getSellerCentralStoresAction = (id: any, loading: any) => (dispatch: any) => {
  if (loading) {
    dispatch(startLoadingAction());
  }

  amazonSellerCentralService.getStore(id).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_SELLER_CENTRAL_DATA",
      payload: response.data
    });
  }).catch(err => {
    console.error(err);
    dispatch({
      type: "GET_SELLER_CENTRAL_DATA_ERROR",
      status: err.response.status
    });
  });


  amazonSellerCentralService.getUserList(id).then(response => {
    //dispatch(stopLoadingAction());
    dispatch({
      type: "SET_SELLER_CENTRAL_USER_LIST",
      payload: response.data
    });
  }).catch(err => {
    console.error(err);

  });

};

export const getUserListAction = (store_id: string) => (dispatch: any) => {
  amazonSellerCentralService.getUserList(store_id).then(response => {
    //dispatch(stopLoadingAction());
    dispatch({
      type: "SET_SELLER_CENTRAL_USER_LIST",
      payload: response.data
    });
  }).catch(err => {
    console.error(err);

  });
}

export const createSellerCentralStoreAction = (data: any, serviceAccountFile: any, onSuccess: (arg0: string) => any) => (dispatch: any) => {

  dispatch(startLoadingAction());
  amazonSellerCentralService.createStore(data).then(response => {

    dispatch(stopLoadingAction());
    dispatch({
      type: "CREATE_SELLER_CENTRAL_STORE",
      payload: data.store
    });


    if (serviceAccountFile) {

      amazonSellerCentralService.updateServiceAccountFile(response.data.store_id, serviceAccountFile).then(updateFileResponse => {

        onSuccess(response.data.store_id);
        dispatch(getAllSellerCentralStoresAction(false));

      }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
          type: "CREATE_SELLER_CENTRAL_STORE_ERRORS",
          payload: err.response
        });
      });


    } else {
      onSuccess(response.data.store_id);

      dispatch(getAllSellerCentralStoresAction(false));
    }

  }).catch(err => {
    console.error(err);
    dispatch(stopLoadingAction());
    dispatch({
      type: "CREATE_SELLER_CENTRAL_STORE_ERRORS",
      payload: err.response
    });
  });
};


export const deleteSellerCentralStoreAction = (storeId: string) => (dispatch: any) => {
  dispatch(startLoadingAction());
  amazonSellerCentralService.deleteStore(storeId).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "DELETE_SELLER_CENTRAL_STORE",
      payload: storeId
    });
    dispatch(getAllSellerCentralStoresAction(false));
  }).catch(err => {
    console.error(err);
    dispatch({
      type: "DELETE_SELLER_CENTER_STORE_ERROR",
      status: err.response.status
    });
  });
};


export const updateStoreDataAction = (data: any, serviceAccountFile: any, deleteServiceAccountFile: boolean, storeId: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  amazonSellerCentralService.updateStore(data).then(response => {

    dispatch({
      type: "UPDATE_SELLER_CENTRAL_STORE",
      payload: response.data
    });

    if (deleteServiceAccountFile) {
      amazonSellerCentralService.deleteServiceAccountFile(storeId).then(response => {
        dispatch(stopLoadingAction());
        dispatch(getSellerCentralStoresAction(storeId, false));
        dispatch(getAllSellerCentralStoresAction(false));
      }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
          type: "UPDATE_SELLER_CENTRAL_STORE_ERRORS",
        });
      });
    } else {


      if (serviceAccountFile) {

        amazonSellerCentralService.updateServiceAccountFile(storeId, serviceAccountFile).then(updateFileResponse => {

          dispatch(stopLoadingAction());
          dispatch(getSellerCentralStoresAction(storeId, false));
          dispatch(getAllSellerCentralStoresAction(false));

        }).catch(err => {
          dispatch(stopLoadingAction());
          dispatch({
            type: "UPDATE_SELLER_CENTRAL_STORE_ERRORS",
          });
        });
      } else {

        dispatch(stopLoadingAction());
        dispatch(getSellerCentralStoresAction(storeId, false));
        dispatch(getAllSellerCentralStoresAction(false));
      }
    }
  }).catch(err => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "UPDATE_SELLER_CENTRAL_STORE_ERRORS",
    });
  });

};

export const sellerCentralZeroOutAction = (storeId: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  amazonSellerCentralService.zeroAllInventory(storeId).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "SELLER_CENTRAL_ZERO_OUT",
      payload: {status: response.status}
    });
  }).catch(err => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "SELLER_CENTRAL_ZERO_OUT_ERROR",
      payload: {data: err.response.data, status: err.response.status}
    });
  });
};


export const sellerCentralUpdateInventoryAction = (storeId: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  amazonSellerCentralService.syncInventory(storeId).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "SELLER_CENTREAL_UPDATE_INVENTORY",
      payload: response
    });
  }).catch(err => {
    console.error("Error=%O", err.response);
    dispatch(stopLoadingAction());
    dispatch({
      type: "SELLER_CENTRAL_UPDATE_INVENTORY_ERROR",
      payload: err.response
    });
  });
};


export const sellerCentralUpdatePriceAction = (storeId: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  amazonSellerCentralService.syncPrices(storeId).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "SELLER_CENTRAL_STORE_UPDATE_PRICE",
      payload: response
    });
  }).catch(err => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "SELLER_CENTRAL_STORE_UPDATE_PRICE_ERROR",
      payload: err.response
    });
  });
};


export const getSellerCentralStoreLogsAction = (storeId: string) => (dispatch: any) => {
  dispatch(startLoadingAction());
  amazonSellerCentralService.getLogs(storeId).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_ALL_SELLER_LOGS",
      payload: response.data
    });
  }).catch(err => {

    dispatch(stopLoadingAction());

    if (err.response) {
      dispatch({
        type: "GET_ALL_SELLER_LOGS_NOT_FOUND",
        payload: err.response.data.result
      });
    }
    console.error("Error=%O", " ", err.response.data.result);
  });
};


export const sellerCentralClearBrandAddAction = () => (dispatch: any) => {
  dispatch({
    type: "SC_CLEAR_BRAND_ADD"
  })
}

export const sellerCentralClearStoreUpdateAction = () => (dispatch: any) => {
  dispatch({
    type: "SC_CLEAR_STORE_UPDATE"
  })
}

export const sellerCentralClearStatusCodeAction = () => (dispatch: any) => {
  dispatch({
    type: "SC_CLEAR_STATUS_CODE"
  })
}

export const sellerCentralClearUpdateInventoryStatusCodeAction = () => (dispatch: any) => {
  dispatch({
    type: "SC_CLEAR_UPDATE_INVENTORY_STATUS_CODE"
  })
}


export const sellerCentralClearZeroOutStatusCodeAction = () => (dispatch: any) => {
  dispatch({
    type: "SC_CLEAR_ZERO_OUT_STATUS_CODE"
  })
}


export const sellerCentralClearUpdatePriceStatusCodeAction = () => (dispatch: any) => {
  dispatch({
    type: "SC_CLEAR_UPDATE_PRICE_STATUS_CODE"
  })
}

export const sellerCentralSetDeleteStatusAction = (deleteStatus: boolean) => (dispatch: any) => {
  dispatch({
    type: "SET_SELLER_CENTRAL_DELETE_STATUS",
    payload: deleteStatus
  });
}