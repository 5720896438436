
import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class AmazonBuyboxTrackerServiceImpl {
    apiUrl = `${config.baseUrl}api/amazon-buybox`;

    getBuyboxBySku(sku: string) {
        return axios.get(`${this.apiUrl}/product/${sku}`, get_config());
    }

    getMapViolations() {
        return axios.get(`${this.apiUrl}/map-violations`, get_config());
    }

    getUnqualifiedProducts() {
        return axios.get(`${this.apiUrl}/unqualified`, get_config())
    }

    getBuyboxBySellerId(sellerId: string) {
        return axios.get(`${this.apiUrl}/missing-by-seller/${sellerId}`, get_config())
    }
}
