const initialState = {
    locales:[],
    locale_code: null,
    translated_products: null,
    products: null,
    table: null,
    item_number: null,
    product: null,
    product_translated: null,
    suggested_translation: null,
    product_request_in_progress: false,
    table_request_in_progress: false,
    
}

const createTable = (products: any, translated: any) => {

    if (products === null || translated === null) {
        return null;
    }

    const translations : any = {};
    for(let idx = 0;  idx<translated.length; idx++) {
        translations[translated[idx].item_number] = translated[idx];
    }    

    return products.map((item: any) => {      
        return {
            product: item,
            translated: translations[item.item_number]
        }
    });
};

const translationsReducer = (state=initialState, action:any) =>{

    let table = null;

    switch(action.type){
        case "SET_LOCALE_LIST":
            return {
                ...state,
                locales:action.payload
            };
        case "SET_TRANSLATED_PRODUCTS":
            table = createTable(state.products, action.payload.data);
            return {
                ...state,
                locale_code: action.payload.locale_code,
                translated_products: action.payload.data,
                table,
                table_request_in_progress: table === null
            }
        case "SET_LOCALE_PRODUCTS":
            table = createTable(state.products, action.payload.data);
            return {
                ...state,
                locale_code: action.payload.locale_code,
                products: action.payload.data,
                table,
                table_request_in_progress: table === null          
            };
        case "SET_PRODUCT_REQUEST_IN_PROGRESS":
            return {
                ...state,
                product_request_in_progress: action.payload
            };
        case "SET_PRODUCT_TABLE_IN_PROGRESS":
            return {
                ...state,
                table_request_in_progress: action.payload
            };            
        case "SET_LOCALE_PRODUCT":            
            return {
                ...state,
                product: action.payload.data,                                
            }                
        case "SET_LOCALE_TRANSLATED_PRODUCT":
            const extra : any = {}
            if (action.payload !== null) {
                extra['item_number'] = action.payload.item_number
                extra['locale_code'] = action.payload.locale_code
            }            
            return {
                ...state,
                ...extra,
                product_translated: action.payload,
                product_request_in_progress: false
            }                
        case "SET_LOCALE_SUGGESTED_TRANSLATION":
            return {
                ...state,
                suggested_translation: action.payload
            }
        case "RESET_TRANSLATION_PRODUCTS":
            return {
                ...state,
                locale_code: null,
                translated_products: null,
                products: null
            }
        case "CLEAR_LOCALE_PRODUCT":
            return {
                ...state,
                locale_code: null,
                item_number: null,
                product: null,
                product_translated: null,
                suggested_translation: null,
            }
        default:
            return state;
    }
}

export default translationsReducer;