
const initialState = {
    sponsoredProductConversionWeeklyReports: {
        page: 0,
        record_from: null,
        record_to: null,
        data: null,
        total_records: null,
        last_page: null,
        pending: false
    },
    sponsoredProductConversionMonthlyReports: {
        page: 0,
        record_from: null,
        record_to: null,
        data: null,
        total_records: null,
        last_page: null,
        pending: false
    },
    sponsoredProductAdvertisingWeeklyReports: {
        page: 0,
        record_from: null,
        record_to: null,
        data: null,
        total_records: null,
        last_page: null,
        pending: false
    },
    sponsoredProductAdvertisingMonthlyReports: {
        page: 0,
        record_from: null,
        record_to: null,
        data: null,
        total_records: null,
        last_page: null,
        pending: false
    },
    ordersDashboardWeeklyReports: {
        page: 0,
        record_from: null,
        record_to: null,
        data: null,
        total_records: null,
        last_page: null,
        pending: false
    },
    ordersDashboardMonthlyReports: {
        page: 0,
        record_from: null,
        record_to: null,
        data: null,
        total_records: null,
        last_page: null,
        pending: false
    },
    salesReports: null,
    strategyListCompany: null,
    strategies: null,
    configurationRecords: null,
    currentConfigurationRecord: null,
    
}

const overstockPpcReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case "GET_ALL_OVERSTOCKPPC_CONFIGURATIONS":
            return {
                ...state,
                configurationRecords: action.payload
            };
        case "GET_CURRENT_OVERSTOCKPPC_CONFIGURATION":
            return {
                ...state,
                currentConfigurationRecord: action.payload
            }            
        case "SET_PRODUCT_CONVERSION_WEEKLY_REPORTS":
            return {
                ...state,
                sponsoredProductConversionWeeklyReports: action.payload,
            };
        case "CLEAR_PRODUCT_CONVERSION_WEEKLY_REPORTS":
            return {
                ...state,
                sponsoredProductConversionWeeklyReports: {
                    page: 0,
                    record_from: null,
                    record_to: null,
                    data: null,
                    total_records: null,
                    last_page: null,
                    pending: true
                }
            };

        case "SET_OVERSTOCK_PPC_SALES_REPORTS":
            return {
                ...state,
                salesReports: action.payload
            }
        case "SET_PRODUCT_CONVERSION_MONTHLY_REPORTS":
            return {
                ...state,
                sponsoredProductConversionMonthlyReports: action.payload,
            };
        case "CLEAR_PRODUCT_CONVERSION_MONTHLY_REPORTS":
            return {
                ...state,
                sponsoredProductConversionMonthlyReports: {
                    page: 0,
                    record_from: null,
                    record_to: null,
                    data: null,
                    total_records: null,
                    last_page: null,
                    pending: true
                }
            };

        case "SET_PRODUCT_ADVERTISING_WEEKLY_REPORTS":
            return {
                ...state,
                sponsoredProductAdvertisingWeeklyReports: action.payload,
            };
        case "CLEAR_PRODUCT_ADVERTISING_WEEKLY_REPORTS":
            return {
                ...state,
                sponsoredProductAdvertisingWeeklyReports: {
                    page: 0,
                    record_from: null,
                    record_to: null,
                    data: null,
                    total_records: null,
                    last_page: null,
                    pending: true
                }
            };

        case "SET_PRODUCT_ADVERTISING_MONTHLY_REPORTS":
            return {
                ...state,
                sponsoredProductAdvertisingMonthlyReports: action.payload,
            };
        case "CLEAR_PRODUCT_ADVERTISING_MONTHLY_REPORTS":
            return {
                ...state,
                sponsoredProductAdvertisingMonthlyReports: {
                    page: 0,
                    record_from: null,
                    record_to: null,
                    data: null,
                    total_records: null,
                    last_page: null,
                    pending: true
                }
            };

        case "SET_ORDERS_DASHBOARD_WEEKLY_REPORTS":
            return {
                ...state,
                ordersDashboardWeeklyReports: action.payload,
            };
        case "CLEAR_ORDERS_DASHBOARD_WEEKLY_REPORTS":
            return {
                ...state,
                ordersDashboardWeeklyReports: {
                    page: 0,
                    record_from: null,
                    record_to: null,
                    data: null,
                    total_records: null,
                    last_page: null,
                    pending: true
                }
            };

        case "SET_ORDERS_DASHBOARD_MONTHLY_REPORTS":
            return {
                ...state,
                ordersDashboardMonthlyReports: action.payload,
            };
        case "CLEAR_ORDERS_DASHBOARD_MONTHLY_REPORTS":
            return {
                ...state,
                ordersDashboardMonthlyReports: {
                    page: 0,
                    record_from: null,
                    record_to: null,
                    data: null,
                    total_records: null,
                    last_page: null,
                    pending: true
                }
            };
        case "CLEAR_OVERSTOCK_PPC_STRATEGY_COMPANY":
            return {
                ...state,
                strategyListCompany: null
            };
        case "SET_OVERSTOCK_PPC_STRATEGIES":
            return {
                ...state,
                strategies: action.payload.results,
                strategyListCompany: action.payload.company
            };
        default:
            return state;
    }

}

export default overstockPpcReducer;
