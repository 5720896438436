import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getFreightClubOrderReportsAction } from '../../../redux/actions/freightClubActions';
import { createNewOrderReportAction } from '../../../redux/actions/createFreightClubReportActions';

import {Audio} from "react-loader-spinner";
import { dateTimeFormater } from '../../../utils/Helper';

import { ToastContainer, toast } from "react-toastify";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'

import './freightClub.css'
import PageHeader from '../../shared/pagesHeader';
import { AppDispatch } from '../../../redux/Store';

const FreightClub = () => {
    const [show, setShow] = useState(false);
    const [orderId, setOrderId] = useState('');

    const navigate = useNavigate()
    const dispatch : AppDispatch = useDispatch();

    const freightClubState = useSelector((state: any) => state.freightClubReducer);
    const createOrderREport = useSelector((state: any) => state.createFreightClubReportReducer);
    const { loading } = useSelector((state: any) => state.LoaderReducer);


    useEffect(() => {
        console.info("Freight Club Arrived");
        dispatch(getFreightClubOrderReportsAction(true));
    }, [dispatch])


    const error = (msg: String) =>
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const createOrderREports = () => {
        let order_id = orderId.trim().replace(/,\s*$/, "");
        const order_ids = order_id.split(',');
        dispatch(createNewOrderReportAction(order_ids));
        setShow(false);
        setOrderId('');
    }

    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        if (createOrderREport.status) {
            createOrderREport.status = false
            success("Order report succesfully created...!!!")
        };
        if (createOrderREport.error) {
            createOrderREport.error = false
            error(createOrderREport.error_message)
        }
    }, [createOrderREport])

    useEffect(() => {
        if (freightClubState.error) {
            freightClubState.error = false
            error(freightClubState.error_message)
        }
    }, [freightClubState])

    return (
        <>
            <section className="user-sec py-2">
                <div className="container">
                    <PageHeader
                    heading={"Orders Report"}
                    backUrl={"/dashboard"}
                    buttonName={"Create New Order Report"}
                    handleClick={() => setShow(true)}
                    />
                    <div className="inner-sec">
                        <div className="table_layout">
                            <div className="table-responsive">
                                {freightClubState.freight_club_reports.length > 0 && (
                                    <table className="table mb-2">
                                        <thead>
                                            <tr>
                                                <th className="cstm-td table_head">ID</th>
                                                <th className="cstm-td table_head">Status</th>
                                                <th className="cstm-td table_head">Created At</th>
                                                <th className="cstm-td table_head">Updated At</th>
                                                <th className="cstm-td table_head">Action</th>
                                            </tr>
                                            

                                        </thead>
                                        <tbody>
                                            {freightClubState.freight_club_reports.map((data: any, index: any) => (
                                                <tr key={data.id}>
                                                    <td
                                                        className="cstm-td report_id">{data.id}</td>
                                                    {data.status_code !== null ? (
                                                        <td className="cstm-td"><span className="badge status_badge success">{data.status_code}</span></td>
                                                    ) : (
                                                        <td className="cstm-td"><span className="badge status_badge danger">In progress</span></td>
                                                    )}
                                                    <td className="cstm-td">{dateTimeFormater(data.created_at)}</td>
                                                    <td className="cstm-td">{dateTimeFormater(data.updated_at)}</td>
                                                    <td className="cstm-td">
                                                        <button
                                                            onClick={() => navigate(`/freight-club/reports/deatil/${data.id}`)}
                                                            className="btn btn-primary">View</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show} size="lg" onHide={handleClose} className="add_user_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Order Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-1" controlId="formBasicUsername">
                            <Form.Label>Order ID</Form.Label>
                            <Form.Control
                                value={orderId}
                                onChange={(e) => setOrderId(e.target.value)}
                                type="text" placeholder="Enter comma seperated multiple order ID" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => createOrderREports()}>
                        Create Order Report
                    </Button>

                </Modal.Footer>
            </Modal>

            <ToastContainer autoClose={3000} />
            {loading &&
                <div className="spinner">
            <Audio
            
            height={100}
            width={100}
            color="#00BFFF"
            ariaLabel="loading"          
        />
                </div>
            }
        </>
    )
};

export default FreightClub;