
import { useState } from "react";

import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";
import SupplierForm from "../../../common/admin/wayfair/SupplierForm";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../redux/Store";
import { useDispatch } from "react-redux";

const WayfairCreateSupplier = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());
        let supplier = {
            "wayfair_supplier_id": value.wayfair_supplier_id,
            "name": value.name,
            "menu_label": value.menu_label,
            "enabled": !!value.enabled,
            "wayfair_client_id": value.wayfair_client_id,
            "wayfair_client_secret": value.wayfair_client_secret,            
            "shopify_marketplace": value.shopify_marketplace
        };

        wayfairService.createOrUpdateSupplier(supplier).then(response => {
            dispatch(stopLoadingAction());
            navigate('/admin/wayfair');
        }).catch((response) => {
            dispatch(stopLoadingAction());
            setErrorMessage(response.message);
        });

    }
    
    return (
    <>
            <div className="wayfair-admin container">
                <PageHeader
                    heading={"Wayfair - Create Supplier"}
                    backUrl={"/admin/wayfair"}
                />
                
                <SupplierForm handleSubmit={handleSubmit} errorMessage={errorMessage}/>
                
            </div>    
    
    </>)
};


export default WayfairCreateSupplier;
