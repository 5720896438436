import { useState, useMemo } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCountryListAction } from "../../../redux/actions/countryActions";
import { getShippingCostsAction } from "../../../redux/actions/shippingCostActions";
import "react-toastify/dist/ReactToastify.css";
import {Audio} from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import styled from 'styled-components';
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";

function tsToDate(ts: string) {
  let response = new Date(Date.parse(ts));
  return response.toLocaleDateString();
}

const columns = [
  {
    name: "Product SKU",
    selector: (row: any) => row.product_sku,
    sortable: true,
  },
  {
    name: "Shipping Cost",
    selector: (row: any) => row.shipment_cost,
    sortable: true,
  },
  {
    name: "Other Cost",
    selector: (row: any) => row.other_cost,
    sortable: true,
  },
  {
    name: "Total Cost",
    selector: (row: any) => row.total_cost,
    sortable: true,
  },
  {
    name: "Height",
    selector: (row: any) => row.height,
    sortable: true,
  },
  {
    name: "Weight",
    selector: (row: any) => row.weight,
    sortable: true,
  },
  {
    name: "Width",
    selector: (row: any) => row.width,
    sortable: true,
  },
  {
    name: "Length",
    selector: (row: any) => row.length,
    sortable: true,
  },
  {
    name: "Updated",
    selector: (row: any) => tsToDate(row.created_at),
    sortable: true,
  },
];


const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "25px",
      outline: "1px solid #FFFFFF",
    },
  },
};


const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;


const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;


function convertArrayOfObjectsToCSV(array: any) {
  let result: any;
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: { [x: string]: any; }) => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(data: any) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(data);
  if (csv == null) return;

  const filename = 'shippingCost.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const Export = ({ onExport }: { onExport: any }) => <Button onClick={(e: any) => onExport(e.target.value)}>Export to csv</Button>;

const FilterComponent = ({ filterText, onFilter, onClear }: { filterText: any, onFilter: any, onClear: any }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By SKU"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);


const FedexCost = () => {

  const dispatch : AppDispatch = useDispatch();

  const [country, setCountry] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true)

  const countryState = useSelector((state: any) => state.countryReducer);
  const shippingCostState = useSelector(
    (state: any) => state.shippingCostReducer
  );
  const { loading } = useSelector((state: any) => state.LoaderReducer);

  useEffect(() => {
    console.info("Shipping Cost Arrived");
    dispatch(getCountryListAction());
  }, [dispatch]);

  const handleCountryChanged = (e: any) => {
    setCountry(e.target.value);
    setButtonStatus(false)
  };

  const handleClick = () => {
    dispatch(getShippingCostsAction(country));
  };


  const filteredItems = shippingCostState.shipping_costs.filter(
    (item: { product_sku: string; }) => item.product_sku && item.product_sku.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);


  const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(filteredItems)} />, [filteredItems]);

  return (
    <>
      <section className="Country-info">
        <div className="container px-md-5">
          <PageHeader
            heading={"Shipping Cost"}
            backUrl={"/dashboard"}
          />
          <div className="row">
            <div className="col-md-12 mb-4">
              <div id="wrapper">
                <form>
                  <fieldset className="country_select d-md-flex align-items-center">
                    <label htmlFor="Select" className="form-label mb-3 me-3">
                      Country:
                    </label>
                    <div className="row w-100">
                      <div className="col-md-10">
                        <div className="mb-3">
                          <select
                            onChange={handleCountryChanged}
                            id="disabledSelect"
                            className="form-select custom-select1"
                            defaultValue=""
                          >
                            <option className="d-none" value="">Select Country</option>
                            {countryState.countries.map(
                              (data: any, index: any) => (
                                <option value={data['country_code']} key={index}>{data['country_name']}</option>
                              )
                            )}

                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button
                          onClick={handleClick}
                          type="button"
                          className="btn btn-primary custom-primary dashbord-btn"
                          disabled={buttonStatus}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="col-md-12">
              {shippingCostState.status && (
                <div className="table-responsive dash_table">
                  {shippingCostState.shipping_costs.length > 0 &&
                    !shippingCostState.pending ? (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      highlightOnHover
                      pointerOnHover
                      customStyles={customStyles}
                      subHeader
                      actions={actionsMemo}
                      subHeaderComponent={subHeaderComponentMemo}
                    />
                  ) : (
                    <div>
                      <h4>No Rates Available Now...!!!</h4>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {loading && (
        <div className="spinner">
            <Audio
            
            height={100}
            width={100}
            color="#00BFFF"
            ariaLabel="loading"          
        />
        </div>
      )}
    </>
  );
};

export default FedexCost;
