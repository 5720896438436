const initialState = {
    currentUserProfile: null,
    status:false,
    updated:false
}

const userProfilesReducer = (state=initialState, action:any) =>{
    switch(action.type){
        case "GET_CURRENT_USER_PROFILE":
            return {
                ...state,
                currentUserProfile:action.payload,
                status:true
              };
        
        case "UPDATE_CURRENT_USER_PROFILE":
            return {
                ...state,
                currentUserProfile:action.payload,
                status:true,
                updated:true
            };
        default:
            return state;
    }
}

export default userProfilesReducer;