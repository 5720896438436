import { AmazonBuyboxTrackerServiceImpl } from "./amazonBuyboxTrackerService";
import { AmazonContentVerificationServiceImpl } from "./amazonContentVerificationService";
import { AmazonSellerCentralServiceImpl } from "./amazonSellerCentralService";
import { AuthServiceImpl } from "./authService";
import { ConfigurationServiceImpl } from "./configurationService";
import { FedexShippingCostServiceImpl } from "./fedexShippingCostService";
import { FreightClubServiceImpl } from "./freightClubService";
import { HmCatalogServiceImpl } from "./hmCatalogService";
import { HmProductImagesServiceImpl } from "./hmProductImagesService";
import { ProductRestockNotificationsServiceImpl } from "./productRestockNotificationsService";
import { ShipmentTrackingServiceImpl } from "./shipmentTrackingService";
import { UserProfileServiceImpl } from "./userProfileService";
import { TranslationServiceImpl } from "./translationService";
import { OverstockPPCServiceImpl } from "./overstockPPCService";
import { InventoryHoldsServiceImpl } from "./inventoryHoldsService";
import { TemporalServiceImpl } from "./temporalService";
import { WayfairServiceImpl } from "./wayfairService";
import { FicServiceImpl } from "./ficService";
import { ShopifyStoreServiceImpl } from "./shopifyStoreService";
import { MapViolationsServiceImpl } from "./mapViolationsService";

export const authService = new AuthServiceImpl();
export const fedexShippingCostService = new FedexShippingCostServiceImpl();
export const productRestockNotificationsService =
  new ProductRestockNotificationsServiceImpl();
export const userProfileService = new UserProfileServiceImpl();
export const freightClubService = new FreightClubServiceImpl();
export const amazonSellerCentralService = new AmazonSellerCentralServiceImpl();
export const amazonContentVerificationService =
  new AmazonContentVerificationServiceImpl();
export const amazonBuyboxTrackerService = new AmazonBuyboxTrackerServiceImpl();
export const hmCatalogService = new HmCatalogServiceImpl();
export const configurationService = new ConfigurationServiceImpl();
export const shipmentTrackingService = new ShipmentTrackingServiceImpl();
export const hmProductImagesService = new HmProductImagesServiceImpl();
export const translationsService = new TranslationServiceImpl();
export const overstockPPCService = new OverstockPPCServiceImpl();
export const inventoryHoldsService = new InventoryHoldsServiceImpl();
export const temporalService = new TemporalServiceImpl();
export const wayfairService = new WayfairServiceImpl();
export const ficService = new FicServiceImpl();
export const shopifyStoreService = new ShopifyStoreServiceImpl();
export const mapViolationsService = new MapViolationsServiceImpl();

authService.init();
