

export const startLoadingAction = () => (dispatch: any) => {
    dispatch({
        type: "START_LOADING"
    });
};

export const stopLoadingAction = () => (dispatch: any) => {
    dispatch({
        type: "STOP_LOADING"
    });
};
