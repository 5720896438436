import { loginAction } from "./authActions";
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { userProfileService } from "../../service";

export const updateCurrentUserProfileAction = (email: any, password: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  userProfileService.updateUserProfile(email, password).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "UPDATE_CURRENT_USER_PROFILE",
      payload: response.data.user_data
    });
    if (email && password) {
      dispatch(loginAction(email, password));
    }
  });
};

export const getCurrentUserProfileAction = () => (dispatch: any) => {
  dispatch(startLoadingAction());
  userProfileService.getUserProfile().then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_CURRENT_USER_PROFILE",
      payload: response.data.user_data
    });
  });
};
