import { useEffect, useState } from "react";
import Select from "react-select";
import PageHeader from "../../shared/pagesHeader";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { lastDateOfMonth, monthsAdd } from "../../../utils/Helper";

import { overstockPPCService } from "../../../service";
import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../redux/actions/loaderActions";
import { AppDispatch } from "../../../redux/Store";
import { ToastContainer, toast } from "react-toastify";
import {
  getOrdersDashboardMonthlyReportsAction,
  getOrdersDashboardWeeklyReportsAction,
  getSalesReportsAction,
  getSponsoredProductAdvertisingMonthlyReportsAction,
  getSponsoredProductAdvertisingWeeklyReportsAction,
  getSponsoredProductConversionMonthlyReportsAction,
  getSponsoredProductConversionWeeklyReportsAction,
  getStrategiesAction,
} from "../../../redux/actions/overstockPpcActions";
import OverstockPPCReportsTable from "./OverstockPPCReportsTable";

import { RotatingLines } from "react-loader-spinner";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { TimeScale } from "chart.js";

import "chartjs-adapter-moment";

ChartJS.register(TimeScale);

const OverstockPPCReports = () => {
  const dispatch: AppDispatch = useDispatch();

  const [uploadFormErrorMessage, setUploadFormErrorMessage] = useState(
    null as string | null
  );
  const [exportErrorMessage, setExportErrorMessage] = useState(
    null as string | null
  );
  const [uploadFormWarningMessages, setUploadFormWarningMessages] = useState(
    []
  );
  const [uploadFormStartDate, setUploadFormStartDate] = useState("");
  const [uploadFormEndDate, setUploadFormEndDate] = useState("");
  const [uploadFormReportFile, setUploadFormReportFile] = useState(
    null as File | null
  );
  const [excelFormFile, setExcelFormFile] = useState(null as File | null);
  const [uploadFormReportType, setUploadFormReportType] = useState("");
  const [showUploadReportForm, setShowUploadReportForm] = useState(false);
  const [showUploadCatalogForm, setShowUploadCatalogForm] = useState(false);
  const [showExportForm, setShowExportForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState("weekly");
  const [selectedStrategy, setSelectedStrategy] = useState(null as any | null);
  const [company, setCompany] = useState("HP");
  const overstockPpcState = useSelector(
    (state: any) => state.overstockPpcReducer
  );

  const weeklyReportTypes = [
    "sponsored_product_conversion_weekly",
    "sponsored_product_advertising_weekly",
    "orders_dashboard_weekly",
  ];

  const weeklyReportFunctions = [
    getSponsoredProductConversionWeeklyReportsAction,
    getSponsoredProductAdvertisingWeeklyReportsAction,
    getOrdersDashboardWeeklyReportsAction
  ];

  const weeklyReportData = [
    overstockPpcState.sponsoredProductConversionWeeklyReports,
    overstockPpcState.sponsoredProductAdvertisingWeeklyReports,
    overstockPpcState.ordersDashboardWeeklyReports
  ];

  const monthlyReportTypes = [
    "sponsored_product_conversion_monthly",
    "sponsored_product_advertising_monthly",
    "orders_dashboard_monthly",
  ];

  const monthlyReportFunctions = [
    getSponsoredProductConversionMonthlyReportsAction,
    getSponsoredProductAdvertisingMonthlyReportsAction,
    getOrdersDashboardMonthlyReportsAction
  ];

  const monthlyReportData = [
    overstockPpcState.sponsoredProductConversionMonthlyReports,
    overstockPpcState.sponsoredProductAdvertisingMonthlyReports,
    overstockPpcState.ordersDashboardMonthlyReports
  ];

  useEffect(() => {
    console.info("Overstock PPC Sponsored Product Conversion Monthly Arrived");
    if (
      (
        overstockPpcState.sponsoredProductConversionMonthlyReports.data ===
        null ||
        overstockPpcState.sponsoredProductConversionMonthlyReports.company !==
        company
      ) && overstockPpcState.sponsoredProductConversionMonthlyReports.pending !== true
    ) {
      dispatch(getSponsoredProductConversionMonthlyReportsAction(company, 1));
    }
  }, [
    dispatch,
    overstockPpcState.sponsoredProductConversionMonthlyReports.data,
    overstockPpcState.sponsoredProductConversionMonthlyReports.company,
    overstockPpcState.sponsoredProductConversionMonthlyReports.pending,
    company,
  ]);

  useEffect(() => {
    console.info("Overstock PPC Sales Reports");
    if (
      overstockPpcState.salesReports === null ||
      overstockPpcState.salesReports.company !== company
    ) {
      dispatch(getSalesReportsAction(company));
    }
  }, [dispatch, overstockPpcState.salesReports, company]);

  useEffect(() => {
    console.info("Overstock PPC Sponsored Product Advertising Monthly Arrived");
    if (
      (
        overstockPpcState.sponsoredProductAdvertisingMonthlyReports.data ===
        null ||
        overstockPpcState.sponsoredProductAdvertisingMonthlyReports.company !==
        company
      ) && overstockPpcState.sponsoredProductAdvertisingMonthlyReports.pending !== true
    ) {
      dispatch(getSponsoredProductAdvertisingMonthlyReportsAction(company, 1));
    }
  }, [
    dispatch,
    overstockPpcState.sponsoredProductAdvertisingMonthlyReports.data,
    overstockPpcState.sponsoredProductAdvertisingMonthlyReports.company,
    overstockPpcState.sponsoredProductAdvertisingMonthlyReports.pending,
    company,
  ]);

  useEffect(() => {
    console.info("Overstock PPC Orders Dashboard Monthly Arrived");
    if (

      (
        overstockPpcState.ordersDashboardMonthlyReports.data === null ||
        overstockPpcState.ordersDashboardMonthlyReports.company !== company
      ) && overstockPpcState.ordersDashboardMonthlyReports.pending !== true
    ) {
      dispatch(getOrdersDashboardMonthlyReportsAction(company, 1));
    }
  }, [
    dispatch,
    overstockPpcState.ordersDashboardMonthlyReports.data,
    overstockPpcState.ordersDashboardMonthlyReports.company,
    overstockPpcState.ordersDashboardMonthlyReports.pending,
    company,
  ]);

  useEffect(() => {
    console.info("Overstock PPC Sponsored Product Conversion Weekly Arrived");
    if (
      (
        overstockPpcState.sponsoredProductConversionWeeklyReports.data === null ||
        overstockPpcState.sponsoredProductConversionWeeklyReports.company !==
        company
      ) && overstockPpcState.sponsoredProductConversionWeeklyReports.pending !== true

    ) {
      dispatch(getSponsoredProductConversionWeeklyReportsAction(company, 1));
    }
  }, [
    dispatch,
    overstockPpcState.sponsoredProductConversionWeeklyReports.data,
    overstockPpcState.sponsoredProductConversionWeeklyReports.company,
    overstockPpcState.sponsoredProductConversionWeeklyReports.pending,
    company,
  ]);

  useEffect(() => {
    console.info("Overstock PPC Sponsored Product Advertising Weekly Arrived");
    if (
      (
        overstockPpcState.sponsoredProductAdvertisingWeeklyReports.data ===
        null ||
        overstockPpcState.sponsoredProductAdvertisingWeeklyReports.company !==
        company
      ) && overstockPpcState.sponsoredProductAdvertisingWeeklyReports.pending !== true
    ) {
      dispatch(getSponsoredProductAdvertisingWeeklyReportsAction(company, 1));
    }
  }, [
    dispatch,
    overstockPpcState.sponsoredProductAdvertisingWeeklyReports.data,
    overstockPpcState.sponsoredProductAdvertisingWeeklyReports.company,
    overstockPpcState.sponsoredProductAdvertisingWeeklyReports.pending,
    company,
  ]);

  useEffect(() => {
    console.info("Overstock PPC Orders Dashboard Weekly Arrived");
    if (
      (
        overstockPpcState.ordersDashboardWeeklyReports.data === null ||
        overstockPpcState.ordersDashboardWeeklyReports.company !== company
      ) && overstockPpcState.ordersDashboardWeeklyReports.pending !== true
    ) {
      dispatch(getOrdersDashboardWeeklyReportsAction(company, 1));
    }
  }, [
    dispatch,
    overstockPpcState.ordersDashboardWeeklyReports.data,
    overstockPpcState.ordersDashboardWeeklyReports.company,
    overstockPpcState.ordersDashboardWeeklyReports.pending,
    company,
  ]);

  useEffect(() => {

    console.info("Overstock PPC Strategies Arrived");

    const rule1 = overstockPpcState.strategies === null;
    const rule2 = overstockPpcState.strategyListCompany !== null && overstockPpcState.strategyListCompany !== company;

    if (
      rule1 || rule2
    ) {
      dispatch(getStrategiesAction(company));
    }
  }, [dispatch, overstockPpcState.strategies, overstockPpcState.strategyListCompany, company]);

  const success = (msg: String) =>
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const resetUploadFormErrorMessages = () => {
    setUploadFormErrorMessage(null);
    setUploadFormWarningMessages([]);
  };

  const resetExportErrorMessages = () => {
    setUploadFormErrorMessage(null);
    setUploadFormWarningMessages([]);
  };

  const handleReportChange = (e: any) => {
    if (uploadFormErrorMessage !== null) {
      resetUploadFormErrorMessages();
    }

    setUploadFormReportFile(e.target.files[0]);
  };

  const handleExcelFormChange = (e: any) => {
    if (exportErrorMessage !== null) {
      resetExportErrorMessages();
    }

    setExcelFormFile(e.target.files[0]);
  };

  const handleDateStartChange = (e: any) => {
    if (uploadFormErrorMessage !== null) {
      resetUploadFormErrorMessages();
    }

    let start_date = e.target.value;
    let end_date = "";

    if (weeklyReportTypes.indexOf(uploadFormReportType) !== -1) {
      let unix_start_date = Date.parse(start_date);
      if (!Number.isNaN(unix_start_date)) {
        let week_ts = new Date(unix_start_date).getUTCDay() * 86400000;
        start_date = new Date(unix_start_date - week_ts)
          .toISOString()
          .slice(0, 10);
        end_date = new Date(Date.parse(start_date) + 518400000)
          .toISOString()
          .slice(0, 10);
      }
    }

    if (monthlyReportTypes.indexOf(uploadFormReportType) !== -1) {
      let unix_start_date = Date.parse(start_date);
      if (!Number.isNaN(unix_start_date)) {
        start_date = new Date(
          unix_start_date -
          (new Date(unix_start_date).getUTCDate() - 1) * 86400000
        )
          .toISOString()
          .slice(0, 10);
        end_date = lastDateOfMonth(new Date(Date.parse(start_date)))
          .toISOString()
          .slice(0, 10);
      }
    }

    setUploadFormStartDate(start_date);
    setUploadFormEndDate(end_date);
  };

  const nextPage = (tableName: string) => {

    return (e: any) => {
      const weeklyReportIndex = weeklyReportTypes.indexOf(tableName);
      const monthlyReportIndex = monthlyReportTypes.indexOf(tableName);

      if (weeklyReportIndex >= 0) {
        if (weeklyReportData[weeklyReportIndex].page < weeklyReportData[weeklyReportIndex].last_page) {
          const nextPageFunction = weeklyReportFunctions[weeklyReportIndex];
          dispatch(nextPageFunction(company, weeklyReportData[weeklyReportIndex].page + 1));
        }
      }

      if (monthlyReportIndex >= 0) {
        if (monthlyReportData[weeklyReportIndex].page < monthlyReportData[weeklyReportIndex].last_page) {
          const nextPageFunction = monthlyReportFunctions[monthlyReportIndex];
          dispatch(nextPageFunction(company, monthlyReportData[monthlyReportIndex].page + 1));
        }
      }
    };

  };


  const prevPage = (tableName: string) => {

    return (e: any) => {
      const weeklyReportIndex = weeklyReportTypes.indexOf(tableName);
      const monthlyReportIndex = monthlyReportTypes.indexOf(tableName);

      if (weeklyReportIndex >= 0) {
        if (weeklyReportData[weeklyReportIndex].page > 1) {
          const prevPageFunction = weeklyReportFunctions[weeklyReportIndex];
          dispatch(prevPageFunction(company, weeklyReportData[weeklyReportIndex].page - 1));
        }
      }

      if (monthlyReportIndex >= 0) {
        if (monthlyReportData[weeklyReportIndex].page > 1) {
          const prevPageFunction = monthlyReportFunctions[monthlyReportIndex];
          dispatch(prevPageFunction(company, monthlyReportData[monthlyReportIndex].page - 1));
        }
      }

    }

  };

  const handleDateEndChange = (e: any) => {
    if (uploadFormErrorMessage !== null) {
      resetUploadFormErrorMessages();
    }

    let start_date = "";
    let end_date = e.target.value;

    if (weeklyReportTypes.indexOf(uploadFormReportType) !== -1) {
      let unix_end_date = Date.parse(end_date);
      if (!Number.isNaN(unix_end_date)) {
        let week_ts_adj = (6 - new Date(unix_end_date).getDay()) * 86400000;
        end_date = new Date(unix_end_date + week_ts_adj)
          .toISOString()
          .slice(0, 10);
        start_date = new Date(Date.parse(end_date) - 518400000)
          .toISOString()
          .slice(0, 10);
      }
    }

    if (monthlyReportTypes.indexOf(uploadFormReportType) !== -1) {
      let unix_end_date = Date.parse(end_date);
      if (!Number.isNaN(unix_end_date)) {
        end_date = lastDateOfMonth(new Date(unix_end_date))
          .toISOString()
          .slice(0, 10);
        unix_end_date = Date.parse(end_date);
        start_date = new Date(
          unix_end_date - (new Date(unix_end_date).getDate() - 1) * 86400000
        )
          .toISOString()
          .slice(0, 10);
      }
    }

    setUploadFormStartDate(start_date);
    setUploadFormEndDate(end_date);
  };

  const handleDateStartKeyDown = (e: any) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      let direction = e.keyCode === 38 ? 1 : -1;

      if (uploadFormErrorMessage !== null) {
        resetUploadFormErrorMessages();
      }

      let start_date = e.target.value;
      let end_date = "";

      if (weeklyReportTypes.indexOf(uploadFormReportType) !== -1) {
        let unix_start_date = Date.parse(start_date);
        if (!Number.isNaN(unix_start_date)) {
          unix_start_date = unix_start_date + direction * 604800000;
          let week_ts = new Date(unix_start_date).getUTCDay() * 86400000;
          start_date = new Date(unix_start_date - week_ts)
            .toISOString()
            .slice(0, 10);
          end_date = new Date(Date.parse(start_date) + 518400000)
            .toISOString()
            .slice(0, 10);
        }
      }

      if (monthlyReportTypes.indexOf(uploadFormReportType) !== -1) {
        let unix_start_date = Date.parse(start_date);
        if (!Number.isNaN(unix_start_date)) {
          unix_start_date = monthsAdd(
            new Date(unix_start_date),
            direction
          ).getTime();
          start_date = new Date(
            unix_start_date -
            (new Date(unix_start_date).getUTCDate() - 1) * 86400000
          )
            .toISOString()
            .slice(0, 10);
          end_date = lastDateOfMonth(new Date(Date.parse(start_date)))
            .toISOString()
            .slice(0, 10);
        }
      }

      setUploadFormStartDate(start_date);
      setUploadFormEndDate(end_date);
    }
  };

  const handleDateEndKeyDown = (e: any) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      let direction = e.keyCode === 38 ? 1 : -1;
      if (uploadFormErrorMessage !== null) {
        resetUploadFormErrorMessages();
      }

      let start_date = "";
      let end_date = e.target.value;

      if (weeklyReportTypes.indexOf(uploadFormReportType) !== -1) {
        let unix_end_date = Date.parse(end_date);
        if (!Number.isNaN(unix_end_date)) {
          unix_end_date = unix_end_date + direction * 604800000;
          let week_ts_adj = (6 - new Date(unix_end_date).getDay()) * 86400000;
          end_date = new Date(unix_end_date + week_ts_adj)
            .toISOString()
            .slice(0, 10);
          start_date = new Date(Date.parse(end_date) - 518400000)
            .toISOString()
            .slice(0, 10);
        }
      }

      if (monthlyReportTypes.indexOf(uploadFormReportType) !== -1) {
        let unix_end_date = Date.parse(end_date);
        if (!Number.isNaN(unix_end_date)) {
          unix_end_date = monthsAdd(
            new Date(unix_end_date),
            direction
          ).getTime();
          end_date = lastDateOfMonth(new Date(unix_end_date))
            .toISOString()
            .slice(0, 10);
          unix_end_date = Date.parse(end_date);
          start_date = new Date(
            unix_end_date - (new Date(unix_end_date).getDate() - 1) * 86400000
          )
            .toISOString()
            .slice(0, 10);
        }
      }

      setUploadFormStartDate(start_date);
      setUploadFormEndDate(end_date);
    }
  };

  const onUploadClicked = (reportType: string) => (e: any) => {
    resetUploadFormErrorMessages();
    setUploadFormStartDate("");
    setUploadFormEndDate("");

    if (reportType === "catalog") {
      setShowUploadReportForm(false);
      setShowUploadCatalogForm(true);
    } else {
      setUploadFormReportType(reportType);
      setShowUploadReportForm(true);
      setShowUploadCatalogForm(false);
    }
  };

  const onExportClicked = () => (e: any) => {
    setShowExportForm(true);
  };

  const onExportProcessClicked = (e: any) => {
    resetExportErrorMessages();

    dispatch(startLoadingAction());

    if (excelFormFile === null) {
      setExportErrorMessage("Excel form file is required");
    } else if (selectedStrategy === null) {
      setExportErrorMessage("PPC strategy is required");
    } else {
      overstockPPCService
        .applyStrategy(company, excelFormFile, selectedStrategy.strategy_code)
        .then((response) => {
          dispatch(stopLoadingAction());
          success("Processing complete!");
          setShowExportForm(false);

          let filename =
            response.headers["content-disposition"].split("filename=")[1];

          var file_data = new Blob([response.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(file_data);
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          dispatch(stopLoadingAction());
          setExportErrorMessage(error.message);
        });
    }
  };

  const onUploadSaveClicked = (e: any) => {
    if (uploadFormStartDate === "") {
      setUploadFormErrorMessage("Report start date is required");
      return;
    }

    if (uploadFormEndDate === "") {
      setUploadFormErrorMessage("Report end date is required");
      return;
    }

    if (uploadFormReportFile === null) {
      setUploadFormErrorMessage("Report end date is required");
      return;
    }

    resetUploadFormErrorMessages();
    dispatch(startLoadingAction());

    overstockPPCService
      .uploadReport(
        company,
        uploadFormStartDate,
        uploadFormEndDate,
        uploadFormReportType,
        uploadFormReportFile
      )
      .then((response) => {
        switch (uploadFormReportType) {
          case "sponsored_product_conversion_weekly":
            dispatch(
              getSponsoredProductConversionWeeklyReportsAction(company, 1)
            );
            break;
          case "sponsored_product_advertising_weekly":
            dispatch(
              getSponsoredProductAdvertisingWeeklyReportsAction(company, 1)
            );
            break;
          case "orders_dashboard_weekly":
            dispatch(getOrdersDashboardWeeklyReportsAction(company, 1));
            break;
          case "sponsored_product_conversion_monthly":
            dispatch(
              getSponsoredProductConversionMonthlyReportsAction(company, 1)
            );
            break;
          case "sponsored_product_advertising_monthly":
            dispatch(
              getSponsoredProductAdvertisingMonthlyReportsAction(company, 1)
            );
            break;
          case "orders_dashboard_monthly":
            dispatch(getOrdersDashboardMonthlyReportsAction(company, 1));
            break;
          default:
            console.warn("Unknown report type", uploadFormReportType);
            break;
        }
        dispatch(stopLoadingAction());
        success("Upload complete!");
        setShowUploadReportForm(false);
      })
      .catch((error) => {
        dispatch(stopLoadingAction());

        if ("undefined" !== typeof error.response.data.error_message) {
          setUploadFormErrorMessage(error.response.data.error_message);
          if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
            setUploadFormWarningMessages(error.response.data.warning_messages);
            console.error("warnings", error.response.data.warning_messages);
          }
        } else {
          setUploadFormErrorMessage(error.message);
        }
      });
  };

  const onUploadCatalogSaveClicked = (e: any) => {
    if (uploadFormReportFile === null) {
      setUploadFormErrorMessage("Catalog file required");
      return;
    }

    resetUploadFormErrorMessages();
    dispatch(startLoadingAction());

    overstockPPCService
      .uploadCatalog(company, uploadFormReportFile)
      .then((response) => {
        dispatch(stopLoadingAction());
        success("Upload complete!");
        setShowUploadCatalogForm(false);
      })
      .catch((error) => {
        dispatch(stopLoadingAction());

        if ("undefined" !== typeof error.response.data.error_message) {
          setUploadFormErrorMessage(error.response.data.error_message);
          if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
            setUploadFormWarningMessages(error.response.data.warning_messages);
            console.error("warnings", error.response.data.warning_messages);
          }
        } else {
          setUploadFormErrorMessage(error.message);
        }
      });
  };

  const createSetTabHandler = (selectTab: string) => {
    return (e: any) => {
      setSelectedTab(selectTab);
    };
  };

  const createSetCompanyHandler = (new_company: string) => {
    return (e: any) => {
      if (company !== new_company) {
        setCompany(new_company);

        dispatch(
          getSponsoredProductConversionWeeklyReportsAction(new_company, 1)
        );
        dispatch(
          getSponsoredProductAdvertisingWeeklyReportsAction(new_company, 1)
        );
        dispatch(getOrdersDashboardWeeklyReportsAction(new_company, 1));
        dispatch(
          getSponsoredProductConversionMonthlyReportsAction(new_company, 1)
        );
        dispatch(
          getSponsoredProductAdvertisingMonthlyReportsAction(new_company, 1)
        );
        dispatch(getOrdersDashboardMonthlyReportsAction(new_company, 1));
        dispatch(getSalesReportsAction(new_company));

      }
    };
  };

  const formTitles: any = {
    sponsored_product_conversion_weekly:
      "Upload Sponsored Product Conversion (Weekly)",
    sponsored_product_conversion_monthly:
      "Upload Sponsored Product Conversion (Monthly)",
    sponsored_product_advertising_weekly:
      "Upload Sponsored Product Advertising (Weekly)",
    sponsored_product_advertising_monthly:
      "Upload Sponsored Product Advertising (Monthly)",
    orders_dashboard_weekly: "Upload Orders Dashboard (Weekly)",
    orders_dashboard_monthly: "Upload Orders Dashboard (Monthly)",
  };

  return (
    <div className="container">
      <PageHeader
        heading={"Overstock PPC Reports"}
        backUrl={"/dashboard"}
        buttonName={"Export"}
        handleClick={onExportClicked()}
        buttonIcon={"fa-download"}
      />
      <hr />

      <ul className="nav nav-tabs">
        <li className="nav-item">
          {company === "HP" ? (
            <span
              className="nav-link active"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetCompanyHandler("HP")}
            >
              Howard Miller
            </span>
          ) : (
            <span
              className="nav-link"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetCompanyHandler("HP")}
            >
              Howard Miller
            </span>
          )}
        </li>
        <li className="nav-item">
          {company === "HB" ? (
            <span
              className="nav-link active"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetCompanyHandler("HB")}
            >
              Hekman
            </span>
          ) : (
            <span
              className="nav-link"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetCompanyHandler("HB")}
            >
              Hekman
            </span>
          )}
        </li>
      </ul>

      <ul className="nav nav-tabs tabs-below">
        <li className="nav-item">
          {selectedTab === "weekly" ? (
            <span
              className="nav-link active"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetTabHandler("weekly")}
            >
              Weekly
            </span>
          ) : (
            <span
              className="nav-link"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetTabHandler("weekly")}
            >
              Weekly
            </span>
          )}
        </li>
        <li className="nav-item">
          {selectedTab === "monthly" ? (
            <span
              className="nav-link active"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetTabHandler("monthly")}
            >
              Monthly
            </span>
          ) : (
            <span
              className="nav-link"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetTabHandler("monthly")}
            >
              Monthly
            </span>
          )}
        </li>
        <li className="nav-item">
          {selectedTab === "sales" ? (
            <span
              className="nav-link active"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetTabHandler("sales")}
            >
              Sales
            </span>
          ) : (
            <span
              className="nav-link"
              style={{ cursor: "pointer" }}
              aria-current="page"
              onClick={createSetTabHandler("sales")}
            >
              Sales
            </span>
          )}
        </li>
      </ul>

      {selectedTab === "weekly" && (
        <div style={{ textAlign: "left" }}>
          <div className="row" style={{ marginTop: "12pt" }}>
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked("catalog")}
              >
                Upload Catalog
              </button>
            </div>
          </div>

          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Sponsored Product Conversion</h2>
          </div>
          <div className="row">
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked("sponsored_product_conversion_weekly")}
              >
                Upload Weekly Report
              </button>
            </div>
          </div>

          {overstockPpcState.sponsoredProductConversionWeeklyReports.data !==
            null ? (
            <>
              <OverstockPPCReportsTable
                rows={
                  overstockPpcState.sponsoredProductConversionWeeklyReports.data
                }
              />

              {overstockPpcState.sponsoredProductConversionWeeklyReports.data.length > 0 && (
                <div className="row">
                  <div className="col-md-2">
                    {overstockPpcState.sponsoredProductConversionWeeklyReports.page > 1 ? (
                      <button className="btn btn-link" onClick={prevPage("sponsored_product_conversion_weekly")}><i className="fas fa-chevron-left"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                    )}

                  </div>
                  <div className="col-md-2">Page {overstockPpcState.sponsoredProductConversionWeeklyReports.page} of {overstockPpcState.sponsoredProductConversionWeeklyReports.last_page}</div>

                  <div className="col-md-2">

                    {overstockPpcState.sponsoredProductConversionWeeklyReports.page < overstockPpcState.sponsoredProductConversionWeeklyReports.last_page ? (
                      <button className="btn btn-link" onClick={nextPage("sponsored_product_conversion_weekly")}><i className="fas fa-chevron-right"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                    )}
                  </div>
                </div>
              )}
            </>

          ) : (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="54"
              visible={true}
            />
          )}

          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Sponsored Product Advertising</h2>
          </div>
          <div className="row">
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked(
                  "sponsored_product_advertising_weekly"
                )}
              >
                Upload Weekly Report
              </button>
            </div>
          </div>

          {overstockPpcState.sponsoredProductAdvertisingWeeklyReports.data !==
            null ? (
            <>
              <OverstockPPCReportsTable
                rows={
                  overstockPpcState.sponsoredProductAdvertisingWeeklyReports.data
                }
              />

              {overstockPpcState.sponsoredProductAdvertisingWeeklyReports.data.length > 0 && (
                <div className="row">
                  <div className="col-md-2">
                    {overstockPpcState.sponsoredProductAdvertisingWeeklyReports.page > 1 ? (
                      <button className="btn btn-link" onClick={prevPage("sponsored_product_advertising_weekly")}><i className="fas fa-chevron-left"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                    )}

                  </div>
                  <div className="col-md-2">Page {overstockPpcState.sponsoredProductAdvertisingWeeklyReports.page} of {overstockPpcState.sponsoredProductAdvertisingWeeklyReports.last_page}</div>

                  <div className="col-md-2">

                    {overstockPpcState.sponsoredProductAdvertisingWeeklyReports.page < overstockPpcState.sponsoredProductAdvertisingWeeklyReports.last_page ? (
                      <button className="btn btn-link" onClick={nextPage("sponsored_product_advertising_weekly")}><i className="fas fa-chevron-right"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                    )}
                  </div>
                </div>
              )}
            </>


          ) : (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="54"
              visible={true}
            />
          )}

          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Orders Dashboard</h2>
          </div>
          <div className="row">
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked("orders_dashboard_weekly")}
              >
                Upload Weekly Report
              </button>
            </div>
          </div>

          {overstockPpcState.ordersDashboardWeeklyReports.data !== null ? (
            <>
              <OverstockPPCReportsTable
                rows={overstockPpcState.ordersDashboardWeeklyReports.data}
              />

              {overstockPpcState.ordersDashboardWeeklyReports.data.length > 0 && (
                <div className="row">
                  <div className="col-md-2">
                    {overstockPpcState.ordersDashboardWeeklyReports.page > 1 ? (
                      <button className="btn btn-link" onClick={prevPage("orders_dashboard_weekly")}><i className="fas fa-chevron-left"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                    )}

                  </div>
                  <div className="col-md-2">Page {overstockPpcState.ordersDashboardWeeklyReports.page} of {overstockPpcState.ordersDashboardWeeklyReports.last_page}</div>

                  <div className="col-md-2">

                    {overstockPpcState.ordersDashboardWeeklyReports.page < overstockPpcState.ordersDashboardWeeklyReports.last_page ? (
                      <button className="btn btn-link" onClick={nextPage("orders_dashboard_weekly")}><i className="fas fa-chevron-right"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="54"
              visible={true}
            />
          )}
        </div>
      )}

      {selectedTab === "monthly" && (
        <div style={{ textAlign: "left" }}>
          <div className="row" style={{ marginTop: "12pt" }}>
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked("catalog")}
              >
                Upload Catalog
              </button>
            </div>
          </div>

          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Sponsored Product Conversion</h2>
          </div>

          <div className="row">
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked(
                  "sponsored_product_conversion_monthly"
                )}
              >
                Upload Monthly Report
              </button>
            </div>
          </div>

          {overstockPpcState.sponsoredProductConversionMonthlyReports.data !==
            null ? (
            <>
              <OverstockPPCReportsTable
                rows={
                  overstockPpcState.sponsoredProductConversionMonthlyReports.data
                }
              />
              {overstockPpcState.sponsoredProductConversionMonthlyReports.data.length > 0 && (
                <div className="row">
                  <div className="col-md-2">
                    {overstockPpcState.sponsoredProductConversionMonthlyReports.page > 1 ? (
                      <button className="btn btn-link" onClick={prevPage("sponsored_product_conversion_monthly")}><i className="fas fa-chevron-left"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                    )}

                  </div>
                  <div className="col-md-2">Page {overstockPpcState.sponsoredProductConversionMonthlyReports.page} of {overstockPpcState.sponsoredProductConversionMonthlyReports.last_page}</div>

                  <div className="col-md-2">

                    {overstockPpcState.sponsoredProductConversionMonthlyReports.page < overstockPpcState.sponsoredProductConversionMonthlyReports.last_page ? (
                      <button className="btn btn-link" onClick={nextPage("sponsored_product_conversion_monthly")}><i className="fas fa-chevron-right"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="54"
              visible={true}
            />
          )}

          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Sponsored Product Advertising</h2>
          </div>
          <div className="row">
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked(
                  "sponsored_product_advertising_monthly"
                )}
              >
                Upload Monthly Report
              </button>
            </div>
          </div>

          {overstockPpcState.sponsoredProductAdvertisingMonthlyReports.data !==
            null ? (
            <>
              <OverstockPPCReportsTable
                rows={
                  overstockPpcState.sponsoredProductAdvertisingMonthlyReports.data
                }
              />
              {overstockPpcState.sponsoredProductAdvertisingMonthlyReports.data.length > 0 && (
                <div className="row">
                  <div className="col-md-2">
                    {overstockPpcState.sponsoredProductAdvertisingMonthlyReports.page > 1 ? (
                      <button className="btn btn-link" onClick={prevPage("sponsored_product_advertising_monthly")}><i className="fas fa-chevron-left"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                    )}

                  </div>
                  <div className="col-md-2">Page {overstockPpcState.sponsoredProductAdvertisingMonthlyReports.page} of {overstockPpcState.sponsoredProductAdvertisingMonthlyReports.last_page}</div>

                  <div className="col-md-2">

                    {overstockPpcState.sponsoredProductAdvertisingMonthlyReports.page < overstockPpcState.sponsoredProductAdvertisingMonthlyReports.last_page ? (
                      <button className="btn btn-link" onClick={nextPage("sponsored_product_advertising_monthly")}><i className="fas fa-chevron-right"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="54"
              visible={true}
            />
          )}

          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Orders Dashboard</h2>
          </div>
          <div className="row">
            <div className="col-md-12" aria-label="Upload">
              <button
                className="btn btn-primary"
                onClick={onUploadClicked("orders_dashboard_monthly")}
              >
                Upload Monthly Report
              </button>
            </div>
          </div>

          {overstockPpcState.ordersDashboardMonthlyReports.data !== null ? (
            <>
              <OverstockPPCReportsTable
                rows={overstockPpcState.ordersDashboardMonthlyReports.data}
              />
              {overstockPpcState.ordersDashboardMonthlyReports.data.length > 0 && (
                <div className="row">
                  <div className="col-md-2">
                    {overstockPpcState.ordersDashboardMonthlyReports.page > 1 ? (
                      <button className="btn btn-link" onClick={prevPage("orders_dashboard_monthly")}><i className="fas fa-chevron-left"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-left"></i></button>
                    )}

                  </div>
                  <div className="col-md-2">Page {overstockPpcState.ordersDashboardMonthlyReports.page} of {overstockPpcState.ordersDashboardMonthlyReports.last_page}</div>

                  <div className="col-md-2">

                    {overstockPpcState.ordersDashboardMonthlyReports.page < overstockPpcState.ordersDashboardMonthlyReports.last_page ? (
                      <button className="btn btn-link" onClick={nextPage("orders_dashboard_monthly")}><i className="fas fa-chevron-right"></i></button>
                    ) : (
                      <button className="btn btn-link disabled"><i className="fas fa-chevron-right"></i></button>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="54"
              visible={true}
            />
          )}
        </div>
      )}

      {selectedTab === "sales" && (
        <div style={{ textAlign: "left" }}>
          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Monthly Sales</h2>
            {overstockPpcState.salesReports !== null &&
              overstockPpcState.salesReports.company === company ? (
              <Chart
                type={"line"}
                options={{
                  scales: {
                    x: {
                      type: "time",
                    },
                  },
                }}
                data={{
                  labels: overstockPpcState.salesReports.monthly.dates,
                  datasets: [
                    {
                      label: "Gross Sales",
                      data: overstockPpcState.salesReports.monthly.total_sales,
                    },
                  ],
                }}
              />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="54"
                    visible={true}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="row" style={{ marginTop: "12pt" }}>
            <h2 className="col-md-12">Weekly Sales</h2>
            {overstockPpcState.salesReports !== null &&
              overstockPpcState.salesReports.company === company ? (
              <Chart
                type={"line"}
                options={{
                  scales: {
                    x: {
                      type: "time",
                    },
                  },
                }}
                data={{
                  labels: overstockPpcState.salesReports.weekly.dates,
                  datasets: [
                    {
                      label: "Gross Sales",
                      data: overstockPpcState.salesReports.weekly.total_sales,
                    },
                  ],
                }}
              />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="54"
                    visible={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        show={showUploadReportForm}
        dialogClassName="modal-lg"
        aria-labelledby="example-modal-sizes-title-lg"
        onHide={() => setShowUploadReportForm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {formTitles[uploadFormReportType]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadFormErrorMessage ? (
            <>
              <div className="row">
                <div className="col-md-10 offset-md-1 alert alert-danger">
                  {uploadFormErrorMessage}
                </div>
              </div>
              {uploadFormWarningMessages.map((warningMessage, idx) => {
                return (
                  <div className="row" key={idx}>
                    <div className="col-md-10 offset-md-1 alert alert-warning">
                      {warningMessage}
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
          <div className="row">
            <div className="col-md-4">
              <p>Date Start</p>
            </div>
            <div className="col-md-8">
              <div className="md-form md-outline input-with-post-icon datepicker">
                <input
                  placeholder="Select date"
                  type="date"
                  onKeyDown={handleDateStartKeyDown}
                  onChange={handleDateStartChange}
                  value={uploadFormStartDate}
                  className="form-control mb-30"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p>Date End</p>
            </div>
            <div className="col-md-8">
              <div className="md-form md-outline input-with-post-icon datepicker">
                <input
                  placeholder="Select date"
                  type="date"
                  onKeyDown={handleDateEndKeyDown}
                  onChange={handleDateEndChange}
                  value={uploadFormEndDate}
                  className="form-control mb-30"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p>Report</p>
            </div>
            <div className="col-md-8">
              <input
                onChange={handleReportChange}
                type="file"
                className="form-control mb-30 detail_content"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={onUploadSaveClicked}>
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUploadCatalogForm}
        dialogClassName="modal-lg"
        aria-labelledby="example-modal-sizes-title-lg"
        onHide={() => setShowUploadCatalogForm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Upload Catalog
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadFormErrorMessage ? (
            <>
              <div className="row">
                <div className="col-md-10 offset-md-1 alert alert-danger">
                  {uploadFormErrorMessage}
                </div>
              </div>
              {uploadFormWarningMessages.map((warningMessage, idx) => {
                return (
                  <div className="row" key={idx}>
                    <div className="col-md-10 offset-md-1 alert alert-warning">
                      {warningMessage}
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}

          <div className="row">
            <div className="col-md-4">
              <p>Catalog</p>
            </div>
            <div className="col-md-8">
              <input
                onChange={handleReportChange}
                type="file"
                className="form-control mb-30 detail_content"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={onUploadCatalogSaveClicked}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showExportForm}
        dialogClassName="modal-lg"
        aria-labelledby="example-modal-sizes-title-lg"
        onHide={() => setShowExportForm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Export Strategy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-md-4">
                <p>Strategy</p>
              </div>
              <div className="col-md-8">
                <Select
                  className="mb-30"
                  options={overstockPpcState.strategies}
                  value={selectedStrategy}
                  onChange={setSelectedStrategy}
                  getOptionLabel={(strategy: any) => strategy.name}
                  getOptionValue={(strategy: any) => strategy.strategy_code}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>Excel form</p>
              </div>
              <div className="col-md-8">
                <input
                  onChange={handleExcelFormChange}
                  type="file"
                  className="form-control mb-30 detail_content"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={onExportProcessClicked}>
            Process
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default OverstockPPCReports;
