
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { shipmentTrackingService } from "../../service";


export const getFedexFicupRatesAction = (loading_status: any = null) => (dispatch: any) => {
  if (loading_status) {
    dispatch(startLoadingAction());
  }  
  shipmentTrackingService.getFedexFicupRates().then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_FEDEX_FICUP_RATES",
      payload: response.data
    });
  });
};


export const getFedexFicupLabelStatus = (loading_status: any = null) => (dispatch: any) => {
  if (loading_status) {
    dispatch(startLoadingAction());
  }  
  shipmentTrackingService.getHmToshipQueueByCarrier(323).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "GET_FEDEX_FICUP_LABEL_STATUS",
      payload: response.data
    });
  });
};
