import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import { AppDispatch } from "../../../redux/Store";

import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../redux/actions/loaderActions";
import { getAccountAction, getCustomersWithAccountsAction } from "../../../redux/actions/ficActions";
import { ficService } from "../../../service";
import PageHeader from "../../shared/pagesHeader";
import { useNavigate, useParams } from "react-router-dom";
import CustomerForm from "../../common/customers/CustomerForm";

const HmCustomer = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");

  const params: any = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const ficState = useSelector((state: any) => state.ficReducer);

  const handleSubmit = (value: any) => {
    setErrorMessage("");
    dispatch(startLoadingAction());
    let customer = {
      id: value.id,
      customer_name: value.customer_name,
      company: value.company,
      jba_customer_no: value.customer_no,
      contact_name: value.contact_name,
      contact_addr_1: value.contact_addr_1,
      contact_addr_2: value.contact_addr_2,
      contact_city: value.contact_city,
      contact_state: value.contact_state,
      contact_country: value.contact_country,
      postal_code: value.postal_code,
      shipping_contact_email: value.shipping_contact_email,
      memo: value.memo,
    };

    ficService
      .createOrUpdateCustomer(customer)
      .then((response) => {
        dispatch(startLoadingAction());
        navigate("/customer");
      })
      .catch((response) => {
        setErrorMessage(response.message);
        dispatch(stopLoadingAction());
      });
  };

  const handleDeleteAccountClick = (id: string) => (event: any) => {
    if (window.confirm("Are you sure you want to delete this account?")) {
      dispatch(startLoadingAction());
      ficService
        .deleteAccounts([id])
        .then((response) => {
          dispatch(getAccountAction(params.id));
        })
        .catch((response) => {
          dispatch(stopLoadingAction());
          setErrorMessage(response.message);
        });
        navigate(`/customer/${params.id}`);
    }
  };

  const handleCreateAccountClick = (event: any) => {
    navigate(`/customer/${params.id}/create-account`);
  };

  useEffect(() => {
    console.info("HM Customer Arrived");
    dispatch(getCustomersWithAccountsAction(params.id));
  }, [dispatch, params.id]);


  return (
    <>
      <div className="container">
        <PageHeader
          heading={"HM Customer"}
          backUrl={"/customer"}
        />

        <CustomerForm
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          customer={ficState.currentCustomer}
        />

        {!ficState.customerAccountsLoading && ficState.customerAccounts && (
          <div className="form-align">
            <h2>Accounts</h2>

            {ficState.customerAccounts.total > 0 ? (
              <>
                {ficState.customerAccounts.records.map((account: any) => {
                  return (
                    <div className="row" key={account.id}>
                      <div className="col-md-12">
                        <button
                          className="btn btn-link"
                          onClick={handleDeleteAccountClick(account.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                        <button
                          className="btn btn-link"
                          onClick={() =>
                            navigate(
                              `/customer/${params.id}/account/${account.id}`
                            )
                          }
                        >
                          {account.customer_dseq}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <p>No accounts found</p>
            )}

            <div className="row">
              <div className="col-md-12">
                <button
                  onClick={handleCreateAccountClick}
                  className="add_new save_btn rounded-pill"
                >
                  <i className={`fas fa-plus me-2`}></i>Create Account
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default HmCustomer;
