

import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";

export class TranslationServiceImpl {

    apiBaseUrl = `${config.baseUrl}api/translations`;

    getLocaleList() {
        return axios.get(`${this.apiBaseUrl}/locale`, get_config());
    }

    getTranslatedProducts(localeCode: string) {
        return axios.get(`${this.apiBaseUrl}/locale/${localeCode}/content-translated`, get_config());
    }

    getProducts(localeCode: string) {
        return axios.get(`${this.apiBaseUrl}/locale/${localeCode}/products?ordering=item_number`, get_config());
    }

    getProductsByItemNumber(localeCode: string, itemNumber: string) {
        return axios.get(`${this.apiBaseUrl}/locale/${localeCode}/product-details-list?item_number=${itemNumber}`, get_config());
    }

    getTranslatedProductsByItemNumber(localeCode: string, itemNumber: string) {
        return axios.get(`${this.apiBaseUrl}/locale/${localeCode}/content-translated?item_number=${itemNumber}`, get_config());
    }

    getSuggestedTranslationsByItemNumber(localeCode: string, itemNumber: string) {
        return axios.get(`${this.apiBaseUrl}/locale/${localeCode}/suggested-translations?item_number=${itemNumber}`, get_config());
    }

    updateTranslation(localeCode: string, itemNumber: string, translation: any) {
        const data: any = {
            'locale_code': localeCode,
            'item_number': itemNumber,
            'translation': translation,

        }
        return axios.post(`${this.apiBaseUrl}/update-translation`, data, get_config());
    }

    resetTranslation(localeCode: string, itemNumber: string) {
        const data: any = {
            'locale_code': localeCode,
            'item_number': itemNumber,
        }
        return axios.post(`${this.apiBaseUrl}/reset-translation`, data, get_config());
    }

}