import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { AppDispatch } from "../../../../redux/Store";


import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";
import { getFabricXrefAction } from "../../../../redux/actions/wayfairActions";
import { wayfairService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";

import FabricXrefForm from "../../../common/admin/wayfair/FabricXrefForm";

const WayfairFabricXref = (props: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const params: any = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);

    const getBackUrl = () => {
        if (searchParams.get('returnTo') === 'order-errors') {
            return `/admin/wayfair/order-errors`;
        }
        return `/admin/wayfair/fabric-mappings`;
    };

    const handleSubmit = (value: any) => {
        setErrorMessage('');
        dispatch(startLoadingAction());

        let has_id = !!value.id;

        let hm_product_status = value.hm_product_status === '__null' ? null : value.hm_product_status;

        let hm_frame = !!value.no_frame ? "NO Frame" : value.hm_frame;

        let fabricXref = has_id ? {
            "id": value.id,
            "supplierpartnumber": value.supplierpartnumber,
            "hm_fabric_number": value.hm_fabric_number,
            "hm_frame": hm_frame,
            "hm_product_status": hm_product_status,
            "hm_order_sku": value.hm_order_sku,
            "is_memocut": !!value.is_memocut && hm_frame === "NO Frame"
        } : {
            "supplierpartnumber": value.supplierpartnumber,
            "hm_fabric_number": value.hm_fabric_number,
            "hm_frame": hm_frame,
            "hm_product_status": hm_product_status,
            "hm_order_sku": value.hm_order_sku,
            "is_memocut": !!value.is_memocut && hm_frame === "NO Frame"
        };


        wayfairService.createOrUpdateFabricXref(fabricXref).then(response => {
            dispatch(stopLoadingAction());
            navigate(getBackUrl());
        }).catch((response) => {
            setErrorMessage(response.message);
            dispatch(stopLoadingAction());
        });

    };

    const onDeleteMappingClicked = () => (event: any) => {
        if (window.confirm("Are you sure you want to delte this mapping? This action cannot be undone!")) {
            dispatch(startLoadingAction());
            wayfairService.deleteFabricMappings([wayfairState.currentFabricXref.id]).then(response => {
                dispatch(stopLoadingAction());
                navigate(getBackUrl());
            }).catch(error => {
                dispatch(stopLoadingAction());
            });
        }
    };


    useEffect(() => {
        console.info("Wayfair Fabric Xref Arrived", params.supplierpartnumber);
        dispatch(getFabricXrefAction(params.supplierpartnumber))
    }, [dispatch, params.supplierpartnumber]);

    console.log("form",wayfairState.currentFabricXref);
    return (
        <>
            <div className="container">
                <PageHeader
                    heading={"Wayfair Fabric Cross-reference"}
                    backUrl={getBackUrl()}
                    buttonIcon={"fa-minus-square"}
                    buttonName={"Delete Mapping"}
                    handleClick={onDeleteMappingClicked()}
                />

                <FabricXrefForm handleSubmit={handleSubmit} errorMessage={errorMessage} fabricXref={wayfairState.currentFabricXref} />

            </div>

        </>)
};

export default WayfairFabricXref;

