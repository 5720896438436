import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";

import { startLoadingAction, stopLoadingAction } from "../../../redux/actions/loaderActions";
import { getFedexFicupLabelStatus } from "../../../redux/actions/fedexFicupActions";
import { shipmentTrackingService } from "../../../service";


const FedexFicupLabelStatus = (props: any) => {

    const dispatch: AppDispatch = useDispatch();

    const fedexFicupState = useSelector((state: any) => state.fedexFicupReducer);


    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });


    useEffect(() => {
        console.info("Fedex Ficup Label Status Arrived");
        dispatch(getFedexFicupLabelStatus(true));
    }, [dispatch]);


    const renderStatus = (status: string) => {

        switch (status) {
            case null:
            case "":
                return (
                    <span className="text-warning"><i className="far fa-hourglass"></i></span>
                );
            case "LABEL_GENERATED":
                return (
                    <span className="text-success" title="Label generated"><i className="fas fa-print"></i></span>
                );
            case "ERROR_GENERATING_LABEL":
                return (
                    <span className="text-danger" title="Error"><i className="fas fa-exclamation-triangle"></i></span>

                );
            case "ERROR_ALREADY_SHIPPED":
                return (
                    <span className="text-danger" title="Already shipped"><i className="fas fa-shipping-fast"></i></span>
                );
            case "FORCE_GENERATE_LABEL_PENDING":
                return (
                    <span className="text-primary" title="Generate label requested"><i className="far fa-clock"></i></span>
                );
        }

        return status;
    };

    const renderTimestamp = (ts: string) => {
        const timestamp = new Date(Date.parse(ts));
        return timestamp.toLocaleString();
    };

    const renderDate = (d: string) => {
        const timestamp = new Date(Date.parse(d));
        return timestamp.toLocaleDateString();
    };

    const reload = () => {
        dispatch(getFedexFicupLabelStatus(true));
    }

    const forceGenerateLabel = (rowId: string) => {
        dispatch(startLoadingAction());
        shipmentTrackingService.forceGenerateFicLabel(rowId).then(response => {
            dispatch(stopLoadingAction());
            success("Label generated");
            setTimeout(reload, 1000);
        }).catch((err: any) => {
            dispatch(stopLoadingAction());
        });
    }

    const generateLabel = (rowId: string) => (e: any) => {
        if (window.confirm("Are you sure you want to generate a FIC label for this shipped item?")) {
            forceGenerateLabel(rowId);
        }
    };

    const regenerateLabel = (rowId: string) => (e: any) => {
        if (window.confirm("Are you sure you want to generate another FIC label for this item?")) {
            forceGenerateLabel(rowId);
        }
    };

    const retryGenerateLabel = (rowId: string) => (e: any) => {
        if (window.confirm("Are you sure you want to retry generating a FIC label for this item?")) {
            success("Label generated");
            forceGenerateLabel(rowId);
        }
    };

    const renderActions = (row: any) => {
        if (row.label_status === 'ERROR_ALREADY_SHIPPED') {
            return (<button className="btn btn-link" onClick={generateLabel(row.id)} title="Generate label"><i className="fas fa-print"></i></button>)
        }

        if (row.label_status === 'LABEL_GENERATED') {
            return (<button className="btn btn-link" onClick={regenerateLabel(row.id)} title="Regenerate label"><i className="fas fa-redo"></i></button>)
        }

        if (row.label_status === 'ERROR_GENERATING_LABEL') {
            return (<button className="btn btn-link" onClick={retryGenerateLabel(row.id)} title="Generate label"><i className="fas fa-redo"></i></button>)
        }

        return null;
    };


    return (
        <>
            <section className="user-pro mt-3">
                <div className="container">
                    <PageHeader
                        heading={"Fedex ficup label status"}
                        backUrl={"/admin"}
                    />
                </div>
            </section>

            <div className="container">

                <div className="row justify-content-center align-items-center mt-3">

                    <div className="table_layout">

                        <div className="table-responsive">
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Order Date</th>
                                        <th>Release Date</th>
                                        <th>Country</th>
                                        <th>Company</th>
                                        <th>Order</th>
                                        <th>SKU</th>
                                        <th>PO</th>
                                        <th>Partner</th>
                                        <th>Tariff Code</th>
                                        <th>Status</th>
                                        <th>Error</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fedexFicupState.label_status !== null ? fedexFicupState.label_status.map((row: any) => {
                                        return (

                                            <tr>
                                                <td>{renderDate(row.system_order_date)}</td>
                                                <td>{renderTimestamp(row.created_at)}</td>
                                                <td>{row.ship_to_country}</td>
                                                <td>{row.company}</td>
                                                <td>{row.order_no}</td>
                                                <td>{row.sku}</td>
                                                <td>{row.customer_po}</td>
                                                <td>{row.customer_no}/{row.customer_dseq}</td>
                                                <td>{row.tariff_code}</td>
                                                <td>{renderStatus(row.label_status)}</td>
                                                <td>{row.error_message}</td>
                                                <td>{renderActions(row)}</td>

                                            </tr>

                                        )
                                    }) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer autoClose={3000} />

        </>
    )
}

export default FedexFicupLabelStatus;