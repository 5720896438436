import { combineReducers } from "redux";
import authReducer from "./authReducer";
import countryReducer from "./countryReducer";
import shippingCostReducer from "./shippingCostReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import userRoleReducer from "./userRoleReducer";
import adminSettingReducer from "./adminSettingsReducer";
import LoaderReducer from "./loaderReducer";
import adminUserReducer from "./adminUserReducer";
import userProfilesReducer from "./userProfilesReducer";
import adminDeleteUserReducer from "./adminDeleteUserReducer";
import freightClubReducer from "./freightClubReducer";
import amazonSellerCentralReducer from "./amazonSellerCentralReducer";
import fedexDebugReducer from "./fedexDebugReducer";
import amazonContentVerificationReducer from "./amazonContentVerificationReducer";
import amazonBuyBoxTrackerReducer from "./amazonBuyboxTrackerReducer";
import hmCatalogsReducer from "./hmCatalogsReducer";
import shipingTrackingReducer from "./shipingTrackingReducer";
import createFreightClubReportReducer from "./createFreightClubReportReducer";
import freightClubReportDetailsReducer from "./freightClubReportDetailsReducer";
import imagesReducer from "./imagesReducer";
import translationsReducer from "./translationsReducer";
import overstockPpcReducer from "./overstockPpcReducer";
import inventoryHoldsReducer from "./inventoryHoldsReducer";
import tradeshowConfigReducer from "./tradeshowConfigReducer";
import fedexFicupReducer from "./fedexFicupReducer";
import temporalReducer from "./temporalReducer";
import wayfairReducer from "./wayfairReducer";
import ficReducer from "./ficReducer";
import shopifyStoreReducer from "./shopifyStoreReducer";
import mapViolationsReducer from "./mapViolationsReducer";

export const rawReducers = {
  authReducer,
  countryReducer,
  shippingCostReducer,
  subscriptionsReducer,
  userRoleReducer,
  adminSettingReducer,
  LoaderReducer,
  adminUserReducer,
  userProfilesReducer,
  adminDeleteUserReducer,
  freightClubReducer,
  freightClubReportDetailsReducer,
  createFreightClubReportReducer,
  amazonSellerCentralReducer,
  fedexDebugReducer,
  amazonContentVerificationReducer,
  amazonBuyBoxTrackerReducer,
  hmCatalogsReducer,
  shipingTrackingReducer,
  imagesReducer,
  translationsReducer,
  overstockPpcReducer,
  inventoryHoldsReducer,
  tradeshowConfigReducer,
  fedexFicupReducer,
  temporalReducer,
  wayfairReducer,
  ficReducer,
  shopifyStoreReducer,
  mapViolationsReducer
};

const allReducers = combineReducers(rawReducers);

export default allReducers;
