import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { fedexDebugShippingAction } from "../../../redux/actions/fedexDebugActions";
import { useDispatch, useSelector } from "react-redux";
import { getCountryListAction } from "../../../redux/actions/countryActions";
import "./admin.css";

import { toast } from "react-toastify";

import { JsonViewer } from '@textea/json-viewer';
import PageHeader from "../../shared/pagesHeader";

import { ocean } from "../../common/jsonViewerTheme"
import { AppDispatch } from "../../../redux/Store";

const FedexDebugShippingCost = (props: any) => {
  const [countryCode, setCountryCode] = useState("");
  const [productSKU, setProductSKU] = useState("");

  const dispatch: AppDispatch = useDispatch();
  const fedexDebugState = useSelector(
    (state: any) => state.fedexDebugReducer
  );
  const countryState = useSelector((state: any) => state.countryReducer);

  useEffect(() => {
    console.info("Fedex Debug Arrived");
    dispatch(getCountryListAction());
  }, [dispatch]);

  const fedexDebugSaveClick = () => {
    if (countryCode !== "" && productSKU !== "") {
      dispatch(fedexDebugShippingAction(countryCode, productSKU));
    } else {
      error("Country and product sku both are required...!!!");
    }
  };

  useEffect(() => {
    if (fedexDebugState.errorCode !== null) {
      error(fedexDebugState.error);
    }
    if (fedexDebugState.status === true) {
      success("Success");
    }
  }, [fedexDebugState]);

  const error = (msg: String) =>
    toast.error(msg, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const success = (msg: String) =>
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_CENTER,
    });


  return (
    <>
      <section className="user-pro mt-3">
        <div className="container">
          <PageHeader
            heading={"Fedex Debug"}
            backUrl={"/admin"}
          />

          <div className="row justify-content-center align-items-center mt-3">
            <div className="col-lg-8 col-md-8">
              <div className="subdomain p-5 text-start">
                <div className="container">
                  <Form.Group className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      <option>Select Country</option>
                      {countryState.countries.map((data: any, index: any) => (
                        <option
                          key={data.country_code}
                          value={data.country_code}
                        >
                          {data.country_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product SKU</Form.Label>
                    <Form.Control
                      placeholder="Enter Product SKU"
                      value={productSKU}
                      onChange={(e) => setProductSKU(e.target.value)}
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={fedexDebugSaveClick}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {fedexDebugState.status && (
            <div className="container mt-5">
              <h3>Response</h3>
              <div className="pt-4">
                <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-data-tab" data-bs-toggle="pill" data-bs-target="#pills-data" type="button" role="tab" aria-controls="pills-data" aria-selected="true">Data</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-records-tab" data-bs-toggle="pill" data-bs-target="#pills-records" type="button" role="tab" aria-controls="pills-records" aria-selected="false">Records</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-request-tab" data-bs-toggle="pill" data-bs-target="#pills-request" type="button" role="tab" aria-controls="pills-request" aria-selected="false">Request</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-response-tab" data-bs-toggle="pill" data-bs-target="#pills-response" type="button" role="tab" aria-controls="pills-request" aria-selected="false">Response</button>
                  </li>
                </ul>
                <hr className="mt-0" />
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active text-start" id="pills-data" role="tabpanel" aria-labelledby="pills-data-tab">
                    <div>
                      <JsonViewer theme={ocean} value={fedexDebugState.data} />
                    </div>
                  </div>
                  <div className="tab-pane fade text-start" id="pills-records" role="tabpanel" aria-labelledby="pills-records-tab">
                    <div>
                      <JsonViewer theme={ocean} value={fedexDebugState.record} />
                    </div>
                  </div>
                  <div className="tab-pane fade text-start" id="pills-request" role="tabpanel" aria-labelledby="pills-request-tab">
                    <div>
                      <JsonViewer theme={ocean} value={fedexDebugState.request} />
                    </div>
                  </div>
                  <div className="tab-pane fade text-start" id="pills-response" role="tabpanel" aria-labelledby="pills-response-tab">
                    <div>
                      <JsonViewer theme={ocean} value={JSON.parse(fedexDebugState.response)} />
                      {/* {fedexDebugReducer.data.data.response} */}
                    </div>
                  </div>
                </div>
              </div>



            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default FedexDebugShippingCost;
