
import '../page.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserProfileAction, updateCurrentUserProfileAction } from '../../../redux/actions/userProfilesActions'
import { ToastContainer, toast } from "react-toastify";
import {Audio} from "react-loader-spinner";
import { checkValidEmail } from '../../../utils/Helper';
import PageHeader from '../../shared/pagesHeader';
import { AppDispatch } from '../../../redux/Store';

const UserProfile = () => {

	const dispatch : AppDispatch = useDispatch();
	const userProfileState = useSelector((state: any) => state.userProfilesReducer);
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('12345678')
	const [username, setUsername] = useState('')
	const [editBtn, setEditBtn] = useState(false)
	const [rePassword, setRePassword] = useState('')
	const [desibledBtn, setDesibledBtn] = useState(true)
	const [editEmail, setEditEmail] = useState(false)
	const [editPassword, setEditPassword] = useState(false)
	const [passwordMatch, setPasswordMath] = useState(true)
	const [editEmailShow, setEditEmailShow] = useState(true)
	const [editPasswordShow, setEditPasswordShow] = useState(true)

	const { loading } = useSelector((state: any) => state.LoaderReducer);


	const warning = (msg: String) =>
		toast.warning(msg, {
			position: toast.POSITION.BOTTOM_CENTER,
		});

	const success = (msg: String) =>
		toast.success(msg, {
			position: toast.POSITION.BOTTOM_CENTER,
		});


	useEffect(() => {
		console.info("Profile Arrived");
		dispatch(getCurrentUserProfileAction())
	}, [dispatch])

	useEffect(() => {
		if(userProfileState.currentUserProfile){
			setEmail(userProfileState.currentUserProfile.email)
			setUsername(userProfileState.currentUserProfile.username)
		}
		
	}, [userProfileState])

	useEffect(() => {
		if (userProfileState.updated) {
			userProfileState.updated = false
			setDesibledBtn(true)
			setEditEmail(false)
			setEditPassword(false)
			setEditPasswordShow(true)
			setEditEmailShow(true)
			setPassword('12345678')
			if(userProfileState.currentUserProfile){
				setEmail(userProfileState.currentUserProfile.email)
				setUsername(userProfileState.currentUserProfile.username)
			}
			
			success("Profile Succesfully Updated")
		}
	}, [userProfileState])

	useEffect(() => {
		if (editEmail) {
			if (email.length < 5) {
				setDesibledBtn(true)
			} else {
				setDesibledBtn(false)
			}
		}
		if (editPassword && editEmail) {
			if (password === rePassword && email.length > 5) {
				setDesibledBtn(false)
			} else {
				setDesibledBtn(true)
			}
		}
		if (editPassword) {
			if (password === rePassword && password.length > 1) {
				setDesibledBtn(false)
			} else {
				setDesibledBtn(true)
			}
		}
		if (rePassword.length > 0) {
			if (password !== rePassword) {
				setPasswordMath(false)
			} else {
				setPasswordMath(true)
			}
		}
	}, [email, editEmail, rePassword, password, editPassword])

	const saveDetails = () => {
		if (editPassword || editEmail) {
			if (editEmail && editPassword) {
				if (!checkValidEmail(email)) {
					warning('Invalid Email')
				} else {
					dispatch(updateCurrentUserProfileAction(email, password))
					setEditBtn(false)
				}
			} else if (!editEmail && editPassword) {
				dispatch(updateCurrentUserProfileAction(userProfileState.currentUserProfile.email, password))
				setEditBtn(false)
			} else if (editEmail && !editPassword) {
				if (!checkValidEmail(email)) {
					warning('Invalid Email')
				} else {
					dispatch(updateCurrentUserProfileAction(email, null))
					setEditBtn(false)
				}
			}
		} else {
			warning("Please select what you want to update")
		}
	}

	const rePasswordChange = (e: any) => {
		setRePassword(e.target.value)
	}

	const editClick = () => {
		if (editPassword || editEmail) {
			setPassword('')
			setRePassword('')
			if (!editEmail) {
				setEditEmailShow(false)
			} else {
				setEditBtn(true)
			}
			if (!editPassword) {
				setEditPasswordShow(false)
			} else {
				setEditBtn(true)
			}
		} else {
			warning("Please select what you want to Update")
		}
	}


	return (
		<>
			<section className="user-pro mt-3">
				<div className="container">
					<PageHeader
					heading={"User Profile"}
					backUrl={"/dashboard"}
					/>
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-8">
							<div className="subdomain p-5 text-start">
								<form>
									<div className="mb-3">
										<label htmlFor="exampleInputUsername" className="form-label">User Name</label>
										<input
											type="Username" value={username} className="form-control" id="exampleInputUsername" aria-describedby="UsernameHelp" readOnly />
									</div>
									{editPasswordShow && (
										<div className="mb-3">
											<label htmlFor="exampleInputPassword1" className="form-label">Password</label>
											<div className="input-group mb-3">
												<div className="input-group-text">
													<input
														onChange={() => setEditPassword(!editPassword)}
														className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"
														checked={editPassword}
													/>
												</div>
												<input
													onChange={(e) => setPassword(e.target.value)}
													type="password" value={password} className="form-control" id="exampleInputPassword1"
													readOnly={!editPassword}
												/>
											</div>
										</div>
									)}
									{editBtn && editPassword && (
										<div className="mb-3">
											<label htmlFor="exampleInputPassword1" className="form-label">Re-Password</label>
											<input
												onChange={(e) => rePasswordChange(e)}
												value={rePassword}
												type="password" className="form-control" id="exampleInputPassword1" />
											{!passwordMatch && (
												<label htmlFor="exampleInputPassword1" className="form-label password_match_check"
												><p>Password not match...!!!</p></label>
											)}
										</div>
									)}

									{editEmailShow && (
										<div className="mb-3">
											<label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
											<div className="input-group mb-3">
												<div className="input-group-text">
													<input
														onChange={() => setEditEmail(!editEmail)}
														className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"
														checked={editEmail}
													/>
												</div>
												<input
													onChange={(e) => setEmail(e.target.value)}
													type="email" value={email} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
													readOnly={!editEmail}
												/>
											</div>
										</div>
									)}

									{!editBtn && (
										<div className="button-sec d-flex justify-content-center mt-5">
											<button
												onClick={() => {
													editClick()

												}}
												type="button" className="btn btn-primary">Edit</button>
										</div>
									)}
									{editBtn && (
										<div className="button-sec d-flex justify-content-center mt-5">
											<button
												onClick={saveDetails}
												type="button" disabled={desibledBtn} className="btn btn-primary">Save Details</button>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ToastContainer autoClose={3000} />

			{loading &&
				<div className="spinner">
            <Audio
            
			height={100}
			width={100}
			color="#00BFFF"
			ariaLabel="loading"          
		/>
				</div>
			}
		</>
	)
}


export default UserProfile