// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.freight-club-id :hover {
    cursor: pointer;
}

.report_id :hover {
    cursor: pointer !important;
}

.product-details-buttom{
    padding-top: 30px;
    
}
/* .btn{
    margin-right: 60px;
} */
.logs{
    padding-top: 30px;
}
.fa-trash{
    color: red;
}
.max-width-900
{
    max-width: 900px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/FreightClub/freightClub.css"],"names":[],"mappings":";AACA;IACI,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;;AAErB;AACA;;GAEG;AACH;IACI,iBAAiB;AACrB;AACA;IACI,UAAU;AACd;AACA;;IAEI,gBAAgB;AACpB","sourcesContent":["\n.freight-club-id :hover {\n    cursor: pointer;\n}\n\n.report_id :hover {\n    cursor: pointer !important;\n}\n\n.product-details-buttom{\n    padding-top: 30px;\n    \n}\n/* .btn{\n    margin-right: 60px;\n} */\n.logs{\n    padding-top: 30px;\n}\n.fa-trash{\n    color: red;\n}\n.max-width-900\n{\n    max-width: 900px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
