const useroleState = {
    is_admin: false,
    is_superuser: false,
    active: false,
}

export const userRoleReducer = (state = useroleState, action: any) => {
    switch (action.type) {
        case "GET_USER_ROLE":

            for (const role of action.payload.data) {
                localStorage.setItem(role['user_role'], 'true')
            }
            localStorage.setItem('active', action.payload.active);
            return {
                ...state,
                is_admin: action.payload.is_admin,
                active: action.payload.active,
            };
        case "SET_ROLE_INACTIVE":
            return {
                ...state,
                active: false
            }
        default:
            return state;
    }
}

export default userRoleReducer;
