import { getFreightClubOrderReportsAction } from "./freightClubActions";
import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { freightClubService } from "../../service";


export const createNewOrderReportAction = (orderIds: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    freightClubService.createOrdersReport(orderIds).then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "CREATE_NEW_ORDER_REPORT",
            payload: response.data
        });
        dispatch(getFreightClubOrderReportsAction(false));
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "CREATE_NEW_ORDER_REPORT_ERROR",
            status: err.response.status
        });
    });
};
