
import { useEffect, useState } from "react";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const WayfairOrderSkuExceptionMapForm = (props: any) => {

    const [validated, setValidated] = useState(false);
    const [wayfairOrderSkuExceptionMap, setWayfairOrderSkuExceptionMap] = useState({} as any);


    useEffect(() => {
        console.info("WayfairOrderSkuExceptionMapForm Arrived");

        if (props.wayfairOrderSkuExceptionMap) {
            setWayfairOrderSkuExceptionMap({
                ...props.wayfairOrderSkuExceptionMap,
            });
        } else {
            setWayfairOrderSkuExceptionMap({
                "id": null,
                "wayfair_customer_po": props.wayfairCustomerPo,
                "wayfair_supplier_part_number": props.wayfairSupplierPartNumber,
                "hm_order_sku": null,
            });
        }

    }, [props.wayfairOrderSkuExceptionMap, props.wayfairCustomerPo, props.wayfairSupplierPartNumber]);


    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        let isValid = true;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            isValid = false;
        }

        setValidated(true);

        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries());

        if (isValid && props.handleSubmit) {
            event.preventDefault();
            event.stopPropagation();
            props.handleSubmit(formDataObj);
        }
    };

    const handleFormFieldUpdate = (event: any) => {
        let formData = { ...wayfairOrderSkuExceptionMap }
        formData[event.target.name] = event.target.value
        setWayfairOrderSkuExceptionMap(formData);
    };



    return (
        <div className="form-align">


            {props.errorMessage && (
                <Alert variant={"danger"}>
                    {props.errorMessage}
                </Alert>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Control type="hidden" name="id" value={wayfairOrderSkuExceptionMap.id || ''} />

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="wayfair_customer_po">
                            <Form.Label>Customer PO</Form.Label>
                            <Form.Control type="text" placeholder="Customer po" name="wayfair_customer_po" value={wayfairOrderSkuExceptionMap.wayfair_customer_po} onChange={handleFormFieldUpdate} readOnly={true} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="wayfair_supplier_part_number">
                            <Form.Label>Supplier Part Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter supplier part number" name="wayfair_supplier_part_number" value={wayfairOrderSkuExceptionMap.wayfair_supplier_part_number} onChange={handleFormFieldUpdate} readOnly={true} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-6" controlId="hm_order_sku">
                            <Form.Label>SKU</Form.Label>
                            <Form.Control type="text" placeholder="Enter SKU" name="hm_order_sku" value={wayfairOrderSkuExceptionMap.hm_order_sku || ''} onChange={handleFormFieldUpdate} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3" style={{ "marginTop": "2ex" }}>
                        <Button variant="primary" type="submit" className="mb-3">
                            Save
                        </Button>
                    </div>
                </div>
            </Form >
        </div >
    )
}

export default WayfairOrderSkuExceptionMapForm;