import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { mapViolationsService } from "../../service";

export const getReportedMapViolationsAction = (loader: boolean, limit: number, offset: number, is_closed: boolean|null) => (dispatch: any) => {
    if (loader) {
        dispatch(startLoadingAction());
    }
    mapViolationsService.getReportedViolations(limit, offset, is_closed).then(response => {
        if (loader) {
            dispatch(stopLoadingAction());
        }
        dispatch({
            type: "GET_REPORTED_MAP_VIOLATIONS",
            payload: {
                data: response.data,
                limit,
                offset,
                is_closed
            }
        });
    }).catch(err => {
        if (loader) {
            dispatch(stopLoadingAction());
        }
    })
}