import "react-toastify/dist/ReactToastify.css";
import "./page.css";
import { useNavigate } from "react-router-dom";
import {
  isFreightClubUser,
  isAmazonVerificationUser,
  isAmazonBuyboxUserVerification,
  isShipmentTrackingUserVerification,
  isLocaleEditor,
  isOverstockppcUser,
  isSuperUser,
  isMapProcessor
} from "../../utils/Helper";

import { FACard } from "../common/dashboard/FACard";

const Dashboard = () => {
  console.info("Dashboard Arrived")
  const navigate = useNavigate();

  return (
    <section className="Country-info">
      <h1 className="dash text-center m-3">DASHBOARD</h1>
      <div className="container dashboard d-block">
        <div className="row">

          <FACard title="Shipping Cost" path="/fedex-cost" icon="fas fa-dollar-sign" />
          <FACard title="Notifications Subscription" path="/subscription-notifications" icon="fas fa-bell" />
          <FACard title="Profile" path="/user-profile" icon="fas fa-user-tie" />

          {isFreightClubUser() && (<FACard title="Freight Club" path="/freight-club/reports" icon="fas fa-shipping-fast" />)}

          {isSuperUser() && (<FACard title="Seller Central Store" path="/rbmws/home" icon="fas fa-store" />)}

          {isAmazonVerificationUser() && (
            <div className="col-md-3 mb-3">
              <div className="card w-100" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Amazon Verifications</h5>
                  <img src="/amazon_verify.svg" className="amazon_verification" alt="amazon" />
                  <h6
                    onClick={() => navigate("/amazon-content-verification")}
                    className="custom-btn"
                  >
                    Go
                  </h6>
                </div>
              </div>
            </div>
          )}

          {isAmazonBuyboxUserVerification() && (<FACard title="Amazon Buybox Tracker" path="/amazon-buybox-tracker" icon="fas fa-box" />)}

          {isSuperUser() && (<FACard title="Catalogs" path="/catalogs" icon="fas fa-solid fa-clipboard-list" />)}

          {isShipmentTrackingUserVerification() && (<FACard title="Shipment tracking" path="/shipping/tracking" icon="fas fa-truck" />)}

          <FACard title="Images" path="/images" icon="fas fa-solid fa-image" />

          {isLocaleEditor() && (<FACard title="Locales" path="/locales" icon="fas fa-globe-americas" />)}
          {isOverstockppcUser() && (<FACard title="Overstock PPC" path="/overstock-ppc" icon="fas fa-solid fa-broadcast-tower" />)}


          <FACard title="Inventory" path="/inventory" icon="fas fa-solid fa-image" />
          {isSuperUser() && <FACard title="HM Customers" path="/customer" icon="fas fa-users-cog" />}
          {isSuperUser() && <FACard title="Shopify Store Configurations" path="/shopify-store" icon="fas fa-store" />}

          {isMapProcessor() && <FACard title="MAP Violations" path="/map-violations" icon="fas fa-tags" />}


        </div>
      </div>
    </section>
  );
};

export default Dashboard;