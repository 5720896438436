import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { freightClubService } from "../../service";


export const getFreightClubOrderReportsAction = (loading: any) => (dispatch: any) => {
    if (loading) {
        dispatch(startLoadingAction());
    }
    freightClubService.getOrdersReportList().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "FREIGHT_CLUB_ALL_REPORTS",
            payload: response.data
        })
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "FREIGHT_CLUB_ALL_REPORTS_ERROR",
            status: err.response.status
        })
    })
};


export const getFreightClubOrderReportXLSAction = (reportId: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    freightClubService.getOrdersReportXls(reportId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', reportId + '.xlsx');
        document.body.appendChild(link);
        link.click();
        dispatch(stopLoadingAction());
    }).catch(err => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "DOWNLOAD_FREIGHTCLUB_REPORT_XLSX_ERROR",
            status: err.response.status
        })
    });

};


export const getCarrierExclusionsAction = (loading_status: any) => (dispatch: any) => {
    if (loading_status) {
        dispatch(startLoadingAction());
    }
    freightClubService.getCarrierExclusions().then(response => {
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_ALL_CARRIEREXCLUSIONS",
            payload: response.data
        })
    }).catch(err => {
        console.error(err);
        dispatch(stopLoadingAction());
        dispatch({
            type: "GET_ALL_CARRIEREXCLUSIONS_ERROR",
        })
    })
}


export const addCarrierExclusionsAction = (carrierName: string) => (dispatch: any) => {
    dispatch(startLoadingAction());
    freightClubService.addCarrierExclusion(carrierName).then(response => {
        dispatch(stopLoadingAction());
        dispatch(getCarrierExclusionsAction(false))
        dispatch({
            type: "ADD_NEW_CARRIER"
        })
    }).catch(err => {
        console.error(err);
        dispatch(stopLoadingAction());
        dispatch({
            type: "ADD_NEW_CARRIER_ERROR"
        })
    })
}


export const deleteCarrierExclusionsAction = (carrierId: any) => (dispatch: any) => {
    dispatch(startLoadingAction());
    freightClubService.deleteCarrierExclusion(carrierId).then(response => {
        dispatch(stopLoadingAction());
        dispatch(getCarrierExclusionsAction(false))
        dispatch({
            type: "DELETE_CARRIER",
            payload: response.data
        })
    }).catch(err => {
        console.error(err);
        dispatch(stopLoadingAction());
        dispatch({
            type: "DELETE_CARRIER_ERROR"
        })
    });
}


export const freightClubClearCarrierExclusionsAddStatusAction = () => (dispatch: any) => {
    dispatch({
        type: "FREIGHT_CLUB_CARRIER_EXCLUSIONS_CLEAR_ADD_STATUS"
    });
}

export const freightClubClearCarrierExclusionsErrorAction = () => (dispatch: any) => {
    dispatch({
        type: "FREIGHT_CLUB_CARRIER_EXCLUSIONS_CLEAR_ERROR"
    });
}

export const freightClubClearCarrierExclusionsDeleteStatusAction = () => (dispatch: any) => {
    dispatch({
        type: "FREIGHT_CLUB_CARRIER_EXCLUSIONS_CLEAR_DELETE_STATUS"
    });
}
