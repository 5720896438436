// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_button{
    text-align: end;
}
.error_center{
   text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/resuablecomponent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;GACG,kBAAkB;AACrB","sourcesContent":[".header_button{\n    text-align: end;\n}\n.error_center{\n   text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
