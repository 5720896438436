export const get_config: any = () => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": [
        "GET",
        "POST",
        "PATCH",
        "PUT",
        "DELETE",
        "OPTIONS",
      ],
      "Authentication-Token": localStorage.getItem("token"),
    },
  };
  return config;
};

export const isLogin = () => {
  if (localStorage.getItem("token")) {
    return true;
  }

  return false;
};

export const isSuperUser = () => {
  if (localStorage.getItem("superuser") === "true") {
    return true;
  }
  return false;
};

export const getSetting = (data: any, name: any, defaultvalue: any) => {
  var matches = data.filter((s: any) => s[0] === name);
  return matches.length > 0 ? matches[matches.length - 1][1] : defaultvalue;
};

export const ClubSurchargeValidate = (n: any) => {
  var regexp = /^-{0,1}((\d*\.\d{2})|(\d+))$/;
  const result = regexp.test(n);
  return result;
};

export const checkDecimal = (n: any) => {
  var regexp = /^-{0,1}\d*\.{0,1}\d+$/;
  const result = regexp.test(n);
  return result;
};

export const checkValidEmail = (e: any) => {
  var regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = regexp.test(e);
  return result;
};

export const isFreightClubUser = () => {
  if (localStorage.getItem("freightclubuser") === "true") {
    return true;
  }
  return false;
};

export const isLocaleEditor = () => {
  if (
    localStorage.getItem("localeeditor") === "true" ||
    localStorage.getItem("superuser") === "true"
  ) {
    return true;
  }
  return false;
};

export const isOverstockppcUser = () => {
  if (
    localStorage.getItem("overstockppcuser") === "true" ||
    localStorage.getItem("superuser") === "true"
  ) {
    return true;
  }
  return false;
};

export const isTradeshowUser = () => {
  if (
    localStorage.getItem("tradeshowuser") === "true" ||
    localStorage.getItem("superuser") === "true"
  ) {
    return true;
  }
  return false;
};

export const isMapProcessor = () => {
  if (
    localStorage.getItem("mapprocessor") === "true" ||
    localStorage.getItem("superuser") === "true"
  ) {
    return true;
  }
  return false;
};

export const isAmazonVerificationUser = () => {
  if (localStorage.getItem("amazonverificationuser") === "true") {
    return true;
  }
  return false;
};

export const isAmazonBuyboxUserVerification = () => {
  if (
    localStorage.getItem("amazonbuyboxhistoryuser") === "true" ||
    localStorage.getItem("superuser") === "true"
  ) {
    return true;
  }
  return false;
};

export const isShipmentTrackingUserVerification = () => {
  if (
    localStorage.getItem("shipmenttrackinguser") === "true" ||
    localStorage.getItem("superuser") === "true"
  ) {
    return true;
  }
  return false;
};

export const dateTimeFormater = (data: any) => {
  const date = data.split("T");
  const time = date[1].split(".")[0];
  return date[0] + " : " + time;
};

export const changeLetterFormate = (data: any) => {
  var str = data.split("_");
  var str1 = str.join(" ");
  return str1.charAt(0).toUpperCase() + str1.slice(1);
};

export const removeItemFromStateArray = (data: any, id: any) => {
  if (data) {
    const index: any = data.findIndex((obj: any) => obj["id"] === id);
    data.splice(index, 1);
    return data;
  }
};

export const daysAdd = (startingDate: Date, number: number) => {
  return new Date(new Date().setDate(startingDate.getDate() + number));
};

export const pad = (n: number, width: number, z?: string) => {
  z = z || "0";
  const value = n + "";
  return value.length >= width
    ? n
    : new Array(width - value.length + 1).join(z) + n;
};

export const dateFormat = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${pad(month, 2)}-${pad(day, 2)}`;
};

export const stringToBinary = (input: any) => {
  return encodeURIComponent(input);
};

export const lastDateOfMonth = (myDate: Date) => {
  let date = new Date(myDate);
  date.setUTCDate(1); // Avoids edge cases on the 31st day of some months
  date.setUTCMonth(date.getUTCMonth() + 1);
  date.setUTCDate(0);
  /*
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  */
  return date;
};

export const monthsAdd = (startingDate: Date, number: number) => {
  let date = new Date(startingDate);
  return new Date(date.setUTCMonth(date.getUTCMonth() + number));
};
