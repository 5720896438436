

import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";

export class WayfairServiceImpl {

    wayfairApiBaseUrl = `${config.baseUrl}api/wayfair`;

    getAllSuppliers() {
        return axios.get(`${this.wayfairApiBaseUrl}/suppliers`, get_config());
    }

    getAllFixedInventory() {
        return axios.get(`${this.wayfairApiBaseUrl}/fixed-inventory`, get_config());
    }

    createOrUpdateSupplier(data: any) {
        return axios.post(`${this.wayfairApiBaseUrl}/suppliers`, data, get_config());
    }

    createOrUpdateFixedInventory(data: any) {
        return axios.post(`${this.wayfairApiBaseUrl}/fixed-inventory`, data, get_config());
    }

    getSupplier(id: string) {
        return axios.get(`${this.wayfairApiBaseUrl}/suppliers?id=${id}`, get_config());
    }

    getFixedInventory(id: string) {
        return axios.get(`${this.wayfairApiBaseUrl}/fixed-inventory?id=${id}`, get_config());
    }

    getFixedInventoryBySupplierPartNumber(wayfair_supplier_part_number: string) {
        return axios.get(`${this.wayfairApiBaseUrl}/fixed-inventory?wayfair_supplier_part_number=${wayfair_supplier_part_number}`, get_config());
    }

    deleteSuppliers(supplier_ids: string[]) {
        const currentConfig = get_config();
        let cfg = {
            ...currentConfig,
            data: { supplier_ids: supplier_ids }
        }
        return axios.delete(`${this.wayfairApiBaseUrl}/suppliers`, cfg);
    }

    deleteFixedInventoryRecords(fixed_inventory_ids: string[]) {
        const currentConfig = get_config();
        let cfg = {
            ...currentConfig,
            data: { fixed_inventory_ids: fixed_inventory_ids }
        }
        return axios.delete(`${this.wayfairApiBaseUrl}/fixed-inventory`, cfg);
    }

    getAllSupplierWarehouses() {
        return axios.get(`${this.wayfairApiBaseUrl}/warehouses`, get_config());
    }

    getSupplierWarehouses(wayfair_supplier_id: string) {
        return axios.get(`${this.wayfairApiBaseUrl}/warehouses?wayfair_supplier_id=${wayfair_supplier_id}`, get_config());
    }

    createOrUpdateWarehouse(data: any) {
        return axios.post(`${this.wayfairApiBaseUrl}/warehouses`, data, get_config());
    }

    getWarehouse(id: string) {
        return axios.get(`${this.wayfairApiBaseUrl}/warehouses?id=${id}`, get_config());
    }

    getWarehouseTypes() {
        return axios.get(`${this.wayfairApiBaseUrl}/warehouseTypes`, get_config());
    }

    deleteWarehouses(warehouse_ids: string[]) {
        const currentConfig = get_config();
        let cfg = {
            ...currentConfig,
            data: { warehouse_ids: warehouse_ids }
        }
        return axios.delete(`${this.wayfairApiBaseUrl}/warehouses`, cfg);
    }

    uploadInventoryExport(wayfairSupplierId: string, inventoryFile: File) {

        const url = `${this.wayfairApiBaseUrl}/upload-inventory-export`;
        const formData = new FormData();

        formData.append("wayfair_supplier_id", wayfairSupplierId);
        formData.append("inventory_export", inventoryFile);

        return axios.post(
            url,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authentication-Token': localStorage.getItem("token")
                },
            }
        );

    }

    getUnresolvedItems(offset: number, limit: number) {
        return axios.get(`${this.wayfairApiBaseUrl}/unresolved-items?limit=${limit}&offset=${offset}&ordering=wayfair_supplier_part_number`, get_config());
    }

    getFabricXref(supplierpartnumber: string) {
        return axios.get(`${this.wayfairApiBaseUrl}/fabric-xref?supplierpartnumber=${supplierpartnumber}`, get_config());
    }

    createOrUpdateFabricXref(data: any) {
        return axios.post(`${this.wayfairApiBaseUrl}/fabric-xref`, data, get_config());
    }

    getUnresolvedItemsXlsx() {
        const currentConfig = get_config();
        let cfg = {
            ...currentConfig,
            responseType: "arraybuffer"
        }

        return axios.get(`${this.wayfairApiBaseUrl}/unresolved-items.xlsx`, cfg);
    }

    importMappings(excelFile: File) {
        const url = `${this.wayfairApiBaseUrl}/import-mappings`;
        const formData = new FormData();

        formData.append("file", excelFile);
        return axios.post(
            url,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authentication-Token': localStorage.getItem("token")
                },
            }
        );
    }

    deleteFabricMappings(fabric_xref_ids: string[]) {
        const currentConfig = get_config();
        let cfg = {
            ...currentConfig,
            data: { fabric_xref_ids: fabric_xref_ids }
        }
        return axios.delete(`${this.wayfairApiBaseUrl}/fabric-xref`, cfg);
    }


    getWayfairOrderErrors(wayfairSandbox: boolean) {
        const sandboxEnc = wayfairSandbox ? 't' : 'f'
        return axios.get(`${this.wayfairApiBaseUrl}/order-errors?ordering=-created_at&wayfair_sandbox=${sandboxEnc}`, get_config());
    }

    getAllWayfairInventoryPerWarehouse(searchTerm = '', warehouseId = '', offset = 0, limit = 10) {
        if (searchTerm !== '') {
            return axios.get(`${this.wayfairApiBaseUrl}/warehouse-staged-inventory-per-warehouse?ordering=wayfair_supplier_part_number&wayfair_supplier_part_number=${searchTerm}&warehouse_id=${warehouseId}&offset=${offset}&limit=${limit}`, get_config());
        }
        else {
            return axios.get(`${this.wayfairApiBaseUrl}/warehouse-staged-inventory-per-warehouse?ordering=wayfair_supplier_part_number&warehouse_id=${warehouseId}&offset=${offset}&limit=${limit}`, get_config());

        }
    }

    getAllWarehouses() {
        return axios.get(`${this.wayfairApiBaseUrl}/all-warehouse`, get_config());
    }

    getAllExcludedProductsPerWarehouse(warehouseId = '', excluded = true) {
        return axios.get(`${this.wayfairApiBaseUrl}/warehouse-product-exclusions?wayfair_warehouse_id=${warehouseId}&excluded=${excluded}`, get_config());
    }

    excludeProducts(warehouseId: string, partNumbers: string[]) {
        return axios.post(
            `${this.wayfairApiBaseUrl}/warehouse-product-exclusions/bulk-update`,
            {
                warehouse_id: warehouseId,
                add_excluded_supplier_part_numbers: partNumbers
            },
            get_config()
        );
    }

    removeExclusions(warehouseId: string, partNumbers: string[]) {
        return axios.post(
            `${this.wayfairApiBaseUrl}/warehouse-product-exclusions/bulk-update`,
            {
                warehouse_id: warehouseId,
                remove_excluded_supplier_part_numbers: partNumbers
            },
            get_config()
        );
    }


    getWayfairOrderItemTrackingErrors(wayfairSandbox: boolean) {
        const sandboxEnc = wayfairSandbox ? 't' : 'f'
        return axios.get(`${this.wayfairApiBaseUrl}/tracking-errors?ordering=-created_at&wayfair_sandbox=${sandboxEnc}`, get_config());
    }

    getWayfairOrderItemTrackingPending(wayfairSandbox: boolean, offset: number, limit: number) {
        const sandboxEnc = wayfairSandbox ? 't' : 'f';
        const params = `pro_no_isnull=t&master_tracking_number_isnull=t&limit=${limit}&offset=${offset}&ordering=-created_at&wayfair_sandbox=${sandboxEnc}`;
        return axios.get(`${this.wayfairApiBaseUrl}/order-item-tracking?${params}`, get_config());
    }

    getWayfairOrderSkuExceptionMap(wayfairCustomerPo: string, wayfairSupplierPartNumber: string) {
        const query = `wayfair_customer_po=${wayfairCustomerPo}&wayfair_supplier_part_number=${wayfairSupplierPartNumber}`;
        const url = `${this.wayfairApiBaseUrl}/sku-exception-maps?${query}`;
        return axios.get(url, get_config());
    }

    createOrUpdateWayfairOrderSkuExceptionMap(data: any) {
        return axios.post(`${this.wayfairApiBaseUrl}/sku-exception-maps`, data, get_config());
    }

};
