import { startLoadingAction, stopLoadingAction } from "./loaderActions";
import { fedexShippingCostService } from "../../service";


export const fedexDebugShippingAction = (country_code: any, product_sku: any) => (dispatch: any) => {
  dispatch(startLoadingAction());
  fedexShippingCostService.debugShippingCost(product_sku, country_code).then(response => {
    dispatch(stopLoadingAction());
    dispatch({
      type: "FEDEX_DEBUG",
      payload: response.data
    });
  }).catch(err => {
    dispatch(stopLoadingAction());
    console.error("Error=%O", err.response)
    dispatch({
      type: "FEDEX_ERROR_DEBUG",
      payload: { data: err.response.data, status: err.response.status }
    });
  });
};
