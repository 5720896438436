import Alert from "react-bootstrap/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import React from "react";

import { AppDispatch } from "../../../../redux/Store";
import { ficService } from "../../../../service";
import { getAllCountriesAction } from "../../../../redux/actions/ficActions";
import PageHeader from "../../../shared/pagesHeader";
import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../../redux/actions/loaderActions";

import { getCountryData } from 'countries-list'

const FicCountryList = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [countryMap, setCountryMap] = useState(new Map());

  const navigate = useNavigate();

  const ficState = useSelector((state: any) => state.ficReducer);

  useEffect(() => {
    const preloadCountryData = () => {
      const countryRecords = ficState.countries && ficState.countries.records;
      if (countryRecords) {
        const map = new Map();
        for (const country of countryRecords) {
          const countryData = getCountryData(country.country_code);
          if (countryData) {
            map.set(country.country_code, countryData);
          }
        }
        setCountryMap(map);
      }
    };
    preloadCountryData();
  }, [ficState.countries]);

  const handleAddCountryClick = () => {
    navigate("/admin/fic/country/create");
  };

  const handleDeleteCountryClick = (id: string) => (event: any) => {
    if (window.confirm("Are you sure you want to delete this country?")) {
      dispatch(startLoadingAction());
      ficService
        .deleteCountries([id])
        .then((response) => {
          dispatch(getAllCountriesAction());
        })
        .catch((response) => {
          dispatch(stopLoadingAction());
          setErrorMessage(response.message);
        });
    }
  };

  useEffect(() => {
    console.info("Fic Country List Arrived");
    dispatch(getAllCountriesAction());
  }, [dispatch]);

  console.log("fic", ficState);

  return (
    <>
      <div className="fic-admin container">
        <PageHeader
          heading={"FIC Country Administration"}
          backUrl={"/admin"}
          buttonName={"Add Country"}
          buttonIcon={"fa-plus"}
          handleClick={handleAddCountryClick}
        />

        {errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}

        <div className="form-align">
          <h2>Countries</h2>
          {ficState.countries && (
            <>
              {ficState.countries.total > 0 ? (
                <>
                  {ficState.countries.records
                  .slice()
                  .sort((a: any, b: any) => 
                    countryMap.get(a.country_code)?.name
                    .localeCompare(
                    countryMap.get(b.country_code)?.name
                    )
                  )
                  .map((country: any) => {
                    return (
                      <div className="row" key={country.id}>
                        <div className="col-md-12">
                          <button
                            className="btn btn-link"
                            onClick={handleDeleteCountryClick(country.id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <button
                            className="btn btn-link"
                            onClick={() =>
                              navigate(`/admin/fic/country/${country.id}`)
                            }
                          >
                            {`${getCountryData(country.country_code).name} [${country.country_code}]`}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>No countries found</p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FicCountryList;
