import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import './admin.css'
import { adminSettingsResetStatusAction, getAllAdminSettingsAction, postAdminSettingsAction } from "../../../redux/actions/adminSettingsActions";
import { useDispatch, useSelector } from "react-redux";
import { getSetting, ClubSurchargeValidate, checkDecimal } from "../../../utils/Helper";
import {Audio} from "react-loader-spinner";
import PageHeader from "../../shared/pagesHeader";
import { AppDispatch } from "../../../redux/Store";



const Settings = () => {

    const [freightClubMarkupInput, setFreightClubMarkupInput] = useState('')
    const [freightClubSurchargeInput, setFreightClubSurchargeInput] = useState('')
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const dispatch : AppDispatch = useDispatch();
    
    const adminSettingsState = useSelector((state: any) => state.adminSettingReducer);

    const warning = (msg: any) =>
        toast.warning(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const markupinputChange = (e: any) => {
        setFreightClubMarkupInput(e.target.value)
    }
    const surchargeInputChange = (e: any) => {
        setFreightClubSurchargeInput(e.target.value)
    }

    const handleClick = async () => {

        dispatch(adminSettingsResetStatusAction());

        if (!checkDecimal(freightClubMarkupInput)) {
            warning("Please enter valid Markup input")

        } else if (!ClubSurchargeValidate(freightClubSurchargeInput)) {
            warning("Please enter valid Surcharge input")
        }
        else {
            setLoading(true)
            dispatch(postAdminSettingsAction(freightClubMarkupInput, freightClubSurchargeInput))
        }
    }

    useEffect(() => {
        console.info("Settings Arrived");
        dispatch(getAllAdminSettingsAction())
    }, [dispatch])

    useEffect(() => {
        const markupSetting = getSetting(adminSettingsState.settings, "hm.freight.club.markup", "0")
        setFreightClubMarkupInput(markupSetting)
        const SurchargeSetting = getSetting(adminSettingsState.settings, "hm.freight.club.surcharge", "0.0")
        setFreightClubSurchargeInput(SurchargeSetting)
        if (adminSettingsState.status === true) {
            setLoading(false)
        }
    }, [adminSettingsState])

    return (
        <>
            <div className="setting container">
                <PageHeader
                    heading={"All User List"}
                    backUrl={"/admin"}
                />

                <div className="container-fluid px-md-5 mt-5">
                    <fieldset>

                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="mb-3 form_field d-flex align-items-center">
                                    <label className="label_field me-4">Freight Club Markup</label>
                                    <input
                                        onChange={markupinputChange}
                                        value={freightClubMarkupInput}
                                        type="number"
                                        min="0.00"
                                        step="0.001"
                                        max="1.00"
                                        className="form-select custom-select1" id="disabledSelect" aria-describedby="emailHelp" placeholder="Freight club markup" />

                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="mb-3 form_field d-flex align-items-center">
                                    <label className="label_field me-4">Freight Club surcharge</label>
                                    <input
                                        onChange={surchargeInputChange}
                                        value={freightClubSurchargeInput}
                                        type="number"
                                        min="0.00"
                                        step="0.001"
                                        max="1.00"
                                        className="form-select custom-select1" id="disabledSelect" aria-describedby="emailHelp" placeholder="Freight club surcharge" />
                                </div>
                            </div>

                        </div>
                        <div className="row justify-content-center">

                            <div className="col-md-2">
                                <button
                                    onClick={handleClick}
                                    type="button"
                                    className="btn btn-primary custom-primary dashbord-btn"
                                >
                                    SAVE
                                </button>
                            </div>
                            <div className="col-md-2">
                                <button
                                    onClick={() => { navigate("/admin") }}
                                    type="button"
                                    className="btn btn-danger custom-primary dashbord-btn"
                                >
                                    CANCEL
                                </button>
                            </div>
                        </div>

                    </fieldset>
                </div>
            </div>

            <ToastContainer autoClose={3000} />
            {loading &&
                <div className="spinner">
            <Audio
            
            height={100}
            width={100}
            color="#00BFFF"
            ariaLabel="loading"          
        />
                </div>
            }

        </>
    )
};

export default Settings;