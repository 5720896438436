import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { AppDispatch } from "../../../../redux/Store";

import {
  startLoadingAction,
  stopLoadingAction,
} from "../../../../redux/actions/loaderActions";
import { getCountryAction } from "../../../../redux/actions/ficActions";
import { ficService } from "../../../../service";
import PageHeader from "../../../shared/pagesHeader";
import { useNavigate, useParams } from "react-router-dom";
import CountryForm from "../../../common/admin/fic/CountryForm";

const FicCountry = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  
  const params: any = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const ficState = useSelector((state: any) => state.ficReducer);

  const handleSubmit = (value: any) => {
    setErrorMessage("");
    dispatch(startLoadingAction());
    let country = {
      id: value.id,
      country_code: value.country_code,
      vat_required: value.vat_required === "on",
      eori_required: value.eori_required === "on",
      reg_required: value.reg_required === "on",
      tax_id_required: value.tax_id_required === "on",
      arn_required: value.arn_required === "on",
      ddp_required: value.ddp_required === "on",
    };

    ficService
      .createOrUpdateCountry(country)
      .then((response) => {
        dispatch(startLoadingAction());
        navigate("/admin/fic/country");
      })
      .catch((response) => {
        setErrorMessage(response.message);
        dispatch(stopLoadingAction());
      });
  };

  console.info(ficState);

  useEffect(() => {
    console.info("Fic Country Arrived");
    dispatch(getCountryAction(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      <div className="container">
        <PageHeader
          heading={"Fic Country"}
          backUrl={"/admin/fic/country"}
        />

        <CountryForm
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          country={ficState.currentCountry}
          disableCountrySelect={true}
        />
      </div>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default FicCountry;
