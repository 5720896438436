
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


import { AppDispatch } from "../../../../redux/Store";
import { wayfairService } from "../../../../service";
import { getAllFixedInventoryAction } from "../../../../redux/actions/wayfairActions";
import PageHeader from "../../../shared/pagesHeader";
import { startLoadingAction, stopLoadingAction } from "../../../../redux/actions/loaderActions";

import { WayfairAdminMenu } from "../../../common/admin/wayfair/WayfairAdminMenu"

const WayfairFixedInventory = (props: any) => {

    const dispatch: AppDispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    
    const navigate = useNavigate();

    const wayfairState = useSelector((state: any) => state.wayfairReducer);
    
    const handleAddFixedInventory = () => {
        navigate('/admin/wayfair/create-fixed-inventory');
    }

    const handleDeleteFixedInventoryClick = (id: string) => (event: any) => {

        if (window.confirm("Are you sure you want to delete this item?")) {
            dispatch(startLoadingAction());
            wayfairService.deleteFixedInventoryRecords([id]).then(response => {
                dispatch(getAllFixedInventoryAction());
            }).catch((response) => {
                dispatch(stopLoadingAction());
                setErrorMessage(response.message);
            });
        }

    }

    useEffect(() => {
        console.info("Wayfair Supplier List Arrived");
        dispatch(getAllFixedInventoryAction())
    }, [dispatch]);
    

    return (
        <>
            <div className="wayfair-admin container">

                <WayfairAdminMenu tab="fixed-inventory" />

                <PageHeader
                    heading={"Fixed Inventory"}
                    backUrl={"/admin"}
                    buttonName={"Add Fixed Inventory"}
                    buttonIcon={"fa-plus"}
                    handleClick={handleAddFixedInventory}
                />

                {errorMessage && (
                    <Alert variant={"danger"}>
                        {errorMessage}
                    </Alert>
                )}

                <div className="form-align">

                    
                    {wayfairState.fixedInventoryRecords && (
                        <>

                            {
                                wayfairState.fixedInventoryRecords.total > 0 ? (
                                    <>

                                        {wayfairState.fixedInventoryRecords.records.map((fixedInventory: any) => {

                                            return (

                                                <div className="row" key={fixedInventory.id}>
                                                    <div className="col-md-12">
                                                        <button className="btn btn-link" onClick={handleDeleteFixedInventoryClick(fixedInventory.id)}><i className="fa fa-trash"></i></button>
                                                        <button className="btn btn-link" onClick={() => navigate(`/admin/wayfair/fixed-inventory/${fixedInventory.id}`)}>{fixedInventory.wayfair_supplier_part_number}</button>
                                                    </div>
                                                </div>)
                                        })}

                                    </>
                                ) : (
                                    <p>No products found</p>
                                )
                            }

                        </>


                    )}

                </div>

            </div>


        </>)
};

export default WayfairFixedInventory;