const initialState = {
    freight_club_reports: [],
    status: false,
    error_code: null,
    error_message: null,
    error: false,
    carrierExclusions: {
        carrierExclusionList: [],
        status: false,
        addStatus: false,
        error: false,
        deleteStatus: false
    }
}

const freightClubReducer = (state = initialState, action: any) => {    
    switch (action.type) {
        case "FREIGHT_CLUB_ALL_REPORTS":
            return {
                ...state,
                freight_club_reports: action.payload.reports,
                status: true
            };
        case "FREIGHT_CLUB_ALL_REPORTS_ERROR":
            return {
                ...state,
                error: true,
                status: false,
                error_code: 500,
                error_message: "An unexpected error has occurred"
            }
        case "GET_ALL_CARRIEREXCLUSIONS":
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    carrierExclusionList: action.payload.carrier_exclusions,
                    status: true
                }
            };
        case "GET_ALL_CARRIEREXCLUSIONS_ERROR":
            return  {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    status: false,
                    error: true
                }
            };
        case "FREIGHT_CLUB_CARRIER_EXCLUSIONS_CLEAR_ADD_STATUS":
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    addStatus: false
                }
            };
        case "FREIGHT_CLUB_CARRIER_EXCLUSIONS_CLEAR_ERROR":
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    error: false
                }
            };
        case "FREIGHT_CLUB_CARRIER_EXCLUSIONS_CLEAR_DELETE_STATUS":            
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    deleteStatus: false
                }
            };
        case "ADD_NEW_CARRIER":
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    addStatus: true
                }
            };
        case "ADD_NEW_CARRIER_ERROR":
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    addStatus: false,
                    error: true
                }
            }
        case "DELETE_CARRIER":
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    addStatus: false,
                    error: false,
                    deleteStatus: true
                }
            }
        case "DELETE_CARRIER_ERROR":
            return {
                ...state,
                carrierExclusions: {
                    ...(state.carrierExclusions),
                    addStatus: false,
                    error: true
                }
            };
        default:
            return state;
    }
}

export default freightClubReducer;

