

const Footer = () => {

    const currentYear = (new Date()).getFullYear();

    return (
        <footer className="page-footer font-small blue">
            <div className="footer-copyright text-center py-3">© 2021-{currentYear} Howard Miller - ALL RIGHTS RESERVED</div>
        </footer>
    )
}

export default Footer;