import { get_config } from "../utils/Helper"
import axios from "axios"
import config from "../constants/config";


export class HmProductImagesServiceImpl {

    apiUrl = `${config.baseUrl}api/product-images`;

    getAmazonImages(offset: number,limit: number, date: string) {
        return axios.get(`${this.apiUrl}/amazon-images?offset=${offset}&limit=${limit}&ordering=-updated_at&updated_at_gte=${date}`, get_config());
    }

}
