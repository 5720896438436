

import { useEffect, useState } from "react";

import PageHeader from "../../shared/pagesHeader";

import { AppDispatch } from "../../../redux/Store";

import { inventoryHoldsService } from "../../../service";
import { startLoadingAction, stopLoadingAction } from "../../../redux/actions/loaderActions";

import { getReportRequestsAction } from "../../../redux/actions/inventoryHoldsActions";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InventoryHoldsModal from "./InentoryHoldsModal";
import WayfairForecastModal from "./WayfairForecastModal";
import OverstockForecastModal from "./OverstockForecastModal";

const InventoryHolds = () => {

    const dispatch: AppDispatch = useDispatch();

    const [uploadFormErrorMessage, setUploadFormErrorMessage] = useState(null as string | null);
    const [uploadFormWarningMessages, setUploadFormWarningMessages] = useState([]);
    const [showUploadHoldRequestsForm, setShowUploadHoldRequestsForm] = useState(false);
    const [showUploadWayfairForecastForm, setShowUploadWayfairForecastForm] = useState(false);
    const [showUploadOverstockForecastForm, setShowUploadOverstockForecastForm] = useState(false);
    const [uploadFormStartDate, setUploadFormStartDate] = useState('');
    const [uploadFormEndDate, setUploadFormEndDate] = useState('');
    const [uploadFormSkuPrefix, setUploadFormSkuPrefix] = useState('HMCC-');
    const [uploadFormStatus, setUploadFormStatus] = useState(null as string | null);
    const [uploadFormRequestId, setUploadFormRequestId] = useState(null as string | null);
    const [uploadFormReportFile, setUploadFormReportFile] = useState(null as File | null);
    const inventoryHoldsState = useSelector((state: any) => state.inventoryHoldsReducer);


    useEffect(() => {
        console.log("Handle holds report request", uploadFormRequestId);

        const pollStatus = () => {
            console.log("pollStatus", uploadFormRequestId);
            if (uploadFormRequestId !== null) {
                console.log("Status request sent");
                inventoryHoldsService.getReportStatus(uploadFormRequestId).then(response => {

                    setUploadFormStatus(response.data.status);
                    if (response.data.status === "COMPLETE") {

                        setUploadFormStatus(null);
                        setUploadFormRequestId(null);
                        setShowUploadHoldRequestsForm(false);
                        dispatch(getReportRequestsAction(true));
                    } else {
                        setTimeout(() => { pollStatus(); }, 15000);
                    }

                });
            }

        }

        pollStatus();

    }, [dispatch, uploadFormRequestId, uploadFormStatus]);


    useEffect(() => {
        console.info("Inventory Holds Report Requests Arrived")
        if (inventoryHoldsState.report_requests === null) {
            dispatch(getReportRequestsAction(true));
        }
    }, [dispatch, inventoryHoldsState.report_requests]);



    const onProcessHoldsClicked = () => () => {

        if (!showUploadHoldRequestsForm) {
            setShowUploadHoldRequestsForm(true);
        }
    }

    const onProcessWayfairForecastClicked = () => () => {

        if (!showUploadWayfairForecastForm) {
            setShowUploadWayfairForecastForm(true);
        }
    }

    const onProcessOverstockForecastClicked = () => () => {

        if (!showUploadOverstockForecastForm) {
            setShowUploadOverstockForecastForm(true);
        }
    }    

    const success = (msg: String) =>
        toast.success(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
        });

    const resetUploadFormErrorMessages = () => {
        setUploadFormErrorMessage(null);
        setUploadFormWarningMessages([]);
    }


    const onUploadProcessHoldsClicked = (e: any) => {

        if (uploadFormStartDate === '') {
            setUploadFormErrorMessage("Start date is required");
            return;
        }

        if (uploadFormEndDate === '') {
            setUploadFormErrorMessage("End date is required");
            return;
        }

        if (uploadFormReportFile === null) {
            setUploadFormErrorMessage("Hold requests file is required");
            return;
        }

        resetUploadFormErrorMessages();
        dispatch(startLoadingAction());

        setUploadFormStatus(null);

        inventoryHoldsService.createReport(
            "retailbloom-buys", 
            uploadFormStartDate,             
            uploadFormEndDate, 
            uploadFormSkuPrefix, 
            uploadFormReportFile).then(
            response => {

                dispatch(stopLoadingAction());
                success("Upload complete!");
                setUploadFormStatus(response.data.status);
                setUploadFormRequestId(response.data.request_id);
                dispatch(getReportRequestsAction(false));

            }
        ).catch(error => {
            dispatch(stopLoadingAction());

            if ('undefined' !== typeof error.response.data.error_message) {
                setUploadFormErrorMessage(error.response.data.error_message);
                if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
                    setUploadFormWarningMessages(error.response.data.warning_messages);
                    console.error("warnings", error.response.data.warning_messages);
                }
            } else {
                setUploadFormErrorMessage(error.message);
            }

        })
    };


    const onUploadProcessWayfairClicked = (e: any) => {

        if (uploadFormStartDate === '') {
            setUploadFormErrorMessage("Start date is required");
            return;
        }

        if (uploadFormEndDate === '') {
            setUploadFormErrorMessage("End date is required");
            return;
        }

        if (uploadFormReportFile === null) {
            setUploadFormErrorMessage("Hold requests file is required");
            return;
        }

        resetUploadFormErrorMessages();
        dispatch(startLoadingAction());

        setUploadFormStatus(null);

        inventoryHoldsService.createReport(
            "wayfair-forecast", 
            uploadFormStartDate, 
            uploadFormEndDate, "", 
            uploadFormReportFile).then(
            response => {

                dispatch(stopLoadingAction());
                success("Upload complete!");
                setUploadFormStatus(response.data.status);
                setUploadFormRequestId(response.data.request_id);
                dispatch(getReportRequestsAction(false));

            }
        ).catch(error => {
            dispatch(stopLoadingAction());

            if ('undefined' !== typeof error.response.data.error_message) {
                setUploadFormErrorMessage(error.response.data.error_message);
                if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
                    setUploadFormWarningMessages(error.response.data.warning_messages);
                    console.error("warnings", error.response.data.warning_messages);
                }
            } else {
                setUploadFormErrorMessage(error.message);
            }

        })
    };



    const onUploadProcessOverstockClicked = (e: any) => {

        if (uploadFormStartDate === '') {
            setUploadFormErrorMessage("Start date is required");
            return;
        }

        if (uploadFormEndDate === '') {
            setUploadFormErrorMessage("End date is required");
            return;
        }

        if (uploadFormReportFile === null) {
            setUploadFormErrorMessage("Hold requests file is required");
            return;
        }

        resetUploadFormErrorMessages();
        dispatch(startLoadingAction());

        setUploadFormStatus(null);

        inventoryHoldsService.createReport(
            "overstock-forecast",
            uploadFormStartDate, 
            uploadFormEndDate, "", 
            uploadFormReportFile).then(
            response => {

                dispatch(stopLoadingAction());
                success("Upload complete!");
                setUploadFormStatus(response.data.status);
                setUploadFormRequestId(response.data.request_id);
                dispatch(getReportRequestsAction(false));

            }
        ).catch(error => {
            dispatch(stopLoadingAction());

            if ('undefined' !== typeof error.response.data.error_message) {
                setUploadFormErrorMessage(error.response.data.error_message);
                if (error.response.data.error_code === "UNEXPECTED_HEADERS") {
                    setUploadFormWarningMessages(error.response.data.warning_messages);
                    console.error("warnings", error.response.data.warning_messages);
                }
            } else {
                setUploadFormErrorMessage(error.message);
            }

        })
    };    


    const downloadResponse = (report_id: string) => () => {

        console.log("Download", report_id);

        dispatch(startLoadingAction());
        inventoryHoldsService.getReportResult(report_id).then(
            response => {

                console.log("response", response.headers);

                dispatch(stopLoadingAction());

                let filename = response.headers['content-disposition'].split('filename=')[1];

                var file_data = new Blob([response.data], { type: 'application/octet-stream' });

                const url = window.URL.createObjectURL(
                    file_data
                );
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        ).catch(error => {
            dispatch(stopLoadingAction());
            console.error(error);
        })
    }


    return (
        <div className="container">

            <PageHeader
                heading={"Inventory Holds"}
                backUrl={"/dashboard"}

            />
            <hr />

            <div className="text-right hm-button-group">
                <div>
                    <button onClick={onProcessHoldsClicked()} className="add_new save_btn rounded-pill">
                        <i className={`fas fa-upload me-2`}></i> Process Holds
                    </button>
                </div>
                <div>
                    <button onClick={onProcessWayfairForecastClicked()} className="add_new save_btn rounded-pill">
                        <i className={`fas fa-upload me-2`}></i> Process Wayfair Forecast
                    </button>
                </div>
                <div>
                    <button onClick={onProcessOverstockForecastClicked()} className="add_new save_btn rounded-pill">
                        <i className={`fas fa-upload me-2`}></i> Process Overstock Forecast
                    </button>
                </div>                
            </div>

            {inventoryHoldsState.report_requests != null && (

                <table>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Updated</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventoryHoldsState.report_requests.map((row: any) => {
                            return (

                                <tr key={row.id}>
                                    {row.status === 'COMPLETE' && (
                                        <>
                                            <td><span onClick={downloadResponse(row.id)} className="btn btn-link">{row.created}</span></td>
                                            <td><span onClick={downloadResponse(row.id)} className="btn btn-link">{row.updated}</span></td>
                                            <td><span onClick={downloadResponse(row.id)} className="btn btn-link">{row.status}</span></td>
                                            <td><span onClick={downloadResponse(row.id)} className="btn btn-link"><i className="fa fa-download" aria-hidden="true"></i></span></td>
                                        </>
                                    )}

                                    {row.status !== 'COMPLETE' && (
                                        <>
                                            <td>{row.created}</td>
                                            <td>{row.updated}</td>
                                            <td>{row.status}</td>
                                            <td></td>
                                        </>
                                    )}

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}



            <InventoryHoldsModal
                show={showUploadHoldRequestsForm}
                setShow={setShowUploadHoldRequestsForm}
                errorMessage={uploadFormErrorMessage}
                setErrorMessage={setUploadFormErrorMessage}
                warningMessages={uploadFormWarningMessages}
                setWarningMessages={setUploadFormWarningMessages}
                formStatus={uploadFormStatus}
                uploadFormStartDate={uploadFormStartDate}
                setUploadFormStartDate={setUploadFormStartDate}
                uploadFormEndDate={uploadFormEndDate}
                setUploadFormEndDate={setUploadFormEndDate}
                uploadFormSkuPrefix={uploadFormSkuPrefix}
                setUploadFormSkuPrefix={setUploadFormSkuPrefix}
                onUploadProcessClicked={onUploadProcessHoldsClicked}
                setUploadFormReportFile={setUploadFormReportFile}
            />

            <WayfairForecastModal
                show={showUploadWayfairForecastForm}
                setShow={setShowUploadWayfairForecastForm}
                errorMessage={uploadFormErrorMessage}
                setErrorMessage={setUploadFormErrorMessage}
                warningMessages={uploadFormWarningMessages}
                setWarningMessages={setUploadFormWarningMessages}
                formStatus={uploadFormStatus}
                uploadFormStartDate={uploadFormStartDate}
                setUploadFormStartDate={setUploadFormStartDate}
                uploadFormEndDate={uploadFormEndDate}
                setUploadFormEndDate={setUploadFormEndDate}
                onUploadProcessClicked={onUploadProcessWayfairClicked}
                setUploadFormReportFile={setUploadFormReportFile}

            />

            <OverstockForecastModal
                show={showUploadOverstockForecastForm}
                setShow={setShowUploadOverstockForecastForm}
                errorMessage={uploadFormErrorMessage}
                setErrorMessage={setUploadFormErrorMessage}
                warningMessages={uploadFormWarningMessages}
                setWarningMessages={setUploadFormWarningMessages}
                formStatus={uploadFormStatus}
                uploadFormStartDate={uploadFormStartDate}
                setUploadFormStartDate={setUploadFormStartDate}
                uploadFormEndDate={uploadFormEndDate}
                setUploadFormEndDate={setUploadFormEndDate}
                onUploadProcessClicked={onUploadProcessOverstockClicked}
                setUploadFormReportFile={setUploadFormReportFile}

            />            



        </div>
    )
}


export default InventoryHolds;