import { removeItemFromStateArray } from "../../utils/Helper";

const initialState = {
    stores: null,
    users: [],
    storeDetails: [],
    status: false,
    statusCode: null,
    createdStatus: null,
    mesg: null,
    deleteStatus: false,
    createStatus: false,
    brandAdd: false,
    storeUpdate: false,
    logs: null,
    logStatus: false,
    zeroOut: {
        status: false,
        msg: null,
        statsCode: null
    },
    updateInventory: {
        status: false,
        msg: null,
        statsCode: null
    },
    updatePrice: {
        status: false,
        msg: null,
        statsCode: null
    }

}

const amazonSellerCentralReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "GET_ALL_SELLER_CENTRAL_STORES_DATA":
            return {
                ...state,
                stores: action.payload,
                status: true
            };
        case "GET_ALL_SELLER_CENTRAL_STORES_DATA_ERROR":
            return {
                ...state,
                statusCode: action.status
            };
        case "SET_SELLER_CENTRAL_USER_LIST":
            return {
                ...state,
                users: action.payload
            }
        case "GET_SELLER_CENTRAL_DATA":
            return {
                ...state,
                storeDetails: action.payload
            };
        case "GET_SELLER_CENTRAL_DATA_ERROR":
            return {
                ...state,
                statusCode: action.status
            };
        case "CREATE_SELLER_CENTRAL_STORE":

            return {
                ...state,
                createStatus: true,
                mesg: "Store data succesfully created...!!!",
                createdStatus: true,
                stores: null
            };
        case "CREATE_SELLER_CENTRAL_STORE_ERRORS":
            return {
                ...state,
                statusCode: action.payload.status,
                status: false,
                mesg: action.payload.data.result,
                createdStatus: false
            };
        case "DELETE_SELLER_CENTRAL_STORE":
            return {
                ...state,
                stores: removeItemFromStateArray(state.stores, action.payload),
                mesg: "Store succesfully deleted",
                deleteStatus: true
            };
        case "SET_SELLER_CENTRAL_DELETE_STATUS":
            return {
                ...state,
                deleteStatus: action.payload
            };
        case "DELETE_SELLER_CENTER_STORE_ERROR":
            return {
                ...state,
                statusCode: action.status,
                status: false
            };
        case "ADD_NEW_BRAND":
            return {
                ...state,
                brandAdd: true,
                mesg: "Brand succesfully add...!!!"
            };
        case "ADD_NEW_BRAND_ERROR":
            return {
                ...state,
            };
        case "UPDATE_SELLER_CENTRAL_STORE":
            return {
                ...state,
                storeUpdate: true,
                mesg: "Store Updated succesfully...!!!"
            };
        case "UPDATE_SELLER_CENTRAL_STORE_ERRORS":
            return {
                ...state,
                statusCode: 500,
                mesg: "Unexpected Server Error...!!!"
            };
        case "SELLER_CENTRAL_ZERO_OUT":
            return {
                ...state,
                zeroOut: {
                    status: true,
                    msg: "Successfully Zero Out",
                    statsCode: action.payload.status
                }
            }
        case "SELLER_CENTRAL_ZERO_OUT_ERROR":
            return {
                ...state,
                zeroOut: {
                    status: false,
                    msg: action.payload.data.result,
                    statsCode: action.payload.status
                }
            }
        case "SELLER_CENTREAL_UPDATE_INVENTORY":
            return {
                ...state,
                updateInventory: {
                    status: true,
                    msg: "Successfully Update Inventory",
                    statsCode: action.payload.status
                }
            }
        case "SELLER_CENTRAL_UPDATE_INVENTORY_ERROR":
            return {
                ...state,
                updateInventory: {
                    status: false,
                    msg: action.payload.data.result,
                    statsCode: action.payload.status
                }
            }
        case "SELLER_CENTRAL_STORE_UPDATE_PRICE":
            return {
                ...state,
                updatePrice: {
                    status: true,
                    msg: "Successfully Update Price",
                    statsCode: action.payload.status
                }
            }
        case "SELLER_CENTRAL_STORE_UPDATE_PRICE_ERROR":
            return {
                ...state,
                updatePrice: {
                    status: false,
                    msg: action.payload.data.result,
                    statsCode: action.payload.status
                }
            }
        case "GET_ALL_SELLER_LOGS":
            return {
                ...state,
                logStatus: true,
                logs: action.payload
            }

        case "GET_ALL_SELLER_LOGS_NOT_FOUND":
            return {
                ...state,
                logStatus: true,
                logs: action.payload
            }
        case "SC_CLEAR_BRAND_ADD":
            return {
                ...state,
                brandAdd: false
            }
        case "SC_CLEAR_STORE_UPDATE":
            return {
                ...state,
                storeUpdate: false
            }
        case "SC_CLEAR_STATUS_CODE":
            return {
                ...state,
                statusCode: null
            }
        case "SC_CLEAR_UPDATE_INVENTORY_STATUS_CODE":
            return {
                ...state,
                updateInventory: {
                    ...state.updateInventory,
                    statusCode: null
                }
            }
        case "SC_CLEAR_ZERO_OUT_STATUS_CODE":
            return {
                ...state,
                zeroOut: {
                    ...state.zeroOut,
                    statusCode: null
                }
            }
        case "SC_CLEAR_UPDATE_PRICE_STATUS_CODE":
            return {
                ...state,
                updatePrice: {
                    ...state.updatePrice,
                    statusCode: null
                }
            }
        default:
            return state
    }
}

export default amazonSellerCentralReducer;